import axios from 'axios';
import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { blankEvent, IEvent } from '../interfaces/IEvent';
import {
    AuthState,
    StoreState,
    UserProfileState
} from '../redux/actions/types';
import { ITag } from '../interfaces/ITag';
import EventCreateModal from '../components/events/EventCreateModal';
import EventCalendar from '../components/events/EventCalendar';
import { EventButton } from '../components/events/EventStyles';
import EventViewModal from '../components/events/EventViewModal';
import EventUpcoming from '../components/events/EventUpcoming';
import usePagination from '../components/events/usePagination';
import EventPopular from '../components/events/EventPopular';
import { useEventTracking } from './eventTracking';

const EventStyle = styled.div`
    padding: 0 4rem;
    padding-bottom: 3.75rem;

    @media only screen and (max-width: 600px) {
        padding: 0 2rem;
        padding-bottom: 2rem;
    }

    .p1 .p2 .p3 {
        font-weight: 400;
        color: rgba(81, 97, 124, 1);
    }

    .h1 {
        font-size: 3rem;
        line-height: 3.625rem;
        font-weight: 700;
        color: rgba(39, 50, 68, 1);
    }

    .h2 {
        font-size: 2rem;
        line-height: 2.375rem;
        font-weight: 700;
        color: rgba(39, 50, 68, 1);
    }

    .h3 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        color: rgba(39, 50, 68, 1);
    }

    .h4 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        color: rgba(81, 97, 124, 1);
    }

    .h5 {
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 500;
        color: rgba(81, 97, 124, 1);
    }

    .h6 {
        font-size: 1rem;
        line-height: 1.625rem;
        font-weight: 500;
        color: rgba(81, 97, 124, 1);
    }

    .p1 {
        font-size: 1.125rem;
        line-height: 1.875rem;
    }

    .p2 {
        font-size: 1.125rem;
        line-height: 1.625rem;
    }

    .p3 {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`;

const NotifModal = styled(Modal)`
    .modal-content {
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 50px 77px rgba(176, 183, 195, 0.22);
        border: none;
    }
`;

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

function Events({ ...props }: authProps): ReactElement {
    const {
        refresh: upcomingEventRefresh,
        data: upcomingEvents
    } = usePagination<IEvent>({
        route: 'api/events/filtered',
        sort: 'date',
        sortOrder: 'asc',
        limit: 5,
        page: 1,
        query: '&rejected=false&approved=true&participantLimit=3'
    });

    const {
        refresh: popularEventRefresh,
        data: popularEvents
    } = usePagination<IEvent>({
        route: 'api/events/filtered',
        sort: 'participantLength',
        sortOrder: 'desc',
        limit: 4,
        page: 1,
        query: '&rejected=false&approved=true&participantLimit=4'
    });

    const upcomingEventsObject = upcomingEvents.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
    }, {} as IEvent);

    const popularEventsObject = upcomingEvents.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
    }, {} as IEvent);

    const [selectedEvent, setSelectedEvent] = useState('');
    const [tags, setTags] = useState<ITag[]>([]);
    const [createEvent, setCreateEvent] = useState(false);
    const [viewEvent, setViewEvent] = useState(false);
    const [notifPending, setNotifPending] = useState(false);

    const getNewEvents = (eventId: string) => {
        if (!eventId) return;
        if (
            Object.prototype.hasOwnProperty.call(popularEventsObject, eventId)
        ) {
            popularEventRefresh();
        }
        if (
            Object.prototype.hasOwnProperty.call(upcomingEventsObject, eventId)
        ) {
            upcomingEventRefresh();
        }
    };

    const setUpdateEvents = (id: string) => {
        if (!props.userProfile.userProfile?.admin) {
            setNotifPending(true);
        } else {
            popularEventRefresh();
            upcomingEventRefresh();
        }
    };

    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile &&
            props.userProfile.userProfile.status !== 'Active'
        ) {
            props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    useEffect(() => {
        axios.get(`api/tags`).then(({ data }) => {
            setTags(data);
        });
    }, []);

    return (
        <EventStyle>
            <div className="d-flex flex-column" style={{ gap: '1rem' }}>
                <Row className="justify-content-between align-middle">
                    <Col xs="auto" className="flex align-items-middle">
                        <div className="h2 mb-0">Events</div>
                    </Col>
                    <Col className="mt-2" xs="auto">
                        {props.auth.isAuthenticated && (
                            <EventButton
                                onClick={() => {
                                    setCreateEvent(true);
                                    useEventTracking(
                                        'Events',
                                        'Clicked Create Event',
                                        `${props.auth.user.username}`
                                    );
                                }}
                            >
                                <img
                                    alt="share"
                                    src={`${process.env.PUBLIC_URL}/assets/interseed_plus.svg`}
                                    style={{
                                        height: '1.5rem',
                                        width: '1.5rem',
                                        color: 'white'
                                    }}
                                    className="d-inline-block mr-2"
                                />
                                Create Event
                            </EventButton>
                        )}
                    </Col>
                </Row>
                <Row style={{ gap: '2rem' }}>
                    <Card className="col-sm-12 col-md-12 col-xl-3 col-12 p-0">
                        <EventUpcoming
                            events={upcomingEvents}
                            setViewEvent={setViewEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                    </Card>
                    <Card
                        className="col p-0 d-none d-sm-block"
                        style={{ minHeight: '720px' }}
                    >
                        <EventCalendar
                            setViewEvent={setViewEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                    </Card>
                </Row>
                <Row>
                    <Card className="col m-0 p-0">
                        <EventPopular
                            events={popularEvents}
                            user={props.auth.user.username || 'Not Logged In'}
                            setViewEvent={setViewEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                    </Card>
                </Row>
            </div>
            <EventCreateModal
                setUpdateEvents={setUpdateEvents}
                show={createEvent}
                setShow={setCreateEvent}
                tags={tags}
                setTags={setTags}
                auth={props.auth}
            />
            <EventViewModal
                show={viewEvent}
                setShow={setViewEvent}
                eventId={selectedEvent}
                getNewEvents={getNewEvents}
                auth={props.auth}
                currentUser={props.userProfile.userProfile}
            />
            <NotifModal
                show={notifPending}
                onHide={() => {
                    setNotifPending(false);
                }}
                size="lg"
                centered
            >
                <Modal.Body
                    style={{
                        padding: '3rem'
                    }}
                >
                    <div className="d-flex justify-content-center">
                        <img
                            alt="share"
                            src={`${process.env.PUBLIC_URL}/assets/interseed_approval.svg`}
                            className="d-inline-block mr-2"
                        />
                    </div>
                    <div className="text-center h2 m-3 mb-4 font-weight-bold">
                        Your Event is Pending for approval
                    </div>
                    <div className="text-center h6 m-3 mb-4">
                        Upon approval, your event will appear on the events page
                    </div>
                    <div
                        className="d-flex"
                        style={{ gap: '1rem', justifyContent: 'center' }}
                    >
                        <EventButton
                            className="col-3"
                            onClick={() => {
                                setNotifPending(false);
                            }}
                        >
                            Done
                        </EventButton>
                    </div>
                </Modal.Body>
            </NotifModal>
        </EventStyle>
    );
}

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(Events));
