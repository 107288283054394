import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Image } from 'react-bootstrap';
import { Ecosystem } from '../../../interfaces/Ecosystem';

const Avatars = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* flex-direction: row-reverse; */
`;

const Avatar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    background-color: #bdbdbd;
    border: 2px solid #fff;
    border-radius: 50%;
    font-size: 1rem;
    height: 60px;
    width: 60px;
    overflow: hidden;
    text-align: center;

    :not(:first-child) {
        margin-left: -0.8rem;
    }

    img {
        margin-right: 0.5rem;
        object-fit: contain;
        text-align: center;
        aspect-ratio: 1;
    }
`;

const AvatarPlus = styled(Avatar)`
    span {
        width: 100%;
        text-align: center;
        font-width: 700;
    }
`;

type Props = Pick<Ecosystem, 'members'>;

const EcosystemAvatars: React.FC<Props> = ({ members }) => (
    <Avatars>
        {members.slice(0, 2).map((member) => (
            <Avatar key={member.profilePicture?.key}>
                <Image
                    src={
                        member.profilePicture
                            ? member.profilePicture.location
                            : `${process.env.PUBLIC_URL}/assets/profile.png`
                    }
                    alt={`Profile - ${member.username}`}
                    roundedCircle
                    thumbnail
                    fluid
                    onError={(e) => {
                        e.currentTarget.onerror = null; // prevents looping
                        e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                    }}
                />
            </Avatar>
        ))}
        {!!members.slice(2).length && (
            <AvatarPlus>
                <span>+{members.slice(2).length}</span>
            </AvatarPlus>
        )}
    </Avatars>
);

export default EcosystemAvatars;
