import React, { useState, useEffect } from 'react';
import {
    ListGroup,
    Container,
    Row,
    Col,
    Badge,
    FormCheck
} from 'react-bootstrap';
import styled from 'styled-components';

import IOrganisation from '../../interfaces/IOrganisation';
import { checkEmail } from '../../utils/commonFunction';

interface orgProp {
    organisation: IOrganisation;
    isMasterChecked: boolean;
    orgEmailList: string[];
    handleCheckbox: (email: string, type: string, isChecked: boolean) => void;
}

const Styles = styled.div`
    h2 {
        margin-bottom: 0.2rem;
    }

    .list-group-item {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .imgcontainer {
        overflow: hidden;
        position: relative;
        width: 100px;
        margin-right: 10px;

        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            object-fit: cover;
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    .badge {
        font-size: 16px;
    }
`;

const ListText = styled.p`
    margin-bottom: 0;
`;

const EmailOrganisationItem: React.FC<orgProp> = ({
    organisation,
    isMasterChecked,
    orgEmailList,
    handleCheckbox
}: orgProp) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(checkEmail(orgEmailList, organisation));
    }, [orgEmailList]);

    return (
        <>
            <Styles>
                <ListGroup.Item>
                    <Container>
                        <Row>
                            <span className="imgcontainer">
                                <img
                                    src={
                                        organisation.logo
                                            ? organisation.logo.location
                                            : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                                    }
                                    alt=""
                                    onError={(e) => {
                                        e.currentTarget.onerror = null; // prevents looping
                                        e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                    }}
                                />
                            </span>
                            <Col className="my-auto" xs>
                                <h2>{organisation.name}</h2>
                                <ListText className="text-muted">
                                    {organisation.country}
                                </ListText>
                                <ListText className="text-muted">
                                    {organisation.startupStage}
                                </ListText>
                                <p className="mt-2">
                                    {organisation.focusAreas
                                        .sort()
                                        .map((focus) => (
                                            <Badge variant="light">
                                                {focus}
                                            </Badge>
                                        ))}
                                </p>
                            </Col>
                            <Col className="my-auto" xs={2}>
                                <FormCheck
                                    type="checkbox"
                                    id="select-org"
                                    checked={isMasterChecked || isChecked}
                                    onChange={() => {
                                        handleCheckbox(
                                            organisation.email,
                                            'org',
                                            !isChecked
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ListGroup.Item>
            </Styles>
        </>
    );
};

export default EmailOrganisationItem;
