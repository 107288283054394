import React, { useState } from 'react';
import Joyride, {
    ACTIONS,
    CallBackProps,
    EVENTS,
    STATUS,
    Step
} from 'react-joyride';
import { useNavbarContext } from '../../contexts/navbar.ctx';
import useMediaQuery from '../../hooks/useMediaQuery';
import useScrollLock from '../../hooks/useScrollLock';
import CustomTooltip from './CustomTooltip';
import tourSteps from './steps';

const Walkthrough: React.FC = () => {
    const isMediumScreen = useMediaQuery('(max-width: 991px)');
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const isSmallOrMediumScreen = isMediumScreen || isSmallScreen;

    const { lockScroll, unlockScroll } = useScrollLock();
    const { setExpanded } = useNavbarContext();

    const onboarded = !!localStorage.getItem('userOnboarded');
    const [userOnboarded] = useState(onboarded);
    const [{ run, steps, stepIndex }, setState] = useState({
        run: !userOnboarded,
        stepIndex: 0,
        steps: tourSteps.map((s) => {
            if (!isSmallOrMediumScreen) {
                return s;
            }
            return {
                ...s,
                placement: 'top'
            } as Step;
        })
    });

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { action, type, index, status, step } = data;

        if (status === STATUS.RUNNING) {
            if (isSmallOrMediumScreen) {
                lockScroll();
            }
        } else {
            unlockScroll();
        }

        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
            setState({
                run: false,
                stepIndex: 0,
                steps: tourSteps.map((s) => {
                    if (!isSmallOrMediumScreen) {
                        return s;
                    }
                    return {
                        ...s,
                        placement: 'top'
                    } as Step;
                })
            });
            if (status === STATUS.FINISHED) {
                setExpanded(false);
                localStorage.setItem('userOnboarded', 'true');
            }
        } else if (
            ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(
                type
            )
        ) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            if (nextStepIndex > 2 && nextStepIndex < 9) {
                setExpanded(true);
            } else {
                setExpanded(false);
            }
            setState({
                run: true,
                stepIndex: nextStepIndex,
                steps: tourSteps.map((s) => {
                    if (!isSmallOrMediumScreen) {
                        return s;
                    }
                    return {
                        ...s,
                        placement: 'top'
                    } as Step;
                })
            });
        }
    };

    return (
        <Joyride
            run={run}
            steps={steps}
            stepIndex={stepIndex}
            showProgress
            continuous
            tooltipComponent={CustomTooltip}
            callback={handleJoyrideCallback}
            floaterProps={{
                hideArrow: isSmallOrMediumScreen,
                disableFlip: isSmallOrMediumScreen,
                disableAnimation: isSmallOrMediumScreen,
                options: {
                    shift: {
                        enabled: !isSmallOrMediumScreen
                    }
                },
                styles: {
                    floater: isSmallOrMediumScreen
                        ? {
                              height: '100vh',
                              width: '100vw',
                              display: 'flex',
                              alignItems: 'flex-end',
                              justifyContent: 'center',
                              marginLeft: stepIndex === 1 ? -10 : 10
                          }
                        : {}
                }
            }}
            styles={{
                options: {
                    arrowColor: '#fff',
                    zIndex: 1000,
                    overlayColor: 'rgba(0, 0, 0, 0.4)',
                    backgroundColor: '#e3ffeb',
                    primaryColor: '#000',
                    textColor: '#004a14'
                },
                overlay: {
                    zIndex: stepIndex > 0 ? 1050 : 1000
                },
                spotlight: {
                    borderRadius: 26,
                    marginLeft:
                        !isSmallOrMediumScreen && stepIndex === 0 ? -20 : 0
                }
            }}
        />
    );
};

export default Walkthrough;
