import React from 'react';

const LastStepContent = () => (
    <>
        <p>Let&apos;s collaborate to scale our sustainable impact. </p>
        <p>
            Any questions? Shoot us an email{' '}
            <em className="text-info">
                <u>hello@interseed.co</u>
            </em>
        </p>
    </>
);

export default LastStepContent;
