/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';
import CloseIcon from './CloseIcon';

interface PageTourModal {
    continuous: boolean; // If the tour is continuous or not
    index: number; // The current step's index
    isLastStep: boolean; // The name says it all
    size: number; // The number of steps in the tour
    step: any; // The current step data
    backProps: any; // The back button's props
    closeProps: any; // The close button's props
    primaryProps: any; // The primary button's props (Close or Next if the tour is continuous)
    skipProps: any; // The skip button's props
    tooltipProps: any; // The root element props (including ref)
}
interface ButtonProps {
    kind: 'next' | 'back';
}

interface StepsProps {
    isLast: boolean;
}

const TooltipBody = styled.div`
    background-color: #fff;
    border-radius: 15px;
    box-sizing: border-box;
    color: #000000;
    font-size: 20px;
    line-height: 23px;
    padding: 20px;
    text-align: left;
    position: relative;

    @media screen and (max-width: 768px) {
        border-radius: 0px;
        width: 85vw;
    }
`;
const TooltipTitle = styled.h1`
    font-size: 18;
    margin: 0 0 10px 0;
`;
const TooltipContent = styled.p<StepsProps>`
    padding: 12px 4px;
    margin: 0px 15px 0px 0px;
    width: 100%;
    max-width: ${({ isLast }) => (isLast ? 'max-content' : '300px')};

    @media screen and (max-width: 768px) {
        max-width: 100vw;
    }
`;
const TooltipFooter = styled.div<StepsProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${({ isLast }) => (isLast ? 'center' : 'flex-end')};
    height: 48px;
    margin-right: 15px;

    .progress {
        width: 40px;
        height: 40px;
        background-color: transparent;
        margin-right: auto;
        display: ${({ isLast }) => (isLast ? 'none' : 'inherit')};

        .CircularProgressbar .CircularProgressbar-text {
            font-size: 28px;
        }
    }
`;

const Button = styled.button<ButtonProps>`
    background-color: ${({ kind }) => {
        let bgColor: string;
        if (kind === 'next') {
            bgColor = '#067DF9';
        } else if (kind === 'back') {
            bgColor = '#fff';
        } else {
            bgColor = '#6F676770';
        }
        return bgColor;
    }};
    border: ${({ kind }) => {
        let border: string;
        if (kind === 'back') {
            border = '1px solid #067DF9';
        } else {
            border = 'none';
        }
        return border;
    }};
    outline: none;
    border-radius: 4px;
    color: ${({ kind }) => {
        let color: string;
        if (kind === 'back') {
            color = '#067DF9';
        } else {
            color = '#fff';
        }
        return color;
    }};
    font-weight: 400;
    padding: 8px 16px;
    font-size: 16px;
    text-transform: capitalize;
    margin-right: ${({ kind }) => (kind === 'next' ? '0px' : '8px')};
    margin-left: ${({ kind }) => (kind === 'next' ? '0px' : 'auto')};

    &:focus {
        outline: none;
    }

    .last {
        padding: 12px 48px;
    }
`;

const CloseButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const CustomTooltip = ({
    continuous,
    index,
    step,
    isLastStep,
    size,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps
}: PageTourModal) => (
    <TooltipBody {...tooltipProps}>
        {!isLastStep && (
            <CloseButtonContainer {...closeProps}>
                <CloseIcon />
            </CloseButtonContainer>
        )}
        {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
        <TooltipContent
            {...(isLastStep ? { isLast: true } : { isLast: false })}
        >
            {step.content}
        </TooltipContent>
        <TooltipFooter {...(isLastStep ? { isLast: true } : { isLast: false })}>
            <div className="progress">
                {!isLastStep && (
                    <CircularProgressbar
                        value={((index + 1) * 100) / (size - 1)}
                        text={`${index + 1} / ${size - 1}`}
                    />
                )}
            </div>
            {index > 0 && index < 9 && (
                <Button {...backProps} kind="back" className="back">
                    prev
                </Button>
            )}
            {continuous && (
                <Button {...primaryProps} kind="next">
                    {isLastStep ? (
                        <span className="last">Let&apos;s go</span>
                    ) : (
                        'next'
                    )}
                </Button>
            )}
        </TooltipFooter>
    </TooltipBody>
);

export default CustomTooltip;
