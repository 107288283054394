import axios from 'axios';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import Linkify from 'linkifyjs/react';
import { differenceInMinutes, format, isValid } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Modal, Row, Image } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { blankEvent, IEvent } from '../../interfaces/IEvent';
import { ITag } from '../../interfaces/ITag';
import IUser from '../../interfaces/IUser';
import { AuthState } from '../../redux/actions/types';
import getSrc from '../../utils/commonFunction';
import { color } from './EventStyles';
import EventActionButtons from './EventActionButtons';
import { useEventTracking } from '../../pages/eventTracking';
import { useModalViewTracking } from '../../pages/pageTracking';

const ModalContent = styled(Modal)`
    p {
        padding: 0;
        margin: 0;
    }
    .modal-content {
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0px 50px 77px rgba(176, 183, 195, 0.22);
        border: none;
    }

    .border-radius {
        border-radius: 15px;
    }

    .p1 {
        font-size: 1.125rem;
        line-height: 1.875rem;
    }

    .p2 {
        font-size: 1.125rem;
        line-height: 1.625rem;
    }

    .p3 {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }
`;

const Backdrop = styled.div`
    z-index: 3001;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
`;

const getTime = (time: string) => {
    const dateObj = new Date(time);
    if (!isValid(dateObj)) return '';
    return format(dateObj, 'hh:mm a (zzzz) - LLLL dd, yyyy');
};

const getDifferenceInHour = (startTime: string, endTime: string) => {
    const diffInMinutes = differenceInMinutes(
        new Date(endTime),
        new Date(startTime)
    );
    const hours = diffInMinutes / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    return `${rhours} hour(s) and ${rminutes} minute(s).`;
};

interface EventViewModalProps {
    isPending?: boolean;
    show: boolean;
    setShow: (val: false) => void;
    eventId: string;
    getNewEvents: (val: string) => void;
    auth: AuthState;
    currentUser?: IUser;
}

export default function EventViewModal({
    isPending = false,
    show,
    setShow,
    eventId,
    getNewEvents,
    auth,
    currentUser
}: EventViewModalProps): ReactElement {
    const [event, setEvent] = useState<IEvent>(blankEvent);
    const [isEventOrganizer, setIsEventOrganizer] = useState(false);
    const [isEventFull, setIsEventFull] = useState(false);
    const [deleteTrigger, setDeleteTrigger] = useState(false);
    const [organizerEventsCount, setOrganizerEventsCount] = useState(0);
    const history = useHistory();
    const location = useLocation();

    const handleClose = () => {
        setShow(false);
    };

    const getEvent = useCallback(() => {
        axios.get(`api/events/${eventId}?participantLimit=35`).then((res) => {
            useModalViewTracking(`event`, `${eventId}`);
            setEvent(res.data[0]);
        });
    }, [eventId]);

    const sortByTag = (id: string) => {
        if (location.pathname.includes('events/')) {
            history.push(`all?tag=${id}`);
        } else history.replace(`events/all?tag=${id}`);
    };

    useEffect(() => {
        setEvent(blankEvent);
        if (eventId === null) handleClose();
        getEvent();
        setIsEventOrganizer(auth.user._id !== event.organizer._id);
        setIsEventFull(
            event.numberOfParticipants <= event.participants.length &&
                event.numberOfParticipants !== 0
        );
        if (event.organizer._id)
            axios
                .get(`api/events/organizerCount/${event.organizer._id}`)
                .then((res) => {
                    setOrganizerEventsCount(res.data);
                });
    }, [eventId]);

    return (
        <ModalContent show={show} onHide={handleClose} size="lg" centered>
            {event ? (
                <Col>
                    <Row className="position-relative">
                        <span
                            className="position-absolute badge badge-pill px-3 py-1"
                            style={{
                                left: '1.5rem',
                                top: '1.5rem',
                                backgroundColor: color.types[event.type],
                                fontWeight: 600,
                                fontSize: '1rem',
                                color: color.types.text
                            }}
                        >
                            {event.type}
                        </span>
                        {(auth.user._id === event.organizer._id ||
                            currentUser?.admin) &&
                            new Date() < new Date(event.startTime) && (
                                <>
                                    <button
                                        className="position-absolute"
                                        type="button"
                                        style={{
                                            right: '4rem',
                                            top: '1.5rem',
                                            width: '2rem',
                                            height: '2rem',
                                            backgroundColor: '#FF5630',
                                            boxShadow:
                                                '0px 50px 77px rgba(176, 183, 195, 0.22)',
                                            borderRadius: '50%',
                                            border: 0,
                                            fontWeight: 600,
                                            color: 'white'
                                        }}
                                        onClick={() => {
                                            setDeleteTrigger(true);
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            size="sm"
                                            icon={faTrash}
                                        />
                                    </button>
                                    <button
                                        className="position-absolute"
                                        type="button"
                                        style={{
                                            right: '6.5rem',
                                            top: '1.5rem',
                                            width: '2rem',
                                            height: '2rem',
                                            backgroundColor: 'white',
                                            boxShadow:
                                                '0px 50px 77px rgba(176, 183, 195, 0.22)',
                                            borderRadius: '50%',
                                            border: 0,
                                            fontWeight: 600,
                                            color: '#007BFF'
                                        }}
                                        onClick={() => {
                                            useEventTracking(
                                                'Events',
                                                'Clicked Edit Event',
                                                `${auth.user.username}`
                                            );
                                            history.push(
                                                `/event/update/${event._id}`
                                            );
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            size="sm"
                                            icon={faEdit}
                                        />
                                    </button>
                                </>
                            )}
                        <button
                            className="position-absolute"
                            type="button"
                            style={{
                                right: '1.5rem',
                                top: '1.5rem',
                                width: '2rem',
                                height: '2rem',
                                backgroundColor: '#FF5630',
                                boxShadow:
                                    '0px 50px 77px rgba(176, 183, 195, 0.22)',
                                borderRadius: '50%',
                                border: 0,
                                fontWeight: 600,
                                color: 'white'
                            }}
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            <FontAwesomeIcon
                                size="sm"
                                icon={faTimes}
                            />
                        </button>
                        <Image
                            src={event.banner?.location}
                            style={{
                                height: '40vh',
                                width: '100%',
                                maxWidth: '100%',
                                objectFit: 'cover',
                                border: 'none'
                            }}
                            fluid
                        />
                    </Row>
                    <Row style={{ padding: '1.5rem' }}>
                        <Col>
                            <Row className="mb-3">
                                {event.tags.map((tag: ITag) => (
                                    <button
                                        className="badge badge-pill badge-primary px-3 py-1 mr-2"
                                        style={{
                                            left: '1.5rem',
                                            top: '1.5rem',
                                            background: '#EBF4FF',
                                            color: color.shade2,
                                            border: 0
                                        }}
                                        key={tag._id}
                                        type="button"
                                        onClick={() => {
                                            sortByTag(tag._id);
                                            useEventTracking(
                                                'Events',
                                                'Clicked Tag Filter',
                                                `${tag.title}`
                                            );
                                        }}
                                    >
                                        {tag.title}
                                    </button>
                                ))}
                            </Row>
                            <Row
                                className="mb-3"
                                style={{ overflowWrap: 'break-word' }}
                            >
                                <div className="col h3 font-weight-bold">
                                    {event.title}
                                </div>
                            </Row>
                            <Row className="ml-0 mr-0">
                                <p
                                    className="font-weight-bold"
                                    style={{
                                        fontSize: '0.85rem',
                                        color: color.shade2
                                    }}
                                >
                                    {getTime(event.startTime)}
                                </p>
                            </Row>
                            {isPending && (
                                <>
                                    <Row>
                                        <p
                                            className="font-weight-bold"
                                            style={{
                                                fontSize: '0.85rem',
                                                color: color.shade2
                                            }}
                                        >
                                            {getTime(event.endTime)}
                                        </p>
                                    </Row>
                                    <Row>
                                        <p
                                            className="font-weight-bold"
                                            style={{
                                                fontSize: '0.85rem',
                                                color: color.shade2
                                            }}
                                        >
                                            {getDifferenceInHour(
                                                event.startTime,
                                                event.endTime
                                            )}
                                        </p>
                                    </Row>
                                </>
                            )}
                            <Row
                                className="my-3 ml-0 mr-0"
                                style={{ overflowWrap: 'break-word' }}
                            >
                                <Linkify
                                    className="p1 text-center text-sm-left"
                                    style={{ whiteSpace: 'pre-wrap' }}
                                >
                                    {event.description}
                                </Linkify>
                            </Row>
                            {(event.isRegistered ||
                                auth.user._id === event.organizer._id ||
                                currentUser?.admin) && (
                                <Row className="mb-3 ml-0 mr-0">
                                    <div
                                        className="p3 col-12 p-0"
                                        style={{ lineHeight: '0.5rem' }}
                                    >
                                        Join Url
                                    </div>
                                    <div className="col-12 p-0">
                                        <a
                                            className="p1 text-truncate"
                                            href={
                                                event.zoom
                                                    ? event.zoom.joinUrl
                                                    : '#'
                                            }
                                            style={{
                                                textDecoration: 'none',
                                                fontWeight: 700
                                            }}
                                        >
                                            {event.zoom && event.zoom.joinUrl}
                                        </a>
                                    </div>
                                </Row>
                            )}
                            {auth.user._id === event.organizer._id && (
                                <Row className="mb-3 ml-0 mr-0">
                                    <div
                                        className="p3"
                                        style={{ lineHeight: '0.5rem' }}
                                    >
                                        Host Url
                                    </div>
                                    <a
                                        className="p1 text-truncate"
                                        href={
                                            event.zoom
                                                ? event.zoom.startUrl
                                                : '#'
                                        }
                                        style={{
                                            textDecoration: 'none',
                                            fontWeight: 700,
                                            lineHeight: '1.5rem'
                                        }}
                                    >
                                        {event.zoom && event.zoom.startUrl}
                                    </a>
                                </Row>
                            )}
                            <Row className="ml-0 mr-0">
                                <div
                                    style={{ color: color.shade2 }}
                                    className="h6 font-weight-bold"
                                >
                                    Organised by:
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <hr
                                    className="m-0"
                                    style={{
                                        width: '100%',
                                        borderColor: '#EBF4FF'
                                    }}
                                />
                            </Row>
                            <Row className="mb-3">
                                <Col xs="auto">
                                    <Image
                                        className=" mb-4"
                                        src={getSrc(event.organizer)}
                                        onClick={() => {
                                            history.push(
                                                `/profile/${event.organizer.username}`
                                            );
                                        }}
                                        alt="Profile"
                                        roundedCircle
                                        fluid
                                        style={{
                                            width: '4rem',
                                            height: '4rem',
                                            objectFit: 'cover',
                                            cursor: 'pointer'
                                        }}
                                    />
                                </Col>
                                <Col className=" align-items-center">
                                    <Row
                                        className="d-flex ml-0 mr-0"
                                        style={{
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div className="h5 font-weight-bold">
                                            {event.organizer.firstName}{' '}
                                            {event.organizer.lastName}
                                        </div>
                                        <div
                                            className=""
                                            style={{ fontSize: '0.75rem' }}
                                        >
                                            Hosted {organizerEventsCount}{' '}
                                            events.
                                        </div>
                                    </Row>
                                    <Row className="ml-0 mr-0">
                                        <div
                                            className="p3"
                                            style={{ color: color.shade2 }}
                                        >
                                            Event Organizer
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="ml-0 mr-0">
                                <div
                                    className="h6 font-weight-bold"
                                    style={{ color: color.shade2 }}
                                >
                                    Participants{' '}
                                    <span style={{ color: color.theme }}>
                                        {event.numberOfParticipants !== 0
                                            ? `(${event.participantLength}/${event.numberOfParticipants})`
                                            : '(No limit)'}
                                    </span>
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <hr
                                    className="m-0"
                                    style={{
                                        width: '100%',
                                        borderColor: '#EBF4FF'
                                    }}
                                />
                            </Row>
                            <Row
                                className="mb-3 d-flex flex-wrap ml-0 mr-0"
                                style={{ gap: '0.5rem' }}
                            >
                                {event.participants.slice(0, 35).length ? (
                                    event.participants.map(
                                        (participant: IUser) => (
                                            <button
                                                type="button"
                                                key={participant._id}
                                                style={{
                                                    cursor: 'pointer',
                                                    border: 0,
                                                    background: 'none'
                                                }}
                                                onClick={() => {
                                                    history.push(
                                                        `/profile/${participant.username}`
                                                    );
                                                }}
                                            >
                                                <Image
                                                    className=""
                                                    src={getSrc(participant)}
                                                    alt="Profile"
                                                    roundedCircle
                                                    fluid
                                                    style={{
                                                        width: '3rem',
                                                        height: '3rem',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </button>
                                        )
                                    )
                                ) : (
                                    <div className="text-middle">
                                        There are currently no participants for
                                        this event.
                                    </div>
                                )}
                                {event.participantLength > 35 && (
                                    <div
                                        className="d-flex font-weight-bold align-items-center justify-content-center"
                                        style={{
                                            width: '3rem',
                                            height: '3rem',
                                            objectFit: 'cover',
                                            border: '2px solid #FFFFFF',
                                            backgroundColor: '#C1C7D0',
                                            color: '#fff',
                                            borderRadius: '999px',
                                            fontSize: '1rem',
                                            zIndex: 10
                                        }}
                                    >
                                        {`+${event.participantLength - 35}`}
                                    </div>
                                )}
                            </Row>
                            {isEventOrganizer && isEventFull && (
                                <div
                                    style={{
                                        backgroundColor: '#FFE8E3',
                                        color: '#FF5630',
                                        fontWeight: 'bold',
                                        borderRadius: '15px',
                                        padding: '1rem',
                                        marginBottom: '3rem',
                                        textAlign: 'center'
                                    }}
                                >
                                    There are no more seats for this session
                                </div>
                            )}
                            <EventActionButtons
                                auth={auth}
                                event={event}
                                isAdmin={
                                    currentUser ? currentUser.admin : false
                                }
                                isDelete={deleteTrigger}
                                setIsDelete={setDeleteTrigger}
                                getEvent={getEvent}
                                getNewEvents={getNewEvents}
                                isPending={isPending}
                            />
                        </Col>
                    </Row>
                </Col>
            ) : null}
        </ModalContent>
    );
}
