import React, {
    createContext,
    Dispatch,
    SetStateAction,
    useContext,
    useState
} from 'react';

type NavContextType = {
    expanded: boolean;
    setExpanded: Dispatch<SetStateAction<boolean>>;
};

const NavbarContext = createContext<NavContextType>({
    expanded: false,
    // eslint-disable-next-line
    setExpanded: (_expanded) => {}
});

const NavbarProvider: React.FC = ({ children }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <NavbarContext.Provider value={{ expanded, setExpanded }}>
            {children}
        </NavbarContext.Provider>
    );
};

export default NavbarProvider;

export const useNavbarContext = () => useContext(NavbarContext);
