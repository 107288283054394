import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { Button, Card, Col, Container, Form, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    Link,
    RouteComponentProps,
    useParams,
    withRouter
} from 'react-router-dom';
import Select from 'react-select';
import styled from 'styled-components';
import BackToMyOrgs from '../components/backbuttons/BackToMyOrgs';
import EcosystemCreateModal from '../components/community/EcosystemCreateModal';
import { Ecosystem } from '../interfaces/Ecosystem';
import { StoreState } from '../redux/actions/types';
import { whitespaceCheck } from '../utils/commonFunction';

const FormHeader = styled.h2`
    margin-bottom: 2rem;
`;

const CardContent = styled(Card.Body)`
    padding 2rem 1rem;

    label {
        font-size: 16px;
    }

    .form-label {
        font-weight: bold;
    }

    .form-row {
        gap: 42px;
    }

    .custom-file {
        font-size: 16px;
    }

    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }

`;

const focusAreas = [
    'Food & Agriculture',
    'Circular Economy',
    'Energy Transitions',
    'Social Causes'
];

const impactAreas = [
    'GOAL 1: No Poverty',
    'GOAL 2: Zero Hunger',
    'GOAL 3: Good Health and Well-being',
    'GOAL 4: Quality Education',
    'GOAL 5: Gender Equality',
    'GOAL 6: Clean Water and Sanitation',
    'GOAL 7: Affordable and Clean Energy',
    'GOAL 8: Decent Work and Economic Growth',
    'GOAL 9: Industry, Innovation and Infrastructure',
    'GOAL 10: Reduced Inequality',
    'GOAL 11: Sustainable Cities and Communities',
    'GOAL 12: Responsible Consumption and Production',
    'GOAL 13: Climate Action',
    'GOAL 14: Life Below Water',
    'GOAL 15: Life on Land',
    'GOAL 16: Peace and Justice Strong Institutions',
    'GOAL 17: Partnerships for the Goals'
];

interface OptionType {
    value: string;
    label: string;
}

interface Thumbnail {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    src?: string | ArrayBuffer;
}

interface Props extends RouteComponentProps {
    history: RouteComponentProps['history'];
}

const CreateEcosystem: React.FC<Props> = ({ history }) => {
    const alert = useAlert();
    const { queryId } = useParams<{ queryId: string }>();

    const [show, setShow] = useState(false);
    const [created, setCreated] = useState({} as Ecosystem);
    const [formData, setFormData] = useState({} as Ecosystem);
    const [file, setFile] = useState({} as File);
    const [disabled, setDisabled] = useState(false);
    const [disabledCreate, setDisabledCreate] = useState(false);
    const [ecosystemsByOrg, setEcosystemsByOrg] = useState<Ecosystem[]>([]);
    const [thumbnail, setThumbnail] = useState<Thumbnail>({
        lastModified: 0,
        name: 'Choose a File',
        size: 0,
        type: ''
    });

    useEffect(() => {
        if (queryId) {
            axios
                .get<Ecosystem[]>(`/api/ecosystems/findbyorg/${queryId}`)
                .then(({ data }) => {
                    setEcosystemsByOrg(data);
                    const { rootOrg } = ecosystemsByOrg[0].organisation;
                    if (rootOrg) {
                        setDisabledCreate(false);
                    } else if (ecosystemsByOrg.length <= 1) {
                        setDisabledCreate(false);
                    }
                })
                .catch((err) => console.log(err.response));
        }
    }, [queryId]);

    const areasOptions = (areas: string[]) =>
        areas.map((area) => ({
            value: area,
            label: area
        }));

    const compareImpactAreas = (a: string, b: string) => {
        const aMatch: RegExpMatchArray | null = a.match(/(\d+)/);
        const bMatch: RegExpMatchArray | null = b.match(/(\d+)/);
        if (!aMatch || !bMatch) return 0;
        const aNum: number = parseInt(aMatch[0], 10);
        const bNum: number = parseInt(bMatch[0], 10);

        if (aNum > bNum) {
            return 1;
        }
        if (bNum > aNum) {
            return -1;
        }
        return 0;
    };

    const handleSelect = (
        option: OptionType[],
        type: 'impactAreas' | 'focusAreas'
    ) => {
        const values: string[] = [];

        for (let i = 0; i < option.length; i++) {
            values.push(option[i].value);
        }
        if (type === 'impactAreas') {
            values.sort(compareImpactAreas);
        } else values.sort();

        setFormData({
            ...formData,
            [type]: values
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = event.target;

        if (type === 'file') {
            if (event.target.files && event.target.files[0]) {
                const selectedFile = event.target.files[0];
                if (!selectedFile) return;
                if (
                    selectedFile.type !== 'image/jpeg' &&
                    selectedFile.type !== 'image/png'
                ) {
                    alert.error('Please choose a JPEG/PNG file.');
                    return;
                }

                if (selectedFile.size > 1000000) {
                    alert.error('Please choose an image under 1mb.');
                    return;
                }

                setFile(selectedFile);

                const thumbnailFile: Thumbnail = selectedFile;
                const reader = new FileReader();

                reader.onload = function read(e) {
                    if (e.target && e.target.result) {
                        thumbnailFile.src = e.target.result;
                        if (thumbnailFile.name.length > 40) {
                            setThumbnail({
                                lastModified: thumbnailFile.lastModified,
                                name: thumbnailFile.name
                                    .slice(0, 40)
                                    .concat(
                                        `... ${thumbnailFile.type.replace(
                                            'image/',
                                            '.'
                                        )}`
                                    ),
                                size: thumbnailFile.size,
                                type: thumbnailFile.type
                            });
                        } else {
                            setThumbnail(thumbnailFile);
                        }
                    }
                };
                reader.readAsDataURL(selectedFile);
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (disabled) return;
        setDisabled(true);
        // Check for whitespace submissions
        if (
            !whitespaceCheck(formData, [
                'name',
                'address',
                'description',
                'email',
                'contact',
                'website'
            ])
        ) {
            alert.error('Required fields cannot be empty.');
            setDisabled(false);
        }

        if (file) {
            const data = new FormData();
            data.append('image', file);

            try {
                const uploadResponse = await axios.post(
                    `/api/uploads/images`,
                    data
                );

                const { data: createdEcosystem } = await axios.post<Ecosystem>(
                    `/api/ecosystems`,
                    {
                        ...formData,
                        logo: uploadResponse.data,
                        organisation: queryId
                    }
                );

                if (createdEcosystem) {
                    setCreated(createdEcosystem);
                    setShow(true);
                }
            } catch (error) {
                alert.error('Error creating ecosystem. Please try again.');
                setDisabled(false);
            }
        }
    };

    return (
        <Container fluid="md" className="font-arimo">
            <BackToMyOrgs history={history} />
            <h1>Create your Ecosystem</h1>
            <Card className="text-left my-auto px-3 mb-4">
                <CardContent>
                    <FormHeader>Tell us about your ecosystem</FormHeader>

                    <hr />
                    <Form className="text-muted" onSubmit={handleSubmit}>
                        <Form.Row className="align-items-center">
                            <Col xs={12} sm={4}>
                                <Image
                                    className="mx-auto d-block profile-picture"
                                    src={
                                        thumbnail.src
                                            ? thumbnail.src.toString()
                                            : `${process.env.PUBLIC_URL}/assets/interseed.png`
                                    }
                                    alt="Ecocsystem Logo"
                                    roundedCircle
                                    thumbnail
                                    fluid
                                    onError={(e) => {
                                        e.currentTarget.onerror = null; // prevents looping
                                        e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                    }}
                                />
                            </Col>
                            <Form.Group as={Col} controlId="formEcosystemLogo">
                                <Form.Label>Add Ecosystem Logo</Form.Label>
                                <Form.Text muted>
                                    This logo will be displayed on members’
                                    profile as well as Organizations.
                                </Form.Text>
                                <Form.File
                                    name="thumbnail"
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={handleChange}
                                    label={thumbnail.name}
                                    custom
                                />
                                <Form.Text muted>
                                    File size limit: 1mb
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="formName" className="required">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                required
                                name="name"
                                type="text"
                                maxLength={100}
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Name of the ecosystem"
                            />
                            <Form.Text muted>Maximum 100 characters</Form.Text>
                        </Form.Group>
                        <Form.Group
                            controlId="formDescription"
                            className="required"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required
                                name="description"
                                as="textarea"
                                rows={4}
                                maxLength={1000}
                                value={formData.description}
                                onChange={handleChange}
                                placeholder="Description"
                            />
                            <Form.Text muted>Maximum 1000 characters</Form.Text>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group
                                as={Col}
                                controlId="formEmail"
                                className="required"
                            >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter Email"
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                controlId="formWebsite"
                                className="required"
                            >
                                <Form.Label>Website</Form.Label>
                                <Form.Control
                                    required
                                    name="website"
                                    type="text"
                                    value={formData.website}
                                    onChange={handleChange}
                                    placeholder="Enter Website"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group
                                as={Col}
                                controlId="formContact"
                                className="required"
                            >
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    required
                                    name="contact"
                                    type="text"
                                    value={formData.contact}
                                    onChange={handleChange}
                                    placeholder="Enter Phone"
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                controlId="formAddress"
                                className="required"
                            >
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    required
                                    name="address"
                                    type="text"
                                    value={formData.address}
                                    onChange={handleChange}
                                    placeholder="Enter Address"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group
                                as={Col}
                                controlId="formFocusAreas"
                                className="required"
                            >
                                <Form.Label>Ecosystem Focus Areas</Form.Label>
                                <Select
                                    isMulti
                                    name="focusAreas"
                                    options={areasOptions(focusAreas)}
                                    placeholder="Choose your Focus Area(s)"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(option) =>
                                        handleSelect(
                                            option as OptionType[],
                                            'focusAreas'
                                        )
                                    }
                                />
                                <input
                                    className="invis"
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0 }}
                                    defaultValue={formData.focusAreas}
                                    required
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                controlId="formImpactAreas"
                                className="required"
                            >
                                <Form.Label>Ecosystem Impact Areas</Form.Label>
                                <Select
                                    isMulti
                                    name="impactAreas"
                                    options={areasOptions(impactAreas)}
                                    blurInputOnSelect
                                    placeholder="Choose the SDGs which most closely align with your impact"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(option) =>
                                        handleSelect(
                                            option as OptionType[],
                                            'impactAreas'
                                        )
                                    }
                                />
                                <input
                                    className="invis"
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0 }}
                                    defaultValue={formData.impactAreas}
                                    required
                                />
                            </Form.Group>
                        </Form.Row>

                        <div className="text-right mt-4">
                            <Button
                                as={Link}
                                className="mr-1"
                                variant="light"
                                to={`/organisation/${queryId}`}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={disabledCreate}
                            >
                                Create
                            </Button>
                        </div>
                    </Form>
                </CardContent>
            </Card>
            <EcosystemCreateModal
                show={show}
                ecosystem={created}
                handleClose={() => setShow(false)}
                profileId={queryId}
            />
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(CreateEcosystem));
