/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { useEventTracking } from '../../pages/eventTracking';

import IStaff from '../../interfaces/IStaff';
import IOrganisation from '../../interfaces/IOrganisation';
import getSrc from '../../utils/commonFunction';

interface StaffProps {
    member: IStaff;
    authId: string;
    staffList: IStaff[];
    orgId: string | undefined;
    profile: IOrganisation;
    setProfile: React.Dispatch<React.SetStateAction<IOrganisation>>;
}

interface staffObj {
    user: string;
    role: string;
}

const Styles = styled.div`
    .card {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .imgcontainer {
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;
        margin-right: 10px;
        justify-content: center;
        align-items: center;

        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #dee2e6;
            padding: 0.25rem;
        }
    }
`;

const StaffItem: React.FC<StaffProps> = ({
    member,
    authId,
    staffList,
    orgId,
    profile,
    setProfile
}: StaffProps) => {
    let currentUser = false;
    if (authId === member.user._id) currentUser = true;

    const [followCheck, setFollowCheck] = useState(false);
    const [disableFollow, setDisableFollow] = useState(true);

    const handleFollow = () => {
        if (disableFollow || currentUser) return;
        setDisableFollow(true);
        if (followCheck) {
            axios.delete(`/api/users/follows/${member.user._id}`).then(() => {
                setFollowCheck(false);
                setDisableFollow(false);
            });
            useEventTracking(
                'Community',
                'Unfollowed Organisation Member on Organisation Profile',
                `${member.user.username}`
            );
        } else {
            axios.put(`/api/users/follows/${member.user._id}`).then(() => {
                setFollowCheck(true);
                setDisableFollow(false);
            });
            useEventTracking(
                'Community',
                'Followed Organisation Member on Organisation Profile',
                `${member.user.username}`
            );
        }
    };

    const handleAccept = (accepted: boolean) => {
        let staffId = '';
        for (let i = 0; i < staffList.length; i += 1) {
            if (staffList[i].user._id === member.user._id) {
                staffId = staffList[i]._id;
            }
        }
        if (accepted && staffId) {
            axios
                .put(`/api/organisations/${orgId}/staff/${staffId}`, {
                    user: member.user._id,
                    role: 'Staff'
                })
                .then((res) => {
                    setProfile({
                        ...profile,
                        staff: res.data
                    });
                });
        }
    };

    useEffect(() => {
        for (let i = 0; i < member.user.followers.length; i += 1) {
            if (member.user.followers[i] === authId) {
                setFollowCheck(true);
                break;
            }
        }
        setDisableFollow(false);
    }, []);

    return (
        <Styles>
            <Card as={Link} to={`/profile/${member.user.username}`}>
                <Card.Body>
                    <Row>
                        <Col sm={2}>
                            <div className="imgcontainer">
                                <img src={getSrc(member.user)} alt="Profile" />
                            </div>
                        </Col>
                        <Col>
                            <Card.Title className="text-center text-sm-left mt-2">
                                {member.user.firstName} {member.user.lastName}
                            </Card.Title>
                            <Card.Text className="text-center text-sm-left">
                                {member.role}
                            </Card.Text>
                        </Col>
                        <Col
                            className="my-auto text-center text-sm-left"
                            sm={2}
                        >
                            {currentUser ? (
                                <Badge
                                    pill
                                    variant="light"
                                    className="mr-2 mt-3"
                                >
                                    <FontAwesomeIcon icon={faUser} /> This Is
                                    You
                                </Badge>
                            ) : (
                                <Button
                                    variant="outline-primary"
                                    className="mt-2"
                                    onClick={(ev) => {
                                        handleFollow();
                                        ev.preventDefault();
                                    }}
                                    disabled={disableFollow}
                                >
                                    {!followCheck ? 'Follow' : 'Unfollow'}
                                </Button>
                            )}

                            {member.role === 'Applicant' ? (
                                <Button
                                    variant="primary"
                                    className="mt-2"
                                    onClick={(ev) => {
                                        handleAccept(true);
                                        ev.preventDefault();
                                    }}
                                >
                                    Accept
                                </Button>
                            ) : null}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Styles>
    );
};

export default StaffItem;
