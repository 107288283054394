import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { populateArray } from '../../utils/commonFunction';

// Import options for organisations
import {
    startupStageOptions,
    focusAreasOptions,
    lookingForOptions
} from '../../options/orgSelectOptions';

interface Props {
    // eslint-disable-next-line
    handleFilterSelect: (newValue: any, type: string) => void;
    orgStages: string[];
    orgFocusAreas: string[];
    orgLookingFor: string[];
}

const OrganisationFilter: React.FC<Props> = ({
    handleFilterSelect,
    orgStages,
    orgFocusAreas,
    orgLookingFor
}: Props) => (
    <div>
        <Row className="mb-3">
            <Col>
                <Select
                    isMulti
                    options={startupStageOptions}
                    placeholder="Startup Stage"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={populateArray(orgStages, true)}
                    onChange={(newValue) =>
                        handleFilterSelect(newValue, 'orgStages')
                    }
                />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                <Select
                    isMulti
                    options={focusAreasOptions}
                    placeholder="Focus Area"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={populateArray(orgFocusAreas, true)}
                    onChange={(newValue) =>
                        handleFilterSelect(newValue, 'orgFocusAreas')
                    }
                />
            </Col>
            <Col>
                <Select
                    isMulti
                    options={lookingForOptions}
                    placeholder="Looking For"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={populateArray(orgLookingFor, true)}
                    onChange={(newValue) =>
                        handleFilterSelect(newValue, 'orgLookingFor')
                    }
                />
            </Col>
        </Row>
    </div>
);

export default OrganisationFilter;
