/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faComment } from '@fortawesome/free-solid-svg-icons';

import { connect } from 'react-redux';
import LandscapeModal from '../landscapes/LandscapeModal';

import ILandscape from '../../interfaces/ILandscape';
import { StoreState } from '../../redux/actions/types';

const Styles = styled.div`
    .landscape-list-item {
        cursor: pointer;
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        /* border: 1px solid #000; */
        outline: none;
    }

    img {
        object-fit: cover;
        width: 100%;
    }

    .upvote-count {
        font-weight: bold;
    }
`;

const LandscapeStyle = styled.div`
    .card-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 0;
    }

    .img {
        width: 70px;
        height: auto;
        margin-right: 1rem;
    }

    .landscape-content h2 {
        font-size: 0.9rem;
    }

    .landscape-content .landscape-tagline {
        font-size: 0.8rem;
        margin: 0.5rem 0;
    }

    .landscape-content .landscape-comment {
        display: flex;
    }

    .landscape-content .landscape-comment div {
        background: #f8f9fa;
        padding: 0.3rem;
        border-radius: 5px;
        font-size: 0.7rem;
    }

    .landscape-content .landscape-comment .landscape-comments {
        margin-right: 0.8rem;
    }

    .card-container .landscape-upvotes {
        padding-left: 1rem;
    }

    .card-container .landscape-upvotes .upvote-count {
        margin: 0.2rem 0.4rem;
    }
`;

const LandscapeItem: React.FC<{
    landscape: ILandscape;
    onClick?: (landscape: ILandscape) => void;
}> = ({ landscape, onClick }) => {
    const [show, setShow] = useState(false);
    const [numComments, setNumComments] = useState(0);
    const [upvoteCheck, setUpvoteCheck] = useState(false);
    const [upvotes, setUpvotes] = useState(landscape.upvotes);
    const [disableUpvote, setDisableUpvote] = useState(false);
    const [initCheck, setInitCheck] = useState(false);

    const handleUpvote = () => {
        if (disableUpvote) return;
        setDisableUpvote(true);

        if (upvoteCheck === false) {
            axios
                .put(
                    `/api/landscapes/${landscape._id}/upvotes/${landscape.author._id}`
                )
                .then((res) => {
                    setUpvotes(res.data.upvotes);
                    setUpvoteCheck(true);
                    setDisableUpvote(false);
                });
        } else {
            axios
                .delete(
                    `/api/landscapes/${landscape._id}/upvotes/${landscape.author._id}`
                )
                .then((res) => {
                    setUpvotes(res.data.upvotes);
                    setUpvoteCheck(false);
                    setDisableUpvote(false);
                });
        }
    };

    const count = () => {
        let commentsList = landscape.comments;
        let commentsCount = commentsList.length;

        if (initCheck) {
            axios.get(`/api/landscapes/${landscape._id}`).then((res) => {
                commentsList = res.data.comments;
                commentsCount = commentsList.length;
                setUpvoteCheck(false);
                for (let i = 0; i < commentsList.length; i += 1) {
                    commentsCount += commentsList[i].replies.length;
                }
                for (let i = 0; i < res.data.upvotes.length; i += 1) {
                    if (res.data.upvotes[i] === landscape.author._id)
                        setUpvoteCheck(true);
                }
                setUpvotes(res.data.upvotes);
                setNumComments(commentsCount);
            });
        } else {
            setUpvoteCheck(false);
            for (let i = 0; i < commentsList.length; i += 1) {
                commentsCount += commentsList[i].replies.length;
            }
            for (let i = 0; i < upvotes.length; i += 1) {
                if (upvotes[i] === landscape.author._id) setUpvoteCheck(true);
            }
            setNumComments(commentsCount);
            setInitCheck(true);
        }
    };

    const updateUpvotes = (newUpvotes: string[]) => {
        setUpvotes(newUpvotes);
    };

    const handleClose = () => {
        setShow(false);
        count();
    };
    const handleShow = () => {
        setShow(true);
    };

    const thumbnail = landscape.thumbnail
        ? landscape.thumbnail.location
        : 'test';

    useEffect(() => {
        count();
    }, []);

    useEffect(() => {
        if (!landscape.author._id) {
            setDisableUpvote(true);
        }
    }, [landscape.author._id]);

    return (
        <>
            <LandscapeModal
                key={landscape._id}
                landscape={landscape}
                upvoteList={upvotes}
                show={show}
                handleClose={handleClose}
                updateUpvotes={updateUpvotes}
            />
            <Styles>
                <div
                    className="landscape-list-item"
                    onClick={() =>
                        onClick ? onClick(landscape) : handleShow()
                    }
                    onKeyPress={handleShow}
                    role="button"
                    tabIndex={0}
                >
                    <LandscapeStyle>
                        <div className="card-container">
                            <div className="img">
                                <img src={thumbnail} alt="" />
                            </div>
                            <div className="landscape-content">
                                <h2>{landscape.title}</h2>
                                <p className="landscape-tagline">
                                    {landscape.tagline}
                                </p>
                                <div className="landscape-comment">
                                    <div className="landscape-comments">
                                        <FontAwesomeIcon icon={faComment} />{' '}
                                        {numComments}
                                    </div>
                                    <div>{landscape.primaryCategories[0]}</div>
                                </div>
                            </div>
                            <div className="landscape-upvotes">
                                <Button
                                    disabled={disableUpvote}
                                    type="button"
                                    variant={
                                        upvoteCheck ? 'dark' : 'outline-dark'
                                    }
                                    onClick={(ev) => {
                                        handleUpvote();
                                        ev.stopPropagation();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faChevronUp}
                                        className="mt-2"
                                    />
                                    <br />
                                    <p className="upvote-count">
                                        {upvotes.length}
                                    </p>
                                </Button>
                            </div>
                        </div>
                    </LandscapeStyle>
                </div>
            </Styles>
        </>
    );
};

export const AuthedLandscapeItem = connect((state: StoreState) => ({
    authId: state.auth.user._id
}))(LandscapeItem);

export default LandscapeItem;
