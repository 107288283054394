/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Link,
    withRouter,
    RouteComponentProps,
    useParams
} from 'react-router-dom';
import {
    Container,
    Card,
    Form,
    Button,
    Col,
    InputGroup
} from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import Textarea from 'react-expanding-textarea';
import { useAlert } from 'react-alert';
import { whitespaceCheck } from '../../utils/commonFunction';

import IOpportunity, { oppBlankObj } from '../../interfaces/IOpportunity';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../../redux/actions/types';

// Select Options
import { primaryCategoriesOptions } from '../../options/landscapeSelectOptions';
import { skillsOptions } from '../../options/profileSelectOptions';

import optionType from '../../interfaces/OptionType';

const CardContent = styled.div`
    padding: 2rem 1rem;

    .form-label {
        font-size: 16px;
        font-weight: bold;
    }

    .custom-file {
        font-size: 16px;
    }

    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }
`;

const FormHeader = styled.h2`
    margin-bottom: 2rem;
`;

interface uploadProps extends RouteComponentProps {
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
}

interface ParamTypes {
    queryId: string | undefined;
}

const descMaxLength = 2000;

// Main React Functional Component
const CreateInternship: React.FC<uploadProps> = ({ ...props }) => {
    const { queryId } = useParams<ParamTypes>();
    const alert = useAlert();

    const [details, setDetails] = useState<IOpportunity>(oppBlankObj);
    const [orgID, setOrgID] = useState('');
    const [orgOptions, setOrgOptions] = useState<optionType[]>();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [descWordsRemaining, setDescWordsRemaining] = useState(descMaxLength);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === 'description') {
            setDescWordsRemaining(descMaxLength - value.length);
        }
        setDetails({
            ...details,
            [name]: value
        });
    };

    // eslint-disable-next-line
    const handleSelect = (newValue: any, type: string) => {
        if (type === 'organisation') {
            setOrgID(newValue.value);
            return;
        }

        const value: string[] = [];
        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }

        value.sort();
        setDetails({
            ...details,
            [type]: value
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (disableSubmit) return;
        setDisableSubmit(true);

        // Check for whitespace submissions
        if (!whitespaceCheck(details, ['position', 'description'])) {
            alert.error(
                'Required fields cannot be filled with only whitespaces.'
            );
            setDisableSubmit(false);
            return;
        }

        axios
            .post(`/api/internships/`, {
                position: details.position,
                description: details.description,
                renumeration: details.renumeration,
                location: details.location,
                verticals: details.verticals,
                skillsets: details.skillsets,
                organisation: orgID
            })
            .then((rec) => {
                setDetails(oppBlankObj);
                props.history.push(`/internship/${rec.data._id}`);
            })
            .catch(() => {
                alert.error('Error creating opportunity. Please try again.');
                setDisableSubmit(false);
            });
        event.preventDefault();
    };

    // Check if user is logged in
    useEffect(() => {
        if (!props.auth.isAuthenticated) {
            props.history.push('/');
            return;
        }

        // Check and Populate Organisation

        if (queryId && props.userProfile.userProfile) {
            const orgs = props.userProfile.userProfile.organisation;
            if (orgs.find((x) => x._id === queryId)) {
                setOrgID(queryId);
            } else {
                // If queryId is invalid, error404
                props.history.replace('/error404');
            }
        }

        // If no queryId, select time!
        else if (props.userProfile.userProfile) {
            const orgs = props.userProfile.userProfile.organisation;
            const tempArray: optionType[] = orgs.map((item) => ({
                value: item._id,
                label: item.name
            }));
            setOrgOptions(tempArray);
        } else {
            props.history.replace('/error404');
        }
    }, [props.auth.isAuthenticated]);

    // Check whether user is onboarded
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    return (
        <Container fluid='md'>
            <h1>Create an Opportunity</h1>
            <Card className="text-left mx-auto px-3 mb-4">
                <CardContent>
                    <FormHeader>Tell us about this opportunity</FormHeader>
                    <hr />
                    <Form className="text-muted" onSubmit={handleSubmit}>
                        {!queryId ? (
                            <Form.Group
                                controlId="formOrganisation"
                                className="required"
                            >
                                <Form.Label>Organisation</Form.Label>
                                <Select
                                    name="organisation"
                                    options={orgOptions}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleSelect(newValue, 'organisation')
                                    }
                                />
                                <input
                                    className="invis"
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0 }}
                                    defaultValue={orgID}
                                    required
                                />
                            </Form.Group>
                        ) : null}

                        <Form.Group
                            controlId="formPosition"
                            className="required"
                        >
                            <Form.Label>Position</Form.Label>
                            <Form.Control
                                required
                                name="position"
                                type="text"
                                maxLength={100}
                                value={details.position}
                                onChange={handleChange}
                                placeholder="Job position / title"
                            />
                            <Form.Text muted>Max 100 characters.</Form.Text>
                        </Form.Group>

                        <Form.Row>
                            <Col>
                                <Form.Group
                                    controlId="formVerticals"
                                    className="required"
                                >
                                    <Form.Label>Verticals</Form.Label>
                                    <Select
                                        isMulti
                                        name="verticals"
                                        options={primaryCategoriesOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={details.verticals.map(
                                            (item) => ({
                                                value: item,
                                                label: item
                                            })
                                        )}
                                        onChange={(newValue) =>
                                            handleSelect(newValue, 'verticals')
                                        }
                                    />
                                    <input
                                        className="invis"
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        defaultValue={details.verticals}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group
                                    controlId="formSkillsets"
                                    className="required"
                                >
                                    <Form.Label>Skillsets</Form.Label>
                                    <Select
                                        isMulti
                                        name="skillsets"
                                        options={skillsOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={details.skillsets.map(
                                            (item) => ({
                                                value: item,
                                                label: item
                                            })
                                        )}
                                        onChange={(newValue) =>
                                            handleSelect(newValue, 'skillsets')
                                        }
                                    />
                                    <input
                                        className="invis"
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        defaultValue={details.skillsets}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Group
                            controlId="formDescription"
                            className="required"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required
                                as={Textarea}
                                name="description"
                                rows={4}
                                maxLength={descMaxLength}
                                value={details.description}
                                onChange={handleChange}
                                placeholder="Describe your opportunity."
                            />
                            <Form.Text muted>
                                {descWordsRemaining} characters remaining.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formYearFounded">
                            <Form.Label>Renumeration</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    required
                                    name="renumeration"
                                    type="number"
                                    value={`${details.renumeration}`}
                                    onChange={handleChange}
                                />
                            </InputGroup>
                        </Form.Group>

                        <div className="text-right mt-2">
                            <Button
                                as={Link}
                                className="mr-1"
                                variant="light"
                                to="/jobs"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={disableSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </CardContent>
            </Card>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(CreateInternship));
