import { AuthErrors } from '../redux/actions/types';

export default interface IRegisterUserInput {
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    password2: string;
    errors: AuthErrors;
}

export const BlankRegisterUserInput = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    password2: '',
    errors: {
        firstName: '',
        username: '',
        email: '',
        password: '',
        password2: ''
    }
};
