/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
    Badge,
    Button,
    Card,
    Image,
    ListGroup,
    ListGroupItem,
    Modal
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendar,
    faEdit,
    faMapMarked
} from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/actions/types';
import IVolunteerOpportunity from '../../interfaces/IVolunteerOpportunity';
import OpportunityModal from '../opportunities/OpportunityModal';
import IOrganisation, { orgBlankObj } from '../../interfaces/IOrganisation';
import { color, EventButton } from '../events/EventStyles';
import { useEventTracking } from '../../pages/eventTracking';

const Styles = styled.div`
    .landscape-list-item {
        cursor: pointer;
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;
        margin: 0;
        padding: 0.5rem 0;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }
`;

const OpportunityStyle = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5rem 0;

    .badge {
        font-size: 16px;
    }

    .opportunity-content {
        display: flex;
        flex-direction: column;
        margin-bottom: -20px;
    }

    .img img {
        width: 60px;
        height: 60px;
        margin: 0 0.5rem;
        border-radius: 50%;
        object-fit: cover;
    }

    .opportunity-content h2 {
        font-size: 1rem;
        margin-bottom: 0.2rem;
        font-weight: bold;
    }

    .opportunity-content h3 {
        font-size: 0.8rem;
        margin-bottom: 0.4rem;
    }

    .opportunity-content h4 {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }

    .opportunity-content p .focus-area-badge {
        background-color: #f8f9fa;
        font-weight: bold;
        font-size: 0.8rem;
        border-radius: 10px;
        padding: 0.4rem;
        display: inline-block;
    }

    .edit-opportunity-button {
        background-color: ${(props) => color.theme};
        width: max-content;
        font-size: 0.9rem;
        // border-radius: 0;
        border-bottom: 1px solid #000;
    }
    .register-button {
        width: max-content;
        font-size: 0.9rem;
        // border-radius: 0;
        border-bottom: 1px solid #000;
`;

const OpportunitiesItem: React.FC<{
    opportunity: IVolunteerOpportunity;
    onClick?: (opportunity: IVolunteerOpportunity) => void;
    userOrgs: string[];
}> = ({ opportunity, onClick, userOrgs }) => {
    const [show, setShow] = useState(false);
    const [initCheck, setInitCheck] = useState(false);

    const handleClose = () => {
        setShow(false);
    };
    const handleShow = () => {
        useEventTracking(
            'Dashboard Map (Volunteering Opportunities)',
            'Viewed Volunteering Opportunity',
            `${opportunity.title}`
        );
        setShow(true);
    };

    const thumbnail = opportunity.thumbnail
        ? opportunity.thumbnail.location
        : 'test';

    const descWordLimit = 12;
    const description =
        opportunity.description.split(' ').length > descWordLimit
            ? `${opportunity.description
                  .split(' ')
                  .slice(0, descWordLimit)
                  .join(' ')}...`
            : opportunity.description;

    return (
        <>
            <OpportunityModal
                opportunity={opportunity}
                show={show}
                handleClose={handleClose}
                userOrgs={userOrgs}
            />
            <Styles>
                <div
                    className="landscape-list-item"
                    onClick={() =>
                        onClick ? onClick(opportunity) : handleShow()
                    }
                    onKeyPress={handleShow}
                    role="button"
                    tabIndex={0}
                >
                    <OpportunityStyle>
                        <div className="img">
                            <img src={thumbnail} alt="" />
                        </div>

                        <div className="opportunity-content">
                            <div className="opportunity-heading">
                                <h2>{opportunity.title}</h2>
                                <h4 className="text-muted">
                                    {opportunity.organisation
                                        ? opportunity.organisation.name
                                        : 'No organisation'}
                                </h4>
                            </div>
                            <div className="opportunity-description">
                                <h3>{description}</h3>
                            </div>
                            <p className="mt-2">
                                {opportunity.primaryTags.map((tag) => (
                                    <div className="focus-area-badge">
                                        {tag}
                                    </div>
                                ))}
                            </p>
                        </div>
                    </OpportunityStyle>
                </div>
            </Styles>
        </>
    );
};

export const AuthedOpportunitiesItem = connect((state: StoreState) => ({
    authId: state.auth.user._id
}))(OpportunitiesItem);

export default OpportunitiesItem;
