import {
  UserProfileActionTypes,
  SET_USER_PROFILE,
  USER_PROFILE_LOADING,
  UserProfileState
} from '../actions/types';

const initialState = {
  userProfile: undefined,
  loading: false
};

export default (
  state: UserProfileState = initialState,
  action: UserProfileActionTypes
) => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload
      };
    case USER_PROFILE_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};
