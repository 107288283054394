export default interface IUser {
    firstName: string;
    lastName: string;
    profilePicture?: { location: string; key: string };
    socialPicture?: string;
    username: string;
    email: string;
    city: string;
    country: string;
    interestAreas: string[];
    sustainabilityInvolvement: string[];
    currentRole: string;
    organisationName: string;
    lookingFor: string[];
    aboutMyself: string;
    skillsets: string[];
    linkedin: string;
    website: string;
    followers: string[];
    following: string[];
    followingOrgs: string[];
    additionalInfo: string;
    admin: boolean;
    organisation: OrganisationIdName[];
    ecosystems: UserEcosystem[];
    landscapes: string[];
    internships: string[];
    status: string;
    confirmationCode: string;
    userType: string[];
    loginType: string;
    _id: string;
    createdAt: Date;
}
interface OrganisationIdName {
    _id: string;
    name: string;
}

export interface UserEcosystem {
    _id: string;
    name: string;
    members: string[];
    focusAreas: string[];
    logo: { location: string; key: string };
}

export const UserBlankObj = {
    firstName: '',
    lastName: '',
    profilePicture: { location: '', key: '' },
    socialPicture: '',
    username: '',
    email: '',
    city: '',
    country: '',
    interestAreas: [],
    sustainabilityInvolvement: [],
    currentRole: '',
    organisationName: '',
    lookingFor: [],
    aboutMyself: '',
    skillsets: [],
    linkedin: '',
    website: '',
    followers: [],
    following: [],
    followingOrgs: [],
    additionalInfo: '',
    admin: false,
    organisation: [],
    ecosystems: [],
    landscapes: [],
    internships: [],
    status: '',
    confirmationCode: '',
    userType: [],
    loginType: '',
    _id: '',
    createdAt: new Date()
};
