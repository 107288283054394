import axios from 'axios';
import debounce from 'lodash.debounce';
import React, {
    ReactElement,
    useEffect,
    useState,
    useRef,
    useCallback
} from 'react';
import { Col, Spinner } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useParams } from 'react-router-dom';
import { IEvent } from '../../interfaces/IEvent';
import { ITag } from '../../interfaces/ITag';
import { AuthState } from '../../redux/actions/types';
import EventCreateModal from './EventCreateModal';
import EventItemVertical from './EventItemVertical';
import { EventButton } from './EventStyles';
import EventViewModal from './EventViewModal';
import usePagination from './usePagination';
import { useEventTracking } from '../../pages/eventTracking';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface EventsInfiniteScrollProps {
    auth: AuthState;
}

export default function EventsInfiniteScroll({
    auth
}: EventsInfiniteScrollProps): ReactElement {
    const { filter } = useParams<{ filter: string }>();
    const routeQuery = useQuery();
    const search = routeQuery.get('search');
    const sort = routeQuery.get('sort');
    const tagInit = routeQuery.get('tag');

    const [eventsLength, setEventsLength] = useState(0);
    const [viewEvent, setViewEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState('');
    const [createEvent, setCreateEvent] = useState(false);
    const [tags, setTags] = useState<ITag[]>([]);

    const getFilter = (filterValue: string) => {
        if (filterValue === 'registered') {
            return `participants=${auth.user._id}`;
        }
        if (filterValue === 'all') return '';
        return `type=${
            filterValue.charAt(0).toUpperCase() + filterValue.slice(1)
        }`;
    };

    const defaultQuery = `&approved=true&participantLimit=4&`;

    let query = `${defaultQuery}${getFilter(filter)}`;
    if (search) {
        query += `&search=${search}`;
    }
    if (tagInit) query += `&tags=${tagInit}`;

    const {
        currentPage,
        setPage,
        data: eventsFiltered,
        loading,
        refresh: refreshEvents,
        setQuery,
        setSort,
        setSortOrder
    } = usePagination<IEvent>({
        route: 'api/events/filtered',
        sort: 'date',
        sortOrder: 'asc',
        limit: 16,
        query,
        isInfiniteScroll: true
    });

    const debouncedSave = useCallback(
        debounce((nextValue) => {
            let eventQuery = defaultQuery;
            let eventLengthQuery = `api/events/count?approved=true&${getFilter(
                filter
            )}`;
            if (filter) {
                eventQuery += `${getFilter(filter)}`;
            }
            if (tagInit) {
                eventQuery += `&tags=${tagInit}`;
                eventLengthQuery += `&tags=${tagInit}`;
            }
            if (nextValue) {
                eventQuery += `&search=${nextValue}`;
                eventLengthQuery += `&search=${nextValue}`;
            }
            axios.get(eventLengthQuery).then(({ data }) => {
                setEventsLength(data);
            });
            setQuery(`${eventQuery}`);
        }, 1000),
        [filter, tagInit]
    );

    const fetchNextData = () => {
        setPage(currentPage + 1);
    };

    useEffect(() => {
        debouncedSave(search);
    }, [search]);

    useEffect(() => {
        setSortOrder(sort === 'popularity' ? 'desc' : 'asc');
        setSort(sort === 'popularity' ? 'participantLength' : sort || 'date');
    }, [sort]);

    useEffect(() => {
        let eventQuery = defaultQuery;
        if (filter) {
            eventQuery += `${getFilter(filter)}`;
        }
        let eventLengthQuery = `api/events/count?approved=true&${getFilter(
            filter
        )}`;
        if (tagInit) {
            eventQuery += `&tags=${tagInit}`;
            eventLengthQuery += `&tags=${tagInit}`;
        }

        if (search) {
            eventQuery += `&search=${search}`;
            eventLengthQuery += `&search=${search}`;
        }
        axios.get(eventLengthQuery).then(({ data }) => {
            setEventsLength(data);
        });
        setQuery(`${eventQuery}`);
    }, [filter, tagInit]);

    useEffect(() => {
        const eventLengthQuery = tagInit
            ? `api/events/count?approved=true&${getFilter(
                  filter
              )}&tags=${tagInit}`
            : `api/events/count?approved=true&${getFilter(filter)}`;
        // : `api/events/count?${getFilter(filter)}`;
        axios.get(`api/tags`).then(({ data }) => {
            setTags(data);
        });
        axios.get(eventLengthQuery).then(({ data }) => {
            setEventsLength(data);
        });
    }, []);

    return (
        <div
            style={{ minHeight: '60vh', width: '100%', paddingBottom: '3rem' }}
        >
            <InfiniteScroll
                className="row position-relative"
                style={{ height: '100%', paddingBottom: '3rem' }}
                dataLength={eventsFiltered.length}
                next={fetchNextData}
                hasMore={eventsLength > currentPage * 16}
                loader={
                    <div
                        className="position-absolute"
                        style={{ left: '50%', bottom: '1rem' }}
                    >
                        <Spinner
                            style={{ position: 'relative', left: '-50%' }}
                            animation="border"
                            variant="primary"
                        />
                    </div>
                }
                scrollThreshold={0.9}
                endMessage={
                    currentPage !== 1 && !loading ? (
                        <div
                            className="position-absolute"
                            style={{ left: '50%', bottom: '1rem' }}
                        >
                            <div style={{ position: 'relative', left: '-50%' }}>
                                <p>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            </div>
                        </div>
                    ) : null
                }
            >
                {eventsFiltered.map((event) => (
                    <div
                        key={event._id}
                        className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-3"
                        style={{
                            opacity: event.upcoming ? 1 : 0.5
                        }}
                    >
                        <EventItemVertical
                            key={event._id}
                            event={event}
                            eventSection="All Events"
                            setViewEvent={setViewEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                    </div>
                ))}
            </InfiniteScroll>
            {eventsFiltered.length || loading ? null : (
                <div>
                    <img
                        alt="share"
                        src={`${process.env.PUBLIC_URL}/assets/interseed_empty_result.svg`}
                        className="my-5 d-inline-block mr-2"
                    />
                    {auth.isAuthenticated ? (
                        <>
                            <div className=" font-weight-bold">
                                Can’t find what you’re looking for? Why not
                                create an event?
                            </div>
                            <div className="mt-5 d-flex justify-content-center">
                                <EventButton
                                    onClick={() => {
                                        setCreateEvent(true);
                                        useEventTracking(
                                            'Events',
                                            'Clicked Create Event',
                                            `${auth.user.username}`
                                        );
                                    }}
                                >
                                    <img
                                        alt="share"
                                        src={`${process.env.PUBLIC_URL}/assets/interseed_plus.svg`}
                                        style={{
                                            height: '1.5rem',
                                            width: '1.5rem',
                                            color: 'white'
                                        }}
                                        className="d-inline-block mr-2"
                                    />
                                    Create Event
                                </EventButton>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className=" font-weight-bold">
                                Can’t find what you’re looking for? Why not sign
                                in and create an event?
                            </div>
                        </>
                    )}
                </div>
            )}
            <EventViewModal
                isPending={false}
                show={viewEvent}
                setShow={setViewEvent}
                eventId={selectedEvent}
                auth={auth}
                getNewEvents={refreshEvents}
            />
            <EventCreateModal
                setUpdateEvents={refreshEvents}
                show={createEvent}
                setShow={setCreateEvent}
                tags={tags}
                setTags={setTags}
                auth={auth}
            />
        </div>
    );
}
