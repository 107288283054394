import optionType from '../interfaces/OptionType';
import { populateArray } from '../utils/commonFunction';

const fundingAmounts = [
    'Below 50,000',
    '50-100k',
    '100-250k',
    '250-500k',
    'Above 500k'
];

const fundingStages = [
    'Idea',
    'Prototype/Proof of Concept',
    'Go to Market',
    'Growth & Expansion'
];

const fundingStatus = ['Open', 'Closed', 'Opening Soon', 'Closing Soon'];

const fundingTypes = ['Grant', 'Equity', 'Prize', 'Loan'];

const focusAreas = [
    'Circular Economy',
    'Energy Transitions',
    'Food & Agriculture',
    'Others',
    'Carbon Emissions',
    'Pollution',
    'Resource Resilience',
    'Health',
    'Education',
    'Peace & Justice',
    'Gender',
    'Water',
    'Sanitation and Hygiene',
    'Environment',
    'Economic Empowerment',
    'Education',
    'Climate Change and Resilience (Environment)',
    'Waste Management (Environment)',
    'Biodiversity (Environment)',
    'Youth'
];

const availableFor = [
    'Brunei',
    'Myanmar',
    'Cambodia',
    'Indonesia',
    'Laos',
    'Malaysia',
    'Philippines',
    'Singapore',
    'Thailand',
    'Vietnam',
    'Timor-Leste',
    'Global',
    'Southeast Asia',
    'Asia Pacific'
];

export const fundingAmountsOptions: optionType[] = populateArray(
    fundingAmounts,
    false
);
export const fundingStagesOptions: optionType[] = populateArray(
    fundingStages,
    false
);
export const fundingStatusOptions: optionType[] = populateArray(
    fundingStatus,
    false
);
export const fundingTypesOptions: optionType[] = populateArray(
    fundingTypes,
    false
);
export const focusAreasOptions: optionType[] = populateArray(focusAreas, true);
export const availableForOptions: optionType[] = populateArray(
    availableFor,
    true
);
