import React from 'react';

const EcosystemProjectIcon = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.9442 8.81902C18.7062 9.09302 18.3612 9.25005 18.0002 9.25005H11.9922C11.3072 9.25005 10.7502 8.69311 10.7502 8.00811V2.00005C10.7502 1.63805 10.9072 1.2941 11.1822 1.0551C11.4532 0.820105 11.8172 0.713014 12.1622 0.762014C15.8172 1.27301 18.7262 4.18206 19.2382 7.83806C19.2872 8.19006 19.1802 8.54802 18.9442 8.81902ZM16.9813 11.6031C16.7503 11.3201 16.4083 11.157 16.0433 11.157H10.0922C9.40322 11.157 8.84222 10.596 8.84222 9.90703V3.95611C8.84222 3.59211 8.68021 3.25 8.39721 3.018C8.12321 2.794 7.76723 2.705 7.42523 2.773C5.27323 3.206 3.33925 4.51101 2.12225 6.35601C0.891248 8.22101 0.46926 10.4791 0.93426 12.7121C1.58926 15.8561 4.14223 18.408 7.28723 19.064C7.88023 19.188 8.47527 19.2491 9.06427 19.2491C10.6923 19.2491 12.2733 18.7821 13.6423 17.8781C15.4873 16.6601 16.7922 14.727 17.2252 12.575C17.2962 12.232 17.2073 11.8771 16.9813 11.6031Z"
            fill="#25314C"
        />
    </svg>
);

export default EcosystemProjectIcon;
