import { faLongArrowAltLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useEffect, useState } from 'react';
import { Card, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    useParams,
    useHistory,
    RouteComponentProps,
    withRouter,
    useLocation,
    Link
} from 'react-router-dom';
import Select from 'react-select';
import styled from 'styled-components';
import EventsInfiniteScroll from '../components/events/EventsInfiniteScroll';
import { color } from '../components/events/EventStyles';
import {
    AuthState,
    StoreState,
    UserProfileState
} from '../redux/actions/types';
import { useEventTracking } from './eventTracking';

const StyledEvents = styled.div`
    padding: 0 4rem;
    padding-bottom: 3.75rem;
    display: flex;
    flex-direction: column;

    .p1 .p2 .p3 {
        font-weight: 400;
        color: rgba(81, 97, 124, 1);
    }

    .h3 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        color: rgba(39, 50, 68, 1);
        margin: 0;
    }

    .p3 {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .select__control {
        border: 0;
        border-radius: 15px;
        background-color: #fafbfc;
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 700;
        color: rgba(81, 97, 124, 1);
    }

    .select__indicator-separator {
        display: none;
    }

    .select__menu {
        font-size: 1rem;
        border-radius: 15px;
        text-align: left;
        overflow: hidden;
    }
`;

const filterOptions = [
    { value: 'network', label: 'Network' },
    { value: 'workshop', label: 'Workshop' },
    { value: 'pitch', label: 'Pitch' },
    { value: 'registered', label: 'Registered' }
];

const sortOptions = [
    { value: 'date', label: 'Date' },
    { value: 'popularity', label: 'Popularity' }
];

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface EventsFiltered extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

function EventsFiltered({ ...props }: EventsFiltered): ReactElement {
    const routeQuery = useQuery();
    const { filter } = useParams<{ filter: string }>();
    const searchInit = routeQuery.get('search');
    const sortInit = routeQuery.get('sort');
    const tagInit = routeQuery.get('tag');
    const history = useHistory();
    const [search, setSearch] = useState(searchInit || '');
    const [filterBy, setFilterBy] = useState<{
        value: string;
        label: string;
    } | null>(null);
    const [sortBy, setSortBy] = useState<{
        value: string;
        label: string;
    } | null>(null);

    const clearTagFilter = () => {
        let route = `${filterBy?.value || 'all'}?`;
        if (search !== '') route += `search=${search}&`;
        if (sortBy?.value) route += `sort=${sortBy.value}`;
        else route = route.substring(0, route.length - 1);
        history.push(route);
    };

    const onSortChange = (value: { value: string; label: string } | null) => {
        if (value?.value != null)
            useEventTracking(
                'Events',
                'Clicked Sort By Filter',
                `${value?.value}`
            );
        setSortBy(value);
        let route = `${filterBy?.value || 'all'}?`;
        if (tagInit) route += `tag=${tagInit}&`;
        if (search !== '') route += `search=${search}&`;
        if (value?.value) route += `sort=${value.value}`;
        else route = route.substring(0, route.length - 1);
        history.push(route);
    };

    const onFilterChange = (value: { value: string; label: string } | null) => {
        if (value?.value != null)
            useEventTracking(
                'Events',
                'Clicked Filter Events',
                `${value?.value}`
            );
        setFilterBy(value);
        let route = `${value?.value || 'all'}?`;
        if (tagInit) route += `tag=${tagInit}&`;
        if (search !== '') route += `search=${search}&`;
        if (sortBy?.value) route += `sort=${sortBy.value}`;
        else route = route.substring(0, route.length - 1);
        history.push(route);
    };

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== '')
            useEventTracking(
                'Events',
                'Search (Events)',
                `${event.target.value}`
            );
        setSearch(event.target.value);
        let route = `${filterBy?.value || 'all'}?`;
        if (tagInit) route += `tag=${tagInit}&`;
        if (search !== '') route += `search=${event.target.value}&`;
        if (sortBy?.value) route += `sort=${sortBy.value}`;
        else route = route.substring(0, route.length - 1);
        history.push(route);
    };

    useEffect(() => {
        let route = `${filterBy?.value || 'all'}?`;
        if (tagInit) route += `tag=${tagInit}&`;
        if (search !== '') route += `search=${search}&`;
        if (sortBy?.value) route += `sort=${sortBy.value}`;
        else route = route.substring(0, route.length - 1);
        history.push(route);
    }, [tagInit]);

    return (
        <StyledEvents style={{ gap: '1rem' }}>
            <div className="text-left back-link">
                <Link to="/events">
                    <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Events
                </Link>
            </div>
            <Row className="justify-content-between">
                <Col xs="auto" className="flex align-items-center">
                    <div className="h2 mb-0">Events</div>
                </Col>
            </Row>
            <Row>
                <Card className="col-12">
                    <div className="d-flex p-3 justify-content-between align-items-center">
                        <div className="h3 text-capitalize m-0">
                            {filter} Events
                        </div>
                        <div
                            className="d-flex align-items-center"
                            style={{ gap: '1rem' }}
                        >
                            {tagInit && (
                                <button
                                    className="badge badge-pill badge-primary px-3 py-2 "
                                    style={{
                                        background: '#EBF4FF',
                                        color: color.shade2,
                                        fontSize: '1rem',
                                        cursor: 'pointer',
                                        border: 0
                                    }}
                                    onClick={clearTagFilter}
                                    type="button"
                                >
                                    Filtered By Tag
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        className="ml-2"
                                    />
                                </button>
                            )}
                            <div
                                style={{
                                    width: '10rem'
                                }}
                            >
                                <Select
                                    className="h5 m-0"
                                    classNamePrefix="select"
                                    name="type"
                                    options={sortOptions}
                                    value={sortBy}
                                    placeholder="Sort By"
                                    isClearable
                                    onChange={onSortChange}
                                    style={{
                                        backgroundColor: '#FFF',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    width: '10rem'
                                }}
                            >
                                <Select
                                    className="h5 m-0"
                                    classNamePrefix="select"
                                    name="type"
                                    options={
                                        props.auth.isAuthenticated
                                            ? filterOptions
                                            : filterOptions.slice(0, -1)
                                    }
                                    value={filterBy}
                                    isClearable
                                    placeholder="Filter Events"
                                    onChange={onFilterChange}
                                    style={{
                                        backgroundColor: '#FFF',
                                        fontWeight: 'bold'
                                    }}
                                />
                            </div>
                            <div>
                                <FormControl
                                    className="h5 m-0 position-relative"
                                    required
                                    name="title"
                                    type="text"
                                    style={{
                                        backgroundColor: '#FAFBFC',
                                        fontWeight: 'bold',
                                        paddingLeft: '1.25rem',
                                        borderRadius: '15px',
                                        border: 0,
                                        margin: 0,
                                        width: '18rem'
                                    }}
                                    maxLength={100}
                                    value={search}
                                    onChange={onSearchChange}
                                    placeholder="Search for an event"
                                />
                            </div>
                        </div>
                    </div>
                    <hr
                        className="mb-3"
                        style={{ backgroundColor: '#f3f3f3', margin: 0 }}
                    />
                    <Row className="px-3">
                        <EventsInfiniteScroll auth={props.auth} />
                    </Row>
                </Card>
            </Row>
        </StyledEvents>
    );
}

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(EventsFiltered));
