import { Popup } from 'react-leaflet';
import styled from 'styled-components';

export default styled(Popup)`
    .leaflet-popup-content-wrapper {
        border-radius: 10px;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .leaflet-popup-content {
        width: auto !important;
    }

    .leaflet-popup-tip-container {
        display: none;
    }

    .leaflet-popup-close-button {
        display: none;
    }
`;
