/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import JobsList from '../components/opportunities/JobsList';
import CreateInternshipWidget from '../components/opportunities/CreateInternshipWidget';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

const Jobs: React.FC<authProps> = ({ ...props }) => {
    // Check whether user is onboarded
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    return (
        <div>
            <Container className="text-left">
                <Row>
                    <Col md={8}>
                        <h3>Job Opportunities</h3>
                        <JobsList />
                    </Col>
                    <Col>
                        <h3>Job Opportunities</h3>
                        <CreateInternshipWidget />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(Jobs));
