/* eslint-disable react/require-default-props */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import IOrganisation from '../../interfaces/IOrganisation';
import { StoreState } from '../../redux/actions/types';

interface orgProp {
    organisation: IOrganisation;
    onClick?: (organisation: IOrganisation) => void;
    authId?: string;
}

const Styles = styled.div`
    .list-group-item {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;
        margin: 0;
        padding: 0.5rem 0;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .badge {
        font-size: 16px;
    }

    .container {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
    }

    .img img {
        width: 60px;
        height: 60px;
        margin: 0 0.5rem;
        border-radius: 50%;
        object-fit: cover;
    }

    .organisation-content {
        display: flex;
        flex-direction: column;
        margin-bottom: -20px;
    }

    .organisation-content h2 {
        font-size: 1rem;
        margin-bottom: 0.2rem;
        font-weight: bold;
    }

    .organisation-content h3 {
        font-size: 0.8rem;
        margin-bottom: 0.4rem;
    }

    .organisation-content h4 {
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }

    .organisation-content p .focus-area-badge {
        background-color: #f8f9fa;
        font-weight: bold;
        font-size: 0.8rem;
        border-radius: 10px;
        padding: 0.4rem;
        display: inline-block;
    }

    .organisation-btn {
        padding: 0.5rem 0.5rem;
        background: transparent;
        margin: 0 0.5rem 0 auto;
        border-color: #007bff;
        border-radius: 5px;
        color: #007bff;
        font-size: 0.8rem;
    }
`;

const SingleOrganisationView: React.FC<orgProp> = ({
    organisation,
    onClick,
    authId
}: orgProp) => {
    const link = `/organisation/${organisation._id}`;
    return (
        <>
            <Styles>
                <ListGroup.Item
                    onClick={() => onClick && onClick(organisation)}
                    as={Link}
                    to={onClick ? '#' : link}
                >
                    <div className="container">
                        <div className="img">
                            <img
                                src={
                                    organisation.logo
                                        ? organisation.logo.location
                                        : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                                }
                                alt=""
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                }}
                            />
                        </div>
                        <div className="organisation-content">
                            <h2>{organisation.name}</h2>
                            <h3>
                                {`${organisation.description.slice(0, 50)}
                                ...`}
                            </h3>
                            <h4 className="text-muted">
                                {organisation.country}
                            </h4>
                            <p className="mt-2">
                                {organisation.focusAreas.map((focus) => (
                                    <div className="focus-area-badge">
                                        {focus}
                                    </div>
                                ))}
                            </p>
                        </div>
                        <button
                            type="button"
                            className="mt-2 organisation-btn"
                            onClick={(ev) => {
                                // handleFollow(authId);
                                ev.preventDefault();
                            }}
                        >
                            {authId ? 'Follow' : 'Sign Up'}
                        </button>
                    </div>
                </ListGroup.Item>
            </Styles>
        </>
    );
};

export const AuthedSingleOrganisationView = connect((state: StoreState) => ({
    authId: state.auth.user._id
}))(SingleOrganisationView);

export default SingleOrganisationView;
