/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
// import styled from 'styled-components';
import { Container, Row, Col, Card, Image } from 'react-bootstrap';

import { useEventTracking } from './eventTracking';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

const Resources: React.FC<authProps> = ({ ...props }) => {
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status]);

    return (
        <Container className="mb-4">
            <h1 className="mb-4">What resources are you looking for?</h1>
            <Row>
                <Col sm={4}>
                    <Card className="p-3 mb-4">
                        <a
                            target="_self"
                            rel="noreferrer"
                            href={`${process.env.PUBLIC_URL}/landscapes`}
                            onClick={() => {
                                const user = props.auth.user.username
                                    ? props.auth.user.username
                                    : 'Not Logged In';
                                useEventTracking(
                                    'Resources',
                                    'Clicked Content',
                                    `${user}`
                                );
                            }}
                        >
                            <Image
                                className="mb-4"
                                src={`${process.env.PUBLIC_URL}/assets/content.png`}
                                alt="Market Research"
                                fluid
                            />
                            <h1>Market Research</h1>
                            <br />
                        </a>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card className="p-3 mb-4">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://interseed.transistor.fm/"
                            onClick={() => {
                                const user = props.auth.user.username
                                    ? props.auth.user.username
                                    : 'Not Logged In';
                                useEventTracking(
                                    'Resources',
                                    'Clicked Podcasts',
                                    `${user}`
                                );
                            }}
                        >
                            <Image
                                className="mb-4"
                                src={`${process.env.PUBLIC_URL}/assets/podcasts.png`}
                                alt="Podcasts"
                                fluid
                            />
                            <h1>Podcasts</h1>
                            <br />
                        </a>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card className="text-muted p-3 mb-4">
                        {/* <a target="_blank" rel="noreferrer" href='https://interseed.ghost.io/all-articles'> */}
                        <Image
                            className="mb-4"
                            src={`${process.env.PUBLIC_URL}/assets/mentorship.png`}
                            alt="Mentorship"
                            fluid
                        />
                        <h1>Mentorship</h1>
                        <p className="mb-0">(Coming Soon)</p>
                        {/* </a>  */}
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(Resources));
