import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

export const useEventTracking = (
    evCategory: string,
    evAction: string,
    evLabel: string
) => {
    let trackingIdGa4: string =
        process.env.REACT_APP_IS_DEPLOYMENT === 'true'
            ? (process.env.REACT_APP_DEPLOYMENT_GA4_TRACKING_ID as string)
            : (process.env.REACT_APP_DEV_GA4_TRACKING_ID as string);

    let trackingId: string =
        process.env.REACT_APP_IS_DEPLOYMENT === 'true'
            ? (process.env
                  .REACT_APP_DEPLOYMENT_GOOGLE_ANALYTICS_TRACKING_ID as string)
            : (process.env
                  .REACT_APP_DEV_GOOGLE_ANALYTICS_TRACKING_ID as string);

    ReactGA.ga((tracker) => {
        const devIds = [
            '2066269666.1625038575',
            '1720690886.1626353533',
            '1780118932.1624453263',
            '560514541.1609813473',
            '1833658964.1623769062',
            '1809401172.1609771667'
        ];

        trackingIdGa4 =
            window.location.hostname !== 'localhost' ||
            !devIds.includes(tracker.get('clientId'))
                ? (process.env.REACT_APP_DEPLOYMENT_GA4_TRACKING_ID as string)
                : (process.env.REACT_APP_DEV_GA4_TRACKING_ID as string);

        trackingId =
            window.location.hostname !== 'localhost' ||
            !devIds.includes(tracker.get('clientId'))
                ? (process.env
                      .REACT_APP_DEPLOYMENT_GOOGLE_ANALYTICS_TRACKING_ID as string)
                : (process.env
                      .REACT_APP_DEV_GOOGLE_ANALYTICS_TRACKING_ID as string);
    });

    ReactGA.initialize(trackingId);
    ReactGA.event({
        category: evCategory,
        action: evAction,
        label: evLabel
    });

    ReactGA4.initialize(trackingIdGa4);
    ReactGA4.event({
        category: evCategory,
        action: evAction,
        label: evLabel
    });
    // console.log('GA event:', evCategory, ':', evAction, ':', evLabel);
};

const volOppFilterType = {
    generalArea: 'General Area',
    primaryTags: 'Area of Interest',
    secondaryTags: 'Skills Preferred',
    opportunityLength: 'Commitment'
};

export const sendDashboardSearch = (mode: any, searchParams: any) => {
    const filters = JSON.parse(JSON.stringify(searchParams));
    Object.entries(filters).forEach((data) => {
        // handle query search
        if (data[0] === 'query') {
            useEventTracking('Dashboard', `Search (${mode})`, `${data[1]}`);
        }
        // handle filters
        else {
            // handle volunteering opportunities filters
            let filterType = data[0];
            if (mode === 'Volunteering Opportunities') {
                filterType = volOppFilterType[filterType];
            }
            if (filterType) {
                const evAction = `Clicked ${filterType} filter (${mode})`;
                useEventTracking('Dashboard', evAction, `${data[1]}`);
            }
        }
    });
};

/**
 * Checks where user upvotes landscapes from window pathname
 * and by comparing landscape author to auth id
 * @param path
 * @param authId
 * @param landscapeAuthor
 * @param upvoteAction
 * @returns
 */
export const checkLandscapeUpvoteSource = (
    path: string,
    authId: string,
    landscapeAuthor: string,
    upvoteAction: string
) => {
    let category = 'Landscapes';
    let action =
        upvoteAction === 'put'
            ? 'Upvoted Landscape'
            : 'Removed Landscape Upvote';
    if (
        `${path.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` === 'landscapes' &&
        landscapeAuthor === authId
    ) {
        action =
            upvoteAction === 'put'
                ? 'Upvoted Own Landscape'
                : 'Removed Own Landscape Upvote';
    } else if (
        `${path.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` === 'profile' &&
        landscapeAuthor === authId
    ) {
        category = 'My Profile';
        action =
            upvoteAction === 'put'
                ? 'Upvoted Own Landscape'
                : 'Removed Own Landscape Upvote';
    } else if (
        `${window.location.pathname.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` ===
        'profile'
    ) {
        category = 'Community';
    }
    return [category, action];
};
