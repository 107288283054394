/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import {
    Card,
    ListGroup,
    FormControl,
    Container,
    Button,
    Row,
    Col,
    ButtonGroup,
    ToggleButton,
    FormCheck,
    Alert
} from 'react-bootstrap';
import Select from 'react-select';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EmailUserItem from './EmailUserItem';
import EmailOrganisationItem from './EmailOrganisationItem';
import IUser from '../../interfaces/IUser';
import IOrganisation from '../../interfaces/IOrganisation';
import UserFilter from './UserFilter';
import OrganisationFilter from './OrganisationFilter';
import createdOnOptions, {
    getStrToDateMapping
} from '../../options/createdOnSelectOptions';

const Styles = styled.div`
    .card-body {
        padding: 0;
    }
    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }
`;

interface Props {
    authId: string;
}

export interface filterType {
    userCountries: string[];
    userInterests: string[];
    userLookingFor: string[];
    orgStages: string[];
    orgFocusAreas: string[];
    orgLookingFor: string[];
    createdOn: string;
}

export interface emailType {
    user: string[];
    org: string[];
}

export const EmailList: React.FC<Props> = ({ authId }: Props) => {
    const [masterUserList, setMasterUserList] = useState<IUser[]>([]);
    const [masterOrgsList, setMasterOrgsList] = useState<IOrganisation[]>([]);
    const [userList, setUserList] = useState<IUser[]>([]);
    const [orgsList, setOrgsList] = useState<IOrganisation[]>([]);
    const [initFilterCheck, setInitFilterCheck] = useState(false);
    const [search, setSearch] = useState('');
    const [shownUsers, setShownUsers] = useState(3);
    const [shownOrgs, setShownOrgs] = useState(3);

    const [sort, setSort] = useState('all');
    const [emptyUserListText, setEmptyUserListText] = useState(
        'Loading users...'
    );
    const [emptyOrgsListText, setEmptyOrgsListText] = useState(
        'Loading organisations...'
    );

    const [filter, setFilter] = useState<filterType>({
        userCountries: [],
        userInterests: [],
        userLookingFor: [],
        orgStages: [],
        orgFocusAreas: [],
        orgLookingFor: [],
        createdOn: ''
    });

    const [emailList, setEmailList] = useState<emailType>({
        user: [],
        org: []
    });

    const [areAllUsersSelected, setAreAllUsersSelected] = useState(false);
    const [areAllOrgsSelected, setAreAllOrgsSelected] = useState(false);

    const [isCopied, setIsCopied] = useState(false);

    // Mapping of string date options to date objects
    const dateOptionsMapping = getStrToDateMapping(new Date());

    // If userItem or orgItem is ticked, add their emails to the emailList, else remove their emails
    const handleCheckbox = (
        email: string,
        type: string,
        isChecked: boolean
    ) => {
        let temp = [...emailList[type]];
        if (isChecked) {
            temp.push(email);
            setEmailList({ ...emailList, [type]: temp });
        } else {
            temp = temp.filter((item) => item !== email);
            setEmailList({ ...emailList, [type]: temp });
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearch(value);
    };

    // Check if copy button should be disabled
    const checkCopyDisabled = () => {
        if (sort === 'all') {
            return emailList.user.length + emailList.org.length === 0;
        }

        if (sort === 'users') {
            return emailList.user.length === 0;
        }

        return emailList.org.length === 0;
    };

    // Check which emailList should be copied
    const getCopiedEmailList = () => {
        if (sort === 'all') {
            return emailList.user.concat(emailList.org);
        }

        if (sort === 'users') {
            return emailList.user;
        }

        return emailList.org;
    };

    // Main function for filtering and searching
    const filterMainFunction = () => {
        const users: IUser[] = [...masterUserList];
        const orgs: IOrganisation[] = [...masterOrgsList];
        let userEmailList: string[] = [...emailList.user];
        let orgEmailList: string[] = [...emailList.org];

        const lastIncludedDate: Date = dateOptionsMapping[filter.createdOn];

        setEmptyUserListText('Loading users...');
        setEmptyOrgsListText('Loading organisations...');

        // For Users
        let i = 0;
        let userLength = users.length;

        while (i < userLength) {
            let searchCheck = false;
            let userCountryCheck = false;
            let userInterestCheck = false;
            let userLookingForCheck = false;
            let createdOnCheck = false;

            // Check User Countries
            if (filter.userCountries.length > 0) {
                for (let j = 0; j < filter.userCountries.length; j += 1) {
                    if (users[i].country === filter.userCountries[j]) {
                        userCountryCheck = true;
                        break;
                    }
                }
            } else userCountryCheck = true;

            // Check User Interests
            if (filter.userInterests.length > 0) {
                for (let j = 0; j < users[i].interestAreas.length; j += 1) {
                    for (let k = 0; k < filter.userInterests.length; k += 1) {
                        if (
                            users[i].interestAreas[j] ===
                            filter.userInterests[k]
                        ) {
                            userInterestCheck = true;
                            break;
                        }
                    }
                    if (userInterestCheck === true) break;
                }
            } else userInterestCheck = true;

            // Check User Looking For
            if (filter.userLookingFor.length > 0) {
                for (let j = 0; j < users[i].lookingFor.length; j += 1) {
                    for (let k = 0; k < filter.userLookingFor.length; k += 1) {
                        if (
                            users[i].lookingFor[j] === filter.userLookingFor[k]
                        ) {
                            userLookingForCheck = true;
                            break;
                        }
                    }
                    if (userLookingForCheck === true) break;
                }
            } else userLookingForCheck = true;

            // Check Search
            if (search.length > 0) {
                if (
                    users[i].firstName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    users[i].lastName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    users[i].aboutMyself
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else {
                searchCheck = true;
            }

            // Check User Created On
            createdOnCheck =
                filter.createdOn.length === 0 ||
                new Date(users[i].createdAt) >= lastIncludedDate;

            // Results of Check
            if (
                !(
                    userCountryCheck &&
                    userInterestCheck &&
                    userLookingForCheck &&
                    searchCheck &&
                    createdOnCheck
                )
            ) {
                const delUserEmail = users[i].email;
                userEmailList = userEmailList.filter(
                    (email) => email !== delUserEmail
                );

                users.splice(i, 1);
                i -= 1;
                userLength = users.length;
            }

            i += 1;
        }

        setUserList(users.slice(0).reverse());

        // For Organisations
        let j = 0;
        let orgLength = orgs.length;

        while (j < orgLength) {
            let searchCheck = false;
            let orgStagesCheck = false;
            let orgFocusAreasCheck = false;
            let orgLookingForCheck = false;
            let createdOnCheck = false;

            // Check Organisation Stage
            if (filter.orgStages.length > 0) {
                for (let k = 0; k < filter.orgStages.length; k += 1) {
                    if (orgs[j].startupStage === filter.orgStages[k]) {
                        orgStagesCheck = true;
                        break;
                    }
                }
            } else orgStagesCheck = true;

            // Check Organisation Focus Areas
            if (filter.orgFocusAreas.length > 0) {
                for (let k = 0; k < orgs[j].focusAreas.length; k += 1) {
                    for (let l = 0; l < filter.orgFocusAreas.length; l += 1) {
                        if (orgs[j].focusAreas[k] === filter.orgFocusAreas[l]) {
                            orgFocusAreasCheck = true;
                            break;
                        }
                    }
                    if (orgFocusAreasCheck === true) break;
                }
            } else orgFocusAreasCheck = true;

            // Check Organisation Looking For
            if (filter.orgLookingFor.length > 0) {
                for (let k = 0; k < orgs[j].lookingFor.length; k += 1) {
                    for (let l = 0; l < filter.orgLookingFor.length; l += 1) {
                        if (orgs[j].lookingFor[k] === filter.orgLookingFor[l]) {
                            orgLookingForCheck = true;
                            break;
                        }
                    }
                    if (orgLookingForCheck === true) break;
                }
            } else orgLookingForCheck = true;

            // Check Search
            if (search.length > 0) {
                if (
                    orgs[j].name.toLowerCase().indexOf(search.toLowerCase()) !==
                        -1 ||
                    orgs[j].description
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else {
                searchCheck = true;
            }

            // Check User Created On
            createdOnCheck =
                filter.createdOn.length === 0 ||
                new Date(orgs[j].createdAt) >= lastIncludedDate;

            // Results of Check
            if (
                !(
                    orgFocusAreasCheck &&
                    orgLookingForCheck &&
                    orgStagesCheck &&
                    searchCheck &&
                    createdOnCheck
                )
            ) {
                const delOrgEmail = orgs[j].email;
                orgEmailList = orgEmailList.filter(
                    (email) => email !== delOrgEmail
                );

                orgs.splice(j, 1);
                j -= 1;
                orgLength = orgs.length;
            }
            j += 1;
        }

        setEmailList({
            user: userEmailList,
            org: orgEmailList
        });

        setOrgsList(orgs.slice(0).reverse());

        setEmptyUserListText('No users found.');
        setEmptyOrgsListText('No organisations found.');
    };

    // eslint-disable-next-line
    const handleFilterSelect = (newValue: any, type: string) => {
        if (type === 'createdOn') {
            setFilter({
                ...filter,
                createdOn: newValue.value
            });
        } else {
            const value = newValue.map((item) => item.value);

            setFilter({
                ...filter,
                [type]: value
            });
        }
    };

    // To convert email array into long string
    const convertArrToString = (strArr: string[]) => {
        let emailStr = '';
        for (let i = 0; i < strArr.length; i += 1) {
            emailStr += `${strArr[i]} `;
        }
        return emailStr;
    };

    const handleMasterCheckboxSelect = () => {
        if (areAllOrgsSelected && areAllUsersSelected) {
            setEmailList({
                user: [],
                org: []
            });
        } else {
            const tempUserList = userList.map((user) => user.email);
            const tempOrgsList = orgsList.map((org) => org.email);
            setEmailList({
                user: tempUserList,
                org: tempOrgsList
            });
        }

        setAreAllUsersSelected(!(areAllOrgsSelected && areAllUsersSelected));
        setAreAllOrgsSelected(!(areAllOrgsSelected && areAllUsersSelected));
    };

    // *** useEffects ***

    // Upon Initialisation
    useEffect(() => {
        axios.get(`/api/users/`).then(({ data }) => {
            const filteredData = data.filter(
                (item) => item.status === 'Active' && !item.admin
            );

            for (let i = 0; i < filteredData.length; i += 1) {
                if (filteredData[i]._id === authId) {
                    filteredData.push(filteredData.splice(i, 1)[0]);
                    break;
                }
            }
            setMasterUserList(filteredData);
        });

        axios.get(`/api/organisations/`).then(({ data }) => {
            setMasterOrgsList(data);
        });
    }, []);

    // Whenever filter is selected or Search is filled
    useEffect(() => {
        if (initFilterCheck === false) {
            setInitFilterCheck(true);
        } else filterMainFunction();
    }, [search, filter, masterUserList, masterOrgsList]);

    useEffect(() => {
        setAreAllUsersSelected(
            userList.length !== 0 && emailList.user.length === userList.length
        );
        setAreAllOrgsSelected(
            orgsList.length !== 0 && emailList.org.length === orgsList.length
        );
    }, [emailList, userList, orgsList]);

    useEffect(() => {
        if (isCopied) {
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }
    }, [isCopied]);

    return (
        <Styles>
            <Card className="mb-4">
                <Card.Header className="p-4">
                    <FormControl
                        placeholder="Search..."
                        name="search"
                        type="text"
                        value={search}
                        onChange={handleSearch}
                        className="mb-4"
                    />
                    <Select
                        options={createdOnOptions}
                        placeholder="Account Created On"
                        className="basic-single mb-4"
                        classNamePrefix="select"
                        onChange={(newValue) =>
                            handleFilterSelect(newValue, 'createdOn')
                        }
                    />
                    {sort === 'all' ? <h5>User Filter</h5> : null}
                    {sort !== 'organisations' ? (
                        <UserFilter
                            handleFilterSelect={handleFilterSelect}
                            userCountries={filter.userCountries}
                            userInterests={filter.userInterests}
                            userLookingFor={filter.userLookingFor}
                        />
                    ) : null}
                    {sort === 'all' ? <h5>Organisation Filter</h5> : null}
                    {sort !== 'users' ? (
                        <OrganisationFilter
                            handleFilterSelect={handleFilterSelect}
                            orgStages={filter.orgStages}
                            orgFocusAreas={filter.orgFocusAreas}
                            orgLookingFor={filter.orgLookingFor}
                        />
                    ) : null}
                    <Row className="text-center">
                        <Col>
                            <ButtonGroup aria-label="Sort Buttons">
                                <Button
                                    variant={
                                        sort === 'all'
                                            ? 'primary'
                                            : 'outline-primary'
                                    }
                                    onClick={() => setSort('all')}
                                >
                                    All
                                </Button>
                                <Button
                                    variant={
                                        sort === 'users'
                                            ? 'primary'
                                            : 'outline-primary'
                                    }
                                    onClick={() => setSort('users')}
                                >
                                    Users
                                </Button>
                                <Button
                                    variant={
                                        sort === 'organisations'
                                            ? 'primary'
                                            : 'outline-primary'
                                    }
                                    onClick={() => setSort('organisations')}
                                >
                                    Organisations
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="p-3">
                    {sort === 'all' &&
                    userList.length + orgsList.length !== 0 ? (
                        <Button
                            className="mb-2"
                            id="select-all-users-orgs"
                            // type="radio"
                            variant={
                                areAllUsersSelected && areAllOrgsSelected
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            onClick={handleMasterCheckboxSelect}
                        >
                            Select all
                        </Button>
                    ) : null}
                    {sort === 'users' && userList.length !== 0 ? (
                        <Button
                            className="mb-2"
                            id="select-all-users"
                            variant={
                                areAllUsersSelected
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            onClick={() => {
                                const tempUserList = userList.map(
                                    (user) => user.email
                                );
                                if (areAllUsersSelected) {
                                    setEmailList({
                                        ...emailList,
                                        user: []
                                    });
                                } else {
                                    setEmailList({
                                        ...emailList,
                                        user: tempUserList
                                    });
                                }
                                setAreAllUsersSelected(!areAllUsersSelected);
                            }}
                        >
                            Select All Users
                        </Button>
                    ) : null}

                    {sort === 'organisations' && orgsList.length !== 0 ? (
                        <Button
                            className="mb-2"
                            id="select-all-users"
                            variant={
                                areAllOrgsSelected
                                    ? 'primary'
                                    : 'outline-primary'
                            }
                            onClick={() => {
                                const tempOrgsList = orgsList.map(
                                    (org) => org.email
                                );
                                if (areAllOrgsSelected) {
                                    setEmailList({
                                        ...emailList,
                                        org: []
                                    });
                                } else {
                                    setEmailList({
                                        ...emailList,
                                        org: tempOrgsList
                                    });
                                }
                                setAreAllOrgsSelected(!areAllOrgsSelected);
                            }}
                        >
                            Select All Organisations
                        </Button>
                    ) : null}
                    {sort !== 'organisations' ? (
                        <div>
                            <h4 className="ml-3 mt-3">Users</h4>
                            <ListGroup variant="flush">
                                {userList
                                    .slice(0, shownUsers)
                                    .map((user, i) => (
                                        <div
                                            className={
                                                userList.slice(0, shownUsers)[
                                                    i + 1
                                                ]
                                                    ? 'list-item-border'
                                                    : ''
                                            }
                                        >
                                            <EmailUserItem
                                                key={user._id}
                                                user={user}
                                                isMasterChecked={
                                                    areAllUsersSelected
                                                }
                                                userEmailList={emailList.user}
                                                handleCheckbox={handleCheckbox}
                                            />
                                        </div>
                                    ))}
                            </ListGroup>
                            {userList.length === 0 ? (
                                <Container>
                                    <p className="mt-3">{emptyUserListText}</p>
                                </Container>
                            ) : null}
                            {shownUsers < userList.length ? (
                                <Button
                                    type="button"
                                    variant="light"
                                    onClick={() => {
                                        setShownUsers(shownUsers + 5);
                                    }}
                                    block
                                >
                                    Show More
                                </Button>
                            ) : null}
                        </div>
                    ) : null}

                    {sort !== 'users' ? (
                        <div>
                            <h4 className="ml-3 mt-5">Organisations</h4>
                            <ListGroup variant="flush">
                                {orgsList
                                    .slice(0, shownOrgs)
                                    .map((organisation, i) => (
                                        <div
                                            className={
                                                orgsList.slice(0, shownOrgs)[
                                                    i + 1
                                                ]
                                                    ? 'list-item-border'
                                                    : ''
                                            }
                                        >
                                            <EmailOrganisationItem
                                                key={organisation._id}
                                                organisation={organisation}
                                                isMasterChecked={
                                                    areAllOrgsSelected
                                                }
                                                orgEmailList={emailList.org}
                                                handleCheckbox={handleCheckbox}
                                            />
                                        </div>
                                    ))}
                            </ListGroup>
                            {orgsList.length === 0 ? (
                                <Container>
                                    <p className="mt-3">{emptyOrgsListText}</p>
                                </Container>
                            ) : null}
                            {shownOrgs < orgsList.length ? (
                                <Button
                                    type="button"
                                    variant="light"
                                    onClick={() => {
                                        setShownOrgs(shownOrgs + 5);
                                    }}
                                    block
                                >
                                    Show More
                                </Button>
                            ) : null}
                        </div>
                    ) : null}
                    <CopyToClipboard
                        text={convertArrToString(getCopiedEmailList())}
                        onCopy={() => setIsCopied(true)}
                    >
                        <Button disabled={checkCopyDisabled()}>
                            Copy Email to Clipboard
                        </Button>
                    </CopyToClipboard>
                    {isCopied ? <Alert variant="success">Copied</Alert> : null}
                </Card.Body>
            </Card>
        </Styles>
    );
};

export default EmailList;
