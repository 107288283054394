import axios from 'axios';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Ecosystem } from '../../interfaces/Ecosystem';
import { OrgEcosystem } from '../../interfaces/IOrganisation';
import JoinEcosystemModalAsOrg from './ecosystem/JoinEcosystemModalAsOrg';

const StyledContainer = styled(Container)`
    .form-row {
        margin-left: 1px;
    }

    label.form-label {
        color: #6c757d;
        font-size: 14px;
        font-weight: 400;
    }

    input.form-control {
        width: 50%;
        background: #ffffff;
        border: 1px solid #ced4da;
        border-radius: 3px;
        padding: 9px 12px;
        margin-right: 14px;

        &::placeholder {
            color: #6c757d;
        }
    }

    .btn.btn-primary {
        padding: 8px 38px;
        font-weight: 600;
    }

    .imgcontainer {
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;

        img {
            object-fit: contain;
            width: 100%;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #dee2e6;
            aspect-ratio: 1;
            max-height: 4.5rem;
            max-width: 4.5rem;
        }
    }
`;

const ListHeading = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #002a52;
`;

const Description = styled.p`
    margin-top: 1rem;
    color: #315578;
`;

const FocusAreas = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    & > div {
        background-color: #f5faff;
        color: #2b6098;
        padding: 7px 9px;
        border: 1px solid #cecece;
        border-radius: 8px;
        font-size: 16px;
    }
`;

interface Props {
    newEcosystem: OrgEcosystem;
    setNewEcosystem: (ecosystem: OrgEcosystem) => void;
}

const JoinEcosystemAsOrg: React.FC<Props> = ({
    newEcosystem,
    setNewEcosystem
}) => {
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [ecosystem, setEcosystem] = useState<Ecosystem>({} as Ecosystem);
    const [error, setError] = useState<string>('');
    const [show, setShow] = useState<boolean>(false);

    const handleAddEcosystem = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        axios
            .get<Ecosystem>(`/api/ecosystems/verification/${verificationCode}`)
            .then(({ data }) => {
                setEcosystem(data);
                setVerificationCode('');
                setShow(true);
            })
            .catch((err) => setError(err.response.data.message));

        setError('');
    };

    return (
        <>
            <StyledContainer className="py-0 px-0">
                <p className="mt-0 mb-1 mx-0">
                    Add an Ecosystem that this Organization is affiliated with,
                    or is a part of.
                </p>
                <Form.Row>
                    <Form.Label htmlFor="verificationCode">
                        Verification Code
                    </Form.Label>
                </Form.Row>
                <Form.Row>
                    <Form.Control
                        type="text"
                        id="verificationCode"
                        aria-describedby="verificationCode"
                        placeholder="Code"
                        onChange={(e) => setVerificationCode(e.target.value)}
                        value={verificationCode}
                    />
                    <Button
                        type="submit"
                        className="mb-3"
                        onClick={handleAddEcosystem}
                    >
                        Add
                    </Button>
                </Form.Row>
                {newEcosystem._id && (
                    <Row>
                        <Col sm={2} className="imgcontainer">
                            <Image
                                className="img-thumbnail"
                                src={
                                    ecosystem.logo
                                        ? ecosystem.logo.location
                                        : `${process.env.PUBLIC_URL}/assets/interseed.png`
                                }
                                alt="Ecosystem"
                                roundedCircle
                                thumbnail
                                fluid
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                }}
                            />
                        </Col>
                        <Col className="my-auto">
                            <ListHeading>{ecosystem.name}</ListHeading>
                            {ecosystem.focusAreas && (
                                <FocusAreas className="mt-2">
                                    {ecosystem.focusAreas
                                        .sort()
                                        .map((focus) => (
                                            <div>{focus}</div>
                                        ))}
                                </FocusAreas>
                            )}
                            <Description>{ecosystem.description}</Description>
                        </Col>
                        <Col sm={2} className="text-center my-auto">
                            <Button
                                variant="outline-danger"
                                className="mt-2 ml-3"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setNewEcosystem({} as OrgEcosystem);
                                }}
                            >
                                Remove
                            </Button>
                        </Col>
                    </Row>
                )}
            </StyledContainer>
            {error && <p>{error}</p>}
            <JoinEcosystemModalAsOrg
                show={show}
                ecosystem={ecosystem}
                handleClose={() => setShow(false)}
                setNewEcosystem={setNewEcosystem}
            />
        </>
    );
};

export default JoinEcosystemAsOrg;
