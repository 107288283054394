import ILandscape from '../interfaces/ILandscape';
import IOrganisation from '../interfaces/IOrganisation';
import IOpportunity from '../interfaces/IOpportunity';
import IUser from '../interfaces/IUser';
import IRegisterUserInput from '../interfaces/IRegisterUserInput';
import { IEvent } from '../interfaces/IEvent';
import IVolunteerOpportunity from '../interfaces/IVolunteerOpportunity';
import { Ecosystem } from '../interfaces/Ecosystem';

export default function getSrc(item) {
    let src = '';
    if (item.profilePicture) {
        src = item.profilePicture.location;
    } else if (item.socialPicture) {
        src = item.socialPicture;
    } else {
        src = `${process.env.PUBLIC_URL}/assets/profile.png`;
    }
    return src;
}

export const populateArray = (options: string[], sort: boolean) => {
    if (sort) options.sort();

    return options.map((text: string) => {
        const temp = { value: text, label: text };
        return temp;
    });
};

export const whitespaceCheck = (
    data:
        | ILandscape
        | IOpportunity
        | IUser
        | IOrganisation
        | IEvent
        | IRegisterUserInput
        | IVolunteerOpportunity
        | Ecosystem,
    property: string[]
) => {
    for (let i = 0; i < property.length; i += 1) {
        if (data[property[i]].toString().trim() === '') {
            return false;
        }
    }
    return true;
};

// To check whether the user / organisation's email is in the email list
export const checkEmail = (emailList: string[], item: IUser | IOrganisation) =>
    emailList.includes(item.email);
