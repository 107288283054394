/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Link,
    withRouter,
    RouteComponentProps,
    useParams
} from 'react-router-dom';
import {
    Container,
    Card,
    Form,
    Button,
    Col,
    InputGroup,
    Row
} from 'react-bootstrap';
import { format, parse } from 'date-fns';
import styled from 'styled-components';
import Select from 'react-select';
import Textarea from 'react-expanding-textarea';
import { useAlert } from 'react-alert';
import { whitespaceCheck } from '../../utils/commonFunction';
import UploadImage from '../../components/events/UploadImage';

import IVolunteerOpportunity, {
    VolunteerOpportunityBlankObj
} from '../../interfaces/IVolunteerOpportunity';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../../redux/actions/types';

// Select Options
import {
    primaryCategoriesOptions,
    secondaryCategoriesOptions,
    generalAreaOptions,
    sustainabilityVerticalsOptions,
    countriesOptions
} from '../../options/landscapeSelectOptions';
import { skillsOptions } from '../../options/profileSelectOptions';

import optionType from '../../interfaces/OptionType';

const CardContent = styled.div`
    padding: 2rem 1rem;

    .form-label {
        font-size: 16px;
        font-weight: bold;
    }

    .custom-file {
        font-size: 16px;
    }

    .custom-control-label {
        font-size: 1rem;
    }

    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }
`;

const FormHeader = styled.h2`
    margin-bottom: 2rem;
`;

interface uploadProps extends RouteComponentProps {
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
}

interface ParamTypes {
    opportunityId: string | undefined;
}

interface ThumbFile {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    src?: string | ArrayBuffer;
}

const descMaxLength = 2000;
const reqMaxLength = 1000;
const titleMaxLength = 100;

// Main React Functional Component
const EditOpportunity: React.FC<uploadProps> = ({ ...props }) => {
    const { opportunityId } = useParams<ParamTypes>();
    const alert = useAlert();

    const [details, setDetails] = useState<IVolunteerOpportunity>(
        VolunteerOpportunityBlankObj
    );
    const [queryId, setQueryId] = useState('');
    const [editCheck, setEditCheck] = useState(false);
    const [registrationClosingTime, setRegistrationClosingTime] = useState('');
    const [thumb, setThumb] = useState<ThumbFile>({
        lastModified: 0,
        name: 'Choose a file',
        size: 0,
        type: ''
    });
    const [thumbReal, setThumbReal] = useState<File>();
    const [orgID, setOrgID] = useState('');
    const [orgOptions, setOrgOptions] = useState<optionType[]>();
    const [oppLength, setOppLength] = useState('Ad-hoc');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [descWordsRemaining, setDescWordsRemaining] = useState(descMaxLength);
    const [reqWordsRemaining, setReqWordsRemaining] = useState(reqMaxLength);
    const [titleWordsRemaining, setTitleWordsRemaining] = useState(
        titleMaxLength
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (!editCheck) setEditCheck(true);

        if (name === 'description') {
            setDescWordsRemaining(descMaxLength - value.length);
        }
        if (name === 'title') {
            setTitleWordsRemaining(titleMaxLength - value.length);
        }
        if (name === 'email' || name === 'phoneNumber') {
            setDetails({
                ...details,
                contactInformation: {
                    ...details.contactInformation,
                    [name]: value
                }
            });
        } else {
            setDetails({
                ...details,
                [name]: value
            });
        }
    };

    const setImage = (image: File) => {
        setThumbReal(image);
        const file: ThumbFile = image;
        const reader = new FileReader();

        reader.onload = function read(e) {
            if (e.target && e.target.result) {
                file.src = e.target.result;
                if (file.name.length > 40) {
                    setThumb({
                        lastModified: file.lastModified,
                        name: file.name
                            .slice(0, 40)
                            .concat(`... ${file.type.replace('image/', '.')}`),
                        size: file.size,
                        type: file.type
                    });
                } else setThumb(file);
            }
        };
        reader.readAsDataURL(image);
    };

    // eslint-disable-next-line
    const handleSelect = (newValue: any, type: string) => {
        const value: string[] = [];

        if (!editCheck) setEditCheck(true);

        if (type === 'organisation') {
            setOrgID(newValue.value);
            return;
        }

        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }

        if (type === 'country' || type === 'generalArea') {
            setDetails({
                ...details,
                [type]: newValue.value
            });
        } else {
            value.sort();
            setDetails({
                ...details,
                [type]: value
            });
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (disableSubmit) return;
        setDisableSubmit(true);

        // Check for whitespace submissions
        if (!whitespaceCheck(details, ['title', 'description'])) {
            alert.error(
                'Required fields cannot be filled with only whitespaces.'
            );
            setDisableSubmit(false);
            return;
        }

        if (thumbReal) {
            const data: any = new FormData();
            data.append('image', thumbReal);
            axios.post(`/api/uploads/images`, data).then((res) => {
                const thumbnailData = res.data;
                console.log(res.data);

                axios
                    .put(`/api/opportunities/${details._id}`, {
                        title: details.title,
                        opportunityLength: oppLength,
                        opportunityTypes: details.opportunityTypes,
                        commitmentLevel: details.commitmentLevel,
                        address: details.address,
                        generalArea: details.generalArea,
                        country: 'Singapore',
                        description: details.description,
                        requirements: details.requirements,
                        organisation: orgID,
                        signUpLink: details.signUpLink,
                        startDate: parse(
                            details.startDate,
                            'yyyy-MM-dd',
                            new Date()
                        ),
                        startTime: details.startTime,
                        endDate: parse(
                            details.endDate,
                            'yyyy-MM-dd',
                            new Date()
                        ),
                        endTime: details.endTime,
                        registrationClosingDate: parse(
                            details.registrationClosingDate,
                            'yyyy-MM-dd',
                            new Date()
                        ),
                        contactInformation: {
                            email: details.contactInformation.email,
                            phoneNumber: details.contactInformation.phoneNumber
                        },
                        // shifts: details.shifts?.map(function (shift) {
                        //     return {
                        //         date: shift.date,
                        //         registrationClosingDate: shift.registrationClosingDate,
                        //         startTime: shift.startTime,
                        //         endTime: shift.endTime,
                        //         duration: shift.duration
                        //     }
                        // }),
                        primaryTags: details.primaryTags,
                        secondaryTags: details.secondaryTags,
                        thumbnail: thumbnailData
                    })
                    .then((rec) => {
                        setDetails(VolunteerOpportunityBlankObj);
                        // TODO: Implement page for opportunities
                        // props.history.push(`/opportunity/${rec.data._id}`);
                        props.history.push(`/dashboard`);
                    })
                    .catch(() => {
                        alert.error(
                            'Error creating opportunity. Please try again.'
                        );
                        setDisableSubmit(false);
                    });
            });
        } else if (details.thumbnail) {
            axios
                .put(`/api/opportunities/${details._id}`, {
                    title: details.title,
                    opportunityLength: oppLength,
                    opportunityTypes: details.opportunityTypes,
                    commitmentLevel:
                        oppLength === 'Regular' ? details.commitmentLevel : 0,
                    address: details.address,
                    generalArea: details.generalArea,
                    country: 'Singapore',
                    description: details.description,
                    requirements: details.requirements,
                    organisation: orgID,
                    signUpLink: details.signUpLink,
                    startDate: parse(
                        details.startDate,
                        'yyyy-MM-dd',
                        new Date()
                    ),
                    startTime: details.startTime,
                    endDate: parse(
                        details.endDate,
                        'yyyy-MM-dd',
                        new Date()
                    ),
                    endTime: details.endTime,
                    registrationClosingDate: parse(
                        details.registrationClosingDate,
                        'yyyy-MM-dd',
                        new Date()
                    ),
                    contactInformation: {
                        email: details.contactInformation.email,
                        phoneNumber: details.contactInformation.phoneNumber
                    },
                    // shifts: details.shifts?.map(function (shift) {
                    //     return {
                    //         date: shift.date,
                    //         registrationClosingDate: shift.registrationClosingDate,
                    //         startTime: shift.startTime,
                    //         endTime: shift.endTime,
                    //         duration: shift.duration
                    //     }
                    // }),
                    primaryTags: details.primaryTags,
                    secondaryTags: details.secondaryTags,
                    thumbnail: details.thumbnail
                })
                .then((rec) => {
                    setDetails(VolunteerOpportunityBlankObj);
                    // TODO: Implement page for opportunities
                    // props.history.push(`/opportunity/${rec.data._id}`);
                    props.history.push(`/dashboard`);
                })
                .catch(() => {
                    alert.error(
                        'Error creating opportunity. Please try again.'
                    );
                    setDisableSubmit(false);
                });
        } else {
            alert.error('Error creating opportunity. Please insert a banner.');
            setDisableSubmit(false);
        }
    };

    // Check if user is logged in
    useEffect(() => {
        if (!props.auth.isAuthenticated) {
            props.history.push('/');
            return;
        }

        // Check and Populate Organisation
        if (queryId && props.userProfile.userProfile) {
            const orgs = props.userProfile.userProfile.organisation;
            if (orgs.find((x) => x._id === queryId)) {
                setOrgID(queryId);
            } else {
                // If queryId is invalid, error404
                props.history.replace('/error404');
            }
        }

        // If no queryId, select time!
        else if (props.userProfile.userProfile) {
            const orgs = props.userProfile.userProfile.organisation;
            const tempArray: optionType[] = orgs.map((item) => ({
                value: item._id,
                label: item.name
            }));
            setOrgOptions(tempArray);
        } else {
            props.history.replace('/error404');
        }
    }, [props.auth.isAuthenticated]);

    // Check whether user is onboarded
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    // Enable submit upon edit
    useEffect(() => {
        if (editCheck) setDisableSubmit(false);
    }, [editCheck]);

    // Retrieve Opportunity Data
    useEffect(() => {
        axios
            .get<IVolunteerOpportunity>(`/api/opportunities/${opportunityId}`)
            .then(({ data }) => {
                const newData = data;
                const registrationClosingDate = new Date(
                    data.registrationClosingDate
                );

                newData.registrationClosingDate = format(
                    registrationClosingDate,
                    'yyyy-MM-dd'
                );
                setRegistrationClosingTime(
                    format(registrationClosingDate, 'HH:mm')
                );
                
                newData.startDate = data.startDate 
                    ? format(new Date(data.startDate), 'yyyy-MM-dd')
                    : data.startDate;
                newData.endDate = data.endDate 
                    ? format(new Date(data.endDate), 'yyyy-MM-dd')
                    : data.endDate;

                setDetails(newData);
                setQueryId(data.organisation._id);
                setOrgID(data.organisation._id);
                setDescWordsRemaining(descMaxLength - data.description.length);
                setReqWordsRemaining(reqMaxLength - data.requirements.length);
                setTitleWordsRemaining(titleMaxLength - data.title.length);
                if (data.thumbnail) {
                    setThumb({
                        ...thumb,
                        name: data.thumbnail.location.replace(
                            'https://interseedstorage.s3.ap-southeast-1.amazonaws.com/',
                            ''
                        ),
                        src: data.thumbnail.location
                    });
                }
            })
            .catch(() => {
                props.history.push('/error404');
            });
    }, []);

    return (
        <Container fluid='md'>
            <h1>Edit your Volunteering Opportunity</h1>
            <Card className="text-left mx-auto px-3 mb-4">
                <CardContent>
                    <FormHeader>
                        Tell us about this volunteering opportunity
                    </FormHeader>
                    <hr />
                    <Form className="text-muted" onSubmit={handleSubmit}>
                        {!queryId ? (
                            <Form.Group
                                controlId="formOrganisation"
                                className="required"
                            >
                                <Form.Label>Organisation</Form.Label>
                                <Select
                                    name="organisation"
                                    options={orgOptions}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleSelect(newValue, 'organisation')
                                    }
                                />
                                <input
                                    className="invis"
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0 }}
                                    defaultValue={orgID}
                                    required
                                />
                            </Form.Group>
                        ) : null}

                        <Form.Group controlId="formTitle" className="required">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                required
                                name="title"
                                type="text"
                                maxLength={titleMaxLength}
                                value={details.title}
                                onChange={handleChange}
                                placeholder="Name of the opportunity."
                            />
                            <Form.Text muted>
                                {titleWordsRemaining} characters remaining.
                            </Form.Text>
                        </Form.Group>

                        <Form.Row>
                            <Col xs={12} lg={6}>
                                <Form.Group
                                    controlId="formPrimaryTags"
                                    className="required"
                                >
                                    <Form.Label>
                                        Sustainability Verticals
                                    </Form.Label>
                                    <Select
                                        isMulti
                                        name="primaryTags"
                                        options={sustainabilityVerticalsOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={details.primaryTags.map(
                                            (item) => ({
                                                value: item,
                                                label: item
                                            })
                                        )}
                                        onChange={(newValue) =>
                                            handleSelect(
                                                newValue,
                                                'primaryTags'
                                            )
                                        }
                                    />
                                    <input
                                        className="invis"
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        defaultValue={details.primaryTags}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group
                                    controlId="formSecondaryTags"
                                >
                                    <Form.Label>Skills Required</Form.Label>
                                    <Select
                                        isMulti
                                        name="primaryTags"
                                        options={skillsOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={details.secondaryTags.map(
                                            (item) => ({
                                                value: item,
                                                label: item
                                            })
                                        )}
                                        onChange={(newValue) =>
                                            handleSelect(
                                                newValue,
                                                'secondaryTags'
                                            )
                                        }
                                    />
                                    <input
                                        className="invis"
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        defaultValue={details.secondaryTags}
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Group
                            controlId="formOpportunityTypes"
                            className="required"
                        >
                            <Form.Label>Type of Opportunity</Form.Label>
                            <Select
                                isMulti
                                name="opportunityTypes"
                                options={[
                                    {
                                        value: 'Event-Based',
                                        label: 'Event-Based'
                                    },
                                    {
                                        value: 'Skill-Based',
                                        label: 'Skill-Based'
                                    }
                                ]}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={details.opportunityTypes.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'opportunityTypes')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={details.opportunityTypes}
                                required
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formDescription"
                            className="required"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required
                                as={Textarea}
                                name="description"
                                rows={4}
                                maxLength={descMaxLength}
                                value={details.description}
                                onChange={handleChange}
                                placeholder="Describe your opportunity."
                            />
                            <Form.Text muted>
                                {descWordsRemaining} characters remaining.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group
                            controlId="formRequirements"
                            // className="required"
                        >
                            <Form.Label>Requirements</Form.Label>
                            <Form.Control
                                as={Textarea}
                                name="requirements"
                                rows={2}
                                maxLength={reqMaxLength}
                                value={details.requirements}
                                onChange={handleChange}
                                placeholder="List down any requirements for your opportunity."
                            />
                            <Form.Text muted>
                                {reqWordsRemaining} characters remaining.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group
                            controlId="formSignUpLink"
                            className="required"
                        >
                            <Form.Label>Sign Up Link</Form.Label>

                            <Form.Control
                                required
                                type="url"
                                name="signUpLink"
                                maxLength={descMaxLength}
                                value={details.signUpLink}
                                onChange={handleChange}
                                placeholder="https://forms.office.com"
                            />
                        </Form.Group>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Form.Group
                                    controlId="formEmail"
                                    className="required"
                                >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        required
                                        name="email"
                                        type="email"
                                        maxLength={descMaxLength}
                                        value={details.contactInformation.email}
                                        onChange={handleChange}
                                        placeholder="The email users can contact you at."
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group controlId="formPhoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        name="phoneNumber"
                                        type="number"
                                        maxLength={descMaxLength}
                                        value={
                                            details.contactInformation
                                                .phoneNumber
                                        }
                                        onChange={handleChange}
                                        placeholder="The number users can contact you at."
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group
                            controlId="formOpportunityLength"
                            className="required"
                        >
                            <Form.Label>
                                Is your volunteering opportunity regular or
                                ad-hoc?
                            </Form.Label>
                            <Form.Text className="text-muted mt-n2 mb-2">
                                Ad-hoc: Volunteers only need to attend one or
                                two sessions <br /> Regular: Volunteers are
                                expected to attend on a regular basis
                            </Form.Text>
                            <Form.Check
                                name="opportunityLength"
                                type="switch"
                                id="custom-switch"
                                label={oppLength}
                                onChange={(e) => {
                                    setOppLength(
                                        oppLength === 'Ad-hoc'
                                            ? 'Regular'
                                            : 'Ad-hoc'
                                    );
                                }}
                            />
                        </Form.Group>
                        {oppLength === 'Regular' ? (
                            <Form.Group
                                controlId="formCommitmentLevel"
                                className="required"
                            >
                                <Form.Label>Commitment Level</Form.Label>
                                <Form.Control
                                    required
                                    name="commitmentLevel"
                                    type="text"
                                    maxLength={100}
                                    value={details.commitmentLevel}
                                    onChange={handleChange}
                                    placeholder="No. of hours for users to commit per month.    "
                                />
                            </Form.Group>
                        ) : null}

                        <Row>
                            <Form.Group
                                as={Col}
                                controlId="formRegistrationClosingDate"
                                className="required"
                            >
                                <Form.Label>
                                    Registration Closing Date
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    min={format(new Date(), 'yyyy-MM-dd')}
                                    style={
                                        details.registrationClosingDate
                                            ? {
                                                  backgroundColor: '#FFF',
                                                  fontWeight: 'bold'
                                              }
                                            : {}
                                    }
                                    placeholder="Date"
                                    required
                                    name="registrationClosingDate"
                                    value={details.registrationClosingDate}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            {/* <Form.Group
                                as={Col}
                                controlId="formEndTime"
                                className="required"
                            >
                                <Form.Label>
                                    Registration Closing Time
                                </Form.Label>
                                <Form.Control
                                    type="time"
                                    placeholder="Registration Closing Time"
                                    required
                                    style={
                                        endTime
                                            ? {
                                                  backgroundColor: '#FFF',
                                                  fontWeight: 'bold'
                                              }
                                            : {}
                                    }
                                    name="registrationClosingTime"
                                    value={registrationClosingTime}
                                    onChange={(e) =>
                                        setRegistrationClosingTime(
                                            e.target.value
                                        )
                                    }
                                />
                                <Form.Text className="text-muted">
                                    Timezone:{' '}
                                    {
                                        Intl.DateTimeFormat().resolvedOptions()
                                            .timeZone
                                    }
                                </Form.Text>
                            </Form.Group> */}
                        </Row>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Form.Group controlId="formStartDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Text className="text-muted mt-n2 mb-2">
                                        The date of the first volunteering
                                        opportunity slot
                                    </Form.Text>
                                    <Form.Control
                                        type="date"
                                        min={format(new Date(), 'yyyy-MM-dd')}
                                        style={
                                            details.startDate
                                                ? {
                                                      backgroundColor: '#FFF',
                                                      fontWeight: 'bold'
                                                  }
                                                : {}
                                        }
                                        placeholder="Date"
                                        name="startDate"
                                        value={details.startDate}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group controlId="formEndDate">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Text className="text-muted mt-n2 mb-2">
                                        The date of the last volunteering
                                        opportunity slot
                                    </Form.Text>
                                    <Form.Control
                                        type="date"
                                        min={format(new Date(), 'yyyy-MM-dd')}
                                        style={
                                            details.endDate
                                                ? {
                                                      backgroundColor: '#FFF',
                                                      fontWeight: 'bold'
                                                  }
                                                : {}
                                        }
                                        placeholder="Date"
                                        name="endDate"
                                        value={details.endDate}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Form.Group controlId="formStartTime">
                                    <Form.Label>Start Time </Form.Label>
                                    <Form.Text className="text-muted mt-n2 mb-2">
                                        The start time of your volunteering
                                        slot. If you have more than 1 time slot,
                                        you can leave this field empty and write
                                        the details in your registration form
                                    </Form.Text>
                                    <Form.Control
                                        type="time"
                                        placeholder="Start Time"
                                        style={
                                            details.startTime
                                                ? {
                                                      backgroundColor: '#FFF',
                                                      fontWeight: 'bold'
                                                  }
                                                : {}
                                        }
                                        name="startTime"
                                        value={details.startTime}
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-muted">
                                        Timezone:{' '}
                                        {
                                            Intl.DateTimeFormat().resolvedOptions()
                                                .timeZone
                                        }
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group controlId="formEndTime">
                                    <Form.Label>End Time</Form.Label>
                                    <Form.Text className="text-muted mt-n2 mb-2">
                                        The end time of your volunteering slot.
                                        If you have more than 1 time slot, you
                                        can leave this field empty and write the
                                        details in your registration form
                                    </Form.Text>
                                    <Form.Control
                                        type="time"
                                        placeholder="End Time"
                                        style={
                                            details.endTime
                                                ? {
                                                      backgroundColor: '#FFF',
                                                      fontWeight: 'bold'
                                                  }
                                                : {}
                                        }
                                        name="endTime"
                                        value={details.endTime}
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-muted">
                                        Timezone:{' '}
                                        {
                                            Intl.DateTimeFormat().resolvedOptions()
                                                .timeZone
                                        }
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        {/* <Form.Group
                            controlId="formCountry"
                            className="required"
                        >
                            <Form.Label>Country</Form.Label>
                            <Select
                                name="country"
                                options={countriesOptions}
                                // value={{
                                //     value: details.country,
                                //     label: details.country
                                // }}

                                placeholder="Select your country..."
                                className="basic-single"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'country')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={details.country}
                                required
                            />
                        </Form.Group> */}

                        <Form.Group
                            controlId="formGeneralArea"
                            className="required"
                        >
                            <Form.Label>General Area</Form.Label>
                            <Select
                                name="generalArea"
                                options={generalAreaOptions}
                                className="basic-single"
                                classNamePrefix="select"
                                value={{
                                    value: details.generalArea,
                                    label: details.generalArea
                                }}
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'generalArea')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={details.generalArea}
                                required
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formAddress"
                            className="required"
                        >
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                required
                                name="address"
                                maxLength={descMaxLength}
                                value={details.address}
                                onChange={handleChange}
                                placeholder="The venue of your opportunity."
                            />
                        </Form.Group>

                        <Form.Group className="align-items-center mb-3 required">
                            <Form.Label>Upload Banner</Form.Label>
                            <Col xs={12}>
                                <UploadImage
                                    resetImage={setImage}
                                    src={thumb.src as string}
                                />
                            </Col>
                        </Form.Group>

                        <div className="text-right mt-2">
                            <Button
                                as={Link}
                                className="mr-1"
                                variant="light"
                                to="/dashboard"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={disableSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </CardContent>
            </Card>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(EditOpportunity));
