import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, ListGroup, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Ecosystem, JoinAs } from '../../interfaces/Ecosystem';
import { useEventTracking } from '../../pages/eventTracking';
import EcosystemAvatars from './ecosystem/EcosystemAvatars';
import EcosystemJoinModal from './EcosystemJoinModal';

interface EcosystemProp {
    ecosystem: Ecosystem;
    authId: string;
}

const Styles = styled.div`
    h2 {
        margin-bottom: 0.2rem;
    }

    .list-group-item {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .imgcontainer {
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 1.5rem;

        img {
            object-fit: contain;
            width: 6rem;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #dee2e6;
            padding: 0.25rem;
            aspect-ratio: 1;
        }
    }

    .badge {
        font-size: 16px;
    }
`;

const ListHeading = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #002a52;
`;

// const Description = styled.p`
//     color: #315578;
// `;

const Address = styled.div`
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    gap: 10px;

    span {
        color: #002a52;
        font-size: 18px;
        line-height: 21px;
    }
`;

const FocusAreas = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    & > div {
        background-color: #d3e8ff;
        color: #2b6098;
        padding: 7px 9px;
        border-radius: 8px;
    }
`;

const EcosystemItem: React.FC<EcosystemProp> = ({ ecosystem, authId }) => {
    const [joined, setJoined] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!authId) return;
        if (ecosystem.members.map(({ _id }) => _id).includes(authId))
            setJoined(true);
    }, []);

    return (
        <>
            <Styles>
                <ListGroup.Item
                    as={Link}
                    to={`/ecosystem/${ecosystem._id}`}
                    onClick={() => {
                        if (
                            `${
                                window.location.pathname.split(
                                    /\B\/([a-zA-Z0-9-]{0,})/
                                )[1]
                            }` === 'ecosystem'
                        ) {
                            useEventTracking(
                                `My Ecosystem`,
                                'Viewed Ecosystem Profile',
                                `${ecosystem.name}`
                            );
                        } else {
                            useEventTracking(
                                `Community`,
                                'Viewed Ecosystem Profile',
                                `${ecosystem.name}`
                            );
                        }
                    }}
                >
                    <Container>
                        <Row>
                            <Col className="mb-2 imgcontainer" sm={2}>
                                <Image
                                    className="img-thumbnail"
                                    src={
                                        ecosystem.logo
                                            ? ecosystem.logo.location
                                            : `${process.env.PUBLIC_URL}/assets/interseed.png`
                                    }
                                    alt="Ecosystem"
                                    roundedCircle
                                    thumbnail
                                    fluid
                                    onError={(e) => {
                                        e.currentTarget.onerror = null; // prevents looping
                                        e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                    }}
                                />
                            </Col>
                            <Col
                                className="my-auto text-sm-left text-center"
                                sm={7}
                            >
                                <ListHeading>{ecosystem.name}</ListHeading>
                                {/* <Description> */}
                                {/*     {ecosystem.description} */}
                                {/* </Description> */}
                                <EcosystemAvatars members={ecosystem.members} />
                                <FocusAreas className="mt-2">
                                    {ecosystem.focusAreas
                                        .sort()
                                        .map((focus) => (
                                            <div>{focus}</div>
                                        ))}
                                </FocusAreas>
                                <Address>
                                    <FontAwesomeIcon
                                        icon={faMapMarkerAlt}
                                        color="#667F97"
                                        size="sm"
                                    />
                                    <span>{ecosystem.address}</span>
                                </Address>
                            </Col>
                            <Col sm={2} className="text-center my-auto">
                                {authId ? (
                                    <Button
                                        variant={
                                            !joined
                                                ? 'outline-primary'
                                                : 'primary'
                                        }
                                        className="mt-2 ml-3"
                                        onClick={(
                                            e: React.MouseEvent<HTMLButtonElement>
                                        ) => {
                                            e.preventDefault();
                                            if (!joined) {
                                                setShow(true);
                                            }
                                        }}
                                    >
                                        {!joined ? 'Join' : 'Joined'}
                                    </Button>
                                ) : (
                                    <Button
                                        variant="outline-primary"
                                        className="mt-2"
                                        as={Link}
                                        to="/login"
                                    >
                                        Join
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </ListGroup.Item>
                <EcosystemJoinModal
                    show={show}
                    ecosystem={ecosystem}
                    joinAs={JoinAs.USER}
                    authId={authId}
                    handleClose={() => setShow(false)}
                />
            </Styles>
        </>
    );
};

export default EcosystemItem;
