import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Tab, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IOrganisation from '../../../interfaces/IOrganisation';

const ProjectsContainer = styled.div`
    width: 95%;
    margin: 1.5rem;
    background-color: #f5faff;
    border-radius: 20px;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 20px;
    color: black;
    padding: 1rem;

    &:hover {
        background-color: #ccc;
        border-radius: 0;
        text-decoration: none;
    }

    h3 {
        font-size: min(16px, 1.5vw);

        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        margin-bottom: 1rem;
        max-height: 4.5rem;
        max-width: 4.5rem;
    }
`;

function ProjectItem({ project }: { project: Project }) {
    const [logo, setLogo] = useState<string>('');

    useEffect(() => {
        axios
            .get<IOrganisation>(`/api/organisations/${project._id}`)
            .then(({ data }) =>
                setLogo(
                    data.logo?.location ??
                        `${process.env.PUBLIC_URL}/assets/interseed.png`
                )
            );
    }, [project._id]);

    return (
        <Item as={Link} to={`/organisation/${project._id}`}>
            <Image
                className="img-thumbnail"
                src={logo}
                alt="project"
                roundedCircle
                thumbnail
                fluid
                onError={(e) => {
                    e.currentTarget.onerror = null; // prevents looping
                    e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/interseed.png`;
                }}
            />
            <h2>{project.name}</h2>
        </Item>
    );
}

interface Project {
    _id: string;
    name: string;
}

interface Props {
    projects: Project[];
}

const EcosystemProjects: React.FC<Props> = ({ projects }) => (
    <Tab.Pane eventKey="projects">
        <ProjectsContainer>
            <Row xs={1} md={5} className="g-4">
                {projects.map((project) => (
                    <ProjectItem key={project._id} project={project} />
                ))}
            </Row>
        </ProjectsContainer>
    </Tab.Pane>
);

export default EcosystemProjects;
