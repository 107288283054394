/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import {
    Card,
    ListGroup,
    ButtonGroup,
    FormControl,
    Button,
    Row,
    Col,
    Container
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Select from 'react-select';
import LandscapeItem from './LandscapeItem';
import ILandscape from '../../interfaces/ILandscape';
import {
    primaryCategoriesOptions,
    secondaryCategoriesOptions,
    countriesOptions
} from '../../options/landscapeSelectOptions';
import { useEventTracking } from '../../pages/eventTracking';

const Styles = styled.div`
    .card-body {
        padding: 0;
    }

    .basic-multi-select {
        font-size: 16px;
    }
`;

interface Props {
    authId: string;
}

interface filterType {
    countries: string[];
    primaryCategories: string[];
    secondaryCategories: string[];
}

export const LandscapesList: React.FC<Props> = ({ authId }: Props) => {
    const [masterList, setMasterList] = useState<ILandscape[]>([]);
    const [list, setList] = useState<ILandscape[]>([]);
    const [initFilterCheck, setInitFilterCheck] = useState(false);
    // eslint-disable-next-line
    const [sort, setSort] = useState('newest');
    const [filter, setFilter] = useState<filterType>({
        countries: [],
        primaryCategories: [],
        secondaryCategories: []
    });
    const [search, setSearch] = useState('');
    const [shownLandscapes, setShownLandscapes] = useState(10);

    const comparePop = (a: ILandscape, b: ILandscape) => {
        if (a.upvotes.length > b.upvotes.length) {
            return -1;
        }
        if (b.upvotes.length > a.upvotes.length) {
            return 1;
        }
        if (a.createdAt > b.createdAt) {
            return -1;
        }
        if (b.createdAt > a.createdAt) {
            return 1;
        }
        return 0;
    };

    const compareDate = (a: ILandscape, b: ILandscape) => {
        if (a.createdAt > b.createdAt) {
            return -1;
        }
        if (b.createdAt > a.createdAt) {
            return 1;
        }
        return 0;
    };

    const handleSort = (type: string) => {
        useEventTracking('Landscapes', 'Clicked Sort By Filter', `${type}`);
        const tempList: ILandscape[] = list;
        const tempMasterList: ILandscape[] = masterList;
        setSort('');
        if (type === 'popular') {
            tempList.sort(comparePop);
            tempMasterList.sort(comparePop);
        } else {
            tempList.sort(compareDate);
            tempMasterList.sort(compareDate);
        }
        setSort(type);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearch(value);
    };

    const updateLandscape = (id: string, upvotes: string[]) => {
        const tempMasterList: ILandscape[] = masterList;
        for (let i = 0; i < list.length; i += 1) {
            if (tempMasterList[i]._id === id) {
                tempMasterList[i].upvotes = upvotes;
                setMasterList(tempMasterList);
            }
        }
    };

    // eslint-disable-next-line
    const handleFilterSelect = (newValue: any, type: string) => {
        const value: string[] = [];
        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }
        if (value.join() !== '') {
            useEventTracking(
                'Landscapes',
                `Clicked ${type} filter`,
                value.join()
            );
        }
        setFilter({
            ...filter,
            [type]: value
        });
    };

    // Main function for filtering and searching
    const filterMainFunction = () => {
        const landscapes: ILandscape[] = Object.assign([], masterList);
        let i = 0;
        let { length } = landscapes;
        while (i < length) {
            let primCatCheck = false;
            let secCatCheck = false;
            let countryCheck = false;
            let searchCheck = false;

            // Check Primary Categories
            if (filter.primaryCategories.length > 0) {
                for (
                    let j = 0;
                    j < landscapes[i].primaryCategories.length;
                    j += 1
                ) {
                    for (
                        let k = 0;
                        k < filter.primaryCategories.length;
                        k += 1
                    ) {
                        if (
                            landscapes[i].primaryCategories[j] ===
                            filter.primaryCategories[k]
                        ) {
                            primCatCheck = true;
                            break;
                        }
                    }
                    if (primCatCheck === true) break;
                }
            } else primCatCheck = true;

            // Check Secondary Categories
            if (filter.secondaryCategories.length > 0) {
                for (
                    let j = 0;
                    j < landscapes[i].secondaryCategories.length;
                    j += 1
                ) {
                    for (
                        let k = 0;
                        k < filter.secondaryCategories.length;
                        k += 1
                    ) {
                        if (
                            landscapes[i].secondaryCategories[j] ===
                            filter.secondaryCategories[k]
                        ) {
                            secCatCheck = true;
                            break;
                        }
                    }
                    if (secCatCheck === true) break;
                }
            } else secCatCheck = true;

            // Check Countries
            if (filter.countries.length > 0) {
                for (let j = 0; j < landscapes[i].countries.length; j += 1) {
                    if (landscapes[i].countries[j] === 'Global') {
                        countryCheck = true;
                        break;
                    }
                    for (let k = 0; k < filter.countries.length; k += 1) {
                        if (filter.countries[k] !== 'Global') {
                            if (
                                landscapes[i].countries[j] ===
                                'Across Southeast Asia'
                            ) {
                                countryCheck = true;
                                break;
                            }
                        }
                        if (
                            landscapes[i].countries[j] === filter.countries[k]
                        ) {
                            countryCheck = true;
                            break;
                        }
                    }
                    if (countryCheck === true) break;
                }
            } else countryCheck = true;

            // Check Search
            if (search.length > 0) {
                if (
                    landscapes[i].title
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    landscapes[i].tagline
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    landscapes[i].description
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else searchCheck = true;

            // Results of Check
            if (
                primCatCheck === false ||
                secCatCheck === false ||
                countryCheck === false ||
                searchCheck === false
            ) {
                landscapes.splice(i, 1);
                i -= 1;
                length = landscapes.length;
            }
            i += 1;
        }
        setList(landscapes.slice(0).reverse());
    };

    // *** useEffects ***

    // Upon Initialisation
    useEffect(() => {
        // Axios request for retrieving landscape
        axios.get(`/api/landscapes/`).then((res) => {
            setMasterList(res.data);
            setList(res.data.slice(0).reverse());
        });
    }, []);

    // Whenever filter is selected or Search is filled
    useEffect(() => {
        if (initFilterCheck === false) {
            setInitFilterCheck(true);
        } else {
            filterMainFunction();
            handleSort(sort);
        }
    }, [search, filter, masterList]);

    return (
        <div>
            <h4>Market Research</h4>
            <Styles>
                <Card className="mb-4">
                    <Card.Header>
                        <Row className="mb-3">
                            <Col className="mx-auto mb-2 text-center" sm={12}>
                                <ButtonGroup aria-label="Sort Buttons">
                                    <Button
                                        variant={
                                            sort === 'newest'
                                                ? 'primary'
                                                : 'outline-primary'
                                        }
                                        onClick={() => handleSort('newest')}
                                    >
                                        Newest
                                    </Button>
                                    <Button
                                        variant={
                                            sort === 'popular'
                                                ? 'primary'
                                                : 'outline-primary'
                                        }
                                        onClick={() => handleSort('popular')}
                                    >
                                        Popular
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col className="mb-2" sm={12}>
                                <FormControl
                                    placeholder="Search for a Landscape"
                                    name="search"
                                    type="text"
                                    value={search}
                                    onChange={handleSearch}
                                />
                            </Col>

                            <Col className="mb-2" sm={6}>
                                <Select
                                    isMulti
                                    options={primaryCategoriesOptions}
                                    placeholder="Primary categories"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'primaryCategories'
                                        )
                                    }
                                />
                            </Col>
                            <Col className="mb-2" sm={6}>
                                <Select
                                    isMulti
                                    options={secondaryCategoriesOptions}
                                    placeholder="Secondary categories"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'secondaryCategories'
                                        )
                                    }
                                />
                            </Col>

                            <Col sm={12}>
                                <Select
                                    isMulti
                                    options={countriesOptions}
                                    placeholder="Country or countries"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'countries'
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className="p-3">
                        <ListGroup variant="flush">
                            {list.map((item) => (
                                <div>
                                    {item.title.includes('📌') ? (
                                        <div className="list-item-border">
                                            <LandscapeItem
                                                key={item._id}
                                                landscape={item}
                                                authId={authId}
                                                updateLandscape={
                                                    updateLandscape
                                                }
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                            {list.slice(0, shownLandscapes).map((item, i) => (
                                <div>
                                    {item.title.includes('📌') ? null : (
                                        <div
                                            className={
                                                list.slice(0, shownLandscapes)[
                                                    i + 1
                                                ]
                                                    ? 'list-item-border'
                                                    : ''
                                            }
                                        >
                                            <LandscapeItem
                                                key={item._id}
                                                landscape={item}
                                                authId={authId}
                                                updateLandscape={
                                                    updateLandscape
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                            {list.length === 0 ? (
                                <Container>
                                    <p className="mt-3">
                                        No landscapes found. Be the first person
                                        to{' '}
                                        <Link
                                            to={
                                                authId ? `/upload` : `/register`
                                            }
                                        >
                                            submit
                                        </Link>{' '}
                                        one!
                                    </p>
                                </Container>
                            ) : null}
                        </ListGroup>
                        {shownLandscapes < list.length ? (
                            <Button
                                type="button"
                                variant="light"
                                onClick={() => {
                                    setShownLandscapes(shownLandscapes + 10);
                                }}
                                block
                            >
                                Show More
                            </Button>
                        ) : null}
                    </Card.Body>
                </Card>
            </Styles>
        </div>
    );
};

export default LandscapesList;
