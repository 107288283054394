/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React from 'react';
import { ListGroup, Container, Row, Col, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import IOpportunity from '../../interfaces/IOpportunity';

import { useEventTracking } from '../../pages/eventTracking';

interface jobProp {
    job: IOpportunity;
    logo: boolean;
}

const Styles = styled.div`
    h2 {
        margin-bottom: 0.2rem;
    }

    .list-group-item {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .imgcontainer {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        width: 100%;
        margin-right: 10px;

        img {
            object-fit: cover;
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    .badge {
        font-size: 16px;
    }
`;

const ListText = styled.p`
    margin-bottom: 0;
`;

const JobItem: React.FC<jobProp> = ({ job, logo }: jobProp) => (
    <Styles>
        <ListGroup.Item
            as={Link}
            to={`/internship/${job._id}`}
            onClick={() => {
                useEventTracking(
                    'Jobs',
                    'Clicked Job Opportunity',
                    `${job.position}`
                );
            }}
        >
            <Container>
                <Row>
                    {logo ? (
                        <Col sm={2} className="imgcontainer mb-2">
                            <img
                                src={
                                    job.organisation.logo
                                        ? job.organisation.logo.location
                                        : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                                }
                                alt=""
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                }}
                            />
                        </Col>
                    ) : null}
                    <Col sm={8} className="text-center text-sm-left">
                        <h2>{job.position}</h2>
                        {job.organisation.name ? (
                            <ListText>
                                at <b>{job.organisation.name}</b>
                            </ListText>
                        ) : null}
                        <ListText className="text-muted">
                            ${job.renumeration}
                        </ListText>
                        <p className="mt-2">
                            {job.verticals.map((vertical) => (
                                <Badge variant="light">{vertical}</Badge>
                            ))}
                        </p>
                        <p className="mt-2">
                            {job.skillsets.map((skillset) => (
                                <Badge pill variant="info">
                                    {skillset}
                                </Badge>
                            ))}
                        </p>
                    </Col>
                </Row>
            </Container>
        </ListGroup.Item>
    </Styles>
);

export default JobItem;
