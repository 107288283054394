import optionType from '../interfaces/OptionType';
import { populateArray } from '../utils/commonFunction';

const createdOn = [
    'Today',
    'Yesterday',
    'Last Week',
    'Last 30 days',
    'Last 90 days',
    'Last 12 months'
];

export const getStrToDateMapping = (today: Date) => {
    // Initialise date objects
    const yesterday = new Date();
    const lastweek = new Date();
    const last30days = new Date();
    const last90days = new Date();
    const last12months = new Date();

    // Set dates accordingly
    yesterday.setDate(today.getDate() - 1);
    lastweek.setDate(today.getDate() - 7);
    last30days.setDate(today.getDate() - 30);
    last90days.setDate(today.getDate() - 90);
    last12months.setMonth(today.getMonth() - 12);

    const mapping = {
        Today: today,
        Yesterday: yesterday,
        'Last Week': lastweek,
        'Last 30 days': last30days,
        'Last 90 days': last90days,
        'Last 12 months': last12months
    };
    return mapping;
};

const createdOnOptions: optionType[] = populateArray(createdOn, false);

export default createdOnOptions;
