import React, { ReactElement } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IEvent } from '../../interfaces/IEvent';
import { useEventTracking } from '../../pages/eventTracking';
import EventItemVertical from './EventItemVertical';
import { EventButton } from './EventStyles';

interface EventPopularProps {
    events: IEvent[];
    user: string;
    setViewEvent: (val: boolean) => void;
    setSelectedEvent: (val: string) => void;
}

export default function EventPopular({
    events,
    user,
    setViewEvent,
    setSelectedEvent
}: EventPopularProps): ReactElement {
    const history = useHistory();

    return (
        <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center pr-3">
                <div className="h3 p-3 text-left ">Popular Events</div>
                <button
                    className="h6 font-weight-bold "
                    style={{
                        color: '#51617C',
                        border: 0,
                        backgroundColor: '#fff'
                    }}
                    onClick={() => {
                        useEventTracking(
                            'Events',
                            'Clicked View All Events',
                            `${user}`
                        );
                        history.push('events/all');
                    }}
                    type="button"
                >
                    View All Events
                </button>
            </div>
            <hr style={{ backgroundColor: '#f3f3f3', margin: 0 }} />
            <div
                className="d-flex flex-wrap p-3"
                style={{ gridRowGap: '1rem' }}
            >
                {events.map((event) => (
                    <div
                        className="col-xl-3 col-md-6 col-sm-12"
                        style={{ opacity: event.upcoming ? 1 : 0.5 }}
                        key={event._id}
                    >
                        <EventItemVertical
                            event={event}
                            eventSection="Popular Events"
                            setViewEvent={setViewEvent}
                            setSelectedEvent={setSelectedEvent}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
