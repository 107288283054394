import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import queryString from 'querystring';

import axios from 'axios';

import { loginUser, registerUser } from '../../redux/actions/AuthActions';
import { ISocialUserData } from '../../interfaces/AuthInterface';
import { AuthState, StoreState } from '../../redux/actions/types';
import { useEventTracking } from '../../pages/eventTracking';

interface ParamTypes {
    queryId: string | undefined;
}

const GoogleIcon = styled.img`
    padding-bottom: 4px;
    margin-right: 15px;
`;

interface googleProps extends RouteComponentProps {
    loginUser: (
        arg0: ISocialUserData,
        arg1: RouteComponentProps['history'],
        arg2: boolean
    ) => void;
    auth: AuthState;
    history: RouteComponentProps['history'];
    setDisableSubmit: Dispatch<SetStateAction<boolean>>;
}

const Google: React.FC<googleProps> = (props: googleProps) => {
    const getAuthUrl = () => {
        let url =
            'https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?';

        const scopes = [
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email'
        ];

        const body = queryString.stringify({
            response_type: 'code',
            scope: `${scopes[0]} ${scopes[1]}`,
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
            flowName: 'GeneralOAuthFlow'
        });

        // Append url with the body
        url = `${url}${body}`;

        return url;
    };

    const errorGoogle = (err) => {
        console.log(err);
        props.history.push('./error500');
    };

    const responseGoogle = () => {
        try {
            if (!props.location.search && !props.auth.isAuthenticated) {
                const url = getAuthUrl();
                window.location.replace(url);
            } else if (!props.auth.isAuthenticated && props.location.search) {
                const toParse = props.location.search.replace('?', '');
                const parsed = queryString.parse(toParse);

                const userData = {
                    network: 'google',
                    token: parsed.code
                };

                props.loginUser(userData, props.history, true);
                props.setDisableSubmit(true);
            }
        } catch (err) {
            errorGoogle(err);
        }
    };

    useEffect(() => {
        if (!props.auth.isAuthenticated && props.location.search) {
            responseGoogle();
        }
    }, []);

    const googleContent = (
        <Button
            className="google-badge"
            variant="outline-secondary"
            block
            onClick={() => {
                useEventTracking(
                    'Log In',
                    'Clicked Continue with Google',
                    `Third Party Auth`
                );
                responseGoogle();
            }}
        >
            <GoogleIcon
                src={`${process.env.PUBLIC_URL}/assets/google.svg`}
                alt="google-login"
                height={20}
                width={20}
            />
            Continue with Google
        </Button>
    );

    return <div>{googleContent}</div>;
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, { registerUser, loginUser })(
    withRouter(Google)
);
