import { Button } from 'react-bootstrap';
import styled from 'styled-components';

export const text = {
    sizes: {
        h1: '48px',
        h2: '32px',
        h3: '24px',
        h4: '20px',
        h5: '18px',
        h6: '16px',

        p1: '18px',
        p2: '16px',
        p3: '14px'
    }
};

export const color = {
    theme: '#007BFF',
    shade1: '#EBF4FF',
    shade2: '#8B9BB6',
    shade3: '#51617C',
    shade4: '#273244',
    types: {
        Pitch: '#04B000',
        Network: '#FF7E21',
        Workshop: '#962EFF',
        text: '#EBF4FF'
    }
};

export const EventButton = styled(Button)<{ [key: string]: any }>`
    background-color: ${(props) =>
        props.secondary ? color.shade3 : color.theme};
    font-size: ${text.sizes.p2};
    font-weight: bold;
    color: white;
    border-radius: 15px;
    padding: 0.5rem 1.25rem;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.5s ease;

    &:hover {
        background-color: ${color.shade3};
    }
`;
