export default interface INews {
    title: string;
    link: string;
    datePublished: string;
    summary: string;
    image: string;
    source: string;
}

export const newsBlankObj = {
    title: '',
    link: '',
    datePublished: '',
    summary: '',
    image: '',
    source: ''
};
