/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
    Badge,
    Button,
    Card,
    Image,
    ListGroup,
    ListGroupItem,
    Modal
} from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendar,
    faEdit,
    faMapMarked
} from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { StoreState } from '../../redux/actions/types';
import IVolunteerOpportunity from '../../interfaces/IVolunteerOpportunity';
import IOrganisation, { orgBlankObj } from '../../interfaces/IOrganisation';
import { color, EventButton } from '../events/EventStyles';
import { useEventTracking } from '../../pages/eventTracking';

const OpportunityModalStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .card-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1rem 0.5rem;
        width: 100%;
        padding: 2rem;
    }

    .img {
        width: 70px;
        height: auto;
        margin-right: 1rem;
    }

    .opportunity-thumbnail {
    }

    .opportunity-heading {
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto auto;
        column-gap: 1rem;
    }

    .opportunity-heading h2 {
        font-size: 2rem;
        font-weight: 700;
        line-height: 1;
        grid-area: 1 / 2 / span 1 / span 1;
    }

    .opportunity-heading h4 {
        font-size: 1.6rem;
        line-height: 1;
        grid-area: 2 / 2 / span 1 / span 1;
    }

    .opportunity-heading a {
        text-decoration: none;
    }

    .logo {
        grid-area: 1 / 1 / span 2 / span 1;
        height: 5rem;
        width: 5rem;
    }

    .opportunity-tags {
        font-size: 1.2rem;
    }

    .opportunity-description {
        margin-top: 2rem;
    }

    .opportunity-description p {
        font-size: 1.2rem;
        line-height: 1;

        margin-bottom: 0.2rem;
    }

    .opportunity-details {
        font-size: 1.2rem;
    }

    .register-button {
        margin-top: 2rem;
        width: 100%;
        font-size: 0.9rem;
        // border-radius: 0;
        border-bottom: 1px solid #000;
        border-radius: 5px;
    }
`;

const OpportunityModal: React.FC<{
    opportunity: IVolunteerOpportunity;
    show: boolean;
    handleClose: () => void;
    userOrgs: string[];
}> = ({ opportunity, show, handleClose, userOrgs }) => {
    const [profile, setProfile] = useState<IOrganisation>(orgBlankObj);

    useEffect(() => {
        axios
            .get<IOrganisation>(`/api/organisations/${opportunity.organisation._id}`)
            .then(({ data }) => {
                setProfile(data);
            });
    }, []);

    return (
        <Modal
            centered
            size="lg"
            show={show}
            onHide={() => {
                handleClose();
            }}
            style={{ borderRadius: '15px' }}
        >
            <OpportunityModalStyle>
                <div className="card-container">
                    {opportunity.thumbnail ? (
                        <Card.Img
                            className="opportunity-thumbnail"
                            variant="top"
                            src={opportunity.thumbnail.location}
                            style={{ marginBottom: '2rem' }}
                        />
                    ) : null}

                    <div className="opportunity-tags">
                        {opportunity.primaryTags.map((tag) => (
                            <>
                                <Badge pill variant="primary">
                                    {tag}
                                </Badge>{' '}
                            </>
                        ))}
                    </div>
                    <div className="opportunity-tags">
                        {opportunity.secondaryTags.map((tag) => (
                            <>
                                <Badge pill variant="info">
                                    {tag}
                                </Badge>{' '}
                            </>
                        ))}
                    </div>
                    <div className="opportunity-heading">
                        <Image
                            className="logo"
                            src={
                                profile.logo
                                    ? profile.logo.location
                                    : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                            }
                            alt="Profile"
                            roundedCircle
                            thumbnail
                            fluid
                        />
                        <h2>{opportunity.title}</h2>
                        {opportunity.organisation ? (
                            <a href={`/organisation/${opportunity.organisation._id}`}><h4>{opportunity.organisation.name}</h4></a>
                        ) : (
                            <h4>No organisation</h4>
                        )}
                    </div>
                    <div className="opportunity-details">
                        <FontAwesomeIcon
                            size="lg"
                            icon={faMapMarked}
                            style={{ marginRight: '0.5rem' }}
                            className="fa-fw"
                        />
                        {`${opportunity.generalArea}`}
                        <br />
                        <FontAwesomeIcon
                            size="lg"
                            icon={faCalendar}
                            style={{ marginRight: '0.5rem' }}
                            className="fa-fw"
                        />
                        {`Register By: ${new Date(opportunity.registrationClosingDate).toLocaleDateString(
                            'en-US',
                            {
                                weekday: 'short',
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric'
                            }
                        )}`}
                        <br />
                    </div>

                    <Card style={{ width: '60%', marginTop: '1rem' }}>
                        <ListGroup as="ol">
                            {opportunity.shifts
                                ? opportunity.shifts.map((shift) => (
                                    <ListGroup.Item
                                        as="li"
                                        className="d-flex justify-content-between align-items-start"
                                    >
                                        <div className="ms-2 me-auto">
                                            {/* <div
                                                className="fw-bold"
                                                style={{ fontWeight: 'bold' }}
                                            >{`Register by ${new Date(
                                                shift.registrationClosingDate
                                            ).toLocaleDateString('en-US', {
                                                weekday: 'short',
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric'
                                            })}`}</div> */}
                                            {`${new Date(
                                                shift.startTime
                                            ).toLocaleString('en-US', {
                                                weekday: 'short',
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric'
                                            })} - ${new Date(
                                                shift.endTime
                                            ).toLocaleString('en-US', {
                                                weekday: 'short',
                                                year: 'numeric',
                                                month: 'short',
                                                day: 'numeric'
                                            })} `}
                                        </div>
                                        <Badge variant="primary" pill>
                                            {`${shift.duration} hours`}
                                        </Badge>
                                    </ListGroup.Item>
                                ))
                                : null}
                        </ListGroup>
                    </Card>

                    <div className="opportunity-description">
                        <p style={{ fontWeight: 'bold' }}>Requirements:</p>
                        <p style={{ whiteSpace: 'pre-wrap' }}>
                            {opportunity.requirements}
                        </p>
                        <br />
                        <p style={{ fontWeight: 'bold' }}>Description:</p>
                        <p style={{ whiteSpace: 'pre-wrap' }}>
                            {opportunity.description}
                        </p>
                    </div>
                
                    {opportunity.organisation &&
                    userOrgs.includes(opportunity.organisation._id) ? (
                        <Link to={`/editopportunity/${opportunity._id}`}>
                            <Button
                                // TODO Make class for this button and make it nicer + cleaner
                                className="register-button"
                                variant="success"
                                color="#007bff"
                            >
                                Edit Opportunity
                            </Button>
                        </Link>
                    ) : (
                        <Button
                            className="register-button"
                            variant="success"
                            onClick={() => {
                                useEventTracking(
                                    'Dashboard Map (Volunteering Opportunities)',
                                    'Registered for Volunteering Opportunity',
                                    `${opportunity.title}`
                                );
                                window.open(opportunity.signUpLink, '_blank');
                            }}
                        >
                            Register
                        </Button>
                    )}
                </div>
            </OpportunityModalStyle>
        </Modal>
    );
}

export default OpportunityModal;
