import React from 'react';

export const Error500: React.FC = () => (
    <div>
        <h1>Error 500: Internal Server Error</h1>
        <p>
            Should the error persist, please contact our developers at
            dev@interseed.co
        </p>
    </div>
);

export default Error500;
