import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import { useEventTracking } from '../../pages/eventTracking';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
}

const CreateInternshipWidget: React.FC<authProps> = ({ ...props }) => {
    const [widgetDetails, setWidgetDetails] = useState({
        header: 'Join us to share an opportunity!',
        buttonLink: '/register',
        buttonText: 'Sign Up Now'
    });

    useEffect(() => {
        if (props.auth.isAuthenticated) {
            if (
                props.userProfile.userProfile &&
                props.userProfile.userProfile.organisation.length > 0
            ) {
                setWidgetDetails({
                    header: 'Have a job opportunity in your organisation?',
                    buttonLink: '/createinternship',
                    buttonText: 'Add Opportunity'
                });
            } else {
                setWidgetDetails({
                    header: 'Create an organisation to share an opportunity!',
                    buttonLink: '/createorganisation',
                    buttonText: 'Create Organisation'
                });
            }
        }
    }, []);

    return (
        <div className="mb-3">
            <Card>
                <Card.Body className="text-center">
                    <p>{widgetDetails.header}</p>
                    <Link to={widgetDetails.buttonLink}>
                        <Button
                            type="button"
                            onClick={() => {
                                useEventTracking(
                                    'Jobs',
                                    `Clicked ${widgetDetails.buttonText}`,
                                    `${props.auth.user.username}`
                                );
                            }}
                            block
                        >
                            {widgetDetails.buttonText}
                        </Button>
                    </Link>
                </Card.Body>
            </Card>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(CreateInternshipWidget));
