/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import {
    Link,
    RouteComponentProps,
    withRouter,
    useParams
} from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Container,
    Row,
    Col,
    Badge,
    Card,
    Button,
    Overlay,
    Tooltip,
    Form,
    Image
} from 'react-bootstrap';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import Textarea from 'react-expanding-textarea';
import isEmpty from 'is-empty';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon
} from 'react-share';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import { ShareButtons } from '../Styles/Styles';

import IOpportunity, { oppBlankObj } from '../interfaces/IOpportunity';

import BackToJobs from '../components/backbuttons/BackToJobs';

import { useEventTracking } from './eventTracking';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';
import { loadUserProfile } from '../redux/actions/UserProfileActions';

interface pageProps extends RouteComponentProps {
    loadUserProfile: (arg0: string) => void;
    auth: AuthState;
    userProfile: UserProfileState;
}

const CardDesc = styled.div`
    iframe {
        width: 100%;
        height: 400px;
    }
`;

const ApplyForm = styled.div`
    .form-label {
        font-size: 16px;
        font-weight: bold;
    }

    .custom-file {
        font-size: 16px;
    }

    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }

    .form-text {
        font-size: 12px;
    }
`;

const maxChars = 2000;

interface ParamTypes {
    queryId: string | undefined;
}

interface uploadedFile {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    src?: string | ArrayBuffer;
}

const InternshipPage: React.FC<pageProps> = ({ ...props }) => {
    const { queryId } = useParams<ParamTypes>();

    const alert = useAlert();

    const shareLink = `https://app.interseed.co/internships/${queryId}`;

    const [opportunity, setOpportunity] = useState<IOpportunity>(oppBlankObj);

    const [ownerCheck, setOwnerCheck] = useState(true);

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [applyMessage, setApplyMessage] = useState('');
    const [inputFile, setInputFile] = useState<File>();
    const [displayFile, setDisplayFile] = useState<uploadedFile>({
        lastModified: 0,
        name: '',
        size: 0,
        type: ''
    });
    const [deleteCheck, setDeleteCheck] = useState(false);
    // const [initCheck, setInitCheck] = useState(false);
    const [disableApply, setDisableApply] = useState(true);
    const [showApply, setShowApply] = useState(false);
    const [copyShow, setCopyShow] = useState(false);
    const copyTarget = useRef(null);
    const [charsRemaining, setCharsRemaining] = useState(maxChars);

    const applyRef = React.useRef<HTMLFormElement>(null);
    const handleShowApply = () => {
        setShowApply(true);
        if (applyRef.current) {
            applyRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, type } = event.target;

        if (type === 'file') {
            if (event.target.files && event.target.files[0]) {
                setInputFile(event.target.files[0]);
                const file: uploadedFile = event.target.files[0];
                const reader = new FileReader();

                reader.onload = function read(e) {
                    if (e.target && e.target.result) {
                        file.src = e.target.result;
                        if (file.name.length > 40) {
                            setDisplayFile({
                                lastModified: file.lastModified,
                                name: file.name
                                    .slice(0, 40)
                                    .concat(`... ${file.type}`),
                                size: file.size,
                                type: file.type
                            });
                        } else setDisplayFile(file);
                    }
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        } else {
            setApplyMessage(value);
            setCharsRemaining(maxChars - value.length);
        }
    };

    const handleDelete = () => {
        if (deleteCheck && ownerCheck) {
            axios
                .delete(`/api/internships/${opportunity._id}`, {
                    data: {
                        organisation: opportunity.organisation._id
                    }
                })
                .then(() => {
                    props.history.push(
                        `/organisation/${opportunity.organisation._id}`
                    );
                })
                .catch(() => {
                    props.history.push('/error500');
                });
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (disableSubmit) return;
        setDisableSubmit(true);
        if (inputFile) {
            // eslint-disable-next-line
            const data: any = new FormData();
            data.append('document', inputFile);

            axios
                .post(`/api/uploads/files`, data)
                .then((res) => {
                    const inputFileData = res.data;
                    axios
                        .get(`/api/users/profiles/${props.auth.user.username}`)
                        .then((result) => {
                            const info = result.data;
                            axios
                                .post(`/api/internships/${queryId}/apply`, {
                                    firstName: info.firstName,
                                    lastName: info.lastName,
                                    organisationEmail:
                                        opportunity.organisation.email,
                                    organisation: opportunity.organisation.name,
                                    applicantEmail: info.email,
                                    message: applyMessage,
                                    position: opportunity.position,
                                    file: inputFileData
                                })
                                .then(() => {
                                    setShowApply(!setShowApply);
                                    setDisableSubmit(false);
                                    props.loadUserProfile(
                                        props.auth.user.username
                                    );

                                    // Temporary
                                    alert.success(
                                        'Application sent! You will receive a confirmation message to your email shortly'
                                    );

                                    props.history.push(`/jobs`);
                                })
                                .catch(() => {
                                    props.history.push(`/error500`);
                                });
                        })
                        .catch(() => {
                            props.history.push(`/error500`);
                        });
                })
                .catch(() => {
                    props.history.push(`/error500`);
                });
        } else {
            axios
                .get(`/api/users/profiles/${props.auth.user.username}`)
                .then(({ data }) => {
                    axios
                        .post(`/api/internships/${queryId}/apply`, {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            organisationEmail: opportunity.organisation.email,
                            organisation: opportunity.organisation.name,
                            applicantEmail: data.email,
                            message: applyMessage,
                            position: opportunity.position,
                            file: inputFile
                        })
                        .then(() => {
                            setShowApply(!setShowApply);
                            setDisableSubmit(false);
                            props.loadUserProfile(props.auth.user.username);

                            // Temporary
                            alert.success(
                                'Application sent! You will receive a confirmation message to your email shortly'
                            );

                            props.history.push(`/jobs`);
                        })
                        .catch(() => {
                            props.history.push(`/error500`);
                            // console.log(`Error! ${error}`);
                        });
                })
                .catch(() => {
                    props.history.push(`/error500`);
                    // console.log(`Error! ${error}`);
                });
        }
        event.preventDefault();
    };

    useEffect(() => {
        axios
            .get<IOpportunity>(`/api/internships/${queryId}`)
            .then(({ data }) => {
                setOpportunity({
                    position: data.position,
                    description: data.description,
                    renumeration: data.renumeration,
                    location: data.location,
                    verticals: data.verticals,
                    skillsets: data.skillsets,
                    organisation: data.organisation,
                    _id: data._id
                });
                props.loadUserProfile(props.auth.user.username);
            })
            .catch(() => {
                props.history.replace('/Error404');
            });
    }, []);

    useEffect(() => {
        if (props.userProfile.userProfile) {
            const organisationId = props.userProfile.userProfile.organisation.map(
                (obj) => obj._id
            );
            if (
                !isEmpty(opportunity.organisation._id) &&
                !organisationId.includes(opportunity.organisation._id)
            ) {
                setOwnerCheck(false);
            } else {
                setOwnerCheck(true);
            }
        }

        if (
            props.auth.isAuthenticated &&
            props.userProfile.userProfile &&
            queryId &&
            !props.userProfile.userProfile.internships.includes(queryId) &&
            !ownerCheck
        ) {
            setDisableApply(false);
        } else {
            setDisableApply(true);
        }
    }, [
        props.auth.isAuthenticated,
        props.userProfile.userProfile,
        opportunity.organisation,
        ownerCheck
    ]);

    useEffect(() => {
        if (applyRef.current) {
            applyRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [showApply]);

    const showApplyText = () => {
        if (disableApply) {
            if (ownerCheck) {
                return 'Cannot apply to your own organisation';
            }

            return 'Applied';
        }
        return 'Apply';
    };

    const showApplyButton = () => {
        if (!showApply) {
            if (props.auth.user._id) {
                return (
                    <Button
                        disabled={disableApply}
                        type="button"
                        variant={disableApply ? 'secondary' : 'primary'}
                        onClick={handleShowApply}
                        block
                    >
                        {showApplyText()}
                    </Button>
                );
            }

            // Button for non-users
            return (
                <Button
                    type="button"
                    variant="primary"
                    as={Link}
                    to="/login"
                    onClick={() => {
                        useEventTracking(
                            'Jobs',
                            'Clicked Sign up or log in to apply from Job Opportunities',
                            'Redirected from Jobs'
                        );
                    }}
                    block
                >
                    Sign up or log in to apply
                </Button>
            );
        }
        return null;
    };

    return (
        <div>
            <Container className="text-left">
                <BackToJobs />
                <h1>{opportunity.position}</h1>
                <h2>
                    {opportunity.skillsets.map((skillset) => (
                        <Badge variant="light">{skillset}</Badge>
                    ))}
                </h2>
                <hr />

                <Row>
                    <Col md={8}>
                        <Card className="mb-4">
                            <CardDesc>
                                <Card.Body>
                                    <p className="allow-whitespace">
                                        {opportunity.description}
                                    </p>
                                    <ShareButtons>
                                        <FacebookShareButton
                                            url={shareLink}
                                            className="left"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Jobs',
                                                    'Shared Job via Facebook',
                                                    `${opportunity.position}`
                                                );
                                            }}
                                        >
                                            <FacebookIcon size={32} />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url={shareLink}
                                            className="middle"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Jobs',
                                                    'Shared Job via Twitter',
                                                    `${opportunity.position}`
                                                );
                                            }}
                                        >
                                            <TwitterIcon size={32} />
                                        </TwitterShareButton>
                                        <LinkedinShareButton
                                            url={shareLink}
                                            className="middle"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Jobs',
                                                    'Shared Job via LinkedIn',
                                                    `${opportunity.position}`
                                                );
                                            }}
                                        >
                                            <LinkedinIcon size={32} />
                                        </LinkedinShareButton>
                                        <TelegramShareButton
                                            url={shareLink}
                                            className="middle"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Jobs',
                                                    'Shared Job via Telegram',
                                                    `${opportunity.position}`
                                                );
                                            }}
                                        >
                                            <TelegramIcon size={32} />
                                        </TelegramShareButton>
                                        <WhatsappShareButton
                                            url={shareLink}
                                            className="right"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Jobs',
                                                    'Shared Job via Whatsapp',
                                                    `${opportunity.position}`
                                                );
                                            }}
                                        >
                                            <WhatsappIcon size={32} />
                                        </WhatsappShareButton>
                                        <span className="right">
                                            <CopyToClipboard text={shareLink}>
                                                <Button
                                                    ref={copyTarget}
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={() => {
                                                        useEventTracking(
                                                            'Jobs',
                                                            'Shared Job via Link',
                                                            `${opportunity.position}`
                                                        );

                                                        setCopyShow(true);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faLink}
                                                    />{' '}
                                                    Share Via Link
                                                </Button>
                                            </CopyToClipboard>
                                            <Overlay
                                                target={copyTarget.current}
                                                show={copyShow}
                                                placement="right"
                                            >
                                                <Tooltip id="copy-overlay">
                                                    Copied!
                                                </Tooltip>
                                            </Overlay>
                                        </span>
                                    </ShareButtons>
                                </Card.Body>
                            </CardDesc>
                        </Card>
                        <Card>
                            <Card.Body>
                                {showApplyButton()}
                                <ApplyForm>
                                    <Form
                                        ref={applyRef}
                                        className="text-muted mt-2"
                                        onSubmit={handleSubmit}
                                        style={{
                                            display:
                                                showApply && props.auth.user._id
                                                    ? 'block'
                                                    : 'none'
                                        }}
                                    >
                                        <Form.Group
                                            controlId="formApplyMessage"
                                            className="required"
                                        >
                                            <Form.Label>Message</Form.Label>
                                            <Form.Control
                                                as={Textarea}
                                                required
                                                rows={4}
                                                name="message"
                                                maxLength={3000}
                                                value={applyMessage}
                                                onChange={handleChange}
                                                placeholder="Why do you want this opportunity?"
                                            />
                                            <Form.Text muted>
                                                {charsRemaining} characters
                                                remaining.
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group
                                            controlId="formFile"
                                            className="required"
                                        >
                                            <Form.Label>Resume/CV</Form.Label>
                                            <Form.File
                                                required
                                                name="resume"
                                                type="file"
                                                accept=".pdf,.doc,.docx"
                                                onChange={handleChange}
                                                label={displayFile.name}
                                                custom
                                            />
                                        </Form.Group>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Jobs',
                                                    'Clicked Apply',
                                                    `${opportunity.position}`
                                                );
                                            }}
                                            disabled={disableSubmit}
                                        >
                                            Send
                                        </Button>
                                    </Form>
                                </ApplyForm>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md>
                        <Card className="mb-3">
                            <Card.Body>
                                <h2>Organisation</h2>
                                <Link
                                    to={`/organisation/${opportunity.organisation._id}`}
                                >
                                    <Image
                                        style={{
                                            height: '25px',
                                            width: '25px'
                                        }}
                                        className="mr-3"
                                        src={
                                            opportunity.organisation.logo
                                                ? opportunity.organisation.logo
                                                      .location
                                                : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                                        }
                                        alt="Profile"
                                        roundedCircle
                                        fluid
                                        onError={(e) => {
                                            e.currentTarget.onerror = null; // prevents looping
                                            e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                        }}
                                    />
                                    {opportunity.organisation.name}
                                </Link>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Body>
                                <h2>Verticals</h2>
                                <p>
                                    {opportunity.verticals.map((vertical) => (
                                        <Badge variant="light">
                                            {vertical}
                                        </Badge>
                                    ))}
                                </p>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Body>
                                <h2>Renumeration</h2>
                                <p>${opportunity.renumeration}</p>
                            </Card.Body>
                        </Card>
                        {ownerCheck ? (
                            <div className="text-right mt-2">
                                {!deleteCheck ? (
                                    <>
                                        <Button
                                            as={Link}
                                            to={`/editinternship/${opportunity._id}`}
                                            variant="success"
                                            size="sm"
                                            className="mr-2"
                                        >
                                            Edit Opportunity
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => setDeleteCheck(true)}
                                        >
                                            Delete Opportunity
                                        </Button>
                                    </>
                                ) : (
                                    <div>
                                        <p>
                                            Are you very sure? This cannot be
                                            undone.
                                        </p>
                                        <Button
                                            variant="light mr-1"
                                            size="sm"
                                            onClick={() =>
                                                setDeleteCheck(false)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={handleDelete}
                                        >
                                            Confirm Delete
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps, { loadUserProfile })(
    withRouter(InternshipPage)
);
