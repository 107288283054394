/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { EmailList } from '../components/admin/EmailList';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
}

const AdminSendEmailPage: React.FC<authProps> = ({ ...props }) => {
    // If user is not authenticated, redirect them to the not logged in page
    useEffect(() => {
        if (!props.auth.isAuthenticated) {
            // replace ensures that you can press back to go to previous page
            props.history.replace('/notloggedin');
        } else if (!props.userProfile.userProfile?.admin) {
            // If user is not admin, redirect them to dashboard
            props.history.replace('/dashboard');
        }
    }, [props.auth.isAuthenticated]);

    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status]);

    return (
        <div>
            <Container className="text-left">
                <EmailList authId={props.auth.user._id} />
            </Container>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(AdminSendEmailPage));
