/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Row,
    Col,
    Badge,
    Card,
    Image,
    Overlay,
    Tooltip
} from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon
} from 'react-share';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faLink } from '@fortawesome/free-solid-svg-icons';

import {
    useEventTracking,
    checkLandscapeUpvoteSource
} from '../../pages/eventTracking';

import { ShareButtons } from '../../Styles/Styles';

import { Comments } from './Comments';

import ILandscape from '../../interfaces/ILandscape';

// Interface for Redux types
import { StoreState, AuthState } from '../../redux/actions/types';

import getSrc from '../../utils/commonFunction';
import { useModalViewTracking } from '../../pages/pageTracking';

interface landscapeModalProps extends RouteComponentProps {
    landscape: ILandscape;
    show: boolean;
    upvoteList: string[];
    handleClose: () => void;
    updateUpvotes: (newUpvotes: string[]) => void;
    auth: AuthState;
}

const ItemStyles = styled.div`
    p {
        font-size: 30;
    }
    iframe {
        width: 100%;
        height: 400px;
    }
`;

const HeaderThumb = styled.div`
    height: 79px;
    left: 19px;
    width: 75px;
    img {
        object-fit: contain;
        width: 100%;
        max-height: 100%;
    }
`;

const Title = styled.h2`
    font-size: 20px;
`;

const LandscapeModal: React.FC<landscapeModalProps> = ({
    landscape,
    show,
    upvoteList,
    handleClose,
    updateUpvotes,
    ...props
}: landscapeModalProps) => {
    const shareLink = `https://app.interseed.co/landscape/${landscape._id}`;

    const [upvotes, setUpvotes] = useState(upvoteList);
    const [upvoteCheck, setUpvoteCheck] = useState(false);
    const [disableUpvote, setDisableUpvote] = useState(false);
    const [ownerCheck, setOwnerCheck] = useState(false);
    const [deleteCheck, setDeleteCheck] = useState(false);
    const [copyShow, setCopyShow] = useState(false);
    const [landscapeLink, setLandscapeLink] = useState('');
    const copyTarget = useRef(null);

    const handleUpvote = () => {
        if (disableUpvote) return;
        setDisableUpvote(true);

        if (upvoteCheck === false) {
            axios
                .put(
                    `/api/landscapes/${landscape._id}/upvotes/${props.auth.user._id}`
                )
                .then((res) => {
                    setUpvotes(res.data.upvotes);
                    setUpvoteCheck(true);
                    updateUpvotes(res.data.upvotes);
                    setDisableUpvote(false);
                });
            const [category, action] = checkLandscapeUpvoteSource(
                window.location.pathname,
                props.auth.user._id,
                landscape.author._id,
                'put'
            );
            useEventTracking(`${category}`, `${action}`, `${landscape.title}`);
        } else {
            axios
                .delete(
                    `/api/landscapes/${landscape._id}/upvotes/${props.auth.user._id}`
                )
                .then((res) => {
                    setUpvotes(res.data.upvotes);
                    setUpvoteCheck(false);
                    updateUpvotes(res.data.upvotes);
                    setDisableUpvote(false);
                });
            const [category, action] = checkLandscapeUpvoteSource(
                window.location.pathname,
                props.auth.user._id,
                landscape.author._id,
                'delete'
            );
            useEventTracking(`${category}`, `${action}`, `${landscape.title}`);
        }
    };

    const handleDelete = () => {
        if (deleteCheck && ownerCheck) {
            if (landscape.thumbnail) {
                const { key } = landscape.thumbnail;
                axios.delete(`/api/uploads/images/${key}`);
            }
            axios
                .delete(`/api/landscapes/${landscape._id}`)
                .then(() => {
                    props.history.go(0);
                })
                .catch(() => {
                    props.history.push('/error500');
                });
        }
    };

    const handleShow = () => {
        useModalViewTracking('landscape', `${landscape._id}`);
        useEventTracking(
            'Landscapes',
            'Viewed Landscape',
            `${landscape.title}`
        );
        setUpvoteCheck(false);
        for (let i = 0; i < upvoteList.length; i += 1) {
            if (upvoteList[i] === props.auth.user._id) setUpvoteCheck(true);
        }
        setUpvotes(upvoteList);
    };

    const modifyLandscapeLink = () => {
        if (
            landscape.link.match(
                /^https:\/\/docs.google.com\/presentation\/d\/e\//
            )
        ) {
            setLandscapeLink(
                `${landscape.link.match(
                    /.+?(?=pub)/
                )}embed?start=false&loop=true&delayms=3000`
            );
        } else if (
            landscape.link.match(
                /^https:\/\/docs.google.com\/document\/d\/e\//
            ) &&
            landscape.link.match(/\/pub$/)
        ) {
            setLandscapeLink(`${landscape.link}?embedded=true`);
        }
    };

    useEffect(() => {
        if (landscape.author._id === props.auth.user._id) setOwnerCheck(true);
        modifyLandscapeLink();
    }, [landscape]);

    useEffect(() => {
        if (!props.auth.isAuthenticated) {
            setDisableUpvote(true);
        }
    }, [props.auth.isAuthenticated]);

    useEffect(() => {
        if (copyShow) {
            setTimeout(() => {
                setCopyShow(false);
            }, 5000);
        }
    }, [copyShow]);

    return (
        <Modal show={show} onHide={handleClose} onShow={handleShow} size="xl">
            <Modal.Header closeButton>
                <HeaderThumb className="mr-4">
                    <img
                        src={
                            landscape.thumbnail
                                ? landscape.thumbnail.location
                                : 'test'
                        }
                        alt=""
                    />
                </HeaderThumb>
                <div>
                    <Title>
                        <Link
                            to={`/landscape/${landscape._id}`}
                            onClick={() => {
                                useEventTracking(
                                    'Landscapes',
                                    'Viewed Landscape',
                                    `${landscape.title}`
                                );
                            }}
                        >
                            {landscape.title}
                        </Link>
                    </Title>
                    <p style={{ wordBreak: 'break-all' }}>
                        {landscape.tagline}
                    </p>
                    <h2>
                        {landscape.primaryCategories.sort().map((category) => (
                            <Badge variant="primary" className="mx-1">
                                {category}
                            </Badge>
                        ))}
                        {landscape.secondaryCategories
                            .sort()
                            .map((category) => (
                                <Badge variant="light" className="mx-1">
                                    {category}
                                </Badge>
                            ))}
                    </h2>
                </div>
            </Modal.Header>
            <Modal.Body>
                <ItemStyles>
                    <Row>
                        <Col md={8}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <iframe
                                        src={landscapeLink}
                                        title="Test"
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                    <a
                                        href={landscape.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => {
                                            useEventTracking(
                                                'Landscapes',
                                                'Clicked Access the Document Hyperlink',
                                                `${landscape.title}`
                                            );
                                        }}
                                    >
                                        Access the document
                                    </a>
                                    <hr />
                                    <p className="allow-whitespace">
                                        {landscape.description}
                                    </p>
                                    <ShareButtons>
                                        <FacebookShareButton
                                            url={shareLink}
                                            className="left"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Landscapes',
                                                    'Shared Landscape via Facebook',
                                                    `${landscape.title}`
                                                );
                                            }}
                                        >
                                            <FacebookIcon size={32} />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url={shareLink}
                                            className="middle"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Landscapes',
                                                    'Shared Landscape via Twitter',
                                                    `${landscape.title}`
                                                );
                                            }}
                                        >
                                            <TwitterIcon size={32} />
                                        </TwitterShareButton>
                                        <LinkedinShareButton
                                            url={shareLink}
                                            className="middle"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Landscapes',
                                                    'Shared Landscape via LinkedIn',
                                                    `${landscape.title}`
                                                );
                                            }}
                                        >
                                            <LinkedinIcon size={32} />
                                        </LinkedinShareButton>
                                        <TelegramShareButton
                                            url={shareLink}
                                            className="middle"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Landscapes',
                                                    'Shared Landscape via Telegram',
                                                    `${landscape.title}`
                                                );
                                            }}
                                        >
                                            <TelegramIcon size={32} />
                                        </TelegramShareButton>
                                        <WhatsappShareButton
                                            url={shareLink}
                                            className="middle"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Landscapes',
                                                    'Shared Landscape via Whatsapp',
                                                    `${landscape.title}`
                                                );
                                            }}
                                        >
                                            <WhatsappIcon size={32} />
                                        </WhatsappShareButton>
                                        <div className="right mt-2">
                                            <CopyToClipboard text={shareLink}>
                                                <Button
                                                    ref={copyTarget}
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={() => {
                                                        setCopyShow(true);
                                                        useEventTracking(
                                                            'Landscapes',
                                                            'Shared Landscape via Link',
                                                            `${landscape.title}`
                                                        );
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faLink}
                                                    />{' '}
                                                    Share Via Link
                                                </Button>
                                            </CopyToClipboard>
                                            <Overlay
                                                target={copyTarget.current}
                                                show={copyShow}
                                                placement="right"
                                            >
                                                <Tooltip id="copy-overlay">
                                                    Copied!
                                                </Tooltip>
                                            </Overlay>
                                        </div>
                                    </ShareButtons>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Comments
                                    key={landscape._id}
                                    landscapeTitle={landscape.title}
                                    id={landscape._id}
                                    authId={props.auth.user._id}
                                />
                            </Card>
                        </Col>
                        <Col md>
                            {props.auth.isAuthenticated ? (
                                <Button
                                    disabled={disableUpvote}
                                    type="button"
                                    variant={
                                        upvoteCheck
                                            ? 'primary'
                                            : 'outline-primary'
                                    }
                                    onClick={handleUpvote}
                                    block
                                    className="mb-4"
                                >
                                    <FontAwesomeIcon icon={faChevronUp} />{' '}
                                    UPVOTE &nbsp;&nbsp;
                                    {upvotes.length}
                                </Button>
                            ) : (
                                <Button
                                    type="button"
                                    variant="outline-primary"
                                    as={Link}
                                    to="/login"
                                    block
                                    className="mb-4"
                                >
                                    <FontAwesomeIcon icon={faChevronUp} />{' '}
                                    UPVOTE &nbsp;&nbsp;
                                    {upvotes.length}
                                </Button>
                            )}

                            <Card className="mb-3">
                                <Card.Body>
                                    <h2>Author</h2>
                                    <Link
                                        to={`/profile/${landscape.author.username}`}
                                    >
                                        <Image
                                            style={{
                                                height: '25px',
                                                width: '25px'
                                            }}
                                            className="mr-3"
                                            src={getSrc(landscape.author)}
                                            alt="Profile"
                                            roundedCircle
                                            fluid
                                        />
                                        {landscape.author.firstName}
                                        {landscape.author.lastName
                                            ? ` ${landscape.author.lastName}`
                                            : null}
                                    </Link>
                                </Card.Body>
                            </Card>
                            <Card className="mb-3">
                                <Card.Body>
                                    <h2>Countries</h2>
                                    <p>
                                        {landscape.countries
                                            .sort()
                                            .map((country) => (
                                                <Badge variant="light">
                                                    {country}
                                                </Badge>
                                            ))}
                                    </p>
                                </Card.Body>
                            </Card>
                            {ownerCheck ? (
                                <div className="text-right mt-2">
                                    {!deleteCheck ? (
                                        <>
                                            <Button
                                                as={Link}
                                                to={`/editlandscape/${landscape._id}`}
                                                variant="success"
                                                onClick={() => {
                                                    useEventTracking(
                                                        'Landscapes',
                                                        'Edit Landscape',
                                                        `${landscape.author.username}`
                                                    );
                                                }}
                                                size="sm"
                                                className="mr-2"
                                            >
                                                Edit Landscape
                                            </Button>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={() =>
                                                    setDeleteCheck(true)
                                                }
                                            >
                                                Delete Landscape
                                            </Button>
                                        </>
                                    ) : (
                                        <div>
                                            <p>
                                                Are you very sure? This cannot
                                                be undone.
                                            </p>
                                            <Button
                                                variant="light mr-1"
                                                size="sm"
                                                onClick={() => {
                                                    useEventTracking(
                                                        'Landscapes',
                                                        'Delete Landscape',
                                                        `${landscape.author.username}`
                                                    );
                                                    setDeleteCheck(false);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                onClick={handleDelete}
                                            >
                                                Confirm Delete
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </ItemStyles>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(LandscapeModal));
