/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
    ListGroup,
    Container,
    Row,
    Col,
    Badge,
    Button,
    Image
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import IOrganisation from '../../interfaces/IOrganisation';

import { useEventTracking } from '../../pages/eventTracking';

interface orgProp {
    organisation: IOrganisation;
    authUsername: string;
    authId: string;
}

const Styles = styled.div`
    h2 {
        margin-bottom: 0.2rem;
    }

    .list-group-item {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .imgcontainer {
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 1.5rem;

        img {
            object-fit: contain;
            width: 6rem;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #dee2e6;
            padding: 0.25rem;
            aspect-ratio: 1;
        }
    }

    .badge {
        font-size: 16px;
    }
`;

const ListText = styled.p`
    margin-bottom: 0;
`;

const OrganisationItem: React.FC<orgProp> = ({
    organisation,
    authUsername,
    authId
}: orgProp) => {
    let memberCheck = false;
    for (let i = 0; i < organisation.staff.length; i += 1) {
        if (
            authId === organisation.staff[i].user._id &&
            organisation.staff[i].role !== 'Applicant'
        ) {
            memberCheck = true;
            break;
        }
    }

    const [followCheck, setFollowCheck] = useState(false);
    const [disableFollow, setDisableFollow] = useState(true);

    const handleFollow = () => {
        if (disableFollow) return;
        setDisableFollow(true);
        let category = 'Community';
        let location =
            `${window.location.pathname.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` ===
            'community'
                ? 'on Community Page'
                : "On Others' Profile";
        if (
            location === "On Others' Profile" &&
            window.location.pathname.split(/.*\/(.*)/)[1] === authUsername
        ) {
            location = 'On Own Profile';
            category = 'My Profile';
        }
        if (followCheck) {
            axios
                .delete(`/api/organisations/follows/${organisation._id}`)
                .then(() => {
                    setFollowCheck(false);
                    setDisableFollow(false);
                });
            useEventTracking(
                `${category}`,
                `Unfollowed Organisation ${location}`,
                `${organisation.name}`
            );
        } else {
            axios
                .put(`/api/organisations/follows/${organisation._id}`)
                .then(() => {
                    setFollowCheck(true);
                    setDisableFollow(false);
                });
            useEventTracking(
                `${category}`,
                `Followed Organisation ${location}`,
                `${organisation.name}`
            );
        }
    };

    useEffect(() => {
        if (!authId) return;
        for (let i = 0; i < organisation.followers.length; i += 1) {
            if (organisation.followers[i]._id === authId) {
                setFollowCheck(true);
                break;
            }
        }
        setDisableFollow(false);
    }, []);

    return (
        <>
            <Styles>
                <ListGroup.Item
                    as={Link}
                    to={`/organisation/${organisation._id}`}
                    onClick={() => {
                        if (
                            `${
                                window.location.pathname.split(
                                    /\B\/([a-zA-Z0-9-]{0,})/
                                )[1]
                            }` === 'organisations'
                        ) {
                            useEventTracking(
                                `My Organisation`,
                                'Viewed Organisation Profile',
                                `${organisation.name}`
                            );
                        } else {
                            useEventTracking(
                                `Community`,
                                'Viewed Organisation Profile',
                                `${organisation.name}`
                            );
                        }
                    }}
                >
                    <Container>
                        <Row>
                            <Col className="mb-2 imgcontainer" sm={2}>
                                <Image
                                    className="img-thumbnail"
                                    src={
                                        organisation.logo
                                            ? organisation.logo.location
                                            : `${process.env.PUBLIC_URL}/assets/interseed.png`
                                    }
                                    alt="Profile"
                                    roundedCircle
                                    thumbnail
                                    fluid
                                    onError={(e) => {
                                        e.currentTarget.onerror = null; // prevents looping
                                        e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                    }}
                                />
                            </Col>
                            <Col
                                className="my-auto text-sm-left text-center"
                                sm={7}
                            >
                                <h2>{organisation.name}</h2>
                                <ListText>{organisation.tagline}</ListText>
                                <ListText className="text-muted">
                                    {organisation.country}
                                </ListText>
                                <p className="mt-2">
                                    {organisation.focusAreas
                                        .sort()
                                        .map((focus) => (
                                            <Badge variant="light">
                                                {focus}
                                            </Badge>
                                        ))}
                                </p>
                            </Col>
                            <Col sm={2} className="text-center my-auto">
                                {memberCheck ? (
                                    <Badge
                                        pill
                                        variant="light"
                                        className="mt-3"
                                    >
                                        <FontAwesomeIcon icon={faUser} /> Member
                                    </Badge>
                                ) : (
                                    <>
                                        {authId ? (
                                            <Button
                                                variant={
                                                    !followCheck
                                                        ? 'outline-primary'
                                                        : 'primary'
                                                }
                                                className="mt-2 ml-3"
                                                onClick={(ev) => {
                                                    handleFollow();
                                                    ev.preventDefault();
                                                }}
                                                disabled={disableFollow}
                                            >
                                                {!followCheck
                                                    ? 'Follow'
                                                    : 'Unfollow'}
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outline-primary"
                                                className="mt-2"
                                                as={Link}
                                                to="/login"
                                            >
                                                Follow
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </ListGroup.Item>
            </Styles>
        </>
    );
};

export default OrganisationItem;
