import React, { Dispatch, SetStateAction } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { linkedinLoginUser } from '../../redux/actions/AuthActions';
import { ISocialUserData } from '../../interfaces/AuthInterface';
import { AuthState, StoreState } from '../../redux/actions/types';
import { useEventTracking } from '../../pages/eventTracking';

const LinkedinIcon = styled.img`
    padding-bottom: 4px;
    margin-right: 15px;
`;

interface linkedinProps extends RouteComponentProps {
    linkedinLoginUser: (
        arg0: ISocialUserData,
        arg1: RouteComponentProps['history']
    ) => void;
    auth: AuthState;
    history: RouteComponentProps['history'];
    setDisableSubmit: Dispatch<SetStateAction<boolean>>;
}

const LinkedInComponent: React.FC<linkedinProps> = (props: linkedinProps) => {
    const responseLinkedIn = (res) => {
        const userData = {
            network: 'linkedIn',
            token: res.code
        };

        if (!props.auth.isAuthenticated) {
            props.linkedinLoginUser(userData, props.history);
            props.setDisableSubmit(true);
        }
    };

    const errorLinkedIn = () => {
        props.history.push('./error500');
    };

    const linkedinContent = (
        <LinkedIn
            as={Button}
            clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
            onSuccess={responseLinkedIn}
            onFailure={errorLinkedIn}
            redirectUri={process.env.REACT_APP_LINKEDIN_REDIRECT_URL}
            scope="r_emailaddress r_liteprofile"
            renderElement={({ onClick, disabled }) => (
                <Button
                    className="linkedin-badge"
                    variant="outline-secondary"
                    onClick={() => {
                        useEventTracking(
                            'Log In',
                            'Clicked Continue with LinkedIn',
                            `Third Party Auth`
                        );
                        onClick();
                    }}
                    disabled={disabled}
                    block
                >
                    <LinkedinIcon
                        src={`${process.env.PUBLIC_URL}/assets/linkedin.svg`}
                        alt="google-login"
                        height={20}
                        width={20}
                    />
                    Continue with LinkedIn
                </Button>
            )}
        />
    );

    return <div>{linkedinContent}</div>;
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps, { linkedinLoginUser })(
    withRouter(LinkedInComponent)
);
