import {
    faCheckCircle,
    faSearch,
    faUser,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, Image, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Ecosystem, JoinAs } from '../../interfaces/Ecosystem';
import IOrganisation, { OrgEcosystem } from '../../interfaces/IOrganisation';
import CommunitySearch from './CommunitySearch';
import GoBack from './GoBack';

interface JoinAsSelected {
    selected?: boolean;
}

const VerifiedText = styled.p`
    color: #007BFF;
    font-weight: 700;
    font-style: italic; 
    font-size; 18px;
    line-height: 23px;
`;

const JoinAsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    @media screen and (max-width: 768px) {
        gap: 8px;
    }
`;

const JoinAsOptions = styled.div<JoinAsSelected>`
    width: 200px;
    height: 248px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: ${(props) => (props.selected ? '#007BFF' : '#bfd4ec')};
    color: ${(props) => (props.selected ? '#007BFF' : '#000')};
    border-radius: 5px;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        height: 150px;
    }
`;

const SelectedIcon = styled.div`
    position: absolute;
    top: -12px;
    right: -12px;
    color: #007bff;
    background: #fff;
`;

const StyledForm = styled(Form)<{ step: VerifyStatus }>`
    .form-control {
        padding: 23px 29px;
        border-color: ${(prop) =>
            prop.step === 'failed' ? '#FB441B' : '#A1A1A1'};

        color: ${(prop) => (prop.step === 'failed' ? '#FB441B' : '#002A52')};
    }
`;

const OrgsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 1.5rem 0;
    gap: 18px;
    height: 260px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 1rem 0;
`;

const OrgsOptions = styled.div<JoinAsSelected>`
    width: 120px;
    height: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-color: ${(props) => (props.selected ? '#007BFF' : '#bfd4ec')};
    color: ${(props) => (props.selected ? '#007BFF' : '#000')};
    border-radius: 5px;
    position: relative;
    padding: 1rem;

    &:hover {
        cursor: pointer;
    }
`;

const Wrapper = styled.div`
    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        margin-top: 1rem;
        max-height: 4rem;
        max-width: 4rem;
    }
`;

type VerifyStatus = 'initial' | 'verified' | 'failed';

interface Props {
    show: boolean;
    ecosystem: OrgEcosystem | Ecosystem;
    joinAs: JoinAs;
    authId: string;
    handleClose: () => void;
}

const EcosystemJoinModal: React.FC<Props> = ({
    show,
    ecosystem,
    joinAs,
    authId,
    handleClose
}) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [verifyStatus, setVerifyStatus] = useState<VerifyStatus>('initial');
    const [joinAsSelected, setJoinAsSelected] = useState<JoinAs>(joinAs);
    const [orgsAsAdmin, setOrgsAsAdmin] = useState<IOrganisation[]>();
    const [showOrgSelector, setShowOrgSelector] = useState(false);
    const [selectedOrgId, setSelectedOrgId] = useState<string>();
    const [search, setSearch] = useState<string>();

    const history = useHistory();

    const reset = () => {
        setVerificationCode('');
        setVerifyStatus('initial');
        setJoinAsSelected(joinAs);
        setShowOrgSelector(false);
        setSelectedOrgId('');
        setSearch('');
        handleClose();
    };

    const handleVerify = () => {
        setVerifyStatus('initial');
        axios
            .get<Ecosystem>(`/api/ecosystems/verification/${verificationCode}`)
            .then(({ data }) => {
                if (data._id === ecosystem._id) {
                    setVerifyStatus('verified');
                } else {
                    setVerifyStatus('failed');
                }
            })
            .catch((err) => {
                console.log(err.response.data);
                setVerifyStatus('failed');
            });
    };

    const handleJoin = (joinId: string) => {
        axios
            .put<Ecosystem>(`/api/ecosystems/${ecosystem._id}/join/${joinId}`, {
                verificationCode,
                joinAs: joinAsSelected
            })
            .then((data) => {
                console.log(data);
                reset();
                history.go(0);
            })
            .catch((err) => console.log(err.response.data));
    };

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (joinAsSelected === JoinAs.ORGANISATION) {
            if (orgsAsAdmin && orgsAsAdmin.length > 0) {
                setShowOrgSelector(true);
            } else {
                setShowOrgSelector(false);
            }

            if (selectedOrgId) {
                handleJoin(selectedOrgId);
            }
        } else if (joinAsSelected === JoinAs.USER) {
            handleJoin(authId);
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keyword = event.target.value;

        if (keyword === '') {
            setOrgsAsAdmin(orgsAsAdmin);
        } else {
            const filtered = orgsAsAdmin?.filter(
                (org) =>
                    org.name.toLowerCase().includes(keyword.toLowerCase()) ||
                    org.description
                        .toLowerCase()
                        .includes(keyword.toLowerCase())
            );
            console.log({ keyword, orgsAsAdmin, filtered });
            setOrgsAsAdmin(filtered);
        }
        setSearch(keyword);
    };

    useEffect(() => {
        axios
            .get<IOrganisation[]>(`/api/organisations/${authId}/Founder`)
            .then(({ data }) => {
                setOrgsAsAdmin(data);
            })
            .catch((err) => console.log(err.response.data));
    }, [authId]);

    return (
        <Modal
            show={show}
            onHide={reset}
            size="lg"
            centered
            dialogClassName="modal-width font-arimo"
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title as="h1" className="ml-3 font-weight-bold">
                    Join the Network!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-3 pt-0">
                {verifyStatus === 'verified' ? (
                    <>
                        <VerifiedText>
                            Your verification is successfully completed!
                        </VerifiedText>
                        {!showOrgSelector ? (
                            <JoinAsContainer>
                                <JoinAsOptions
                                    selected={joinAsSelected === JoinAs.USER}
                                    onClick={() =>
                                        setJoinAsSelected(JoinAs.USER)
                                    }
                                >
                                    <FontAwesomeIcon icon={faUser} size="2x" />
                                    <div className="mt-3 font-weight-bold">
                                        Individual User
                                    </div>
                                    {joinAsSelected === JoinAs.USER && (
                                        <SelectedIcon>
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                size="lg"
                                            />
                                        </SelectedIcon>
                                    )}
                                </JoinAsOptions>
                                <JoinAsOptions
                                    selected={
                                        joinAsSelected === JoinAs.ORGANISATION
                                    }
                                    onClick={() =>
                                        setJoinAsSelected(JoinAs.ORGANISATION)
                                    }
                                >
                                    <FontAwesomeIcon icon={faUsers} size="2x" />
                                    <div className="mt-3 font-weight-bold">
                                        Organisation ({orgsAsAdmin?.length})
                                    </div>
                                    {joinAsSelected === JoinAs.ORGANISATION && (
                                        <SelectedIcon>
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                size="lg"
                                            />
                                        </SelectedIcon>
                                    )}
                                </JoinAsOptions>
                            </JoinAsContainer>
                        ) : (
                            <>
                                <GoBack
                                    setShowOrgSelector={setShowOrgSelector}
                                />
                                <CommunitySearch>
                                    <FormControl
                                        placeholder="Search"
                                        name="search"
                                        type="text"
                                        value={search}
                                        onChange={handleSearch}
                                    />
                                    <div className="icon">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </div>
                                </CommunitySearch>
                                <OrgsContainer>
                                    {orgsAsAdmin?.map((org) => (
                                        <OrgsOptions
                                            key={org._id}
                                            selected={selectedOrgId === org._id}
                                            onClick={() =>
                                                setSelectedOrgId(org._id)
                                            }
                                        >
                                            <Wrapper>
                                                <Image
                                                    className="img-thumbnail"
                                                    src={
                                                        org.logo?.location ??
                                                        `${process.env.PUBLIC_URL}/assets/interseed.png`
                                                    }
                                                    alt="Organisation"
                                                    roundedCircle
                                                    thumbnail
                                                    fluid
                                                    onError={(e) => {
                                                        e.currentTarget.onerror = null; // prevents looping
                                                        e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/interseed.png`;
                                                    }}
                                                />
                                            </Wrapper>
                                            <div className="mt-2 mb-3 font-weight-bold">
                                                {org.name}
                                            </div>
                                            {selectedOrgId === org._id && (
                                                <SelectedIcon>
                                                    <FontAwesomeIcon
                                                        icon={faCheckCircle}
                                                        size="lg"
                                                    />
                                                </SelectedIcon>
                                            )}
                                        </OrgsOptions>
                                    ))}
                                </OrgsContainer>
                            </>
                        )}
                        <Button
                            className="btn-block my-2"
                            variant="primary"
                            onClick={handleClick}
                            disabled={
                                joinAsSelected === JoinAs.ORGANISATION &&
                                orgsAsAdmin?.length === 0
                            }
                        >
                            Join
                        </Button>
                    </>
                ) : (
                    <>
                        <p className="justify-content-center my-3">
                            Thank you for checking out {ecosystem.name}! Please
                            enter verification code to join this network!
                        </p>
                        <StyledForm step={verifyStatus}>
                            <Form.Group className="mb-0">
                                <Form.Row className="justify-content-center my-3">
                                    <Form.Control
                                        type="text"
                                        name="verificationCode"
                                        id="verificationCode"
                                        placeholder="Enter Verification Code"
                                        onChange={(e) => {
                                            setVerifyStatus('initial');
                                            setVerificationCode(e.target.value);
                                        }}
                                        value={verificationCode}
                                        required
                                    />
                                </Form.Row>
                                {verifyStatus === 'failed' && (
                                    <p className="text-danger mt-0 mb-3">
                                        Invalid verification code! Please try
                                        again.
                                    </p>
                                )}
                                <Form.Row className="my-2">
                                    <Button
                                        className="btn-block"
                                        variant="primary"
                                        onClick={handleVerify}
                                        disabled={verifyStatus === 'failed'}
                                    >
                                        Join
                                    </Button>
                                </Form.Row>
                            </Form.Group>
                        </StyledForm>
                    </>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default EcosystemJoinModal;
