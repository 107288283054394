/* eslint @typescript-eslint/no-var-requires: "off" */
/* eslint-disable react/jsx-props-no-spreading */

import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import {
    transitions,
    positions,
    Provider as AlertProvider,
    AlertComponentPropsWithStyle
} from 'react-alert';
import AlertMUITemplate from 'react-alert-template-mui';
import App from './App';

// Alert configuration
const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_CENTER,
    timeout: 0,
    offset: '30px',
    // you can also just use 'scale'
    transition: transitions.SCALE
};

ReactDOM.render(
    <React.StrictMode>
        <AlertProvider
            template={AlertMUITemplate as FC<AlertComponentPropsWithStyle>}
            {...options}
        >
            <Router>
                <App />
            </Router>
        </AlertProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
