/*
TODO: Still up to change based on Surya & YZ Koh
*/

import IOrganisation from './IOrganisation';

export default interface IVolunteerOpportunity {
    title: string;
    opportunityLength: string;
    opportunityTypes: string[];
    commitmentLevel: string;
    address: string;
    generalArea: string;
    country: string;
    description: string;
    requirements: string;
    organisation: OrganisationIdName | Record<string, any>;
    signUpLink: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    registrationClosingDate: string;
    contactInformation: IContact;
    shifts?: {
        date: string;
        startTime: string;
        endTime: string;
        duration: string;
    }[];
    primaryTags: string[];
    secondaryTags: string[];
    thumbnail?: { location: string; key: string };
    _id: string;
    createdAt: Date;
}

interface IContact {
    email: string;
    phoneNumber: string;
}

interface OrganisationIdName {
    _id: string;
    name: string;
}

export const VolunteerOpportunityBlankObj = {
    title: '',
    opportunityLength: '',
    opportunityTypes: [],
    commitmentLevel: '',
    address: '',
    generalArea: '',
    country: '',
    description: '',
    requirements: '',
    organisation: [],
    signUpLink: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    registrationClosingDate: '',
    contactInformation: { email: '', phoneNumber: '' },
    shifts: [
        {
            date: '',
            startTime: '',
            endTime: '',
            duration: ''
        }
    ],
    primaryTags: [],
    secondaryTags: [],
    thumbnail: { location: '', key: '' },
    _id: '',
    createdAt: new Date()
};
