import IComment from './IComment';
import IUser, { UserBlankObj } from './IUser';

export default interface ILandscape {
    author: IUser;
    comments: IComment[] | [];
    upvotes: string[];
    primaryCategories: string[];
    secondaryCategories: string[];
    createdAt: Date;
    description: string;
    link: string;
    noOfComments: number;
    noOfUpvotes?: number;
    tagline: string;
    title: string;
    updatedAt: Date;
    countries: string[];
    thumbnail: { location: string; key: string };
    __v: number;
    _id: string;
}

export const LandscapeBlankObj: ILandscape = {
    author: UserBlankObj,
    comments: [],
    upvotes: [],
    primaryCategories: [],
    secondaryCategories: [],
    createdAt: new Date(),
    description: '',
    link: '',
    noOfComments: 0,
    noOfUpvotes: 0,
    tagline: '',
    title: '',
    updatedAt: new Date(),
    countries: [],
    thumbnail: { location: '', key: '' },
    __v: 0,
    _id: ''
};
