import React from 'react';

const CopyIcon: React.FC = () => (
    <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.26471 17.5C1.77941 17.5 1.36382 17.3334 1.01794 17.0002C0.67206 16.667 0.499413 16.2669 0.500001 15.8V3.9H2.26471V15.8H11.9706V17.5H2.26471ZM5.79412 14.1C5.30882 14.1 4.89324 13.9334 4.54735 13.6002C4.20147 13.267 4.02882 12.8669 4.02941 12.4V2.2C4.02941 1.7325 4.20235 1.33215 4.54824 0.998951C4.89412 0.665751 5.30941 0.499435 5.79412 0.500001H13.7353C14.2206 0.500001 14.6362 0.666601 14.9821 0.999801C15.3279 1.333 15.5006 1.73307 15.5 2.2V12.4C15.5 12.8675 15.3271 13.2679 14.9812 13.6011C14.6353 13.9343 14.22 14.1006 13.7353 14.1H5.79412Z"
            fill="currentcolor"
        />
    </svg>
);

export default CopyIcon;
