import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface IdProp {
    id: string;
}

const BackToProfileComponent: React.FC<IdProp> = (id) => (
    <div className="text-left back-link mb-4">
        <Link to={`/organisation/${id.id}`}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Organisation
        </Link>
    </div>
);

export default BackToProfileComponent;
