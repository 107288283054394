import React from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import { useEventTracking } from '../../pages/eventTracking';

// Interface for Redux types
import { StoreState, AuthState } from '../../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
}

const SubmitFundingWidget: React.FC<authProps> = ({ ...props }) => (
    <div className="mb-3">
        <Card>
            <Card.Body className="text-center">
                <p>
                    {props.auth.isAuthenticated
                        ? `Submit a funding opportunity!`
                        : `Join us to share your funding opportunity!`}
                </p>
                {props.auth.isAuthenticated ? (
                    <a
                        href="https://airtable.com/shr726nJ5gR8S0TM6"
                        onClick={() => {
                            useEventTracking(
                                'Funding',
                                'Clicked Submit a Funding Opportunity',
                                `${props.auth.user.username}`
                            );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button type="button" block>
                            Submit
                        </Button>
                    </a>
                ) : (
                    <Link to="/register">
                        <Button
                            type="button"
                            block
                            onClick={() => {
                                useEventTracking(
                                    'Funding',
                                    'Clicked Sign Up Now From Funding Opportunities',
                                    'Redirected from Funding Opportunities'
                                );
                            }}
                        >
                            Sign Up Now
                        </Button>
                    </Link>
                )}
            </Card.Body>
        </Card>
    </div>
);

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(SubmitFundingWidget));
