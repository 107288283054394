/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Link,
    withRouter,
    RouteComponentProps,
    useParams
} from 'react-router-dom';
import {
    Container,
    Card,
    Form,
    Button,
    Row,
    Col,
    Modal
} from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import Textarea from 'react-expanding-textarea';
import { useAlert } from 'react-alert';

import LandscapePreview from '../../components/landscapes/LandscapePreview';
import BackToLandscapes from '../../components/backbuttons/BackToLandscapes';

import ILandscape, { LandscapeBlankObj } from '../../interfaces/ILandscape';

import { whitespaceCheck } from '../../utils/commonFunction';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../../redux/actions/types';

// Select Options
import {
    primaryCategoriesOptions,
    secondaryCategoriesOptions,
    countriesOptions
} from '../../options/landscapeSelectOptions';

const CardContent = styled.div`
    padding: 2rem 1rem;
    .form-label {
        font-size: 16px;
        font-weight: bold;
    }
    .custom-file {
        font-size: 16px;
    }
    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }
`;

const FormHeader = styled.h2`
    margin-bottom: 2rem;
`;

interface uploadProps extends RouteComponentProps {
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
}

interface thumbFile {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    src?: string | ArrayBuffer;
}

interface ParamTypes {
    queryId: string | undefined;
}

const descMaxLength = 5000;

// Main React Functional Component
const EditLandscape: React.FC<uploadProps> = ({ ...props }) => {
    const { queryId } = useParams<ParamTypes>();
    const alert = useAlert();

    const [details, setDetails] = useState<ILandscape>(LandscapeBlankObj);

    const [thumbReal, setThumbReal] = useState<File>();
    const [thumb, setThumb] = useState<thumbFile>({
        lastModified: 0,
        name: 'Choose a file',
        size: 0,
        type: ''
    });

    const [editCheck, setEditCheck] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [descWordsRemaining, setDescWordsRemaining] = useState(descMaxLength);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = event.target;

        if (!editCheck) setEditCheck(true);

        if (type === 'file') {
            if (event.target.files && event.target.files[0]) {
                if (
                    event.target.files[0].type !== 'image/jpeg' &&
                    event.target.files[0].type !== 'image/png'
                ) {
                    alert.error('Please choose a JPEG/PNG file.');
                    return;
                }
                if (event.target.files[0].size > 4000000) {
                    // eslint-disable-next-line
                    alert.error('Please choose an image under 4mb.');
                    return;
                }

                setThumbReal(event.target.files[0]);

                const file: thumbFile = event.target.files[0];
                const reader = new FileReader();

                reader.onload = function read(e) {
                    if (e.target && e.target.result) {
                        file.src = e.target.result;
                        if (file.name.length > 40) {
                            setThumb({
                                lastModified: file.lastModified,
                                name: file.name
                                    .slice(0, 40)
                                    .concat(
                                        `... ${file.type.replace(
                                            'image/',
                                            '.'
                                        )}`
                                    ),
                                size: file.size,
                                type: file.type
                            });
                        } else setThumb(file);
                    }
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        } else {
            if (name === 'description') {
                setDescWordsRemaining(descMaxLength - value.length);
            }
            setDetails({
                ...details,
                [name]: value
            });
        }
    };

    // eslint-disable-next-line
    const handlePrimarySelect = (newValue: any) => {
        if (newValue.length > 2) return;

        if (!editCheck) setEditCheck(true);

        const value: string[] = [];

        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }
        value.sort();
        setDetails({
            ...details,
            primaryCategories: value
        });
    };

    // eslint-disable-next-line
    const handleSecondarySelect = (newValue: any) => {
        const value: string[] = [];

        if (!editCheck) setEditCheck(true);

        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }
        value.sort();
        setDetails({
            ...details,
            secondaryCategories: value
        });
    };

    // eslint-disable-next-line
    const handleCountriesSelect = (newValue: any) => {
        const value: string[] = [];

        if (!editCheck) setEditCheck(true);

        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }
        value.sort();
        setDetails({
            ...details,
            countries: value
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (disableSubmit) return;
        setDisableSubmit(true);

        let modifiedLink: RegExpMatchArray | string | null = '';

        // Modify Google Slides Link
        if (
            details.link.match(
                /^https:\/\/docs.google.com\/presentation\/d\/e\//
            )
        ) {
            // modifiedLink = `${details.link.match(/.+?(?=pub)/)}embed?start=false&loop=true&delayms=3000`;
            modifiedLink = details.link;
        } else if (
            details.link.match(
                /^https:\/\/docs.google.com\/document\/d\/e\//
            ) &&
            details.link.match(/\/pub$/)
        ) {
            // modifiedLink = `${details.link}?embedded=true`;
            modifiedLink = details.link;
        } else {
            // eslint-disable-next-line
            handleShow();
            setDisableSubmit(false);
            return;
        }

        // Check for whitespace submissions
        if (
            !whitespaceCheck(details, [
                'title',
                'tagline',
                'description',
                'link'
            ])
        ) {
            alert.error(
                'Required fields cannot be filled with only whitespaces.'
            );
            setDisableSubmit(false);
            return;
        }

        if (thumbReal) {
            // eslint-disable-next-line
            const data: any = new FormData();
            data.append('image', thumbReal);

            if (details.thumbnail) {
                const { key } = details.thumbnail;
                axios.delete(`/api/uploads/images/${key}`);
            }

            axios
                .post(`/api/uploads/images`, data)
                .then((res) => {
                    const thumbnailData = res.data;
                    axios
                        .put(`/api/landscapes/${queryId}`, {
                            title: details.title,
                            tagline: details.tagline,
                            description: details.description,
                            link: modifiedLink,
                            thumbnail: thumbnailData,
                            primaryCategories: details.primaryCategories,
                            secondaryCategories: details.secondaryCategories,
                            countries: details.countries
                        })
                        .then((rec) => {
                            props.history.push(`/landscape/${rec.data._id}`);
                        })
                        .catch(() => {
                            alert.error(
                                'Error editing landscape. Please try again.'
                            );
                            setDisableSubmit(false);
                        });
                })
                .catch(() => {
                    alert.error(
                        'Error editing landscape (Image Upload Failed). Please try again.'
                    );
                    setDisableSubmit(false);
                });
        } else {
            axios
                .put(`/api/landscapes/${queryId}`, {
                    title: details.title,
                    tagline: details.tagline,
                    description: details.description,
                    link: modifiedLink,
                    primaryCategories: details.primaryCategories,
                    secondaryCategories: details.secondaryCategories,
                    countries: details.countries
                })
                .then((rec) => {
                    props.history.push(`/landscape/${rec.data._id}`);
                })
                .catch(() => {
                    alert.error('Error editing landscape. Please try again.');
                    setDisableSubmit(false);
                });
        }
    };

    useEffect(() => {
        if (!props.auth.isAuthenticated) {
            props.history.push('/');
        }
    }, [props.auth.isAuthenticated]);

    useEffect(() => {
        axios
            .get<ILandscape>(`/api/landscapes/${queryId}`)
            .then(({ data }) => {
                setDetails(data);
                setThumb({
                    ...thumb,
                    name: data.thumbnail.location.replace(
                        'https://interseedstorage.s3.ap-southeast-1.amazonaws.com/',
                        ''
                    ),
                    src: data.thumbnail.location
                });
                setDescWordsRemaining(descMaxLength - data.description.length);
            })
            .catch(() => {
                props.history.push('/error404');
            });
    }, []);

    // Enable submit only when something is changed
    useEffect(() => {
        if (editCheck) setDisableSubmit(false);
    }, [editCheck]);

    // Check whether user is onboarded
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    return (
        <Container fluid='md'>
            <BackToLandscapes />
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Invalid google slides/docs link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Please check the following:</p>
                    <ul>
                        <li>
                            You are using a Google Slides or Google Docs link
                        </li>
                        <li>
                            The link you are using is published to web (the
                            thing in bracket)
                        </li>
                        <li>You have copied the publish link correctly</li>
                    </ul>
                </Modal.Body>
            </Modal>
            <h1>Edit your Market Research</h1>
            <Card className="text-left mb-4">
                <CardContent>
                    <FormHeader>Tell us about this research</FormHeader>
                    <hr />
                    <Form className="text-muted" onSubmit={handleSubmit}>
                        <Form.Group controlId="formTitle" className="required">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                required
                                name="title"
                                type="text"
                                maxLength={50}
                                value={details.title}
                                onChange={handleChange}
                                placeholder="Title of your Landscape"
                            />
                            <Form.Text muted>Max 50 characters.</Form.Text>
                        </Form.Group>

                        <Form.Group
                            controlId="formTagline"
                            className="required"
                        >
                            <Form.Label>Tagline</Form.Label>
                            <Form.Control
                                required
                                name="tagline"
                                type="text"
                                maxLength={100}
                                value={details.tagline}
                                onChange={handleChange}
                                placeholder="Enter a concise tagline"
                            />
                            <Form.Text muted>Max 100 characters.</Form.Text>
                        </Form.Group>

                        <Form.Group
                            controlId="formDescription"
                            className="required"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required
                                as={Textarea}
                                name="description"
                                rows={4}
                                maxLength={descMaxLength}
                                value={details.description}
                                onChange={handleChange}
                                placeholder="Enter your landscape description"
                            />
                            <Form.Text muted>
                                {descWordsRemaining} characters remaining.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formSlides" className="required">
                            <Form.Label>Google Slides/Docs</Form.Label>
                            <Form.Control
                                required
                                name="link"
                                type="url"
                                maxLength={500}
                                value={details.link}
                                onChange={handleChange}
                                placeholder="Publish your Google Slides/Docs and insert the Link here"
                            />
                            <Form.Text muted>
                                On Google Slides/Docs: File &#8594; Publish to
                                the web &#8594; Copy the Link (not Embed) and
                                paste here
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formThumbnail">
                            <Form.Label>Thumbnail</Form.Label>
                            <Form.File
                                style={{ overflow: 'hidden' }}
                                name="thumbnail"
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={handleChange}
                                label={thumb.name}
                                custom
                            />
                            <Form.Text muted>File size limit: 4mb</Form.Text>
                        </Form.Group>

                        <Form.Group
                            controlId="formCountries"
                            className="required"
                        >
                            <Form.Label>Countries</Form.Label>
                            <Select
                                isMulti
                                name="countries"
                                options={countriesOptions}
                                placeholder="Which country/countries is/are this landscape conducted on?"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={details.countries.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                onChange={(newValue) =>
                                    handleCountriesSelect(newValue)
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={details.countries}
                                required
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formCategories"
                            className="required"
                        >
                            <Form.Label>Categories</Form.Label>
                            <Row>
                                <Col sm={6} className="mb-3">
                                    <Select
                                        isMulti
                                        name="primary-categories"
                                        options={
                                            details.primaryCategories.length < 2
                                                ? primaryCategoriesOptions
                                                : []
                                        }
                                        noOptionsMessage={() =>
                                            details.primaryCategories.length < 2
                                                ? 'No options available'
                                                : 'Maximum 2 primary categories selected'
                                        }
                                        value={details.primaryCategories.map(
                                            (item) => ({
                                                value: item,
                                                label: item
                                            })
                                        )}
                                        placeholder="Primary (Max 2)"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(newValue) =>
                                            handlePrimarySelect(newValue)
                                        }
                                    />
                                    <input
                                        className="invis"
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        defaultValue={details.primaryCategories}
                                        required
                                    />
                                </Col>
                                <Col sm={6} className="mb-3">
                                    <Select
                                        isMulti
                                        name="secondary-categories"
                                        options={secondaryCategoriesOptions}
                                        value={details.secondaryCategories.map(
                                            (item) => ({
                                                value: item,
                                                label: item
                                            })
                                        )}
                                        placeholder="Secondary (Optional)"
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={(newValue) =>
                                            handleSecondarySelect(newValue)
                                        }
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <hr />
                        <h2>Preview</h2>
                        <LandscapePreview
                            key="landscapePreviewItem"
                            items={details}
                            thumb={thumb}
                        />
                        <div className="text-right mt-4">
                            <Button
                                as={Link}
                                className="mr-1"
                                variant="light"
                                to="/landscapes"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={disableSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </CardContent>
            </Card>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(EditLandscape));
