import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface usernameProp {
    username: string;
}

const BackToProfileComponent: React.FC<usernameProp> = ({
    username
}: usernameProp) => (
    <div className="text-left back-link mb-4">
        <Link to={`/profile/${username}`}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Profile
        </Link>
    </div>
);

export default BackToProfileComponent;
