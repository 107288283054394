import optionType from '../interfaces/OptionType';
import { populateArray } from '../utils/commonFunction';

const primaryCategories = [
    'Circular Economy',
    'Energy Transitions',
    'Future of Food & Agriculture',
    'Others'
];

const secondaryCategories = [
    'Carbon Emissions',
    'Pollution',
    'Resource Resilience',
    'Policy',
    'Society',
    'Technology',
    'Business'
];

const generalAreas = [
    'Yishun',
    'Woodlands',
    'Toa Payoh',
    'Tampines',
    'Serangoon',
    'Sengkang',
    'Sembawang',
    'Queenstown',
    'Punggol',
    'Pasir Ris',
    'Kallang/Whampoa',
    'Jurong West',
    'Jurong East',
    'Hougang',
    'Geylang',
    'Clementi',
    'Choa Chu Kang',
    'Paya Lebar',
    'Bukit Merah',
    'Bukit Batok',
    'Bishan',
    'Bedok',
    'Ang Mo Kio',
    'Bukit Timah',
    'Marine Parade',
    'Central Area',
    'Tengah',
];

const categories = [
    'Circular Economy',
    'Energy Transitions',
    'Future of Food & Agriculture',
    'Carbon Emissions',
    'Pollution',
    'Resource Resilience',
    'Policy',
    'Society',
    'Technology',
    'Business',
    'Others'
];

const sustainabilityVerticals = [
    'Food Waste',
    'Climate Change',
    'Fast Fashion',
    'Marine Conservation',
    'Biodiversity',
    'Sanitation & Hygiene',
    'Waste Management',
    'Youth',
    'Education',
    'Elderly',
    'Animal Conservation',
    'Environment',
    'Renewable Energy',
    'Migrant Workers'
];

const countries = [
    'Brunei',
    'Myanmar',
    'Cambodia',
    'Indonesia',
    'Laos',
    'Malaysia',
    'Philippines',
    'Singapore',
    'Thailand',
    'Vietnam',
    'Timor-Leste',
    'Global',
    'Across Southeast Asia'
];

const opportunityLength = [
    "Ad-hoc",
    "Regular"
]

export const primaryCategoriesOptions: optionType[] = populateArray(primaryCategories, true);
export const secondaryCategoriesOptions: optionType[] = populateArray(secondaryCategories, true);
const generalAreaOptionsWithoutRemote: optionType[] = populateArray(generalAreas, true);
generalAreaOptionsWithoutRemote.push({value: "Remote", label: "Remote"});
generalAreaOptionsWithoutRemote.push({value: "Multiple Locations", label: "Multiple Locations"});
export const generalAreaOptions: optionType[] = generalAreaOptionsWithoutRemote
export const categoriesOptions: optionType[] = populateArray(categories, true);
export const sustainabilityVerticalsOptions: optionType[] = populateArray(sustainabilityVerticals, true);
export const countriesOptions: optionType[] = populateArray(countries, true);
export const opportunityLengthOptions: optionType[] = populateArray(opportunityLength, true);
