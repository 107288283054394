import React, { ReactElement, useState } from 'react';
import { Pagination, Tab, Image } from 'react-bootstrap';
import styled from 'styled-components';
import { blankEvent, IEvent } from '../../interfaces/IEvent';
import IUser from '../../interfaces/IUser';
import { AuthState, UserProfileState } from '../../redux/actions/types';
import EventItemHorizontal from './EventItemHorizontal';
import { color } from './EventStyles';
import EventViewModal from './EventViewModal';
import usePagination from './usePagination';

const TabContentStyled = styled(Tab.Content)`
    li.active > span {
        background-color: #6c7b98 !important;
    }
`;

interface EventProfileContentProps {
    profile: IUser;
    currentUser: IUser | undefined;
    auth: AuthState;
    refreshEventsCount: () => void;
    eventPendingLength: number;
    eventOrganizedLength: number;
    eventRegisteredLength: number;
}

export default function EventProfileContent({
    profile,
    auth,
    currentUser,
    refreshEventsCount,
    eventPendingLength,
    eventOrganizedLength,
    eventRegisteredLength
}: EventProfileContentProps): ReactElement {
    const [selectedEvent, setSelectedEvent] = useState('');
    const [viewEvent, setViewEvent] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const {
        currentPage: pendingEventsPage,
        setPage: setPendingEventsPage,
        data: eventsPending,
        refresh: refreshPendingEvents
    } = usePagination<IEvent>({
        route: 'api/events/filtered',
        sort: 'date',
        sortOrder: 'asc',
        limit: 4,
        page: 1,
        query: profile.admin
            ? `&approved=false&participantLimit=4&filter=current`
            : `&organizer=${profile._id}&approved=false&participantLimit=4&filter=current`
    });
    const {
        currentPage: organizedEventsPage,
        setPage: setOrganizedEventsPage,
        data: organizedEvents,
        refresh: refreshOrganizedEvents
    } = usePagination<IEvent>({
        route: 'api/events/filtered',
        sort: 'date',
        sortOrder: 'asc',
        limit: 4,
        page: 1,
        query: `&organizer=${profile._id}&participantLimit=4`
    });
    const {
        currentPage: registeredEventsPage,
        setPage: setRegisteredEventsPage,
        data: registeredEvents,
        refresh: refreshRegisteredEvents
    } = usePagination<IEvent>({
        route: 'api/events/filtered',
        sort: 'date',
        sortOrder: 'asc',
        limit: 4,
        page: 1,
        query: `&participants=${profile._id}&participantLimit=4`
    });

    const getNewEvents = (event: string) => {
        if (!event) return;
        refreshPendingEvents();
        refreshOrganizedEvents();
        refreshRegisteredEvents();
        refreshEventsCount();
        setViewEvent(false);
    };

    const setViewModal = (val: boolean, pending = false) => {
        setViewEvent(val);
        setIsPending(pending);
    };

    const paginationItems = (type: string) => {
        const items: ReactElement[] = [];
        let length: number;
        let activePage: number;
        let setPage: (val: number) => void;
        switch (type) {
            case 'registered':
                length = eventRegisteredLength;
                activePage = registeredEventsPage;
                setPage = (val: number) => setRegisteredEventsPage(val);
                break;
            case 'organized':
                length = eventOrganizedLength;
                activePage = organizedEventsPage;
                setPage = (val: number) => setOrganizedEventsPage(val);
                break;
            case 'pending':
                length = eventPendingLength;
                activePage = pendingEventsPage;
                setPage = (val: number) => setPendingEventsPage(val);
                break;
            default:
                length = 0;
                activePage = 0;
                break;
        }
        for (let number = 1; number <= Math.ceil(length / 4.0); number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === activePage}
                    style={
                        number === activePage
                            ? { backgroundColor: '#6c7b98' }
                            : {}
                    }
                    onClick={() => {
                        setPage(number);
                    }}
                >
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    };

    return (
        <TabContentStyled>
            <Tab.Pane eventKey="organized-events">
                <div className="d-flex flex-wrap">
                    {organizedEvents.length ? (
                        organizedEvents.map((event) => (
                            <div
                                key={event._id}
                                className="col-lg-6 col-md-12 col-sm-12"
                                style={{ opacity: event.upcoming ? 1 : 0.5 }}
                            >
                                <EventItemHorizontal
                                    event={event}
                                    setViewEvent={(val) =>
                                        setViewModal(val, false)
                                    }
                                    setSelectedEvent={setSelectedEvent}
                                />
                            </div>
                        ))
                    ) : (
                        <div
                            className="col-12 d-flex align-items-center justify-content-center"
                            style={{
                                flexDirection: 'column',
                                gap: '1rem',
                                padding: '1rem 0'
                            }}
                        >
                            <div>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/assets/interseed_event_no_registered.svg`}
                                    alt="No Event"
                                />
                            </div>
                            <div
                                className="text-align-center"
                                style={{
                                    fontSize: '1rem',
                                    color: color.shade2
                                }}
                            >
                                Not organized any events currently.
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-end mr-2">
                    <Pagination>{paginationItems('organized')}</Pagination>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="registered-events">
                <div className="d-flex flex-wrap">
                    {registeredEvents.length ? (
                        registeredEvents.map((event) => (
                            <div
                                key={event._id}
                                className="col-lg-6 col-md-12 col-sm-12"
                                style={{ opacity: event.upcoming ? 1 : 0.5 }}
                            >
                                <EventItemHorizontal
                                    event={event}
                                    setViewEvent={(val) =>
                                        setViewModal(val, false)
                                    }
                                    setSelectedEvent={setSelectedEvent}
                                />
                            </div>
                        ))
                    ) : (
                        <div
                            className="col-12 d-flex align-items-center justify-content-center"
                            style={{
                                flexDirection: 'column',
                                gap: '1rem',
                                padding: '1rem 0'
                            }}
                        >
                            <div>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/assets/interseed_event_no_registered.svg`}
                                    alt="No Event"
                                />
                            </div>
                            <div
                                className="text-align-center"
                                style={{
                                    fontSize: '1rem',
                                    color: color.shade2
                                }}
                            >
                                Not registered in any events currently.
                            </div>
                        </div>
                    )}
                </div>
                <div className="d-flex justify-content-end mr-2">
                    <Pagination>{paginationItems('registered')}</Pagination>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="pending-for-approval-events">
                <div className="d-flex flex-wrap">
                    {eventsPending.map((event) => (
                        <div
                            key={event._id}
                            className="col-lg-6 col-md-12 col-sm-12"
                            style={{ opacity: event.upcoming ? 1 : 0.5 }}
                        >
                            <EventItemHorizontal
                                event={event}
                                setViewEvent={(val) => setViewModal(val, true)}
                                setSelectedEvent={setSelectedEvent}
                            />
                        </div>
                    ))}
                </div>
                <div className="d-flex justify-content-end mr-2">
                    <Pagination>{paginationItems('pending')}</Pagination>
                </div>
            </Tab.Pane>
            <EventViewModal
                isPending={isPending}
                show={viewEvent}
                setShow={setViewEvent}
                eventId={selectedEvent}
                auth={auth}
                currentUser={currentUser}
                getNewEvents={getNewEvents}
            />
        </TabContentStyled>
    );
}
