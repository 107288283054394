import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Image, Row, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Ecosystem, JoinAs } from '../../interfaces/Ecosystem';
import IOrganisation, { OrgEcosystem } from '../../interfaces/IOrganisation';
import { AuthState } from '../../redux/actions/types';
import EcosystemJoinModalAsOrg from '../community/ecosystem/EcosystemJoinModalAsOrg';
import EcosystemJoinModal from '../community/EcosystemJoinModal';

const ItemWrapper = styled.div`
    a.row {
        &:hover {
            text-decoration: none;
        }
    }

    @media screen and (max-width: 768px) {
        margin: 20px 0;
        padding: 20px 0;
    }
`;

const ListHeading = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #002a52;

    @media screen and (max-width: 768px) {
        text-align: center;
    }
`;

const FocusAreas = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    & > div {
        background-color: #f5faff;
        color: #2b6098;
        padding: 7px 9px;
        border: 1px solid #cecece;
        border-radius: 8px;
        font-size: 16px;
    }

    @media screen and (max-width: 768px) {
        justify-content: center;
    }
`;

const StyledForm = styled(Form)`
    .form-row {
        margin-left: 1px;
    }

    label.form-label {
        color: #6c757d;
        font-size: 14px;
    }

    input.form-control {
        width: 50%;
        background: #ffffff;
        border: 1px solid #ced4da;
        border-radius: 3px;
        padding: 9px 12px;
        margin-right: 14px;

        &::placeholder {
            color: #6c757d;
        }
    }

    .btn.btn-primary {
        padding: 8px 38px;
        font-weight: 600;
    }
`;

const Wrapper = styled.div`
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        max-height: 8.5rem;
        max-width: 8.5rem;

        @media screen and (max-width: 991px) {
            max-height: 6.5rem;
            max-width: 6.5rem;
        }
        @media screen and (max-width: 768px) {
            max-height: 6rem;
            max-width: 6rem;
        }
    }
`;

interface Props {
    profile: IOrganisation;
    ownerCheck: boolean;
    auth: AuthState;
    queryId: string;
}

interface ItemProps {
    ecosystem: OrgEcosystem;
    authId: string;
    profileId: string;
}

const EcosystemItem: React.FC<ItemProps> = ({ ecosystem, authId }) => {
    const [joined, setJoined] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (!authId) return;
        if (ecosystem.members.includes(authId)) {
            setJoined(true);
        }
    }, [ecosystem._id, authId]);

    return (
        <ItemWrapper>
            <Row as={Link} to={`/ecosystem/${ecosystem._id}`}>
                <Col sm={3}>
                    <Wrapper>
                        <Image
                            className="img-thumbnail mb-2"
                            src={
                                ecosystem.logo
                                    ? ecosystem.logo.location
                                    : `${process.env.PUBLIC_URL}/assets/interseed.png`
                            }
                            alt="Ecosystem"
                            roundedCircle
                            thumbnail
                            fluid
                            onError={(e) => {
                                e.currentTarget.onerror = null; // prevents looping
                                e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                            }}
                        />
                    </Wrapper>
                </Col>
                <Col className="my-auto">
                    <ListHeading>{ecosystem.name}</ListHeading>
                    {ecosystem.focusAreas && (
                        <FocusAreas className="mt-2">
                            {ecosystem.focusAreas.sort().map((focus) => (
                                <div>{focus}</div>
                            ))}
                        </FocusAreas>
                    )}
                </Col>
                <Col sm={2} className="text-center my-auto">
                    <Button
                        variant={!joined ? 'primary' : 'outline-primary'}
                        className="mt-2 ml-3"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault();
                            if (!joined) {
                                setShow(true);
                            }
                        }}
                    >
                        {!joined ? 'Join' : 'Joined'}
                    </Button>
                </Col>
            </Row>
            <EcosystemJoinModal
                show={show}
                ecosystem={ecosystem}
                joinAs={JoinAs.ORGANISATION}
                authId={authId}
                handleClose={() => setShow(false)}
            />
        </ItemWrapper>
    );
};

const EcosystemTabContent: React.FC<Props> = ({
    profile,
    ownerCheck,
    auth,
    queryId
}) => {
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [show, setShow] = useState(false);
    const [ecosystem, setEcosystem] = useState<Ecosystem>({} as Ecosystem);
    const [ecosystemsByOrg, setEcosystemsByOrg] = useState<Ecosystem[]>([]);
    const [disabled, setDisabled] = useState(true);

    const handleAddEcosystem = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        axios
            .get<Ecosystem>(`/api/ecosystems/verification/${verificationCode}`)
            .then(({ data }) => {
                setEcosystem(data);
                setShow(true);
            })
            .catch((err) => console.log(err.response.data.message));
    };

    useEffect(() => {
        if (profile._id) {
            axios
                .get<Ecosystem[]>(`/api/ecosystems/findbyorg/${profile._id}`)
                .then(({ data }) => {
                    setEcosystemsByOrg(data);
                    if (profile.rootOrg) {
                        setDisabled(false);
                    } else if (ecosystemsByOrg.length <= 1) {
                        setDisabled(false);
                    }
                })
                .catch((err) => console.log(err.response.data.message));
        }
    }, [profile._id]);

    return (
        <Tab.Pane eventKey="ecosystems">
            <h3>Ecosystems</h3>
            {!ownerCheck && profile.ecosystems.length === 0 && (
                <p className="mt-3">
                    <i>No Ecosystems Joined</i>
                </p>
            )}
            {ownerCheck && (
                <>
                    <p className="my-3">
                        <i>Create an Ecosystem managed by {profile.name}</i>
                    </p>
                    {disabled ? (
                        <Button
                            variant="primary"
                            className="mb-2"
                            disabled={disabled}
                        >
                            Create Ecosystem
                        </Button>
                    ) : (
                        <Button
                            variant="primary"
                            className="mb-2"
                            as={Link}
                            to={`/createecosystem/${queryId}`}
                        >
                            Create Ecosystem
                        </Button>
                    )}
                </>
            )}
            <>
                <div className="my-3">
                    <p className="mb-1 mx-0">
                        <i>
                            Part of an Network? Input your Ecosystem Code to
                            join!{' '}
                        </i>
                    </p>
                    <StyledForm>
                        <Form.Row>
                            <Form.Label htmlFor="verificationCode">
                                Verification Code
                            </Form.Label>
                        </Form.Row>
                        <Form.Row>
                            <Form.Control
                                type="text"
                                id="verificationCode"
                                aria-describedby="verificationCode"
                                placeholder="Code"
                                onChange={(e) =>
                                    setVerificationCode(e.target.value)
                                }
                                value={verificationCode}
                            />
                            <Button
                                type="submit"
                                className="mb-3"
                                onClick={handleAddEcosystem}
                            >
                                Add
                            </Button>
                        </Form.Row>
                    </StyledForm>
                </div>
                <EcosystemJoinModalAsOrg
                    show={show}
                    ecosystem={ecosystem}
                    joinAs={JoinAs.ORGANISATION}
                    verificationCode={verificationCode}
                    setVerificationCode={setVerificationCode}
                    profileId={profile._id}
                    handleClose={() => setShow(false)}
                />
            </>
            {profile.ecosystems.map((ecosystemItem) => (
                <EcosystemItem
                    key={ecosystemItem._id}
                    ecosystem={ecosystemItem}
                    authId={auth.user._id}
                    profileId={queryId}
                />
            ))}
        </Tab.Pane>
    );
};

export default EcosystemTabContent;
