/* eslint-disable no-underscore-dangle */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React, {
    MutableRefObject,
    ReactElement,
    RefObject,
    useEffect,
    useRef,
    useState
} from 'react';

// Imports for authentication
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import {
    featureGroup,
    LatLngBounds,
    marker,
    Map as LeafletMap,
    LatLngLiteral,
    LatLng
} from 'leaflet';
import Marker from 'react-leaflet-enhanced-marker';
import styled from 'styled-components';
import 'leaflet/dist/leaflet.css';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import axios, { CancelToken } from 'axios';
import { useDebounce } from 'use-debounce/lib';
import CircleMarker from '../components/dashboard/CircleMarker';
import Search from '../components/dashboard/Search';
import Links from '../components/dashboard/Links';
import SingleProfileView from '../components/dashboard/SingleProfileView';
import ProfileList from '../components/dashboard/ProfileList';
import { AuthedSingleOrganisationView } from '../components/dashboard/SingleOrganisationView';
import OrganisationsList from '../components/dashboard/OrganisationsList';
import LandscapesList from '../components/dashboard/LandscapesList';
import {
    MapMode,
    SearchParams,
    Coordinated,
    SearchResults,
    CommunitySelection,
    CommunityData,
    PopupMap
} from '../components/dashboard/Types';
import ISPopup from '../components/dashboard/ISPopup';
import SearchResultsView from '../components/dashboard/SearchResultsView';
import ILandscape from '../interfaces/ILandscape';
import IUser from '../interfaces/IUser';
import IOrganisation from '../interfaces/IOrganisation';
import { AuthedLandscapeItem } from '../components/dashboard/LandscapeItem';
import { useEventTracking, sendDashboardSearch } from './eventTracking';

// Imports for auth interfaces
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';
import IVolunteerOpportunity from '../interfaces/IVolunteerOpportunity';
import OpportunitiesList from '../components/dashboard/OpportunitiesList';
import { AuthedOpportunitiesItem } from '../components/dashboard/OpportunitiesItem';
import Walkthrough from '../components/walkthrough';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

const DashboardStyle = styled.div`
    position: relative;
    padding: 0 4rem;
    padding-bottom: 3.75rem;

    .leaflet-popup-content {
        margin: 0.2rem !important;
    }

    @media screen and (max-width: 768px) {
        padding: 0;
        top: -60px;
        padding-bottom: 6rem;
    }

    @media screen and (max-width: 399px) {
        padding: 0;
        top: -60px;
        padding-bottom: 1rem;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const HeaderText = styled.h1`
    font-size: 2.7rem;
    text-align: left;
    margin-bottom: 2rem;

    @media screen and (max-width: 768px) {
        padding: 2rem 1rem 0;
        font-size: 20px;
        text-align: center;
    }
`;

const Layout = styled.div`
    display: grid;
    grid-template-columns: 360px 1fr;
    grid-template-rows: auto auto;
    gap: 2rem;

    .top-left {
        grid-area: 1 / 1 / span 1 / span 1;

        @media screen and (max-width: 768px) {
            margin-bottom: 2rem;
            margin-right: 1rem;
        }
    }

    .bottom-left {
        grid-area: 2 / 1 / span 1 / span 1;

        @media screen and (max-width: 768px) {
            margin-bottom: 2rem;
            margin-right: 1rem;
        }
    }

    .map-box {
        grid-area: 1 / 2 / span 2 / span 1;
        align-items: stretch;
        justify-content: stretch;
        position: relative;
        /* z-index: 10; */

        .map {
            position: relative;
            width: 100%;
            height: 100%;

            @media screen and (max-width: 768px) {
                width: 100%;
                margin: 0;
                overflow-y: scroll;
            }
        }
        .overlay {
            position: absolute;
            z-index: 1000;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;

            @media screen and (max-width: 768px) {
                top: 160px;
            }
        }
        .error-fallback {
            padding: 1rem;
        }

        @media screen and (max-width: 768px) {
            grid-area: 1 / 1 / span 2 / span 2;
            padding: 0;
        }
    }
`;

const Box = styled.div`
    background-color: white;
    padding: 0.8rem 1.2rem;
    border-radius: 1rem;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.08);

    @media screen and (max-width: 399px) {
        width: 100vw;
        overflow: hidden;
    }
`;

const ViewContainer = styled.div`
    min-width: 300px;
    .nav-tabs {
        margin-bottom: 12px;
    }
`;

function CommunityView({
    users,
    organisations,
    selected,
    onSelect
}: {
    users: IUser[];
    organisations: IOrganisation[];
    selected?: CommunitySelection;
    onSelect?: (sel: CommunitySelection) => void;
}) {
    const [tabKey, setTabKey] = useState(selected?.type ?? 'user');
    if (selected?.type === 'user') {
        useEventTracking(
            'Dashboard Map',
            'Viewed User Profile',
            `${selected.user.username}`
        );
        return <SingleProfileView entry={selected.user} />;
    }
    if (users.length > 0 && organisations.length === 0) {
        if (users.length === 1) {
            useEventTracking(
                'Dashboard Map',
                'Viewed User Profile',
                `${users[0].username}`
            );
        }
        return users.length > 1 ? (
            <ProfileList
                entries={users}
                onSelectUser={(user) => onSelect?.({ type: 'user', user })}
            />
        ) : (
            <SingleProfileView entry={users[0]} />
        );
    }
    if (selected?.type === 'organisation') {
        return (
            <AuthedSingleOrganisationView
                organisation={selected.organisation}
            />
        );
    }
    if (users.length === 0 && organisations.length > 0) {
        if (organisations.length === 1) {
            useEventTracking(
                'Dashboard Map',
                'Viewed Organisation Profile',
                `${organisations[0].name}`
            );
        } else {
            const orgs = organisations.map((org) => org.name);
            useEventTracking(
                'Dashboard Map',
                'Viewed Organisation Profile',
                `${orgs}`
            );
        }
        return <OrganisationsList entries={organisations} />;
    }

    return (
        <Tabs
            activeKey={tabKey}
            onSelect={(k) => {
                if (k) {
                    setTabKey(k as 'user' | 'organisation');
                    if (k === 'organisation') {
                        useEventTracking(
                            'Dashboard Map',
                            'Viewed Organisation Profile',
                            `${organisations.map((org) => org.name)}`
                        );
                    } else {
                        useEventTracking(
                            'Dashboard Map',
                            'Viewed User Profile',
                            `${users.map((user) => user.username)}`
                        );
                    }
                }
            }}
        >
            <Tab eventKey="user" title="Users">
                <ProfileList
                    entries={users}
                    onSelectUser={(user) => onSelect?.({ type: 'user', user })}
                />
            </Tab>
            <Tab eventKey="organisation" title="Organisations">
                <OrganisationsList entries={organisations} />
            </Tab>
        </Tabs>
    );
}

function CommunityMarker({
    cids,
    popups
}: {
    cids: Coordinated<CommunityData>;
    popups: RefObject<PopupMap<CommunitySelection>>;
}) {
    const [selectedEntry, setSelectedEntry] = useState<CommunitySelection>();
    const [entries, setEntries] = useState<CommunityData>();
    return (
        <Marker
            icon={
                <CircleMarker
                    count={
                        cids.value.users.length +
                        cids.value.organisations.length
                    }
                />
            }
            position={[cids.coordinates.lat, cids.coordinates.lng]}
            eventHandlers={{
                popupopen: async () => {
                    // Test if we only have ids or a full data set
                    // If its just ids, fetch the full values
                    if (cids.value.ids) {
                        const comm = await axios.post(
                            '/api/dashboard/community/byid',
                            {
                                // eslint-disable-next-line no-underscore-dangle
                                users: cids.value.users.map((v) => v._id),
                                organisations: cids.value.organisations.map(
                                    (o) => o._id
                                )
                            }
                        );
                        setEntries(comm.data);
                    } else {
                        setEntries(cids.value);
                    }
                },
                popupclose: () => {
                    setSelectedEntry(undefined);
                }
            }}
        >
            <ISPopup
                ref={(p) => {
                    const all = (cids.value.users as { _id: string }[]).concat(
                        cids.value.organisations
                    );
                    all.forEach((cid) => {
                        /* eslint-disable no-param-reassign */
                        if (popups.current) {
                            if (p) {
                                popups.current[cid._id] = {
                                    popup: p,
                                    setSelected: setSelectedEntry
                                };
                            } else {
                                delete popups.current[cid._id];
                            }
                        }
                        /* eslint-enable no-param-reassign */
                    });
                }}
            >
                {entries ? (
                    <ViewContainer>
                        <CommunityView
                            users={entries.users}
                            organisations={entries.organisations}
                            selected={selectedEntry}
                            onSelect={setSelectedEntry}
                        />
                    </ViewContainer>
                ) : (
                    <Spinner animation="border" />
                )}
            </ISPopup>
        </Marker>
    );
}

function LandscapesView({
    entries,
    selected
}: {
    entries: ILandscape[];
    selected?: ILandscape;
}) {
    return selected ? (
        <AuthedLandscapeItem landscape={selected} />
    ) : (
        <LandscapesList entries={entries} />
    );
}

function LandscapesMarker({
    cids,
    popups
}: {
    cids: Coordinated<ILandscape[]>;
    popups: RefObject<PopupMap<ILandscape>>;
}) {
    const [selectedEntry, setSelectedEntry] = useState<ILandscape>();
    const [entries, setEntries] = useState<ILandscape[]>();
    return (
        <Marker
            icon={<CircleMarker count={cids.value.length} />}
            position={[cids.coordinates.lat, cids.coordinates.lng]}
            eventHandlers={{
                popupopen: async () => {
                    // Test if we only have ids or a full data set
                    // If its just ids, fetch the full values
                    if (!cids.value[0]?.createdAt) {
                        const landscapes = await axios.post(
                            '/api/dashboard/landscapes/byid',
                            {
                                // eslint-disable-next-line no-underscore-dangle
                                ids: cids.value.map((v) => v._id)
                            }
                        );
                        setEntries(landscapes.data);
                    } else {
                        setEntries(cids.value as ILandscape[]);
                    }
                },
                popupclose: () => {
                    setSelectedEntry(undefined);
                }
            }}
        >
            <ISPopup
                ref={(p) => {
                    cids.value.forEach((cid) => {
                        /* eslint-disable no-param-reassign */
                        if (popups.current) {
                            if (p) {
                                popups.current[cid._id] = {
                                    popup: p,
                                    setSelected: setSelectedEntry
                                };
                            } else {
                                delete popups.current[cid._id];
                            }
                        }
                    });
                }}
            >
                {entries ? (
                    <LandscapesView
                        entries={entries}
                        selected={selectedEntry}
                    />
                ) : (
                    <Spinner animation="border" />
                )}
            </ISPopup>
        </Marker>
    );
}

function OpportunitiesView({
    userOrgs,
    entries,
    selected
}: {
    // TODO: Change IVolunteer
    userOrgs: string[];
    entries: IVolunteerOpportunity[];
    selected?: IVolunteerOpportunity;
}) {
    return selected ? (
        <AuthedOpportunitiesItem opportunity={selected} userOrgs={userOrgs} />
    ) : (
        <OpportunitiesList entries={entries} userOrgs={userOrgs} />
    );
}

function OpportunitiesMarker({
    userOrgs,
    cids,
    popups
}: {
    // TODO: Change IVolunteer
    userOrgs: string[];
    cids: Coordinated<IVolunteerOpportunity[]>;
    popups: RefObject<PopupMap<IVolunteerOpportunity>>;
}) {
    const [selectedEntry, setSelectedEntry] = useState<IVolunteerOpportunity>();
    const [entries, setEntries] = useState<IVolunteerOpportunity[]>();
    return (
        <Marker
            icon={<CircleMarker count={cids.value.length} />}
            position={[cids.coordinates.lat, cids.coordinates.lng]}
            eventHandlers={{
                popupopen: async () => {
                    // Test if we only have ids or a full data set
                    // If its just ids, fetch the full values
                    if (!cids.value[0]?.createdAt) {
                        const opportunities = await axios.post(
                            '/api/dashboard/opportunity/byid',
                            {
                                // eslint-disable-next-line no-underscore-dangle
                                ids: cids.value.map((v) => v._id)
                            }
                        );
                        setEntries(opportunities.data);
                    } else {
                        // TODO: Change IVolunteer[]
                        setEntries(cids.value as IVolunteerOpportunity[]);
                    }
                },
                popupclose: () => {
                    setSelectedEntry(undefined);
                }
            }}
        >
            <ISPopup
                ref={(p) => {
                    cids.value.forEach((cid) => {
                        /* eslint-disable no-param-reassign */
                        if (popups.current) {
                            if (p) {
                                popups.current[cid._id] = {
                                    popup: p,
                                    setSelected: setSelectedEntry
                                };
                            } else {
                                delete popups.current[cid._id];
                            }
                        }
                    });
                }}
            >
                {entries ? (
                    <ViewContainer>
                        <OpportunitiesView
                            entries={entries}
                            selected={selectedEntry}
                            userOrgs={userOrgs}
                        />
                    </ViewContainer>
                ) : (
                    <Spinner animation="border" />
                )}
            </ISPopup>
        </Marker>
    );
}

const seAsia = { lat: 5.980408, lng: 116.073456 };

// Return the LatLng the map should center on by default - the geographical center of the visible points
function centerLocation(latlngs: LatLngLiteral[]) {
    const locs = latlngs.length > 0 ? latlngs : [seAsia];
    const total = locs.reduce((cen, coords) => ({
        lat: cen.lat + coords.lat,
        lng: cen.lng + coords.lng
    }));

    return { lat: total.lat / locs.length, lng: total.lng / locs.length };
}

function extendBounds(llBounds: LatLngBounds, ext: number) {
    return llBounds
        .extend({
            lat: llBounds.getCenter().lat - ext,
            lng: llBounds.getCenter().lng - ext
        })
        .extend({
            lat: llBounds.getCenter().lat + ext,
            lng: llBounds.getCenter().lng + ext
        });
}

// Return the bounding box of markers
function bounds(latlngs: LatLngLiteral[]): LatLngBounds {
    const llBounds = featureGroup(
        (latlngs.length > 0 ? latlngs : [seAsia]).map(({ lat, lng }) =>
            marker([lat, lng])
        )
    ).getBounds();

    switch (latlngs.length) {
        // When theres nothing to show, give it enough range to show all of south-east asia
        case 0:
            return extendBounds(llBounds, 20);
        case 1:
            return extendBounds(llBounds, 1);
        default:
            return llBounds.pad(0.05);
    }
}

const CommunityMap = ({
    userOrgs,
    mode,
    community,
    landscapes,
    opportunities,
    communityPopups,
    landscapePopups,
    opportunitiesPopups,
    map
}: {
    userOrgs: string[];
    mode: MapMode;
    community: Coordinated<CommunityData>[];
    landscapes: Coordinated<ILandscape[]>[];
    // TODO: Change IVolunteer
    opportunities: Coordinated<IVolunteerOpportunity[]>[];
    communityPopups: RefObject<PopupMap<CommunitySelection>>;
    landscapePopups: RefObject<PopupMap<ILandscape>>;
    // TODO: Change IVolunteer
    opportunitiesPopups: RefObject<PopupMap<IVolunteerOpportunity>>;
    map: MutableRefObject<LeafletMap | undefined>;
}): ReactElement => {
    /* eslint-disable no-param-reassign */
    map.current = useMap();
    /* eslint-enable no-param-reassign */

    let latlngs;
    if (mode === 'community') {
        latlngs = community.map((c) => c.coordinates);
    } else if (mode === 'landscapes') {
        latlngs = landscapes.map((l) => l.coordinates);
    } else if (mode === 'opportunities') {
        latlngs = opportunities.map((o) => o.coordinates);
    }

    useEffect(() => {
        map.current?.setView(seAsia);
        map.current?.setZoom(5);
    }, []);

    map.current.addEventListener('load', () => {
        map.current?.setView(centerLocation(latlngs));
        map.current?.fitBounds(bounds(latlngs));
    });

    const coordsKey = ({ lat, lng }: LatLngLiteral) => `${lat}-${lng}`;

    switch (mode) {
        case 'community':
            return (
                <>
                    {community.map((c) => (
                        <CommunityMarker
                            cids={c}
                            key={coordsKey(c.coordinates)}
                            popups={communityPopups}
                        />
                    ))}
                </>
            );
        case 'landscapes':
            return (
                <>
                    {landscapes.map((landscape) => (
                        <LandscapesMarker
                            cids={landscape}
                            key={coordsKey(landscape.coordinates)}
                            popups={landscapePopups}
                        />
                    ))}
                </>
            );
            break;
        case 'opportunities':
            return (
                <>
                    {opportunities.map((opportunity) => (
                        <OpportunitiesMarker
                            cids={opportunity}
                            key={coordsKey(opportunity.coordinates)}
                            popups={opportunitiesPopups}
                            userOrgs={userOrgs}
                        />
                    ))}
                </>
            );
        default:
            return <></>;
    }
};

const Dashboard: React.FC<authProps> = ({ ...props }) => {
    const [mode, setMode] = useState<MapMode>('community');
    const [searchResults, setSearchResults] = useState<SearchResults>();

    const [loading, setLoading] = useState(false);
    const [allCommunity, setAllCommunity] = useState<
        Coordinated<CommunityData>[]
    >();
    const [allLandscapes, setAllLandscapes] = useState<
        Coordinated<ILandscape[]>[]
    >();
    const [userOrgs, setUserOrgs] = useState<string[]>([]);
    // TODO: Change the data type from CommunityData to IVolunteerOpportunity
    // Wait until endpoint is ready
    const [allOpportunities, setAllOpportunities] = useState<
        Coordinated<IVolunteerOpportunity[]>[]
    >();
    const [searchParams, setSearchParams] = useState<SearchParams>();
    const communityPopups = useRef<PopupMap<CommunitySelection>>({});
    const landscapePopups = useRef<PopupMap<ILandscape>>({});
    // TODO: Change data type from CommunitySelection to IVolunteerOpportunity
    // Wait until endpoint is ready
    const opportunitiesPopups = useRef<PopupMap<IVolunteerOpportunity>>({});
    const map = useRef<LeafletMap>();
    const [debouncedSearchParams] = useDebounce(searchParams, 200);

    // Redirect users to onboarding if not done so
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    useEffect(() => {
        const orgsObject = props.userProfile.userProfile?.organisation;
        if (orgsObject) {
            const allOrgs: string[] = [];
            Object.values(orgsObject).forEach((val) => {
                allOrgs.push(val._id);
            });
            setUserOrgs(allOrgs);
        } else {
            setUserOrgs([]);
        }
    }, [
        props.userProfile.userProfile,
        props.userProfile,
        props.auth.isAuthenticated
    ]);

    // Load orgs and people if they've never been queried
    useEffect(() => {
        async function loadAll() {
            setLoading(true);
            const community = await axios.get('/api/dashboard/community');
            setAllCommunity(community.data);
            const landscapes = await axios.get('/api/dashboard/landscapes');
            setAllLandscapes(landscapes.data);
            // TODO: Change API to opportunities once done
            const opportunities = await axios.get('/api/dashboard/opportunity');
            setAllOpportunities(opportunities.data);
            setLoading(false);
        }
        loadAll();
    }, []);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        const search = async (cancelToken: CancelToken) => {
            const res = await axios.post(
                `/api/dashboard/${mode}/search`,
                debouncedSearchParams,
                { cancelToken }
            );
            if (mode === 'opportunities') {
                sendDashboardSearch(
                    'Volunteering Opportunities',
                    debouncedSearchParams
                );
            } else sendDashboardSearch(mode, debouncedSearchParams);
            setSearchResults({ type: mode, results: res.data });
        };
        if (debouncedSearchParams) {
            const cancelTokenSource = axios.CancelToken.source();
            search(cancelTokenSource.token);
            return () => {
                cancelTokenSource.cancel();
            };
        }
        setSearchResults(undefined);
    }, [mode, debouncedSearchParams]);

    return (
        <DashboardStyle className="font-arimo">
            <ErrorBoundary
                FallbackComponent={({ resetErrorBoundary }) => (
                    <div className="error-fallback">
                        <p>Oops, the map crashed!</p>
                        <Button onClick={resetErrorBoundary}>Try again</Button>
                    </div>
                )}
            >
                <Walkthrough />
                {/* <HeaderContainer> */}
                <HeaderText data-steps="step-1" className="font-weight-bold">
                    Southeast Asia&apos;s Sustainability Community
                </HeaderText>
                {/* </HeaderContainer> */}
                <Layout>
                    <Box className="top-left">
                        <Search
                            mode={mode}
                            setMode={setMode}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            fullscreen={false}
                        />
                    </Box>
                    <Box className="bottom-left">
                        {searchResults ? (
                            <SearchResultsView
                                userOrgs={userOrgs}
                                searchResults={searchResults}
                                communityPopups={communityPopups}
                                landscapePopups={landscapePopups}
                                opportunitiesPopups={opportunitiesPopups}
                                map={map}
                            />
                        ) : (
                            <Links authId={props.auth.isAuthenticated} />
                        )}
                    </Box>
                    <Box className="map-box">
                        <Search
                            mode={mode}
                            setMode={setMode}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            fullscreen
                        />
                        <MapContainer
                            className="map"
                            zoom={13}
                            scrollWheelZoom={false}
                        >
                            <CommunityMap
                                userOrgs={userOrgs}
                                mode={mode}
                                community={
                                    (searchResults?.type === 'community'
                                        ? searchResults.results
                                        : allCommunity) ?? []
                                }
                                landscapes={
                                    (searchResults?.type === 'landscapes'
                                        ? searchResults.results
                                        : allLandscapes) ?? []
                                }
                                opportunities={
                                    (searchResults?.type === 'opportunities'
                                        ? searchResults.results
                                        : allOpportunities) ?? []
                                }
                                communityPopups={communityPopups}
                                landscapePopups={landscapePopups}
                                opportunitiesPopups={opportunitiesPopups}
                                map={map}
                            />
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </MapContainer>
                        {loading && (
                            <div className="overlay">
                                <Spinner animation="border" />
                            </div>
                        )}
                        {mode === 'community' &&
                            !loading &&
                            allCommunity?.length === 0 && (
                                <div className="overlay">
                                    <h1>No community</h1>
                                </div>
                            )}
                        {mode === 'landscapes' &&
                            !loading &&
                            allLandscapes?.length === 0 && (
                                <div className="overlay">
                                    <h1>No landscapes</h1>
                                </div>
                            )}
                        {mode === 'opportunities' &&
                            !loading &&
                            allOpportunities?.length === 0 && (
                                <div className="overlay">
                                    <h1>No Volunteering Opportunities</h1>
                                </div>
                            )}
                    </Box>
                </Layout>
            </ErrorBoundary>
        </DashboardStyle>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(Dashboard));
