import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Card, Container, Image, Row } from 'react-bootstrap';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

export const AboutUs: React.FC<authProps> = ({ ...props }) => {
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    return (
        <Container>
            <Card className="mb-4">
                <Card.Img
                    style={{ borderRadius: '15px 15px 0 0' }}
                    variant="top"
                    src={`${process.env.PUBLIC_URL}/assets/interseedgroup.jpg`}
                    alt="Interseed Community"
                />
                <Card.Body>
                    <Card.Subtitle className="mt-3">
                        <h1>What&apos;s Interseed?</h1>
                    </Card.Subtitle>
                    <Card.Text>
                        <p>
                            Interseed is a digital platform that accelerates
                            sustainability solutions by enabling impact founders
                            to be fund-ready and find talent.
                        </p>
                    </Card.Text>
                    <Card.Subtitle className="mt-5">
                        <h1>Our Vision</h1>
                    </Card.Subtitle>
                    <Card.Text>
                        <p>
                            An impact-driven Southeast Asian community with the
                            capacity to tackle evolving sustainability issues.
                        </p>
                    </Card.Text>
                    <Card.Subtitle className="mt-5">
                        <h1>Our Story</h1>
                    </Card.Subtitle>
                    <Card.Text>
                        <p>
                            Interseed&apos;s core team also founded YSI SEA in
                            2017.
                        </p>
                        <p>
                            Over 3 years, YSI SEA&apos;s Innovation Programmes
                            incubated 27 regional impact startups. Interacting
                            closely with founders in our programme made us
                            deeply understand the difficulties they face in
                            creating and scaling their sustainability solutions
                        </p>
                        <p>
                            Each year, we also receive 1000s of applicants for
                            the programme but we could only select 25 founders.
                        </p>
                        <p>
                            Interseed was born as a way to reach more impact
                            founders across the region through an online
                            platform and build the capacity of the
                            sustainability sector in the region.
                        </p>
                    </Card.Text>
                </Card.Body>
            </Card>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(AboutUs));
