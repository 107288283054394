/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Row, Col, Card, Image, Button } from 'react-bootstrap';
import { color, EventButton } from '../components/events/EventStyles';

import { useEventTracking } from './eventTracking';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';
import INews, { newsBlankObj } from '../interfaces/INews';
import NewseedModal from '../components/newseed/NewseedModal';

const CardContent = styled(Card)`
    box-shadow: 0px 4px 25px rgba(22, 77, 135, 0.1);
    border-radius: 15px;
    gap: 0.75rem;
    padding: 1rem;
    color: #51617c;
    text-align: left;
    height: 100%;
`;

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

const Newseed: React.FC<authProps> = ({ ...props }) => {
    const [masterList, setMasterList] = useState<INews[]>([]);
    const [list, setList] = useState<INews[]>([]);
    const [newsShown, setNewsShown] = useState(newsBlankObj);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status]);

    useEffect(() => {
        // Axios request for retrieving landscape
        axios.get(`/api/articles/`).then((res) => {
            setMasterList(res.data);
            setList(res.data.slice(0).reverse());
        });
    }, []);

    function sortFunction(a: INews, b: INews) {
        return a.datePublished < b.datePublished ? 1 : -1;
    }
    const handleClose = () => {
        setShow(false);
    };

    function randomImage(index) {
        const randInt = (index % 10) + 1; // there are 10 images in AWS currently uploaded
        const final = `https://interseedstorage.s3.ap-southeast-1.amazonaws.com/newseed_cover_${randInt}.jpg`;
        return final;
    }
    return (
        <Container className="mb-4">
            <h1>NewsSeed</h1>
            <p>
                <i>Your sustainability news feed</i>
            </p>
            <NewseedModal
                news={newsShown}
                show={show}
                handleClose={handleClose}
            />
            {masterList.length === 0 ? <p>Loading news...</p> : null}
            <Row xs={1} md={2} xl={3} className="g-4">
                {masterList.sort(sortFunction).map((item, index) => (
                    <Col className="text-left mb-3">
                        <CardContent className="text-left">
                            <Card.Header as="p">{item.source}</Card.Header>
                            <Card.Body>
                                <Card.Img
                                    variant="top"
                                    src={
                                        item.image === ''
                                            ? randomImage(index)
                                            : item.image
                                    }
                                    style={{
                                        maxWidth: '100%',
                                        aspectRatio: '2/1',
                                        objectFit: 'cover'
                                    }}
                                    className="mb-3"
                                />
                                <Row className=" flex-grow-1">
                                    <Col>
                                        <Row>
                                            <Card.Title>
                                                {item.title.length < 50
                                                    ? item.title
                                                    : item.title
                                                          .slice(0, 55)
                                                          .concat('...')}
                                            </Card.Title>
                                            <Card.Text>
                                                {item.summary
                                                    .slice(0, 100)
                                                    .concat('...')}
                                                <br />
                                                <br />
                                                {item.datePublished}
                                            </Card.Text>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <EventButton
                                style={{
                                    padding: '0.25rem 1.5rem',
                                    width: '100%'
                                }}
                                onClick={() => {
                                    setShow(true);
                                    setNewsShown(item);
                                    useEventTracking(
                                        'Newseed',
                                        'Clicked Read More for Article',
                                        `${item.title} from ${item.source}`
                                    );
                                }}
                            >
                                Read more
                            </EventButton>
                        </CardContent>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(Newseed));
