/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
// import { Container, Row, Col } from 'react-bootstrap';

// Interface for Redux types
import { StoreState, AuthState } from '../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
}

const Collections: React.FC<authProps> = () => (
    <div>
        <h1>Collections</h1>
    </div>
);

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(Collections));
