/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IUser from '../../interfaces/IUser';
import { useEventTracking } from '../../pages/eventTracking';
import getSrc from '../../utils/commonFunction';

interface userProp {
    authUsername: string;
    user: IUser;
    authId: string;
}

const Text = styled.p`
    margin-bottom: 0;
`;

const User = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    /* background-color: #f1f1f1; */
    border-radius: 20px;
    color: black;
    padding: 1rem;

    &:hover {
        background-color: #ccc;
        border-radius: 0;
        text-decoration: none;
    }

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        margin-bottom: 1rem;
        max-height: 4.5rem;
        max-width: 4.5rem;
    }
`;

const UserItem: React.FC<userProp> = ({
    authUsername,
    user,
    authId
}: userProp) => {
    let currentUser = false;
    if (authId === user._id) currentUser = true;

    const [followCheck, setFollowCheck] = useState(false);
    const [disableFollow, setDisableFollow] = useState(true);

    const handleFollow = () => {
        if (disableFollow || currentUser) return;
        setDisableFollow(true);
        let location =
            `${window.location.pathname.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` ===
            'community'
                ? 'on Community Page'
                : 'Profile';
        if (location === 'Profile') {
            location =
                window.location.pathname.split(/.*\/(.*)/)[1] === authUsername
                    ? 'On Own Profile'
                    : "On Others' Profile";
        }
        if (followCheck) {
            axios.delete(`/api/users/follows/${user._id}`).then(() => {
                setFollowCheck(false);
                setDisableFollow(false);
            });
            useEventTracking(
                `Community`,
                `Unfollowed User ${location}`,
                `${user.username}`
            );
        } else {
            axios.put(`/api/users/follows/${user._id}`).then(() => {
                setFollowCheck(true);
                setDisableFollow(false);
            });
            useEventTracking(
                `Community`,
                `Followed User ${location}`,
                `${user.username}`
            );
        }
    };

    useEffect(() => {
        if (!authId) return;
        if (user.followers) {
            for (let i = 0; i < user.followers.length; i += 1) {
                if (user.followers[i] === authId) {
                    setFollowCheck(true);
                    break;
                }
            }
        }
        setDisableFollow(false);
    }, []);

    return (
        <User as={Link} to={`/profile/${user.username}`}>
            <Image
                className="img-thumbnail"
                src={getSrc(user)}
                alt="Profile"
                roundedCircle
                thumbnail
                fluid
                onError={(e) => {
                    e.currentTarget.onerror = null; // prevents looping
                    e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile.png`;
                }}
            />
            <div className="my-auto text-sm-left text-center">
                <h2>
                    {user.firstName} {user.lastName}
                </h2>
                <Text>{user.currentRole}</Text>
                <Text className="text-muted">{user.country}</Text>
                <p className="mt-2">
                    {user.interestAreas
                        ? user.interestAreas
                              .sort()
                              .map((interest) => (
                                  <Badge variant="light">{interest}</Badge>
                              ))
                        : null}
                </p>
            </div>
            <div className="text-center my-auto">
                {currentUser ? (
                    <Badge pill variant="light" className="mt-3">
                        <FontAwesomeIcon icon={faUser} /> This Is You
                    </Badge>
                ) : (
                    <>
                        {authId ? (
                            <Button
                                variant={
                                    !followCheck ? 'outline-primary' : 'primary'
                                }
                                className="mt-2"
                                onClick={(ev) => {
                                    handleFollow();
                                    ev.preventDefault();
                                }}
                                disabled={disableFollow}
                            >
                                {!followCheck ? 'Follow' : 'Unfollow'}
                            </Button>
                        ) : (
                            <Button
                                variant="outline-primary"
                                className="mt-2"
                                as={Link}
                                to="/login"
                            >
                                Follow
                            </Button>
                        )}
                    </>
                )}
            </div>
        </User>
    );
};

export default UserItem;
