import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const BlockerModal = (props) => {
    const handleBlockerClose = () => {
        props.history.goBack();
    };

    return (
        <Modal
            show
            onHide={handleBlockerClose}
            keyboard={false}
            centered
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title>Join The Community!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Thank you for checking out Interseed! Please{' '}
                <a href="/login" style={{ textDecoration: 'none' }}>
                    login
                </a>{' '}
                or{' '}
                <a href="/register" style={{ textDecoration: 'none' }}>
                    register
                </a>{' '}
                to view more of our content!
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleBlockerClose}>
                    Return
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BlockerModal;
