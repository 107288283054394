import isEmpty from 'is-empty';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import {
    RouteComponentProps,
    useParams,
    withRouter,
    Link
} from 'react-router-dom';
import { Row, Container, Button, Form, Modal } from 'react-bootstrap';
import classnames from 'classnames';
import styled from 'styled-components';
import { resetPassword, cleanUpErrors } from '../redux/actions/AuthActions';
import {
    AuthState,
    ErrorState,
    StoreState,
    AuthErrors
} from '../redux/actions/types';
import { IResetPasswordUserData } from '../interfaces/AuthInterface';

interface ParamTypes {
    resetToken: string | undefined;
    userId: string | undefined;
}

interface resetPasswordUserInput {
    password: string;
    password2: string;
    errors: AuthErrors;
}

// Styles
const ResetPasswordForm = styled.div`
    width: 496px;
`;

// Interface for props in this component
interface resetPasswordProps extends RouteComponentProps {
    resetPassword: (
        arg0: IResetPasswordUserData,
        arg1: RouteComponentProps['history']
    ) => void;
    cleanUpErrors: () => void;
    auth: AuthState;
    error: ErrorState;
    history: RouteComponentProps['history'];
}

const ResetPassword: React.FC<resetPasswordProps> = ({ ...props }) => {
    const { resetToken, userId } = useParams<ParamTypes>();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [resetCalled, setResetCalled] = useState(false);
    const [show, setShow] = useState(false);
    const [userInput, setUserInput] = useState<resetPasswordUserInput>({
        password: '',
        password2: '',
        errors: {
            password: '',
            password2: '',
            message: ''
        }
    });

    useEffect(() => {
        if (!isEmpty(props.error.resetPassword)) {
            setUserInput({ ...userInput, errors: props.error.resetPassword });
            setDisableSubmit(false);
            setShow(false);
            if (props.error.resetPassword.message) {
                setShow(true);
            }
        } else if (resetCalled && isEmpty(props.error.resetPassword.message)) {
            setShow(true);
            setResetCalled(false);
            setUserInput({
                ...userInput,
                errors: {
                    password: '',
                    password2: '',
                    message: ''
                }
            });
            setDisableSubmit(false);
        } else {
            setUserInput({
                ...userInput,
                errors: {
                    password: '',
                    password2: '',
                    message: ''
                }
            });
        }
    }, [props.error.resetPassword, resetCalled]);

    useEffect(() => {
        if (props.auth.isAuthenticated) {
            props.history.push('/landscapes');
        }
    }, [props.auth.isAuthenticated]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        if (
            !isEmpty(userInput.errors.password) ||
            !isEmpty(userInput.errors.password2) ||
            !isEmpty(userInput.errors.message)
        ) {
            props.cleanUpErrors();
        }

        setUserInput({
            ...userInput,
            [id]: value,
            errors: {
                password: '',
                password2: '',
                message: ''
            }
        });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (disableSubmit) return;
        setDisableSubmit(true);

        const userData = {
            password: userInput.password,
            password2: userInput.password2,
            token: resetToken,
            userId
        };

        setUserInput({
            ...userInput,
            errors: {
                password: '',
                password2: '',
                message: ''
            }
        });

        props.resetPassword(userData, props.history);

        setResetCalled(true);
    };

    const handleClose = () => {
        setShow(false);
        props.history.push('/login');
    };

    const { errors } = userInput;

    return (
        <Container>
            <Modal show={show} onHide={handleClose} centered size="lg">
                {errors.message ? (
                    <Modal.Header closeButton>
                        Password Reset Failed!
                    </Modal.Header>
                ) : (
                    <Modal.Header closeButton>
                        Password Reset Success!
                    </Modal.Header>
                )}
                <Modal.Body>
                    {errors.message ? (
                        <p>
                            {errors.message} <br />
                            <Link to="/login">Return to login</Link>
                        </p>
                    ) : (
                        <p>
                            Your password has been successfully updated.
                            <br />
                            <Link to="/login">
                                Login to your account with your new password
                            </Link>
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row className="justify-content-center">
                <ResetPasswordForm>
                    <Form noValidate onSubmit={onSubmit}>
                        <Form.Control
                            onChange={onChange}
                            value={userInput.password}
                            id="password"
                            type="password"
                            placeholder="New password"
                            className={classnames('', {
                                invalid: errors.password
                            })}
                        />
                        <Form.Text className="text-danger mb-2">
                            {errors.password}
                        </Form.Text>
                        <Form.Control
                            onChange={onChange}
                            value={userInput.password2}
                            id="password2"
                            type="password"
                            placeholder="Confirm new password"
                            className={classnames('', {
                                invalid: errors.password2
                            })}
                        />
                        <Form.Text className="text-danger mb-2">
                            {errors.password2}
                        </Form.Text>
                        <Button
                            type="submit"
                            variant="success"
                            disabled={disableSubmit}
                            block
                        >
                            Reset Password
                        </Button>
                    </Form>
                </ResetPasswordForm>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    error: state.errors
});

export default connect(mapStateToProps, { resetPassword, cleanUpErrors })(
    withRouter(ResetPassword)
);
