/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Textarea from 'react-expanding-textarea';
import { useAlert } from 'react-alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faComment,
    faEllipsisH,
    faChevronUp
} from '@fortawesome/free-solid-svg-icons';

import { useEventTracking } from '../../pages/eventTracking';

import ReplyItem from './ReplyItem';

import IComment from '../../interfaces/IComment';
import IReply from '../../interfaces/IReply';

import getSrc from '../../utils/commonFunction';

interface CommentProps {
    comment: IComment;
    authId: string;
    id: string | undefined;
    landscapeTitle: string;
    updateComments: () => void;
}

interface ToggleProps {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Comment = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
`;

const ProfilePic = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 100px;
`;

const Content = styled.div`
    margin-left: 15px;
    border-style: none none none solid;
    border-width: 1px 1px 1px 2px;
    border-color: rgba(0, 0, 0, 0.125);
    padding-left: 16px;
`;

const maxChars = 2000;

const CommentItem: React.FC<CommentProps> = ({
    comment,
    authId,
    id,
    landscapeTitle,
    updateComments
}: CommentProps) => {
    const alert = useAlert();

    const CustomToggle = React.forwardRef(
        (
            { children, onClick }: ToggleProps,
            ref: React.Ref<HTMLAnchorElement>
        ) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
                href=""
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                <FontAwesomeIcon icon={faEllipsisH} className="comment-badge" />
                {children}
            </a>
        )
    );

    const [showReply, setShowReply] = useState(false);

    const [reply, setReply] = useState<IReply>({
        author: authId,
        content: '',
        createdAt: '',
        upvotes: [],
        updatedAt: '',
        _id: ''
    });

    const [replies, setReplies] = useState<IReply[]>([]);

    const [upvotes, setUpvotes] = useState(comment.upvotes.length);
    const [upvoteCheck, setUpvoteCheck] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [disableUpvote, setDisableUpvote] = useState(false);
    const [charsRemaining, setCharsRemaining] = useState(maxChars);

    const replyRef = React.useRef<HTMLFormElement>(null);
    const handleShowReply = (name: string) => {
        setShowReply(true);
        setReply({
            ...reply,
            content: `@${name} `
        });
        if (replyRef.current) {
            replyRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleUpvote = () => {
        if (disableUpvote) return;
        setDisableUpvote(true);

        if (upvoteCheck === false) {
            axios
                .put(
                    `/api/landscapes/${id}/comments/${comment._id}/upvotes/${authId}`
                )
                .then(() => {
                    setUpvotes(upvotes + 1);
                    setUpvoteCheck(true);
                    setDisableUpvote(false);
                });
        } else {
            axios
                .delete(
                    `/api/landscapes/${id}/comments/${comment._id}/upvotes/${authId}`
                )
                .then(() => {
                    setUpvotes(upvotes - 1);
                    setUpvoteCheck(false);
                    setDisableUpvote(false);
                });
        }
    };

    const updateReplies = (repliesList: IReply[]) => {
        const newReplies: IReply[] = [];
        for (let i = 0; i < repliesList.length; i += 1) {
            newReplies[i] = repliesList[i];
        }
        setReplies(newReplies);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setReply({
            ...reply,
            [name]: value
        });
        setCharsRemaining(maxChars - value.length);
    };

    const handleDelete = () => {
        axios
            .delete(`/api/landscapes/${id}/comments/${comment._id}`)
            .then(() => {
                updateComments();
            })
            .catch(() => {
                alert.error('Error deleting comment. Please try again.');
            });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (disableSubmit) return;
        setDisableSubmit(true);

        axios
            .post(`/api/landscapes/${id}/comments/${comment._id}/replies`, {
                content: reply.content,
                author: reply.author
            })
            .then((res) => {
                updateReplies(res.data);
                setReply({
                    ...reply,
                    content: ''
                });
                setShowReply(!setShowReply);
                setDisableSubmit(false);
            })
            .catch(() => {
                alert.error('Error submitting comment. Please try again.');
            });
        useEventTracking(
            'Landscapes',
            'Replied to Comment on Landscape',
            `${landscapeTitle}`
        );
        event.preventDefault();
    };

    useEffect(() => {
        updateReplies(comment.replies);
        for (let i = 0; i < comment.upvotes.length; i += 1) {
            if (comment.upvotes[i] === authId) setUpvoteCheck(true);
        }
    }, []);

    useEffect(() => {
        if (replyRef.current) {
            replyRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [showReply]);

    useEffect(() => {
        if (!authId) {
            setDisableUpvote(true);
            setDisableSubmit(true);
        }
    }, [authId]);

    return (
        <Comment>
            <Link to={`/profile/${comment.author.username}`}>
                <h5>
                    <ProfilePic
                        className="mr-3"
                        src={getSrc(comment.author)}
                        alt="PFP"
                    />
                    {comment.author.firstName} {comment.author.lastName}
                </h5>
            </Link>
            <Content>
                <p className="allow-whitespace">{comment.content}</p>
                <Button
                    style={{ display: authId ? 'inline-block' : 'none' }}
                    variant="light"
                    size="sm"
                    className="mr-2"
                    onClick={() =>
                        handleShowReply(
                            `${comment.author.firstName} ${comment.author.lastName}`
                        )
                    }
                >
                    <FontAwesomeIcon icon={faComment} /> Reply
                </Button>
                <Button
                    variant={upvoteCheck ? 'primary' : 'light'}
                    size="sm"
                    className="mr-2"
                    disabled={disableUpvote || !authId}
                    onClick={handleUpvote}
                >
                    <FontAwesomeIcon icon={faChevronUp} /> {upvotes}
                </Button>
                <Dropdown style={{ display: 'inline-block' }}>
                    <Dropdown.Toggle as={CustomToggle} id="ellipses-dropdown" />
                    <Dropdown.Menu>
                        {comment.author._id === authId ? (
                            <Dropdown.Item onClick={handleDelete}>
                                Delete
                            </Dropdown.Item>
                        ) : (
                            <Dropdown.Item href="#report">Report</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
                {replies.slice(0).map((replyx: IReply) => (
                    <ReplyItem
                        key={replyx._id}
                        reply={replyx}
                        authId={authId}
                        commentId={comment._id}
                        id={id}
                        updateReplies={updateReplies}
                        handleShowReply={handleShowReply}
                    />
                ))}
                <Form
                    ref={replyRef}
                    className="text-muted mt-2"
                    onSubmit={handleSubmit}
                    style={{ display: showReply && authId ? 'block' : 'none' }}
                >
                    <Form.Group controlId="formReply">
                        <Form.Control
                            as={Textarea}
                            required
                            name="content"
                            maxLength={3000}
                            value={reply.content}
                            onChange={handleChange}
                            placeholder="What do you think of this landscape?"
                        />
                        <Form.Text muted>
                            {charsRemaining} characters remaining.
                        </Form.Text>
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={disableSubmit}
                    >
                        Send
                    </Button>
                </Form>
            </Content>
        </Comment>
    );
};

export default CommentItem;
