import React from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

// Interface for Redux types
import { StoreState, AuthState } from '../../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
}

const UploadWidget: React.FC<authProps> = ({ ...props }) => (
    <div className="mb-3">
        <h4>Upload a Market Research</h4>
        <Card>
            <Card.Body className="text-center">
                <p>
                    {props.auth.isAuthenticated
                        ? `Have sustainability insights? Open-source your sustainability research to our community!`
                        : `Join us to open-source your sustainability research to a worldwide audience!`}
                </p>
                {props.auth.isAuthenticated ? (
                    <p className="text-muted small-text">
                        <i>Currently only Google Docs & Slides are accepted.</i>
                    </p>
                ) : null}
                <Link to={props.auth.isAuthenticated ? `/upload` : `/register`}>
                    <Button type="button" block>
                        {props.auth.isAuthenticated ? `Upload` : `Sign Up Now`}
                    </Button>
                </Link>
            </Card.Body>
        </Card>
    </div>
);

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(UploadWidget));
