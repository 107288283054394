import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { populateArray } from '../../utils/commonFunction';

// Import options for users
import {
    countriesOptions,
    lookingForOptions,
    interestOptions
} from '../../options/profileSelectOptions';

interface Props {
    // eslint-disable-next-line
    handleFilterSelect: (newValue: any, type: string) => void;
    userCountries: string[];
    userInterests: string[];
    userLookingFor: string[];
}

const UserFilter: React.FC<Props> = ({
    handleFilterSelect,
    userCountries,
    userInterests,
    userLookingFor
}: Props) => (
    <div>
        <Row className="mb-3">
            <Col>
                <Select
                    isMulti
                    options={countriesOptions}
                    placeholder="Country"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={populateArray(userCountries, true)}
                    onChange={(newValue) =>
                        handleFilterSelect(newValue, 'userCountries')
                    }
                />
            </Col>
        </Row>
        <Row className="mb-3">
            <Col>
                <Select
                    isMulti
                    options={interestOptions}
                    placeholder="Interest"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={populateArray(userInterests, true)}
                    onChange={(newValue) =>
                        handleFilterSelect(newValue, 'userInterests')
                    }
                />
            </Col>
            <Col>
                <Select
                    isMulti
                    options={lookingForOptions}
                    placeholder="Looking For"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={populateArray(userLookingFor, true)}
                    onChange={(newValue) =>
                        handleFilterSelect(newValue, 'userLookingFor')
                    }
                />
            </Col>
        </Row>
    </div>
);

export default UserFilter;
