/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
    ListGroup,
    Container,
    Row,
    Col,
    Badge,
    Button,
    Modal,
    Image
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faComment } from '@fortawesome/free-solid-svg-icons';

import LandscapeModal from './LandscapeModal';

import {
    useEventTracking,
    checkLandscapeUpvoteSource
} from '../../pages/eventTracking';

import ILandscape from '../../interfaces/ILandscape';
// import BlockerModal from '../BlockerModal';

interface landscapeProp {
    landscape: ILandscape;
    authId: string;
    updateLandscape: (id: string, noOfUpvotes: string[]) => void;
}

const Styles = styled.div`
    h2 {
        margin-bottom: 0.2rem;
    }

    .list-group-item {
        cursor: pointer;
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;

        &:hover {
            background-color: #ccc !important;
        }
    }

    img {
        object-fit: cover;
        width: 100%;
        border-radius: 50%;
    }

    .imgcontainer {
        display: flex;
        overflow: hidden;
        position: relative;
        margin-right: 10px;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1 / 1;

        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            object-fit: cover;
            aspect-ratio: 1/1;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #dee2e6;
            padding: 0.25rem;
            aspect-ratio: 1;
        }
    }

    .badge {
        font-size: 16px;
    }

    .list-tagline {
        margin-bottom: 0.3rem;
    }

    .upvotes {
        -ms-flex: 0 0 12%;
        flex: 0 0 12%;
        max-width: 12%;
        padding: 0px;

        .btn {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .upvote-count {
        font-weight: bold;
    }
`;

const LandscapeItem: React.FC<landscapeProp> = ({
    landscape,
    authId,
    updateLandscape
}: landscapeProp) => {
    const [show, setShow] = useState(false);
    const [numComments, setNumComments] = useState(0);
    const [upvoteCheck, setUpvoteCheck] = useState(false);
    const [upvotes, setUpvotes] = useState(landscape.upvotes);
    const [disableUpvote, setDisableUpvote] = useState(false);
    const [initCheck, setInitCheck] = useState(false);
    const [blockerShow, setBlockerShow] = useState(false);

    const handleUpvote = () => {
        if (disableUpvote) return;
        setDisableUpvote(true);

        if (upvoteCheck === false) {
            axios
                .put(`/api/landscapes/${landscape._id}/upvotes/${authId}`)
                .then((res) => {
                    setUpvotes(res.data.upvotes);
                    setUpvoteCheck(true);
                    updateLandscape(landscape._id, res.data.upvotes);
                    setDisableUpvote(false);
                });
            const [category, action] = checkLandscapeUpvoteSource(
                window.location.pathname,
                authId,
                landscape.author._id,
                'put'
            );
            useEventTracking(`${category}`, `${action}`, `${landscape.title}`);
        } else {
            axios
                .delete(`/api/landscapes/${landscape._id}/upvotes/${authId}`)
                .then((res) => {
                    setUpvotes(res.data.upvotes);
                    setUpvoteCheck(false);
                    updateLandscape(landscape._id, res.data.upvotes);
                    setDisableUpvote(false);
                });
            const [category, action] = checkLandscapeUpvoteSource(
                window.location.pathname,
                authId,
                landscape.author._id,
                'delete'
            );
            useEventTracking(`${category}`, `${action}`, `${landscape.title}`);
        }
    };

    const count = () => {
        let commentsList = landscape.comments;
        let commentsCount = commentsList.length;

        if (initCheck) {
            axios.get(`/api/landscapes/${landscape._id}`).then((res) => {
                commentsList = res.data.comments;
                commentsCount = commentsList.length;
                setUpvoteCheck(false);
                for (let i = 0; i < commentsList.length; i += 1) {
                    commentsCount += commentsList[i].replies.length;
                }
                for (let i = 0; i < res.data.upvotes.length; i += 1) {
                    if (res.data.upvotes[i] === authId) setUpvoteCheck(true);
                }
                setUpvotes(res.data.upvotes);
                setNumComments(commentsCount);
            });
        } else {
            setUpvoteCheck(false);
            for (let i = 0; i < commentsList.length; i += 1) {
                commentsCount += commentsList[i].replies.length;
            }
            for (let i = 0; i < upvotes.length; i += 1) {
                if (upvotes[i] === authId) setUpvoteCheck(true);
            }
            setNumComments(commentsCount);
            setInitCheck(true);
        }
    };

    const updateUpvotes = (newUpvotes: string[]) => {
        setUpvotes(newUpvotes);
    };

    const handleClose = () => {
        setShow(false);
        count();
    };
    const handleShow = () => {
        if (authId) {
            setShow(true);
        } else {
            setBlockerShow(true);
        }
    };

    const handleBlockerClose = () => {
        setBlockerShow(false);
    };

    const thumbnail = landscape.thumbnail
        ? landscape.thumbnail.location
        : 'test';

    useEffect(() => {
        count();
    }, []);

    useEffect(() => {
        if (!authId) {
            setDisableUpvote(true);
        }
    }, [authId]);

    return (
        <>
            <LandscapeModal
                key={landscape._id}
                landscape={landscape}
                upvoteList={upvotes}
                show={show}
                handleClose={handleClose}
                updateUpvotes={updateUpvotes}
            />
            <Modal
                show={blockerShow}
                onHide={handleBlockerClose}
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Login Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Thank you for checking out Interseed! However, please{' '}
                    <a href="/login" style={{ textDecoration: 'none' }}>
                        login
                    </a>{' '}
                    or{' '}
                    <a href="/register" style={{ textDecoration: 'none' }}>
                        register
                    </a>{' '}
                    to view more of our amazing content!
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleBlockerClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Styles>
                <ListGroup.Item
                    onClick={handleShow}
                    onKeyPress={handleShow}
                    role="button"
                    tabIndex={0}
                >
                    <Container fluid>
                        <Row>
                            <Col sm={2} className="imgcontainer">
                                <img src={thumbnail} alt="" />
                            </Col>

                            <Col
                                className="mt-3 mb-3 text-center text-sm-left"
                                sm={8}
                            >
                                <Row>
                                    <Col sm={12}>
                                        <h2>
                                            {landscape.title || <Skeleton />}
                                        </h2>
                                    </Col>
                                    <Col sm={12}>
                                        <p className="list-tagline">
                                            {landscape.tagline || <Skeleton />}
                                        </p>
                                    </Col>
                                    <Col sm={2}>
                                        <Badge
                                            pill
                                            variant="light"
                                            className="mr-2"
                                        >
                                            <FontAwesomeIcon icon={faComment} />{' '}
                                            {numComments}
                                        </Badge>
                                    </Col>
                                    <Col sm={3}>
                                        {landscape.primaryCategories
                                            .sort()
                                            .map((category) => (
                                                <Badge
                                                    variant="primary"
                                                    className="mx-1"
                                                >
                                                    {category}
                                                </Badge>
                                            ))}
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                sm={1}
                                className="text-center mt- d-block d-sm-none"
                            >
                                {authId ? (
                                    <Button
                                        type="button"
                                        variant={
                                            upvoteCheck
                                                ? 'primary'
                                                : 'outline-primary'
                                        }
                                        onClick={(ev) => {
                                            handleUpvote();
                                            ev.stopPropagation();
                                        }}
                                        className="px-4"
                                    >
                                        <FontAwesomeIcon icon={faChevronUp} />{' '}
                                        UPVOTE {upvotes.length}
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={disableUpvote}
                                        type="button"
                                        variant="primary"
                                        as={Link}
                                        to="/login"
                                        className="px-4"
                                    >
                                        <FontAwesomeIcon icon={faChevronUp} />{' '}
                                        UPVOTE &nbsp;&nbsp;
                                        {upvotes.length}
                                    </Button>
                                )}
                            </Col>
                            <Col
                                sm={1}
                                className="text-center mt- d-none d-md-block"
                            >
                                {authId ? (
                                    <Button
                                        type="button"
                                        variant={
                                            upvoteCheck
                                                ? 'primary'
                                                : 'outline-primary'
                                        }
                                        onClick={(ev) => {
                                            handleUpvote();
                                            ev.stopPropagation();
                                        }}
                                        className="px-4"
                                    >
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            className="mt-3"
                                        />
                                        <br />
                                        <p className="upvote-count">
                                            {upvotes.length}
                                        </p>
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={disableUpvote}
                                        type="button"
                                        variant="primary"
                                        as={Link}
                                        to="/login"
                                        className="px-4"
                                    >
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            className="mt-3"
                                        />
                                        <br />
                                        <p className="upvote-count">
                                            {upvotes.length}
                                        </p>
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
                </ListGroup.Item>
            </Styles>
        </>
    );
};

export default LandscapeItem;
