import axios from 'axios';
import { Dispatch } from 'redux';
import IUser from '../../interfaces/IUser';
import { UserProfileActionTypes } from './types';

// Set logged in user
export const setUserProfile = (profile: IUser | undefined) => {
    const action: UserProfileActionTypes = {
        type: 'SET_USER_PROFILE',
        payload: profile
    };
    return action;
};

// User loading
export const setUserProfileLoading = (status: boolean) => {
    const action: UserProfileActionTypes = {
        type: 'USER_PROFILE_LOADING',
        payload: status
    };
    return action;
};

export const loadUserProfile = (username: string) => (dispatch: Dispatch) => {
    dispatch(setUserProfileLoading(true));
    axios
        .get(
            // eslint-disable-next-line no-underscore-dangle
            `/api/users/profiles/${username}`
        )
        .then((userProfileRes) => {
            dispatch(setUserProfile(userProfileRes.data));
            dispatch(setUserProfileLoading(false));
        })
        .catch((e) => {
            console.log('Couldnt load user profile');
        });
};
