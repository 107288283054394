/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    Button,
    Row,
    Col,
    Badge,
    Card,
    Image,
    Overlay,
    Tooltip
} from 'react-bootstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
// Interface for Redux types
import { StoreState, AuthState } from '../../redux/actions/types';

import getSrc from '../../utils/commonFunction';
import INews from '../../interfaces/INews';

import { useEventTracking } from '../../pages/eventTracking';

interface newseedModalProps {
    news: INews;
    show: boolean;
    handleClose: () => void;
}

const NewseedModal: React.FC<newseedModalProps> = ({
    news,
    show,
    handleClose,
    ...props
}: newseedModalProps) => {
    const [state, setstate] = useState('');

    return (
        <Modal show={show} onHide={handleClose} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>{news.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-5">
                <Row className="justify-content-center my-3">
                    <Image
                        src={news.image === '' ? '' : news.image}
                        style={{
                            maxWidth: '70%',
                            aspectRatio: '16/10',
                            objectFit: 'cover'
                        }}
                    />
                </Row>
                <Row>{news.summary}</Row>
                <Row>
                    <a
                        href={news.link}
                        rel="noreferrer"
                        target="_blank"
                        onClick={() => {
                            handleClose();
                            useEventTracking(
                                'Newseed',
                                'Clicked Read More Here Hyperlink',
                                `${news.title} from ${news.source}`
                            );
                        }}
                    >
                        Read more here
                    </a>
                </Row>
                <br />
                <Row>
                    <i>Source: {news.source}</i>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewseedModal;
