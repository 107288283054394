/* eslint-disable react/require-default-props */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import SingleOrganisationView from './SingleOrganisationView';
import IOrganisation from '../../interfaces/IOrganisation';
import { AuthState, StoreState } from '../../redux/actions/types';

const Styles = styled.div`
    max-height: 20rem;
    overflow-y: auto;

    .card-body {
        padding: 0;
    }

    .basic-multi-select {
        font-size: 16px;
    }
`;

interface Props {
    entries: IOrganisation[];
    onItemClick?: (organisation: IOrganisation) => void;
    auth: AuthState;
}

const OrganisationsList: React.FC<Props> = ({
    entries,
    onItemClick,
    auth
}: Props) => (
    <Styles>
        <Card>
            <Card.Body>
                <ListGroup variant="flush">
                    {entries.map((item) => (
                        <SingleOrganisationView
                            key={item._id}
                            organisation={item}
                            onClick={onItemClick}
                            authId={auth.user._id}
                        />
                    ))}
                </ListGroup>
            </Card.Body>
        </Card>
    </Styles>
);

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(OrganisationsList);
