import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

interface historyProps {
    history: RouteComponentProps['history'];
}

const ClickableStyle = styled.div`
    .clickable {
        color: #0056b3;
        border: none;
        background: none;
    }

    .clickable:hover {
        text-decoration: underline;
    }
`;

const BackToMyOrgsComponent: React.FC<historyProps> = ({ ...props }) => (
    <div className="text-left back-link mb-4">
        <ClickableStyle>
            <button
                type="button"
                className="clickable"
                onClick={props.history.goBack}
            >
                <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back
            </button>
        </ClickableStyle>
    </div>
);

export default BackToMyOrgsComponent;
