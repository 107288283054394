/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import BackButton from '../components/backbuttons/BackButton';
import BlockerModal from '../components/BlockerModal';
import EcosystemInfo from '../components/community/ecosystem/EcosystemInfo';
import EcosystemTabs from '../components/community/ecosystem/EcosystemTabs';
// Interfaces
import { Ecosystem } from '../interfaces/Ecosystem';
import { AuthState, StoreState } from '../redux/actions/types';

const ProfileStyle = styled.div`
    text-align: left;
    h3 {
        overflow: hidden;
        font-size: 13px;
        text-transform: uppercase;
    }
    h3::first-letter {
        font-size: 110%;
    }
    h4 {
        font-size: 18px;
    }
    .nav-pills {
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        a {
            color: black;
            border-radius: 0px;
            border-bottom-width: 3px;
            border-bottom-style: solid;
            border-bottom-color: #dcdcdc;
        }
        a:hover {
            border-bottom-color: #007bff;
        }
        .active,
        .active:hover {
            color: black;
            background: none;
            border-bottom-color: #007bff;
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        max-height: 8.5rem;
        max-width: 8.5rem;

        @media screen and (max-width: 991px) {
            max-height: 6.5rem;
            max-width: 6.5rem;
        }
        @media screen and (max-width: 768px) {
            max-height: 8rem;
            max-width: 8rem;
        }
    }
`;

interface Props extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
}

interface ParamTypes {
    ecosystemId: string | undefined;
}

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

const EcosystemProfile: React.FC<Props> = ({ auth, history }) => {
    const { ecosystemId } = useParams<ParamTypes>();

    const [profile, setProfile] = useState<Ecosystem>({} as Ecosystem);
    const [isAdminUser, setIsAdminUser] = useState(false);
    const [joined, setJoined] = useState(false);
    const [deleted, setDeleted] = useState(false);

    useEffect(() => {
        axios
            .get<Ecosystem>(`/api/ecosystems/${ecosystemId}`)
            .then(({ data }) => {
                setProfile(data);
            })
            .catch((err) => {
                console.log(err.response?.data);
                if (err.response?.status === 404) history.push('/error404');
            });
    }, [ecosystemId]);

    useEffect(() => {
        if (profile._id) {
            if (profile.admins.map((_id) => _id).includes(auth.user._id)) {
                setIsAdminUser(true);
            }

            if (profile.members.map(({ _id }) => _id).includes(auth.user._id)) {
                setJoined(true);
            }
        }
    }, [profile]);

    const ogMetaTitle = `${profile.name} Ecosystem Profile`;

    return (
        <ProfileStyle className="font-arimo">
            <Container>
                <Helmet>
                    <meta
                        name="title"
                        property="og:title"
                        content={ogMetaTitle}
                    />
                    <meta property="og:type" content="profile" />
                    <meta property="profile:username" content={profile.name} />
                    <meta
                        name="image"
                        property="og:image"
                        content={
                            profile.logo
                                ? profile.logo.location
                                : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                        }
                    />
                    <meta
                        name="description"
                        property="og:description"
                        content={profile.description}
                    />
                    <meta property="og:site_name" content="Interseed" />
                </Helmet>
                {auth.isAuthenticated ? null : (
                    <BlockerModal history={history} />
                )}
                <BackButton />
                {profile._id && (
                    <Card className="p-4 mb-2">
                        <Row>
                            <Col sm>
                                <Wrapper>
                                    <Image
                                        className="profile-picture mb-4"
                                        src={
                                            profile.logo
                                                ? profile.logo.location
                                                : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                                        }
                                        alt="Profile"
                                        thumbnail
                                        roundedCircle
                                        fluid
                                        onError={(e) => {
                                            e.currentTarget.onerror = null; // prevents looping
                                            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                        }}
                                    />
                                </Wrapper>
                            </Col>
                            <Col sm={9}>
                                <EcosystemInfo
                                    profile={profile}
                                    isAdminUser={isAdminUser}
                                    joined={joined}
                                    auth={auth}
                                />
                                <EcosystemTabs
                                    profile={profile}
                                    isAdminUser={isAdminUser}
                                    deleted={deleted}
                                    setDeleted={setDeleted}
                                    auth={auth}
                                    ecosystemId={ecosystemId}
                                />
                            </Col>
                        </Row>
                    </Card>
                )}
            </Container>
        </ProfileStyle>
    );
};
export default connect(mapStateToProps)(withRouter(EcosystemProfile));
