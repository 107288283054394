/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import axios from 'axios';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';

import jwtDecode from 'jwt-decode';
import { Provider } from 'react-redux';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

// Components
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import { Layout } from './components/Layout';

// Pages
import Collections from './pages/Collections';
import Landscapes from './pages/Landscapes';
import LandscapePage from './pages/LandscapePage';
import { Error404 } from './pages/errorpages/Error404';
import { Error500 } from './pages/errorpages/Error500';
import UploadLandscape from './pages/createpages/UploadLandscape';
import Community from './pages/Community';
import CreateOrganisation from './pages/createpages/CreateOrganisation';
import OrganisationProfile from './pages/OrganisationProfile';
import CreateOpportunity from './pages/createpages/CreateOpportunity';
import Jobs from './pages/Jobs';
import InternshipPage from './pages/InternshipPage';
import Register from './pages/Register';
import Login from './pages/Login';
import UserProfile from './pages/UserProfile';
import EditProfile from './pages/editpages/EditProfile';
import EditOpportunity from './pages/editpages/EditOpportunity';
import Onboarding from './pages/Onboarding';
import Resources from './pages/Resources';
import EditLandscape from './pages/editpages/EditLandscape';
import EditOrganisationProfile from './pages/editpages/EditOrganisationProfile';
import MyOrganisations from './pages/MyOrganisations';
import About from './pages/About';
import ConfirmationPage from './pages/ConfirmationPage';
import { EmailConfirmation } from './pages/EmailConfirmation';
import ResetPassword from './pages/ResetPassword';
import Funding from './pages/Funding';
import VolOppsPage from './pages/VolOppsPage';
import Dashboard from './pages/Dashboard';
import { usePageTracking } from './pages/pageTracking';
import NotLoggedIn from './pages/NotLoggedIn';
import AdminSendEmailPage from './pages/AdminSendEmailPage';

import store from './redux/store';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './redux/actions/AuthActions';
import { IDecodedToken } from './interfaces/AuthInterface';
import {
    loadUserProfile
    //   setUserProfile,
    //   setUserProfileLoading
} from './redux/actions/UserProfileActions';
import Events from './pages/Events';
import Event from './pages/Event';
import EventsFiltered from './pages/EventsFiltered';
import Newseed from './pages/Newseed';
import EditEvent from './pages/editpages/EditEvent';
import LandingPage from './pages/LandingPage';
import MicrosoftChallenge from './pages/MicrosoftChallenge';
import CreateInternship from './pages/createpages/CreateInternship';
import EditInternship from './pages/editpages/EditInternship';
import EcosystemProfile from './pages/EcosystemProfile';
import CreateEcosystem from './pages/CreateEcosystem';
import NavbarProvider from './contexts/navbar.ctx';

axios.defaults.baseURL =
    process.env.REACT_APP_BASE_URL || 'http://localhost:5000';

// Check for token to keep user logged in
if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);

    // Decode token and get user info and exp
    const decoded: IDecodedToken = jwtDecode(token);

    // Check for expired token in milliseconds
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        // Logout user
        logoutUser();
    } else {
        // Set user and isAuthenticated
        store.dispatch(setCurrentUser(decoded));
        loadUserProfile(decoded.username)(store.dispatch);
    }
}

// Functional Component
const App: React.FC = () => {
    const [refresh, setRefresh] = useState(false);
    usePageTracking();
    return (
        <Provider store={store}>
            <NavbarProvider>
                <NavigationBar refresh={refresh} />
                <div className="App">
                    <header className="App-header">
                        <Layout>
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="landingpage" />
                                </Route>
                                <Route
                                    path="/register"
                                    render={() => (
                                        <Register
                                            refreshNav={() =>
                                                setRefresh(!refresh)
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    path="/login"
                                    render={() => (
                                        <Login
                                            refreshNav={() =>
                                                setRefresh(!refresh)
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    path="/landingpage"
                                    component={LandingPage}
                                />
                                <Route
                                    exact
                                    path="/linkedin"
                                    component={LinkedInPopUp}
                                />
                                <Route
                                    path="/collections"
                                    component={Collections}
                                />
                                <Route path="/about" component={About} />
                                <Route
                                    path="/community"
                                    component={Community}
                                />
                                <Route
                                    path="/landscapes"
                                    component={Landscapes}
                                />
                                <Route
                                    path="/dashboard"
                                    component={Dashboard}
                                    exact
                                />
                                <Route
                                    path="/landscape/:queryId"
                                    render={(props) => (
                                        <LandscapePage
                                            key={props.match.params.queryId}
                                        />
                                    )}
                                />
                                <Route
                                    path="/editlandscape/:queryId"
                                    component={EditLandscape}
                                />
                                <Route path="/jobs" component={Jobs} />
                                <Route
                                    path="/upload"
                                    component={UploadLandscape}
                                    exact
                                />
                                <Route
                                    path="/resources"
                                    component={Resources}
                                />
                                <Route
                                    path="/newseed"
                                    component={Newseed}
                                    exact
                                />
                                <Route
                                    path="/events"
                                    component={Events}
                                    exact
                                />
                                <Route
                                    path="/event/:eventId"
                                    component={Event}
                                    exact
                                />
                                <Route
                                    path="/event/update/:eventId"
                                    component={EditEvent}
                                    exact
                                />
                                <Route
                                    path="/events/:filter"
                                    component={EventsFiltered}
                                    exact
                                />
                                <Route
                                    path="/profile"
                                    exact
                                    component={UserProfile}
                                />
                                <Route
                                    path="/profile/:queryId"
                                    render={(props) => (
                                        <UserProfile
                                            key={props.match.params.queryId}
                                        />
                                    )}
                                />
                                <Route
                                    path="/editprofile"
                                    render={() => (
                                        <EditProfile
                                            refreshNav={() =>
                                                setRefresh(!refresh)
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    path="/onboarding"
                                    render={() => (
                                        <Onboarding
                                            refreshNav={() =>
                                                setRefresh(!refresh)
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    path="/ecosystem/:ecosystemId"
                                    render={(props) => (
                                        <EcosystemProfile
                                            key={props.match.params.ecosystemId}
                                        />
                                    )}
                                />
                                <Route
                                    path="/createecosystem/:queryId"
                                    render={(props) => (
                                        <CreateEcosystem
                                            key={props.match.params.queryId}
                                        />
                                    )}
                                />
                                <Route
                                    path="/organisation/:queryId"
                                    render={(props) => (
                                        <OrganisationProfile
                                            key={props.match.params.queryId}
                                        />
                                    )}
                                />
                                <Route
                                    path="/organisations"
                                    component={MyOrganisations}
                                    exact
                                />
                                <Route
                                    path="/createorganisation"
                                    component={CreateOrganisation}
                                    exact
                                />
                                <Route
                                    path="/editorganisation/:queryId"
                                    component={EditOrganisationProfile}
                                />
                                <Route
                                    path="/createinternship"
                                    component={CreateInternship}
                                    exact
                                />
                                <Route
                                    path="/createinternship/:queryId"
                                    render={(props) => (
                                        <CreateInternship
                                            key={props.match.params.queryId}
                                        />
                                    )}
                                />
                                <Route
                                    path="/editinternship/:queryId"
                                    component={EditInternship}
                                />
                                <Route
                                    path="/internship/:queryId"
                                    render={(props) => (
                                        <InternshipPage
                                            key={props.match.params.queryId}
                                        />
                                    )}
                                />
                                <Route
                                    path="/createopportunity"
                                    component={CreateOpportunity}
                                    exact
                                />
                                <Route
                                    path="/createopportunity/:queryId"
                                    render={(props) => (
                                        <CreateOpportunity
                                            key={props.match.params.queryId}
                                        />
                                    )}
                                />
                                <Route
                                    path="/editopportunity/:opportunityId"
                                    render={(props) => (
                                        <EditOpportunity
                                            key={
                                                props.match.params.opportunityId
                                            }
                                        />
                                    )}
                                />

                                {/*
                            TODO: Create a page view for an opportunity.
                            <Route
                                path="/opportunity/:queryId"
                                render={(props) => (
                                    <InternshipPage
                                        key={props.match.params.queryId}
                                    />
                                )}
                            /> */}
                                <Route path="/funding" component={Funding} />
                                <Route
                                    path="/volunteeringopps"
                                    component={VolOppsPage}
                                />
                                <Route
                                    path="/greentechchallenge"
                                    component={MicrosoftChallenge}
                                />
                                <Route path="/jobs" component={Jobs} />
                                <Route
                                    path="/email-confirmation"
                                    component={EmailConfirmation}
                                />
                                <Route
                                    path="/confirm/:queryId"
                                    render={(props) => (
                                        <ConfirmationPage
                                            key={props.match.params.queryId}
                                        />
                                    )}
                                />
                                <Route
                                    path="/passwordReset/:resetToken/:userId"
                                    render={(props) => (
                                        <ResetPassword
                                            key={props.match.params.resetToken}
                                        />
                                    )}
                                />
                                <Route
                                    path="/admin/sendemail"
                                    component={AdminSendEmailPage}
                                />
                                <Route path="/error500" component={Error500} />
                                <Route component={Error404} />
                            </Switch>
                        </Layout>
                    </header>
                    <Footer />
                </div>
            </NavbarProvider>
        </Provider>
    );
};

export default App;
