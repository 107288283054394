/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, { useEffect, useState } from 'react';
import { ListGroup, Container, Row, Col, Badge } from 'react-bootstrap';
import styled from 'styled-components';

import IVolunteerOpportunity from '../../interfaces/IVolunteerOpportunity';
import OpportunityModal from './OpportunityModal';

import { useEventTracking } from '../../pages/eventTracking';

interface volOppProp {
    opportunity: IVolunteerOpportunity;
    authId: string;
    userOrgs: string[];
}

const Styles = styled.div`
    a {
        color: black;

        &: hover {
            text-decoration: none;
        }
    }

    h2 {
        margin-bottom: 0.2rem;
    }

    .list-group-item {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .imgcontainer {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            object-fit: cover;
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    .badge {
        font-size: 16px;
    }
`;

const ListText = styled.p`
    margin-bottom: 0;
`;

const VolOppItem: React.FC<volOppProp> = ({ opportunity, authId, userOrgs }: volOppProp) => {
    
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        useEventTracking(
            'Volunteering Opportunities Page',
            'Clicked A Volunteering Opportunity Item',
            `${opportunity.title}`
        );
        setShow(true);
    };
    
    return (
        <>
            <OpportunityModal
                opportunity={opportunity}
                show={show}
                handleClose={handleClose}
                userOrgs={userOrgs}
            />
            <Styles>
                <ListGroup.Item
                    onClick={handleShow}
                >
                    <Container>
                        <Row>
                            <Col sm={2} className="imgcontainer mb-2">
                                <img
                                    src={
                                        opportunity.thumbnail && opportunity.thumbnail.location !== ''
                                            ? opportunity.thumbnail.location
                                            : `${process.env.PUBLIC_URL}/assets/funding-placeholder.png`
                                    }
                                    alt=""
                                />
                            </Col>
                            <Col sm={8} className="text-center text-sm-left">
                                <h2>
                                    <b>{opportunity.title}</b>
                                </h2>
                                <ListText className="text-muted mb-1">
                                    <i>
                                        by <b>{opportunity.organisation.name}</b>
                                    </i>
                                </ListText>
                                <p className="mt-2">
                                    Tags:
                                    {opportunity.primaryTags.map((tag: string) => (
                                        <Badge
                                            variant="light"
                                            className="mr-1 mt-1 ml-2"
                                            style={{ whiteSpace: 'normal' }}
                                        >
                                            {tag}
                                        </Badge>
                                    ))}
                                </p>
                                <p className="mt-2">
                                    Type:
                                    {opportunity.opportunityTypes.map((type: string) => (
                                        <Badge
                                            variant="light"
                                            className="mr-1 mt-1 ml-2"
                                            style={{ whiteSpace: 'normal' }}
                                        >
                                            {type}
                                        </Badge>
                                    ))}
                                </p>
                                <ListText>{`${opportunity.generalArea}, ${opportunity.country}`}</ListText>
                                { opportunity.startDate && opportunity.startTime && opportunity.endDate && opportunity.endTime
                                ?
                                    <>
                                        <ListText className="mt-3">
                                            {`Start: ${new Date(opportunity.startDate).toLocaleDateString(
                                                'en-US',
                                                {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric'
                                                }
                                            )} 
                                            ${opportunity.startTime}`}
                                        </ListText>
                                        <ListText className="mt-1">
                                            {`End: ${new Date(opportunity.endDate).toLocaleDateString(
                                                'en-US',
                                                {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric'
                                                }
                                            )} 
                                            ${opportunity.endTime}`}
                                        </ListText>
                                    </>
                                :
                                    <></>
                                }
                                { opportunity.registrationClosingDate
                                ?
                                    <>
                                        <ListText className="mt-3"><b>
                                            {`Register by ${new Date(opportunity.registrationClosingDate).toLocaleDateString(
                                                'en-US',
                                                {
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric'
                                                }
                                            )}`}
                                        </b></ListText>
                                    </>
                                :
                                    <></>
                                }
                            </Col>
                        </Row>
                    </Container>
                </ListGroup.Item>
            </Styles>
        </>
    )
};

export default VolOppItem;
