/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import IUser from '../../interfaces/IUser';
import { StoreState } from '../../redux/actions/types';
import { setUserProfile } from '../../redux/actions/UserProfileActions';
import { useEventTracking } from '../../pages/eventTracking';

const FollowButton = styled.div`
    .follow {
        border-radius: 100%;
        aspect-ratio: 1;
    }
`;

const ProfileFollowButton: React.FC<{
    entryUsername: string;
    entryId: string;
    userProfile: IUser | undefined;
    setUserProfile: (_: IUser | undefined) => void;
}> = ({ entryUsername, entryId, userProfile, setUserProfile: setProfile }) => {
    // Blank out if we dont have a loaded user profile, or its the same user as this entry
    if (!userProfile || userProfile._id === entryId) {
        return <div />;
    }
    const [changingFollowState, setChangingFollowState] = useState(false);
    const following = userProfile.following.includes(entryId);
    return (
        <FollowButton>
            <Button
                variant="primary"
                className="follow"
                disabled={changingFollowState}
                onClick={async (ev) => {
                    ev.stopPropagation();
                    setChangingFollowState(true);
                    if (following) {
                        const userProfileRes = await axios.delete(
                            `/api/users/follows/${entryId}`
                        );
                        useEventTracking(
                            'Dashboard Map',
                            'Unfollowed User',
                            `${entryUsername}`
                        );
                        setProfile(userProfileRes.data);
                    } else {
                        const userProfileRes = await axios.put(
                            `/api/users/follows/${entryId}`
                        );
                        useEventTracking(
                            'Dashboard Map',
                            'Followed User',
                            `${entryUsername}`
                        );
                        setProfile(userProfileRes.data);
                    }
                    setChangingFollowState(false);
                }}
            >
                <FontAwesomeIcon icon={following ? fasHeart : faHeart} />
            </Button>
        </FollowButton>
    );
};

export default connect(
    (s: StoreState) => ({
        userProfile: s.userProfile.userProfile
    }),
    { setUserProfile }
)(ProfileFollowButton);
