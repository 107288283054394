import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const BackToLandscapesComponent = () => (
    <div className="text-left back-link mb-4">
        <Link to="/landscapes">
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Landscapes
        </Link>
    </div>
);

export default BackToLandscapesComponent;
