import React, { ReactNode } from 'react';
import { Container } from 'react-bootstrap';
// import { useLocation } from 'react-router-dom';

export const Layout = ({ children }: { children: ReactNode }) => (
    <Container className="px-0 py-1" fluid>
        {children}
    </Container>
);

export default Layout;
