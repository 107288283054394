import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInMinutes, format, isWithinInterval } from 'date-fns';
import React, { ReactElement } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Card,
    Image,
    ListGroup,
    OverlayTrigger,
    Popover,
    Spinner,
    Tooltip
} from 'react-bootstrap';
import styled from 'styled-components';
import { IEvent } from '../../interfaces/IEvent';
import { ITag } from '../../interfaces/ITag';
import getSrc from '../../utils/commonFunction';
import { color, EventButton } from './EventStyles';
import { useEventTracking } from '../../pages/eventTracking';

const truncate = (str: string) =>
    str.length > 150 ? `${str.substring(0, 122)}...` : str;

const getTime = (startTime: string) =>
    format(new Date(startTime), 'hh:mm a - LLLL dd, yyyy');

const isLiveNow = (startTime: string, endTime: string) =>
    isWithinInterval(new Date(), {
        start: new Date(startTime),
        end: new Date(endTime)
    });

const isLiveSoon = (startTime: string) => {
    const diff = differenceInMinutes(new Date(startTime), new Date());
    return diff <= 60 * 4 && diff > 0;
};

const CardContent = styled(Card)`
    box-shadow: 0px 4px 25px rgba(22, 77, 135, 0.1);
    border-radius: 15px;
    gap: 0.75rem;
    padding: 1rem;
    color: #51617c;
    text-align: left;
    height: 100%;
`;

const LiveNowContent = styled.div`
    font-size: 1rem;
    font-weight: 600;
    color: #fff;
`;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

interface EventItemVerticalProps {
    event: IEvent;
    eventSection: string;
    setViewEvent?: (val: boolean) => void;
    setSelectedEvent?: (val: string) => void;
}

export default function EventItemVertical({
    event,
    eventSection,
    setViewEvent,
    setSelectedEvent
}: EventItemVerticalProps): ReactElement {
    const viewEvent = () => {
        if (setViewEvent && setSelectedEvent) {
            setSelectedEvent(event._id);
            setViewEvent(true);
        }
    };
    const history = useHistory();
    const location = useLocation();

    const sortByTag = (id: string) => {
        if (location.pathname.includes('events/')) {
            history.push(`all?tag=${id}`);
        } else history.replace(`events/all?tag=${id}`);
    };

    const getIsLiveIndicator = (startTime: string, endTime: string) => {
        if (isLiveNow(startTime, endTime))
            return (
                <LiveNowContent
                    className="position-absolute d-flex align-items-center badge badge-pill px-2 py-1 text-uppercase "
                    style={{
                        right: '0.5rem',
                        top: '0.5rem',
                        gap: '0.25rem',
                        fontSize: '0.85rem',
                        fontWeight: 600,
                        backgroundColor: '#FF5630'
                    }}
                >
                    <Spinner
                        animation="grow"
                        variant="light"
                        style={{ width: '0.5rem', height: '0.5rem' }}
                    />
                    Live
                </LiveNowContent>
            );
        if (isLiveSoon(startTime))
            return (
                <span
                    className="position-absolute badge badge-pill px-2 text-uppercase"
                    style={{
                        right: '0.5rem',
                        top: '0.5rem',
                        fontSize: '0.85rem',
                        fontWeight: 600,
                        backgroundColor: '#1BC47D',
                        color: '#FFF'
                    }}
                >
                    Live Soon
                </span>
            );
        return null;
    };

    return (
        <CardContent>
            <div className="position-relative">
                <span
                    className="position-absolute badge badge-pill px-3"
                    style={{
                        left: '0.5rem',
                        top: '0.5rem',
                        fontSize: '1rem',
                        fontWeight: 600,
                        backgroundColor: color.types[event.type],
                        color: color.types.text
                    }}
                >
                    {event.type}
                </span>
                {getIsLiveIndicator(event.startTime, event.endTime)}
                <Image
                    src={event.banner?.location}
                    style={{
                        aspectRatio: '16/9',
                        width: '100%',
                        maxWidth: '100%',
                        borderRadius: '15px',
                        objectFit: 'cover'
                    }}
                    fluid
                />
            </div>
            <div
                className="d-flex flex-wrap align-items-center"
                style={{ gap: '0.5rem' }}
            >
                {event.tags.slice(0, 2).map((tag: ITag) => (
                    <button
                        className="badge badge-pill badge-primary px-3 py-1 "
                        style={{
                            background: '#EBF4FF',
                            color: color.shade2,
                            fontSize: '1rem',
                            border: 0
                        }}
                        type="button"
                        onClick={() => {
                            sortByTag(tag._id);
                            useEventTracking(
                                'Events',
                                'Clicked Tag Filter',
                                `${tag.title}`
                            );
                        }}
                        key={tag._id}
                    >
                        {tag.title}
                    </button>
                ))}
                {event.tags.length > 2 && (
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        key="bottom"
                        placement="bottom"
                        overlay={
                            <Popover id={`bottom-popover-${event._id}`}>
                                <div>
                                    <ListGroup>
                                        {event.tags
                                            .slice(2)
                                            .map((tag: ITag) => (
                                                <ListGroup.Item
                                                    action
                                                    key={tag.title}
                                                    className="text-truncate font-weight-bold"
                                                >
                                                    {tag.title}
                                                </ListGroup.Item>
                                            ))}
                                    </ListGroup>
                                </div>
                            </Popover>
                        }
                    >
                        <span
                            className="badge badge-pill badge-primary px-3 py-1 "
                            style={{
                                background: '#EBF4FF',
                                color: color.shade2,
                                fontSize: '1rem'
                            }}
                            key={`${event._id}-tag-more`}
                        >
                            +{event.tags.length - 2}
                        </span>
                    </OverlayTrigger>
                )}
            </div>
            <div>
                <div className="h5 font-weight-bold text-truncate m-0 text-left">
                    {event.title}
                </div>
            </div>
            <div
                style={{
                    minHeight: '3rem',
                    flexGrow: 1
                }}
            >
                <div
                    className="p3 text-left"
                    style={{
                        color: '#4E5D78',
                        opacity: '0.5'
                    }}
                >
                    {truncate(event.description)}
                </div>
            </div>
            <div className="d-flex align-items-center" style={{ gap: '1rem' }}>
                <span
                    className="badge badge-pill badge-primary px-4 py-1 "
                    style={{
                        background: '#ECF5FF',
                        color: color.theme,
                        fontSize: '0.75rem'
                    }}
                >
                    {getTime(event.startTime)}
                </span>
                {event.isRegistered && (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="registered-tooltip">
                                Registered on this event.
                            </Tooltip>
                        }
                    >
                        <FontAwesomeIcon
                            size="sm"
                            icon={faCalendarCheck}
                            style={{
                                display: 'inline-block',
                                color: color.theme
                            }}
                        />
                    </OverlayTrigger>
                )}
            </div>
            <div className="position-relative" style={{ height: '3rem' }}>
                {event.participants.slice(0, 4).map((participant, index) => (
                    <Image
                        key={participant._id}
                        onClick={() => {
                            history.push(`/profile/${participant.username}`);
                        }}
                        src={getSrc(participant)}
                        alt="Profile"
                        roundedCircle
                        fluid
                        style={{
                            position: 'absolute',
                            left: `${2 * index}rem`,
                            width: '3rem',
                            height: '3rem',
                            objectFit: 'cover',
                            border: '2px solid #FFFFFF',
                            zIndex: index % 2 ? 10 : 5,
                            cursor: 'pointer'
                        }}
                    />
                ))}
                {event.participantLength > 4 && (
                    <div
                        style={{
                            position: 'absolute',
                            left: `${1.5 * event.participants.length}rem`,
                            width: '3rem',
                            height: '3rem',
                            objectFit: 'cover',
                            border: '2px solid #FFFFFF',
                            backgroundColor: '#C1C7D0',
                            color: '#fff',
                            borderRadius: '999px',
                            fontSize: '1rem',
                            zIndex: 10
                        }}
                    >
                        +{event.participantLength - event.participants.length}
                    </div>
                )}
            </div>
            {setViewEvent && (
                <div style={{}}>
                    <EventButton
                        style={{
                            padding: '0.50rem 1.5rem',
                            width: '100%'
                        }}
                        onClick={() => {
                            viewEvent();
                            useEventTracking(
                                'Events',
                                `Clicked View Event from ${eventSection}`,
                                `${event.title}`
                            );
                        }}
                    >
                        View
                    </EventButton>
                </div>
            )}
        </CardContent>
    );
}
