import {
    faEdit,
    faLongArrowAltLeft,
    faUndo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { format, parse } from 'date-fns';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    Link,
    RouteComponentProps,
    useParams,
    withRouter
} from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import { ThumbFile } from '../../components/events/EventCreateModal';
import EventLoading from '../../components/events/EventLoading';
import { color, EventButton } from '../../components/events/EventStyles';
import UploadImage from '../../components/events/UploadImage';
import { blankEvent, IEvent, eventTypes } from '../../interfaces/IEvent';
import { ITag } from '../../interfaces/ITag';
import {
    AuthState,
    StoreState,
    UserProfileState
} from '../../redux/actions/types';
import { whitespaceCheck } from '../../utils/commonFunction';
import { useEventTracking } from '../eventTracking';

const Backdrop = styled.div`
    z-index: 3001;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
`;

const FormContent = styled(Card)`
    font-size: 16px;
    padding: 2rem;
    margin-bottom: 2.5rem;
    label {
        font-size: 14px;
    }

    .form-label {
        font-weight: 700;
        color: #6c757d;
    }

    input,
    textarea {
        background: #fafbfc;
        border: 1px solid #f3f3f3;
        border-radius: 15px;
    }

    input.form-control {
        height: 3rem;
    }

    .custom-file {
        font-size: 16px;
    }

    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }
`;

interface thumbFile {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    src?: string | ArrayBuffer;
}

interface EditEventProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

function EditEvent({
    auth,
    history,
    userProfile
}: EditEventProps): ReactElement {
    const alert = useAlert();
    const { eventId } = useParams<{ eventId: string }>();
    const [event, setEvent] = useState<IEvent>(blankEvent);
    const [loading, setLoading] = useState(false);
    const [sendEmails, setSendEmails] = useState(false);
    const [editZoomLink, setEditZoomLink] = useState(false);
    const [newZoomLink, setNewZoomLink] = useState('');
    const [tags, setTags] = useState<ITag[]>([]);
    const [date, setDate] = useState('');

    const [thumbReal, setThumbReal] = useState<File>();
    const [thumb, setThumb] = useState<thumbFile>({
        lastModified: 0,
        name: 'Choose a file',
        size: 0,
        type: ''
    });

    const selectRef = useRef<any>();
    const multiSelectRef = useRef<any>();

    const setImage = (image: File) => {
        setThumbReal(image);
        const file: ThumbFile = image;
        const reader = new FileReader();

        reader.onload = function read(e) {
            if (e.target && e.target.result) {
                file.src = e.target.result;
                if (file.name.length > 40) {
                    setThumb({
                        lastModified: file.lastModified,
                        name: file.name
                            .slice(0, 40)
                            .concat(`... ${file.type.replace('image/', '.')}`),
                        size: file.size,
                        type: file.type
                    });
                } else setThumb(file);
            }
        };
        reader.readAsDataURL(image);
    };

    const handleChange = (reactEvent: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = reactEvent.target;
        if (name === 'receiveEmails')
            setEvent({
                ...event,
                [name]: checked
            });
        else
            setEvent({
                ...event,
                [name]: value
            });
    };

    const handleSelect = (newValue: any, type: string) => {
        setEvent({
            ...event,
            [type]: type === 'tags' ? newValue : newValue.value
        });
    };

    const onSubmit = async (submitEvent: React.FormEvent<HTMLFormElement>) => {
        submitEvent.preventDefault();
        if (loading) return;

        if (!whitespaceCheck(event, ['title', 'description'])) {
            alert.error(
                'Required fields cannot be filled with only whitespaces.'
            );
            return;
        }
        if (editZoomLink && newZoomLink === '') {
            alert.error('Zoom Link field Link cannot empty.');
            return;
        }

        try {
            useEventTracking(
                'Events',
                'Clicked Submit (Event Edited)',
                `${auth.user.username}`
            );
            setLoading(true);
            const { _id, ...eventInput } = event;
            const eventTags: string[] = [];
            const newTagNames = event.tags
                .filter((tag) => tag.__isNew__)
                .map((tag) => ({ title: tag.label }));
            if (newTagNames.length) {
                const { data: newTags } = await axios.post(
                    `/api/tags/many`,
                    newTagNames
                );
                setTags([...tags, ...newTags]);
                eventTags.push(...newTags.map((tag: ITag) => tag._id));
            }
            eventTags.push(
                ...event.tags
                    .filter((tag) => !tag.__isNew__)
                    .map((tag) => tag.value)
            );
            if (newZoomLink !== '') {
                eventInput.zoom = {
                    joinUrl: newZoomLink,
                    id: '',
                    startUrl: ''
                };
                setEvent({
                    ...event,
                    zoom: { joinUrl: newZoomLink, id: '', startUrl: '' }
                });
            }
            if (thumbReal) {
                // Upload Image First.
                const data: any = new FormData();
                data.append('image', thumbReal);
                if (event.banner) {
                    const { key } = event.banner;
                    await axios.delete(`/api/uploads/images/${key}`);
                }
                const { data: thumbnailData } = await axios.post(
                    `/api/uploads/images`,
                    data
                );

                // Create The Event
                await axios.put(`/api/events/${eventId}`, {
                    ...eventInput,
                    sendEmails,
                    startTime: parse(
                        date + event.startTime,
                        'yyyy-MM-ddHH:mm',
                        new Date()
                    ),
                    endTime: parse(
                        date + event.endTime,
                        'yyyy-MM-ddHH:mm',
                        new Date()
                    ),
                    organizer: auth.user?._id,
                    tags: eventTags,
                    type: event.type,
                    banner: thumbnailData,
                    participants: []
                });
                history.push(`/event/${event._id}`);
            } else {
                await axios.put(`/api/events/${eventId}`, {
                    ...eventInput,
                    sendEmails,
                    startTime: parse(
                        date + event.startTime,
                        'yyyy-MM-ddHH:mm',
                        new Date()
                    ),
                    endTime: parse(
                        date + event.endTime,
                        'yyyy-MM-ddHH:mm',
                        new Date()
                    ),
                    organizer: auth.user?._id,
                    tags: eventTags,
                    type: event.type,
                    participants: []
                });
                history.push(`/event/${event._id}`);
            }
        } catch (error) {
            console.log(error);
            alert.error('Error creating event. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (
            auth.isAuthenticated &&
            userProfile &&
            userProfile.userProfile &&
            userProfile.userProfile.status !== 'Active'
        ) {
            history.push('/onboarding');
        }
        if (!auth.isAuthenticated) {
            history.replace('/login');
        }
    }, [auth.status, auth.isAuthenticated, userProfile]);

    useEffect(() => {
        axios.get(`api/tags`).then(({ data }) => {
            setTags(data);
        });
    }, []);

    useEffect(() => {
        setEvent(blankEvent);
        if (eventId) {
            try {
                setLoading(true);
                axios
                    .get(`api/events/${eventId}?participantLimit=35`)
                    .then((res) => {
                        const eventResponse = res.data[0];
                        if (!eventResponse._id) {
                            history.push('/error404');
                        }
                        const eventDate = new Date(eventResponse.startTime);
                        if (eventDate < new Date()) {
                            history.push('/error404');
                        }
                        if (
                            !(
                                auth.user._id === eventResponse.organizer._id ||
                                userProfile?.userProfile?.admin
                            )
                        ) {
                            history.push('/error404');
                        }
                        setDate(format(eventDate, 'yyyy-MM-dd'));
                        setEvent({
                            ...eventResponse,
                            tags: eventResponse.tags.map((tag) => ({
                                label: tag.title,
                                value: tag._id
                            })),
                            startTime: format(eventDate, 'HH:mm'),
                            endTime: format(
                                new Date(eventResponse.endTime),
                                'HH:mm'
                            )
                        });
                        setThumb({
                            ...thumb,
                            name: eventResponse.banner.location.replace(
                                'https://interseedstorage.s3.ap-southeast-1.amazonaws.com/',
                                ''
                            ),
                            src: eventResponse.banner.location
                        });
                    });
            } catch (err) {
                console.log(err);
                history.push('/error404');
            } finally {
                setLoading(false);
            }
        }
    }, [eventId]);

    return (
        <Container fluid='md'>
            <Form onSubmit={onSubmit}>
                <div className="text-left back-link">
                    <Link to="/events">
                        <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to
                        Events
                    </Link>
                </div>
                <h1 className="text-center">Edit Your Event</h1>
                <FormContent>
                    <div>
                        <Form.Group className="required">
                            <Form.Label>Event Title</Form.Label>
                            <Form.Control
                                required
                                name="title"
                                type="text"
                                style={
                                    event.title
                                        ? {
                                              backgroundColor: '#FFF',
                                              fontWeight: 'bold'
                                          }
                                        : {}
                                }
                                maxLength={50}
                                value={event.title}
                                onChange={handleChange}
                                placeholder="Enter Event Title"
                            />
                            <Form.Text className="text-muted">
                                Maximum 50 Characters.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="required">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Write Your Description Here..."
                                required
                                name="description"
                                value={event.description}
                                rows={3}
                                maxLength={5000}
                                style={
                                    event.description
                                        ? {
                                              backgroundColor: '#FFF',
                                              fontWeight: 'bold'
                                          }
                                        : {}
                                }
                                onChange={handleChange}
                            />
                            <Form.Text className="text-muted">
                                Maximum 5000 Characters.
                            </Form.Text>
                        </Form.Group>
                        <Row>
                            <Form.Group
                                as={Col}
                                controlId="formDate"
                                className="required"
                            >
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    min={format(new Date(), 'yyyy-MM-dd')}
                                    style={
                                        date
                                            ? {
                                                  backgroundColor: '#FFF',
                                                  fontWeight: 'bold'
                                              }
                                            : {}
                                    }
                                    placeholder="Date"
                                    required
                                    name="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                controlId="formStartTime"
                                className="required"
                            >
                                <Form.Label>Start Time </Form.Label>
                                <Form.Control
                                    type="time"
                                    placeholder="Start Time"
                                    required
                                    style={
                                        event.startTime
                                            ? {
                                                  backgroundColor: '#FFF',
                                                  fontWeight: 'bold'
                                              }
                                            : {}
                                    }
                                    name="startTime"
                                    value={event.startTime}
                                    onChange={handleChange}
                                />
                                <Form.Text className="text-muted">
                                    Timezone:{' '}
                                    {
                                        Intl.DateTimeFormat().resolvedOptions()
                                            .timeZone
                                    }
                                </Form.Text>
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                controlId="formEndTime"
                                className="required"
                            >
                                <Form.Label>End Time</Form.Label>
                                <Form.Control
                                    disabled={!event.startTime}
                                    min={event.startTime}
                                    type="time"
                                    placeholder="End Time"
                                    required
                                    name="endTime"
                                    style={{
                                        backgroundColor: '#FFF',
                                        fontWeight: 'bold',
                                        border:
                                            event.endTime &&
                                            event.startTime > event.endTime
                                                ? '1px solid red'
                                                : 0
                                    }}
                                    value={event.endTime}
                                    onChange={handleChange}
                                />
                                <Form.Text className="text-muted">
                                    Timezone:
                                    {
                                        Intl.DateTimeFormat().resolvedOptions()
                                            .timeZone
                                    }
                                </Form.Text>
                            </Form.Group>
                        </Row>
                        <Form.Group>
                            <Form.Label>
                                No. of Participants (Optional)
                            </Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter Participant Number"
                                required
                                name="numberOfParticipants"
                                value={event.numberOfParticipants}
                                min={0}
                                style={
                                    event.numberOfParticipants
                                        ? {
                                              backgroundColor: '#FFF',
                                              fontWeight: 'bold'
                                          }
                                        : {}
                                }
                                onChange={handleChange}
                            />
                            <Form.Text>
                                Not Indicating a number means there will be no
                                limit to the attendees
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="required">
                            <Form.Label>Event Type</Form.Label>
                            <Select
                                name="type"
                                options={eventTypes.map((type) => ({
                                    label: type,
                                    value: type
                                }))}
                                placeholder="Select Event Type"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'type')
                                }
                                value={{
                                    label: event.type,
                                    value: event.type
                                }}
                                ref={selectRef}
                                style={
                                    event.type
                                        ? {
                                              backgroundColor: '#FFF',
                                              fontWeight: 'bold'
                                          }
                                        : {}
                                }
                            />
                            <input
                                tabIndex={-1}
                                autoComplete="off"
                                style={{
                                    opacity: 0,
                                    width: '100%',
                                    height: 0,
                                    position: 'absolute'
                                }}
                                onFocus={() => {
                                    if (selectRef && selectRef.current)
                                        selectRef.current.focus();
                                }}
                                value={event.type ? event.type : ''}
                                onChange={() => {
                                    console.log('Changed');
                                }}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="required">
                            <Form.Label>Event Tags</Form.Label>
                            <CreatableSelect
                                name="tags"
                                options={tags.map((tag) => ({
                                    label: tag.title,
                                    value: tag._id
                                }))}
                                value={event.tags}
                                placeholder="Select Event Tags"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'tags')
                                }
                                style={{
                                    backgroundColor: event.tags.length
                                        ? '#FFF'
                                        : '#FAFBFC'
                                }}
                                isMulti
                            />
                            <input
                                tabIndex={-1}
                                autoComplete="off"
                                style={{
                                    opacity: 0,
                                    width: '100%',
                                    height: 0,
                                    position: 'absolute'
                                }}
                                onFocus={() => {
                                    if (
                                        multiSelectRef &&
                                        multiSelectRef.current
                                    )
                                        multiSelectRef.current.focus();
                                }}
                                value={event.tags ? event.tags : ''}
                                onChange={() => {
                                    console.log('Changed');
                                }}
                                required
                            />
                        </Form.Group>
                        <Form.Group>
                            <div
                                className="d-flex align-items-start"
                                style={{
                                    justifyContent: 'space-between',
                                    alignContent: 'center'
                                }}
                            >
                                <Form.Label>Zoom Meeting Link</Form.Label>
                                <button
                                    type="button"
                                    id="edit-button"
                                    style={{
                                        border: 0,
                                        backgroundColor: '#fff'
                                    }}
                                    onClick={() => {
                                        if (editZoomLink) setNewZoomLink('');
                                        setEditZoomLink(!editZoomLink);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        color={color.theme}
                                        size="sm"
                                        icon={editZoomLink ? faUndo : faEdit}
                                    />
                                </button>
                            </div>
                            <Form.Control
                                name="zoomLink"
                                type="text"
                                disabled={!editZoomLink}
                                style={
                                    event.title
                                        ? {
                                              backgroundColor: '#FFF',
                                              fontWeight: editZoomLink
                                                  ? 'bold'
                                                  : 500,
                                              cursor: editZoomLink
                                                  ? 'default'
                                                  : 'not-allowed'
                                          }
                                        : {}
                                }
                                maxLength={50}
                                value={
                                    editZoomLink
                                        ? newZoomLink
                                        : event.zoom?.joinUrl
                                }
                                onChange={(
                                    changeEvent: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setNewZoomLink(changeEvent.target.value);
                                }}
                                placeholder={
                                    editZoomLink
                                        ? 'Enter Zoom Link here'
                                        : 'https://zoom.us/*********?pwd=****'
                                }
                            />
                            {event.zoom?.id ? (
                                <Form.Text className="text-muted">
                                    This is auto generated by default.
                                </Form.Text>
                            ) : (
                                <Form.Text className="text-muted">
                                    {editZoomLink
                                        ? 'To Undo press the undo button.'
                                        : 'To Edit press the edit button.'}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Row className="align-items-center mb-3 required">
                            <Form.Label>Upload Banner</Form.Label>
                            <Col xs={12}>
                                <UploadImage
                                    resetImage={setImage}
                                    src={thumb.src as string}
                                />
                            </Col>
                        </Form.Row>

                        <Form.Group controlId="formReceiveCheckbox">
                            <Form.Check
                                type="checkbox"
                                name="receiveEmails"
                                label="Receive Email Notification when there's a new participant on the Event."
                                checked={event.receiveEmails}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formSendCheckbox">
                            <Form.Check
                                type="checkbox"
                                name="sendEmails"
                                label="Send Email Notification to registered participants upon update."
                                checked={sendEmails}
                                onChange={(e) => {
                                    setSendEmails(e.target.checked);
                                }}
                            />
                        </Form.Group>
                    </div>
                    <div>
                        <EventButton className="col" type="submit">
                            Submit
                        </EventButton>
                    </div>
                </FormContent>
            </Form>
            {loading && <EventLoading />}
            {loading && <Backdrop />}
        </Container>
    );
}

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(EditEvent));
