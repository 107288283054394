/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React from 'react';
import styled from 'styled-components';
import { Card, ListGroup } from 'react-bootstrap';
import OpportunitiesItem from './OpportunitiesItem';
import IVolunteerOpportunity from '../../interfaces/IVolunteerOpportunity';

const Styles = styled.div`
    max-height: 20rem;
    overflow-y: auto;

    .card-body {
        padding: 0;
    }
`;

const OpportunitiesList: React.FC<{
    entries: IVolunteerOpportunity[];
    userOrgs: string[];
    onItemClick?: (opportunity: IVolunteerOpportunity) => void;
}> = ({ entries, userOrgs, onItemClick }) => (
    <Styles>
        <Card>
            <Card.Body>
                <ListGroup variant="flush">
                    {entries.map((item) => (
                        <OpportunitiesItem
                            key={item._id}
                            opportunity={item}
                            onClick={onItemClick}
                            userOrgs={userOrgs}
                        />
                    ))}
                </ListGroup>
            </Card.Body>
        </Card>
    </Styles>
);

export default OpportunitiesList;
