import axios from 'axios';
import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Ecosystem, JoinAs } from '../../interfaces/Ecosystem';
import JoinEcosystemModal from './ecosystem/JoinEcosystemModal';

const StyledForm = styled(Form)`
    .form-row {
        margin-left: 1px;
    }

    label.form-label {
        color: #6c757d;
        font-size: 14px;
    }

    input.form-control {
        width: 50%;
        background: #ffffff;
        border: 1px solid #ced4da;
        border-radius: 3px;
        padding: 9px 12px;
        margin-right: 14px;

        &::placeholder {
            color: #6c757d;
        }
    }

    .btn.btn-primary {
        padding: 8px 38px;
        font-weight: 600;
    }
`;

interface Props {
    joinAs: JoinAs;
    profileId: string;
}

const JoinEcosystem: React.FC<Props> = ({ profileId, joinAs }) => {
    const [verificationCode, setVerificationCode] = useState<string>('');
    const [ecosystem, setEcosystem] = useState<Ecosystem>({} as Ecosystem);
    const [error, setError] = useState<string>('');
    const [show, setShow] = useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        axios
            .get<Ecosystem>(`/api/ecosystems/verification/${verificationCode}`)
            .then(({ data }) => {
                setEcosystem(data);
                setShow(true);
            })
            .catch((err) => setError(err.response.data.message));

        setError('');
    };

    return (
        <>
            <p className="mt-3 mb-0">
                <i>
                    {joinAs === JoinAs.USER
                        ? 'Join an Ecosystem by entering the code here'
                        : 'Add an Ecosystem that this Organization is affiliated with, or is a part of.'}
                </i>
            </p>
            <>
                <StyledForm onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Label htmlFor="verificationCode">
                            Verification Code
                        </Form.Label>
                    </Form.Row>
                    <Form.Row>
                        <Form.Control
                            type="text"
                            id="verificationCode"
                            aria-describedby="verificationCode"
                            placeholder="Code"
                            onChange={(e) =>
                                setVerificationCode(e.target.value)
                            }
                            value={verificationCode}
                        />
                        <Button type="submit" className="mb-3">
                            Add
                        </Button>
                    </Form.Row>
                </StyledForm>
                {error && <p>{error}</p>}
            </>
            <JoinEcosystemModal
                show={show}
                ecosystem={ecosystem}
                handleClose={() => setShow(false)}
                verificationCode={verificationCode}
                joinAs={joinAs}
                profileId={profileId}
            />
        </>
    );
};

export default JoinEcosystem;
