import React, { ReactElement, useEffect, useState } from 'react';
import {
    Calendar,
    dateFnsLocalizer,
    ToolbarProps,
    NavigateAction,
    View
} from 'react-big-calendar';
import {
    format,
    parse,
    startOfWeek,
    getDay,
    startOfMonth,
    endOfWeek,
    endOfMonth,
    getMonth
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components';
import axios from 'axios';
import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { blankEvent, IEvent } from '../../interfaces/IEvent';
import { useEventTracking } from '../../pages/eventTracking';

const locales = {
    'en-US': enUS
};

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales
});

const formats = {
    dateFormat: 'd',
    weekdayFormat: 'E'
};

const eventStyles = [
    {
        backgroundColor: 'rgba(233, 81, 191, 0.2)',
        color: '#E951BF',
        bar: '#E951BF',
        borderLeft: '0.25rem solid #E951BF'
    },
    {
        backgroundColor: 'rgba(117, 81, 233, 0.2)',
        color: '#7551E9',
        bar: '#7551E9',
        borderLeft: '0.25rem solid #7551E9'
    },
    {
        backgroundColor: 'rgba(255, 158, 88, 0.2)',
        color: 'rgba(255, 158, 88, 1)',
        bar: 'rgba(255, 158, 88, 1)',
        borderLeft: '0.25rem solid rgba(255, 158, 88, 1)'
    },
    {
        backgroundColor: 'rgba(81, 111, 233, 0.2)',
        color: 'rgba(81, 111, 233, 1)',
        bar: 'rgba(81, 111, 233, 1)',
        borderLeft: '0.25rem solid rgba(81, 111, 233, 1)'
    }
];

const CalendarContent = styled.div`
    height: 100%;

    h4 {
        margin: 0;
    }

    .rbc-month-view {
        padding: 2rem;
        border: 0;
    }

    .rbc-month-row:last-child {
        border-bottom: 0.3px solid rgba(63, 63, 63, 0.22);
    }

    .rbc-header {
        padding: 0.5rem 0 1rem 0.5rem;
        font-size: 0.75rem;
        text-transform: uppercase;
        color: #8b9bb6;
        border-left: 0 !important;
    }

    .rbc-month-header {
        display: flex;
        justify-content: space-between;
    }

    .rbc-off-range-bg {
        background-color: #fff;
        background-image: url('${process.env
            .PUBLIC_URL}/assets/interseed_calendar_bg.svg');
    }

    .rbc-month-bg:first-child {
        border-top: 0.3px solid rgba(63, 63, 63, 0.22);
    }

    .rbc-month-bg {
        border: 0.3px solid rgba(63, 63, 63, 0.22);
    }

    .rbc-row-content {
        display: flex;
        // flex-direction: column;
        flex-direction: column-reverse;

        border-left: 0.3px solid rgba(63, 63, 63, 0.22);
        border-right: 0.3px solid rgba(63, 63, 63, 0.22);
        justify-content: flex-end;
        height: 100%;
    }

    .rbc-row-content > .rbc-row:first-child {
        margin-bottom: auto;
        order: 99;
    }

    .rbc-show-more {
        background-color: rgba(255, 158, 88, 0.2);
        color: rgba(255, 158, 88, 1);
        border-left: 0.25rem solid rgba(255, 158, 88, 1);
        font-size: 0.75rem;
        line-height: 1rem;
        text-align: left;
        text-decoration: none;
        display: block;
        width: fit-content;
    }

    .rbc-event-content {
        text-align: right;
    }

    .rbc-day-bg {
        border-top: 0;
        border-bottom: 0;
    }

    .rbc-date-cell {
        font-size: 0.5rem;
    }

    .rbc-event {
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
`;

const CalendarHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    padding-right: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .btn {
        padding: 0;
    }

    .action-icon {
        height: 1.25rem;
        width: 1.25rem;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .btn-action {
        padding: 0 0.5rem;
    }

    button:hover {
        background-color: #f3f3f3;
    }

    .header-actions {
        display: flex;
        align-items: center;
    }
`;

function CustomToolbar(toolbar: ToolbarProps): ReactElement {
    const goToBack = () => {
        // const newDate = subMonths(new Date(toolbar.date), 1);
        toolbar.onNavigate('PREV', toolbar.date);
    };

    const goToNext = () => {
        // const newDate = addMonths(new Date(toolbar.date), 1);
        toolbar.onNavigate('NEXT', toolbar.date);
    };

    const month = format(new Date(toolbar.date), 'MMMM ');
    const year = format(new Date(toolbar.date), 'yyyy');

    return (
        <CalendarHeader>
            <div className="h3 text-left p-3">
                {month}
                <span style={{ color: '#8B9BB6' }}>{year}</span>
            </div>
            <div className="header-actions">
                <button
                    type="button"
                    className="btn btn-action px-3"
                    onClick={goToBack}
                >
                    <FontAwesomeIcon
                        color="gray"
                        size="lg"
                        icon={faChevronLeft}
                    />
                </button>
                <button
                    type="button"
                    className="btn btn-action px-3"
                    onClick={goToNext}
                >
                    <FontAwesomeIcon
                        color="gray"
                        size="lg"
                        icon={faChevronRight}
                    />
                </button>
            </div>
        </CalendarHeader>
    );
}

interface EventCalendarProps {
    setViewEvent: (val: boolean) => void;
    setSelectedEvent: (val: string) => void;
}

export default function EventCalendar({
    setViewEvent,
    setSelectedEvent
}: EventCalendarProps): ReactElement {
    const [events, setEvents] = useState<IEvent[]>([blankEvent]);
    const [moreEvents, setMoreEvents] = useState<IEvent[]>([blankEvent]);
    const [oldDate, setOldDate] = useState(new Date());
    const formattedEvents = events.map((event, index) => ({
        ...event,
        index,
        title: event.title,
        start: new Date(event.startTime),
        end: new Date(event.endTime)
    }));

    const eventRenderProps = (event, start, end, isSelected) => {
        const result = {
            style: {
                borderRadius: 0,
                ...eventStyles[event.index % eventStyles.length],
                backgroundImage: `url('${process.env.PUBLIC_URL}/assets/interseed_calendar_bg.svg')`,
                backgroundSize: 'contain'
            },
            className: 'font-weight-bold event-content'
        };
        return result;
    };

    const onSelectEvent = (event) => {
        const { index, start, end, ...eventInput } = event;
        useEventTracking(
            'Events',
            'Clicked View Event from Calendar',
            `${event.title}`
        );
        setSelectedEvent(eventInput._id);
        setViewEvent(true);
    };

    const onNavigate = (newDate: Date, view: View, action: NavigateAction) => {
        if (getMonth(newDate) === getMonth(oldDate)) return;
        setOldDate(newDate);
        const start = startOfWeek(startOfMonth(newDate));
        const end = endOfWeek(endOfMonth(newDate));
        axios
            .get(
                `api/events/filtered?filter=date&startDateRange=${start.toISOString()}&endDateRange=${end.toISOString()}&participantLimit=0&approved=true&rejected=false`
            )
            .then((res) => {
                setEvents(res.data);
            });
    };

    useEffect(() => {
        const curr = new Date();
        const start = startOfWeek(startOfMonth(curr));
        const end = endOfWeek(endOfMonth(curr));

        axios
            .get(
                `api/events/filtered?filter=date&startDateRange=${start.toISOString()}&endDateRange=${end.toISOString()}&participantLimit=0&approved=true&rejected=false`
            )
            .then((res) => {
                setEvents(res.data);
            });
    }, []);

    const onShowMore = (eventsOnShowMore) => {
        setMoreEvents(eventsOnShowMore);
    };

    return (
        <CalendarContent>
            <Calendar
                defaultDate={new Date()}
                events={formattedEvents}
                views={['month']}
                components={{
                    toolbar: CustomToolbar
                }}
                eventPropGetter={eventRenderProps}
                showMultiDayTimes
                localizer={localizer}
                formats={formats}
                onSelectEvent={onSelectEvent}
                onNavigate={onNavigate}
                messages={{
                    // @ts-ignore
                    showMore: (target) => (
                        <>
                            <OverlayTrigger
                                trigger="click"
                                key="bottom"
                                placement="bottom"
                                rootClose
                                overlay={
                                    <Popover id={`bottom-popover-${target}`}>
                                        <div>
                                            <ListGroup>
                                                {moreEvents
                                                    .slice(
                                                        moreEvents.length -
                                                            target
                                                    )
                                                    .map((event: IEvent) => (
                                                        <ListGroup.Item
                                                            onClick={() => {
                                                                document.body.click();
                                                                onSelectEvent(
                                                                    event
                                                                );
                                                            }}
                                                            action
                                                            className="text-truncate font-weight-bold"
                                                        >
                                                            {event.title}
                                                        </ListGroup.Item>
                                                    ))}
                                            </ListGroup>
                                        </div>
                                    </Popover>
                                }
                            >
                                <span className="pl-1 px-1" role="presentation">
                                    +{target}
                                </span>
                            </OverlayTrigger>
                        </>
                    )
                }}
                onShowMore={onShowMore}
            />
        </CalendarContent>
    );
}
