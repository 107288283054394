/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
    faCalendarCheck,
    faCalendarDay,
    faChalkboard,
    faEnvelope,
    faGlobe,
    faMapMarkerAlt,
    faSitemap,
    faUser,
    faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Container,
    Image,
    Nav,
    Row,
    Tab
} from 'react-bootstrap';
import Helmet from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import {
    Link,
    RouteComponentProps,
    useParams,
    withRouter
} from 'react-router-dom';
import styled from 'styled-components';
import BackButton from '../components/backbuttons/BackButton';
import BlockerModal from '../components/BlockerModal';
import EcosystemList from '../components/community/EcosystemList';
import JoinEcosystem from '../components/community/JoinEcosystem';
import OrganisationItem from '../components/community/OrganisationItem';
import UserItem from '../components/community/UserItem';
import EventProfileContent from '../components/events/EventProfileContent';
// Components
import LandscapeItem from '../components/landscapes/LandscapeItem';
import { JoinAs } from '../interfaces/Ecosystem';
import ILandscape from '../interfaces/ILandscape';
import IOrganisation from '../interfaces/IOrganisation';
// Interfaces for User Profile
import IUser, { UserBlankObj } from '../interfaces/IUser';
import { logoutUser } from '../redux/actions/AuthActions';
// Interface for Redux types
import {
    AuthState,
    StoreState,
    UserProfileState
} from '../redux/actions/types';
import { setUserProfile } from '../redux/actions/UserProfileActions';
// Common function
import getSrc from '../utils/commonFunction';
import { useEventTracking } from './eventTracking';

const ProfileStyle = styled.div`
    text-align: left;

    h3 {
        overflow: hidden;
        font-size: 13px;
        text-transform: uppercase;
    }

    h3::first-letter {
        font-size: 110%;
    }

    h4 {
        font-size: 18px;
    }

    .nav-pills {
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 10px;

        a {
            color: black;
            border-radius: 0px;
            border-bottom-width: 3px;
            border-bottom-style: solid;
            border-bottom-color: #dcdcdc;
        }

        a:hover {
            border-bottom-color: #007bff;
        }

        .active,
        .active:hover {
            color: black;
            background: none;
            border-bottom-color: #007bff;
        }
    }
`;

const LeftBarH3 = styled.h3`
    :after {
        content: '';
        display: inline-block;
        height: 0.3em;
        vertical-align: bottom;
        width: 100%;
        margin-right: -100%;
        margin-left: 10px;
        border-top: 1px solid black;
    }
`;

const NBul = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const bottomContainer = {
    flex: '1 1 0%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 10,
    marginBottom: 10
};

const NavGrid = styled(Nav)`
    @media screen and (max-width: 576px) {
        display: grid;
        gap: 0;
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 462px) {
        a {
            font-size: 12px;
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        max-height: 8.5rem;
        max-width: 8.5rem;

        @media screen and (max-width: 991px) {
            max-height: 6.5rem;
            max-width: 6.5rem;
        }
        @media screen and (max-width: 768px) {
            max-height: 8rem;
            max-width: 8rem;
        }
    }
`;

interface authProps extends RouteComponentProps {
    logoutUser: () => void;
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
    profile: IUser | undefined;
    setProfile: (_: IUser | undefined) => void;
}

interface ParamTypes {
    queryId: string | undefined;
}

// eslint-disable-next-line
const UserProfile: React.FC<authProps> = ({ ...props }) => {
    const { queryId } = useParams<ParamTypes>();

    // const profile = props.profile ?? UserBlankObj
    const [profile, setProfile] = useState<IUser>(UserBlankObj);
    const [landscapes, setLandscapes] = useState<ILandscape[]>([]);
    const [followingUsers, setFollowingUsers] = useState<IUser[]>([]);
    const [followingOrgs, setFollowingOrgs] = useState<IOrganisation[]>([]);
    const [followers, setFollowers] = useState<IUser[]>([]);
    const [organisations, setOrganisations] = useState<IOrganisation[]>([]);

    const [eventPendingLength, setEventPendingLength] = useState(0);
    const [eventOrganizedLength, setEventOrganizedLength] = useState(0);
    const [eventRegisteredLength, setEventRegisteredLength] = useState(0);

    const [initCheck, setInitCheck] = useState(false);
    // const [deleteCheck, setDeleteCheck] = useState(false);
    const [ownerCheck, setOwnerCheck] = useState(false);
    const [followCheck, setFollowCheck] = useState(false);
    const [disableFollow, setDisableFollow] = useState(true);

    const updateLandscape = (id: string, upvotes: string[]) => {
        const tempLandscapes: ILandscape[] = landscapes;
        for (let i = 0; i < landscapes.length; i += 1) {
            if (tempLandscapes[i]._id === id) {
                tempLandscapes[i].upvotes = upvotes;
                setLandscapes(tempLandscapes);
            }
        }
    };

    const category =
        `${window.location.pathname.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` ===
            'profile' && profile._id === props.auth.user._id
            ? 'My Profile'
            : 'Community';

    // const handleDelete = () => {
    //     if (deleteCheck && ownerCheck) {
    //         axios.delete(`/api/users/profiles`).then(() => {
    //             props.logoutUser();
    //             props.history.push(`/login`);
    //         });
    //     }
    // };

    const handleFollow = () => {
        if (disableFollow || ownerCheck) return;
        setDisableFollow(true);
        if (followCheck) {
            axios.delete(`/api/users/follows/${profile._id}`).then(() => {
                setFollowCheck(false);
                setDisableFollow(false);
            });
            useEventTracking(
                `${category}`,
                `Unfollowed User on Their Profile`,
                `${profile.username}`
            );
        } else {
            axios.put(`/api/users/follows/${profile._id}`).then(() => {
                setFollowCheck(true);
                setDisableFollow(false);
            });
            useEventTracking(
                `${category}`,
                `Followed User on Their Profile`,
                `${profile.username}`
            );
        }
    };

    const refreshEventsCount = () => {
        axios
            .get(`api/events/count?participants=${profile._id}`)
            .then(({ data }) => {
                setEventRegisteredLength(data);
            });
        axios
            .get(
                profile.admin
                    ? `api/events/count?approved=false&filter=current`
                    : `api/events/count?approved=false&filter=current&organizer=${profile._id}`
            )
            .then(({ data }) => {
                setEventPendingLength(data);
            });
        axios
            .get(`api/events/count?organizer=${profile._id}&approved=true`)
            .then(({ data }) => {
                setEventOrganizedLength(data);
            });
    };

    // Check if user is onboarded
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    // Check if the User profile being accessed has been onboarded
    useEffect(() => {
        if (!initCheck) return;
        if (profile.status !== 'Active') props.history.push('/error404');
    }, [profile]);

    // Owner Check and Landscape Init
    useEffect(() => {
        if (!queryId)
            props.history.push(`/profile/${props.auth.user.username}`);
        if (initCheck) {
            if (profile._id === props.auth.user._id) {
                setOwnerCheck(true);
            } else if (props.auth.user._id) {
                for (let i = 0; i < profile.followers.length; i += 1) {
                    if (profile.followers[i] === props.auth.user._id) {
                        setFollowCheck(true);
                        break;
                    }
                }
                setDisableFollow(false);
            }
            axios.get(`/api/landscapes/`).then(({ data }) => {
                const tempList: ILandscape[] = [];
                for (let i = 0; i < data.length; i += 1) {
                    if (data[i].author._id === profile._id) {
                        tempList.unshift(data[i]);
                    }
                }
                setLandscapes(tempList);
            });
            refreshEventsCount();
            axios.get(`/api/users/`).then(({ data }) => {
                // tempList stores following user, tempList 2 stores followers
                const tempList: IUser[] = [];
                const tempList2: IUser[] = [];
                // For every person in Interseed
                for (let i = 0; i < data.length; i += 1) {
                    // For each follower each person has
                    for (let j = 0; j < data[i].followers.length; j += 1) {
                        // Add that person to tempList if he is followed by the current user
                        if (data[i].followers[j] === profile._id) {
                            tempList.unshift(data[i]);
                            break;
                        }
                    }
                    // For each following each person has
                    for (let k = 0; k < data[i].following.length; k += 1) {
                        // Add that person to tempList2 if he is following the current user
                        if (data[i].following[k] === profile._id) {
                            tempList2.unshift(data[i]);
                            break;
                        }
                    }
                }
                setFollowingUsers(tempList);
                setFollowers(tempList2);
            });
            axios.get(`/api/organisations/`).then(({ data }) => {
                // Check if own organisation
                const tempList: IOrganisation[] = [];
                for (let i = 0; i < data.length; i += 1) {
                    for (let j = 0; j < profile.organisation.length; j += 1) {
                        if (data[i]._id === profile.organisation[j]._id) {
                            tempList.unshift(data[i]);
                            break;
                        }
                    }
                }
                setOrganisations(tempList);

                // Check if following organisation
                const tempList2: IOrganisation[] = [];
                for (let i = 0; i < data.length; i += 1) {
                    for (let j = 0; j < profile.followingOrgs.length; j += 1) {
                        if (data[i]._id === profile.followingOrgs[j]) {
                            tempList2.unshift(data[i]);
                            break;
                        }
                    }
                }
                setFollowingOrgs(tempList2);
            });
        } else {
            axios.get(`/api/users/profiles/${queryId}`).then(({ data }) => {
                setProfile(data);
                useEventTracking(
                    'Community',
                    `Viewed User Profile`,
                    `${queryId}`
                );
            });
            setInitCheck(true);
        }
    }, [profile]);

    const ogMetaTitle = `${profile.firstName} ${profile.lastName}'s Profile`;

    return (
        <ProfileStyle className="font-arimo">
            <Container>
                <Helmet>
                    <meta
                        name="title"
                        property="og:title"
                        content={ogMetaTitle}
                    />
                    <meta property="og:type" content="profile" />
                    <meta
                        property="profile:first_name"
                        content={profile.firstName}
                    />
                    <meta
                        property="profile:last_name"
                        content={profile.lastName}
                    />
                    <meta
                        property="profile:username"
                        content={profile.username}
                    />
                    <meta
                        name="image"
                        property="og:image"
                        content={getSrc(profile)}
                    />
                    <meta
                        name="description"
                        property="og:description"
                        content={profile.aboutMyself}
                    />
                    <meta property="og:site_name" content="Interseed" />
                </Helmet>
                {props.auth.isAuthenticated ? null : (
                    <BlockerModal history={props.history} />
                )}
                <BackButton />
                <Card className="p-4 mb-4">
                    <Row>
                        <Col sm={3} className="text-center">
                            <Wrapper>
                                <Image
                                    className="profile-picture mb-4"
                                    src={getSrc(profile)}
                                    alt="Profile"
                                    roundedCircle
                                    thumbnail
                                    fluid
                                />
                            </Wrapper>
                            <div className="mb-4">
                                <h1>
                                    {profile.firstName || <Skeleton />}{' '}
                                    {profile.lastName}
                                </h1>
                            </div>
                            <div>
                                <p>{profile.currentRole}</p>
                            </div>
                            <div>
                                {profile.country ? (
                                    <p className="mt-2">
                                        <FontAwesomeIcon
                                            icon={faMapMarkerAlt}
                                        />{' '}
                                        {profile.city
                                            ? `${profile.city}, `
                                            : null}
                                        {profile.country}
                                    </p>
                                ) : null}
                            </div>
                            <div>
                                <p>
                                    <b>{followers.length}</b> Followers
                                </p>
                            </div>
                            {!ownerCheck && initCheck ? (
                                <>
                                    {props.auth.user._id ? (
                                        <Button
                                            variant={
                                                !followCheck
                                                    ? 'outline-primary'
                                                    : 'primary'
                                            }
                                            className="mb-3"
                                            onClick={(ev) => {
                                                handleFollow();
                                                ev.preventDefault();
                                            }}
                                            disabled={disableFollow}
                                        >
                                            {!followCheck
                                                ? 'Follow'
                                                : 'Unfollow'}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="outline-primary"
                                            className="mb-3"
                                            as={Link}
                                            to="/login"
                                        >
                                            Follow
                                        </Button>
                                    )}
                                </>
                            ) : null}
                            <Col sm={12} className="text-left">
                                <LeftBarH3>Skills</LeftBarH3>
                                <NBul>
                                    {profile.skillsets.sort().map((skill) => (
                                        <li>{skill}</li>
                                    ))}
                                </NBul>
                            </Col>
                            <Col sm={12} className="text-left">
                                <LeftBarH3>Interests</LeftBarH3>
                                <NBul>
                                    {profile.interestAreas
                                        .sort()
                                        .map((interest) => (
                                            <li>{interest}</li>
                                        ))}
                                </NBul>
                            </Col>
                        </Col>
                        <Col sm={9}>
                            <br />
                            <Tab.Container
                                id="profile-tabs"
                                defaultActiveKey="about"
                            >
                                {/* <br /> */}
                                <NavGrid
                                    variant="pills"
                                    title="About"
                                    className="mb-4"
                                >
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="about"
                                            onClick={() => {
                                                useEventTracking(
                                                    `${category}`,
                                                    'Clicked About on User Profile',
                                                    `${profile.username}`
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faUser}
                                                className="mr-2"
                                            />{' '}
                                            About
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="organisations"
                                            onClick={() => {
                                                useEventTracking(
                                                    `${category}`,
                                                    'Clicked Organisations on User Profile',
                                                    `${profile.username}`
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faSitemap}
                                                className="mr-2"
                                            />{' '}
                                            Organisations (
                                            {profile.organisation.length})
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="following"
                                            onClick={() => {
                                                useEventTracking(
                                                    `${category}`,
                                                    'Clicked Following on User Profile',
                                                    `${profile.username}`
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faUserFriends}
                                                className="mr-2"
                                            />{' '}
                                            Following (
                                            {profile.following.length +
                                                profile.followingOrgs.length}
                                            )
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="followers">
                                            <FontAwesomeIcon
                                                icon={faUserFriends}
                                                className="mr-2"
                                            />{' '}
                                            Followers ({followers.length})
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="landscapes"
                                            onClick={() => {
                                                useEventTracking(
                                                    `${category}`,
                                                    'Clicked Landscapes on User Profile',
                                                    `${profile.username}`
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faChalkboard}
                                                className="mr-2"
                                            />{' '}
                                            Landscapes ({landscapes.length})
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="events">
                                            <FontAwesomeIcon
                                                icon={faCalendarDay}
                                                className="mr-2"
                                            />{' '}
                                            Events (
                                            {eventOrganizedLength +
                                                (props.auth.user._id ===
                                                profile._id
                                                    ? eventPendingLength
                                                    : 0) +
                                                eventRegisteredLength}
                                            )
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="ecosystems">
                                            <div className="d-flex justify-content-center">
                                                <img
                                                    alt="ecosystem"
                                                    src={`${process.env.PUBLIC_URL}/assets/interseed_ecosystem.svg`}
                                                    className="d-inline-block mr-2"
                                                />
                                                Ecosystems (
                                                {profile.ecosystems?.length ??
                                                    0}
                                                )
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </NavGrid>
                                <Tab.Content>
                                    <Tab.Pane eventKey="about">
                                        <Row>
                                            <Col sm={12}>
                                                <h3>About Me</h3>
                                                <p className="allow-whitespace">
                                                    {profile.aboutMyself}
                                                </p>
                                                <hr />
                                            </Col>
                                            <Col sm={6}>
                                                <div className="mb-4">
                                                    <h3>Looking For</h3>
                                                    <NBul>
                                                        {profile.lookingFor
                                                            .sort()
                                                            .map((looking) => (
                                                                <li>
                                                                    {looking}
                                                                </li>
                                                            ))}
                                                    </NBul>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div>
                                                    <h3>Involvements</h3>
                                                    <NBul>
                                                        {profile.sustainabilityInvolvement.map(
                                                            (involvement) => (
                                                                <li>
                                                                    {
                                                                        involvement
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                    </NBul>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div>
                                                    <h3>Contact</h3>
                                                    <Row>
                                                        <Col
                                                            xs={1}
                                                            className="text-center"
                                                        >
                                                            <NBul>
                                                                {profile.website ? (
                                                                    <li>
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faGlobe
                                                                            }
                                                                        />
                                                                    </li>
                                                                ) : null}
                                                                {profile.linkedin ? (
                                                                    <li>
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faLinkedin
                                                                            }
                                                                        />
                                                                    </li>
                                                                ) : null}
                                                                {profile.email ? (
                                                                    <li>
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faEnvelope
                                                                            }
                                                                        />
                                                                    </li>
                                                                ) : null}
                                                            </NBul>
                                                        </Col>
                                                        <Col>
                                                            <NBul>
                                                                {profile.website ? (
                                                                    <li>
                                                                        <a
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            href={`http://${profile.website}`}
                                                                        >
                                                                            Website
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {profile.linkedin ? (
                                                                    <li>
                                                                        <a
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            href={
                                                                                profile.linkedin
                                                                            }
                                                                        >
                                                                            LinkedIn
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                                {profile.email ? (
                                                                    <li>
                                                                        <a
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            href={`mailto:${profile.email}`}
                                                                            onClick={() => {
                                                                                useEventTracking(
                                                                                    'Community',
                                                                                    'Emailed User',
                                                                                    `${profile.username}`
                                                                                );
                                                                            }}
                                                                        >
                                                                            Email
                                                                        </a>
                                                                    </li>
                                                                ) : null}
                                                            </NBul>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* {ownerCheck ? (
                                            <div className="text-right mt-2">
                                                {!deleteCheck ? (
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() =>
                                                            setDeleteCheck(true)
                                                        }
                                                    >
                                                        Delete Account
                                                    </Button>
                                                ) : (
                                                    <div>
                                                        <p>
                                                            Are you very sure?
                                                            This cannot be
                                                            undone.
                                                        </p>
                                                        <p>
                                                            If you don&apos;t
                                                            transfer ownership
                                                            of currently owned
                                                            organisations, they
                                                            will be deleted as
                                                            well.
                                                        </p>
                                                        <Button
                                                            variant="light mr-1"
                                                            size="sm"
                                                            onClick={() =>
                                                                setDeleteCheck(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={
                                                                handleDelete
                                                            }
                                                        >
                                                            Confirm Delete
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null} */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="organisations">
                                        <h3>Organisations</h3>
                                        {organisations.length === 0 ? (
                                            <p className="mt-3">
                                                <i>No organisations found</i>
                                            </p>
                                        ) : null}
                                        {ownerCheck ? (
                                            <Button
                                                variant="primary"
                                                className="mb-2"
                                                as={Link}
                                                to="/createorganisation"
                                                onClick={() => {
                                                    useEventTracking(
                                                        'My Profile',
                                                        'Clicked Create Organisation on User Profile',
                                                        `${profile.username}`
                                                    );
                                                }}
                                            >
                                                Create Organisation
                                            </Button>
                                        ) : null}
                                        {organisations.map(
                                            (organisation, i) => (
                                                <div
                                                    className={
                                                        organisations[i + 1]
                                                            ? 'list-item-border'
                                                            : ''
                                                    }
                                                >
                                                    <OrganisationItem
                                                        key={organisation._id}
                                                        authUsername={
                                                            props.auth.user
                                                                .username
                                                        }
                                                        organisation={
                                                            organisation
                                                        }
                                                        authId={
                                                            props.auth.user._id
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="following">
                                        <Tab.Container
                                            id="following-tabs"
                                            defaultActiveKey="following-users"
                                        >
                                            <Nav
                                                variant="pills"
                                                title="About"
                                                className="mb-4"
                                            >
                                                <Nav.Item>
                                                    <Nav.Link
                                                        eventKey="following-users"
                                                        onClick={() => {
                                                            useEventTracking(
                                                                `${category}`,
                                                                'Clicked Following: Users on User Profile',
                                                                `${profile.username}`
                                                            );
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faUserFriends}
                                                        />{' '}
                                                        Users (
                                                        {
                                                            profile.following
                                                                .length
                                                        }
                                                        )
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link
                                                        eventKey="following-organisations"
                                                        onClick={() => {
                                                            useEventTracking(
                                                                `${category}`,
                                                                'Clicked Following: Organisations on User Profile',
                                                                `${profile.username}`
                                                            );
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faSitemap}
                                                        />{' '}
                                                        Organisations (
                                                        {
                                                            profile
                                                                .followingOrgs
                                                                .length
                                                        }
                                                        )
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="following-users">
                                                    {followingUsers.length ===
                                                    0 ? (
                                                        <p className="mt-3">
                                                            <i>
                                                                No users found
                                                            </i>
                                                        </p>
                                                    ) : null}
                                                    {followingUsers.map(
                                                        (following) => (
                                                            <UserItem
                                                                key={
                                                                    following._id
                                                                }
                                                                authUsername={
                                                                    props.auth
                                                                        .user
                                                                        .username
                                                                }
                                                                user={following}
                                                                authId={
                                                                    props.auth
                                                                        .user
                                                                        ._id
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="following-organisations">
                                                    {followingOrgs.length ===
                                                    0 ? (
                                                        <p className="mt-3">
                                                            <i>
                                                                No organisations
                                                                found
                                                            </i>
                                                        </p>
                                                    ) : null}
                                                    {followingOrgs.map(
                                                        (following) => (
                                                            <OrganisationItem
                                                                key={
                                                                    following._id
                                                                }
                                                                authUsername={
                                                                    props.auth
                                                                        .user
                                                                        .username
                                                                }
                                                                organisation={
                                                                    following
                                                                }
                                                                authId={
                                                                    props.auth
                                                                        .user
                                                                        ._id
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="followers">
                                        <h3>Followers</h3>
                                        {followers.length === 0 ? (
                                            <p className="mt-3">
                                                <i>No users found</i>
                                            </p>
                                        ) : null}
                                        {followers.map((follower) => (
                                            <UserItem
                                                key={follower._id}
                                                authUsername={
                                                    props.auth.user.username
                                                }
                                                user={follower}
                                                authId={props.auth.user._id}
                                            />
                                        ))}
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="landscapes">
                                        <h3>Landscapes</h3>
                                        {landscapes.length === 0 ? (
                                            <p className="mt-3">
                                                <i>No landscapes found</i>
                                            </p>
                                        ) : null}
                                        {landscapes.map((item) => (
                                            <LandscapeItem
                                                key={item._id}
                                                landscape={item}
                                                authId={props.auth.user._id}
                                                updateLandscape={
                                                    updateLandscape
                                                }
                                            />
                                        ))}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="events">
                                        <Tab.Container
                                            id="organized-events"
                                            defaultActiveKey="registered-events"
                                        >
                                            <Nav
                                                variant="pills"
                                                title="Organized Events"
                                                className="mb-4"
                                            >
                                                <Nav.Item>
                                                    <Nav.Link eventKey="registered-events">
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faCalendarCheck
                                                            }
                                                        />{' '}
                                                        Registered (
                                                        {eventRegisteredLength})
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="organized-events">
                                                        <FontAwesomeIcon
                                                            icon={faSitemap}
                                                        />{' '}
                                                        Organized (
                                                        {eventOrganizedLength})
                                                    </Nav.Link>
                                                </Nav.Item>
                                                {props.auth.user._id ===
                                                    profile._id &&
                                                    eventPendingLength !==
                                                        0 && (
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="pending-for-approval-events">
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faSitemap
                                                                    }
                                                                />{' '}
                                                                Pending for
                                                                Approval (
                                                                {
                                                                    eventPendingLength
                                                                }
                                                                )
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    )}
                                            </Nav>
                                            {profile._id && (
                                                <EventProfileContent
                                                    key={profile._id}
                                                    eventPendingLength={
                                                        eventPendingLength
                                                    }
                                                    eventRegisteredLength={
                                                        eventRegisteredLength
                                                    }
                                                    eventOrganizedLength={
                                                        eventOrganizedLength
                                                    }
                                                    currentUser={
                                                        props.userProfile
                                                            ?.userProfile
                                                    }
                                                    profile={profile}
                                                    auth={props.auth}
                                                    refreshEventsCount={
                                                        refreshEventsCount
                                                    }
                                                />
                                            )}
                                        </Tab.Container>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="ecosystems">
                                        <h3>Ecosystems</h3>
                                        <JoinEcosystem
                                            profileId={profile._id}
                                            joinAs={JoinAs.USER}
                                        />
                                        {profile.ecosystems.length >= 1 && (
                                            <EcosystemList
                                                auth={props.auth}
                                                ecosystems={profile.ecosystems}
                                            />
                                        )}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                    <Row style={bottomContainer} className="mt-4">
                        {ownerCheck ? (
                            <Link
                                to="/editprofile"
                                aria-label="Edit Profile"
                                onClick={() => {
                                    useEventTracking(
                                        'My Profile',
                                        'Clicked Edit on User Profile',
                                        `${profile.username}`
                                    );
                                }}
                            >
                                <Button>Edit Profile</Button>
                            </Link>
                        ) : null}
                    </Row>
                </Card>
            </Container>
        </ProfileStyle>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    profile: state.userProfile.userProfile,
    userProfile: state.userProfile
});

export default connect(mapStateToProps, {
    logoutUser,
    setProfile: setUserProfile
})(withRouter(UserProfile));
