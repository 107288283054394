/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import {
    faFacebook,
    faLinkedin,
    faTwitter
} from '@fortawesome/free-brands-svg-icons';
import {
    faBirthdayCake,
    faEnvelope,
    faGlobe,
    faMapMarkedAlt,
    faMapMarkerAlt,
    faPhoneSquareAlt,
    faUser,
    faUserFriends,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    Image,
    Nav,
    Row,
    Tab
} from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import {
    Link,
    RouteComponentProps,
    useParams,
    withRouter
} from 'react-router-dom';
import styled from 'styled-components';
import BackButton from '../components/backbuttons/BackButton';
import BlockerModal from '../components/BlockerModal';
import UserItem from '../components/community/UserItem';
import EcosystemTabContent from '../components/organisations/EcosystemTabContent';
// Components
import StaffItem from '../components/organisations/StaffItem';
import useMediaQuery from '../hooks/useMediaQuery';
// Interfaces
import IOrganisation, { orgBlankObj } from '../interfaces/IOrganisation';
import IStaff from '../interfaces/IStaff';
import { AuthState, StoreState } from '../redux/actions/types';
import { useEventTracking } from './eventTracking';

const ProfileStyle = styled.div`
    text-align: left;
    h3 {
        overflow: hidden;
        font-size: 13px;
        text-transform: uppercase;
    }
    h3::first-letter {
        font-size: 110%;
    }
    h4 {
        font-size: 18px;
    }
    .nav-pills {
        font-size: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
        a {
            color: black;
            border-radius: 0px;
            border-bottom-width: 3px;
            border-bottom-style: solid;
            border-bottom-color: #dcdcdc;
        }
        a:hover {
            border-bottom-color: #007bff;
        }
        .active,
        .active:hover {
            color: black;
            background: none;
            border-bottom-color: #007bff;
        }
    }
`;

const LeftBarH3 = styled.h3`
    :after {
        content: '';
        display: inline-block;
        height: 0.3em;
        vertical-align: bottom;
        width: 100%;
        margin-right: -100%;
        margin-left: 10px;
        border-top: 1px solid black;
    }
`;

const NBul = styled.ul`
    list-style-type: none;
    padding: 0;
    font-size: 15px;
`;

const Wrapper = styled.div`
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        max-height: 8rem;
        max-width: 8.5rem;

        @media screen and (max-width: 991px) {
            max-height: 6.1rem;
            max-width: 6.5rem;
        }
        @media screen and (max-width: 768px) {
            max-height: 8rem;
            max-width: 8.5rem;
        }
    }
`;

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
}

interface ParamTypes {
    queryId: string;
}

interface staffObj {
    user: string;
    role: string;
}

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

const OrganisationProfile: React.FC<authProps> = ({ ...props }) => {
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const { queryId } = useParams<ParamTypes>();

    const [profile, setProfile] = useState<IOrganisation>(orgBlankObj);
    const [ownerCheck, setOwnerCheck] = useState(false);
    const [initCheck, setInitCheck] = useState(false);
    const [staffList, setStaffList] = useState<IStaff[]>([]);
    const [applicantList, setApplicantList] = useState<IStaff[]>([]);
    const [staffCheck, setStaffCheck] = useState(false);
    const [applyCheck, setApplyCheck] = useState(false);

    const [followCheck, setFollowCheck] = useState(false);
    const [disableFollow, setDisableFollow] = useState(true);

    const [deleteCheck, setDeleteCheck] = useState(false);

    const compareImpactAreas = (a: string, b: string) => {
        const aMatch: RegExpMatchArray | null = a.match(/(\d+)/);
        const bMatch: RegExpMatchArray | null = b.match(/(\d+)/);
        if (!aMatch || !bMatch) return 0;
        const aNum: number = parseInt(aMatch[0], 10);
        const bNum: number = parseInt(bMatch[0], 10);

        if (aNum > bNum) {
            return 1;
        }
        if (bNum > aNum) {
            return -1;
        }
        return 0;
    };

    const category =
        `${window.location.pathname.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` ===
            'organisation' && ownerCheck
            ? 'My Organisation'
            : 'Community';

    const handleApply = () => {
        const tempArray: staffObj[] = [];
        if (!applyCheck) {
            tempArray.push({
                user: props.auth.user._id,
                role: 'Applicant'
            });
            axios
                .post(`/api/organisations/${queryId}/staff`, {
                    staff: tempArray
                })
                .then(() => {
                    setApplyCheck(!applyCheck);
                });
        } else {
            axios
                .get(`/api/organisations/${queryId}/staff`)
                .then(({ data }) => {
                    for (let i = 0; i < data.length; i++) {
                        if (data[i].user._id === props.auth.user._id) {
                            const staffId = data[i]._id;
                            axios
                                .delete(
                                    `/api/organisations/${queryId}/staff/${staffId}`
                                )
                                .then(() => {
                                    setApplyCheck(!applyCheck);
                                });
                        }
                    }
                });
        }
    };

    const handleFollow = () => {
        if (disableFollow || ownerCheck) return;
        setDisableFollow(true);
        if (followCheck) {
            axios
                .delete(`/api/organisations/follows/${profile._id}`)
                .then(() => {
                    setFollowCheck(false);
                    setDisableFollow(false);
                });
            useEventTracking(
                `Community`,
                'Unfollowed Organisation on Organisation Profile',
                `${profile.name}`
            );
        } else {
            axios.put(`/api/organisations/follows/${profile._id}`).then(() => {
                setFollowCheck(true);
                setDisableFollow(false);
            });
            useEventTracking(
                `Community`,
                'Followed Organisation on Organisation Profile',
                `${profile.name}`
            );
        }
    };

    const handleDelete = () => {
        if (deleteCheck && ownerCheck) {
            axios
                .delete(`/api/organisations/${queryId}`)
                .then(() => {
                    window.location.replace(`/organisations`);
                })
                .catch(() => {
                    props.history.push('/error500');
                });
        }
    };

    useEffect(() => {
        axios
            .get<IOrganisation>(`/api/organisations/${queryId}`)
            .then(({ data }) => {
                setProfile(data);
            })
            .catch(() => {
                props.history.push('/error404');
            });
    }, []);

    useEffect(() => {
        if (!initCheck) {
            setInitCheck(true);
        } else {
            setApplicantList([]);
            for (let i = 0; i < profile.staff.length; i += 1) {
                if (
                    props.auth.user._id === profile.staff[i].user._id &&
                    profile.staff[i].role === 'Founder'
                )
                    setOwnerCheck(true);
                if (profile.staff[i].role === 'Applicant') {
                    const tempList = applicantList;
                    if (i === 0) {
                        // When initialising, set the tempList to be empty and put applicants one by one. This prevents duplicate when approving applicants
                        tempList.length = 0;
                    }
                    tempList.push(profile.staff[i]);
                    setApplicantList(tempList);
                    if (props.auth.user._id === profile.staff[i].user._id)
                        setApplyCheck(true);
                } else {
                    if (props.auth.user._id === profile.staff[i].user._id)
                        setStaffCheck(true);
                    const tempArray = staffList;
                    if (i === 0) {
                        // When initialising, set the tempArray to be empty and put staff one by one. This prevents duplicate when approving applicants
                        tempArray.length = 0;
                    }
                    tempArray.push(profile.staff[i]);
                    setStaffList(tempArray);
                }
            }
            if (!props.auth.user._id) return;
            for (let i = 0; i < profile.followers.length; i += 1) {
                if (profile.followers[i]._id === props.auth.user._id) {
                    setFollowCheck(true);
                    break;
                }
            }
            setDisableFollow(false);
        }
    }, [profile]);

    const ogMetaTitle = `${profile.name} Organisation Profile`;

    return (
        <ProfileStyle className="font-arimo">
            <Container>
                <Helmet>
                    <meta
                        name="title"
                        property="og:title"
                        content={ogMetaTitle}
                    />
                    <meta property="og:type" content="profile" />
                    <meta property="profile:username" content={profile.name} />
                    <meta
                        name="image"
                        property="og:image"
                        content={
                            profile.logo
                                ? profile.logo.location
                                : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                        }
                    />
                    <meta
                        name="description"
                        property="og:description"
                        content={profile.tagline}
                    />
                    <meta property="og:site_name" content="Interseed" />
                </Helmet>
                {props.auth.isAuthenticated ? null : (
                    <BlockerModal history={props.history} />
                )}
                <BackButton />
                <Card className="p-4 mb-4">
                    <Row>
                        <Col sm>
                            <Wrapper>
                                <Image
                                    className="profile-picture mb-4"
                                    src={
                                        profile.logo
                                            ? profile.logo.location
                                            : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                                    }
                                    alt="Profile"
                                    roundedCircle
                                    thumbnail
                                    fluid
                                    onError={(e) => {
                                        e.currentTarget.onerror = null; // prevents looping
                                        e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                    }}
                                />
                            </Wrapper>
                            {isSmallScreen && <h1>{profile.name}</h1>}
                            <div className="mb-4">
                                <LeftBarH3>Stage</LeftBarH3>
                                <p className="mt-2">{profile.startupStage}</p>
                            </div>
                            <div className="mb-4">
                                <LeftBarH3>Looking For</LeftBarH3>
                                <NBul>
                                    {profile.lookingFor
                                        .sort()
                                        .map((opportunities) => (
                                            <li>{opportunities}</li>
                                        ))}
                                </NBul>
                            </div>
                            <div className="mb-4">
                                <LeftBarH3>Impact Areas</LeftBarH3>
                                <NBul>
                                    {profile.impactAreas
                                        .sort(compareImpactAreas)
                                        .map((impactArea) => (
                                            <li>{impactArea}</li>
                                        ))}
                                </NBul>
                            </div>
                            <h2>
                                {profile.priorFunding ? (
                                    <Badge variant="success">
                                        Received Prior Funding
                                    </Badge>
                                ) : (
                                    <Badge variant="info">
                                        No Prior Funding
                                    </Badge>
                                )}
                            </h2>
                        </Col>
                        <Col sm={9}>
                            <Row>
                                <Col sm={6}>
                                    {!isSmallScreen && <h1>{profile.name}</h1>}
                                    <p>{profile.tagline}</p>
                                    <p>
                                        {profile.industries.map(
                                            (industry, i) => (
                                                <span key={industry}>
                                                    {industry}
                                                    {profile.industries[i + 1]
                                                        ? ', '
                                                        : ''}
                                                </span>
                                            )
                                        )}
                                    </p>
                                </Col>
                                <Col>
                                    {profile.country ? (
                                        <p className="mt-2">
                                            <FontAwesomeIcon
                                                icon={faMapMarkerAlt}
                                            />{' '}
                                            {profile.country}, {profile.city}
                                        </p>
                                    ) : null}
                                    {profile.yearFounded ? (
                                        <p className="mt-2">
                                            <FontAwesomeIcon
                                                icon={faBirthdayCake}
                                            />{' '}
                                            {profile.yearFounded}
                                        </p>
                                    ) : null}
                                </Col>
                            </Row>

                            <Button
                                variant="outline-dark"
                                className="mt-2 mr-2"
                                href={`mailto:${profile.email}`}
                                onClick={() => {
                                    useEventTracking(
                                        'Community',
                                        'Emailed Organisation',
                                        `${profile.name}`
                                    );
                                }}
                            >
                                Email
                            </Button>
                            {!ownerCheck && initCheck ? (
                                <>
                                    {props.auth.user._id ? (
                                        <Button
                                            variant={
                                                !followCheck
                                                    ? 'outline-primary'
                                                    : 'primary'
                                            }
                                            className="mt-2"
                                            onClick={(ev) => {
                                                handleFollow();
                                                ev.preventDefault();
                                            }}
                                            disabled={disableFollow}
                                        >
                                            {!followCheck
                                                ? 'Follow'
                                                : 'Unfollow'}
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="outline-primary"
                                            className="mt-2"
                                            as={Link}
                                            to="/login"
                                        >
                                            Follow
                                        </Button>
                                    )}
                                </>
                            ) : null}
                            <Tab.Container
                                id="profile-tabs"
                                defaultActiveKey="about"
                            >
                                <Nav
                                    variant="pills"
                                    title="About"
                                    className="mb-4"
                                >
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="about"
                                            onClick={() => {
                                                useEventTracking(
                                                    `${category}`,
                                                    'Clicked About on Organisation Profile',
                                                    `${profile.name}`
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faUser} />{' '}
                                            About
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="members"
                                            onClick={() => {
                                                useEventTracking(
                                                    `${category}`,
                                                    'Clicked Members on Organisation Profile',
                                                    `${profile.name}`
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faUsers} />{' '}
                                            Members (
                                            {
                                                profile.staff.filter(
                                                    (staff) =>
                                                        staff.role !==
                                                        'Applicant'
                                                ).length
                                            }
                                            )
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="followers"
                                            onClick={() => {
                                                useEventTracking(
                                                    `${category}`,
                                                    'Clicked Followers on Organisation Profile',
                                                    `${profile.name}`
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faUserFriends}
                                            />{' '}
                                            Followers (
                                            {profile.followers.length})
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="ecosystems"
                                            onClick={() => {
                                                useEventTracking(
                                                    `${category}`,
                                                    'Clicked Ecosystems on Organisation Profile',
                                                    `${profile.name}`
                                                );
                                            }}
                                        >
                                            <div className="d-flex justify-content-center">
                                                <img
                                                    alt="ecosystem"
                                                    src={`${process.env.PUBLIC_URL}/assets/interseed_ecosystem.svg`}
                                                    className="d-inline-block mr-2"
                                                />
                                                Ecosystems (
                                                {profile.ecosystems?.length ??
                                                    0}
                                                )
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link
                                            eventKey="opportunities"
                                            onClick={() => {
                                                useEventTracking(
                                                    `${category}`,
                                                    'Clicked Opportunities on Organisation Profile',
                                                    `${profile.name}`
                                                );
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faChalkboard}
                                            />{' '}
                                            Opportunities (
                                            {profile.opportunities.length})
                                        </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="about">
                                        <h3>Company Information</h3>
                                        <p className="allow-whitespace">
                                            {profile.description}
                                        </p>
                                        <div className="mb-2">
                                            <h3>Contact Information</h3>
                                            <Row>
                                                <Col
                                                    xs={1}
                                                    className="text-center"
                                                >
                                                    <NBul>
                                                        {profile.website ? (
                                                            <li>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faGlobe
                                                                    }
                                                                />
                                                            </li>
                                                        ) : null}
                                                        {profile.email ? (
                                                            <li>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faEnvelope
                                                                    }
                                                                />
                                                            </li>
                                                        ) : null}
                                                        {profile.phoneNumber ? (
                                                            <li>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faPhoneSquareAlt
                                                                    }
                                                                />
                                                            </li>
                                                        ) : null}
                                                        {profile.address ? (
                                                            <li>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faMapMarkedAlt
                                                                    }
                                                                />
                                                            </li>
                                                        ) : null}
                                                    </NBul>
                                                </Col>
                                                <Col>
                                                    <NBul>
                                                        {profile.website ? (
                                                            <li>
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    href={`${profile.website}`}
                                                                    onClick={() => {
                                                                        useEventTracking(
                                                                            `${category}`,
                                                                            'Clicked Organisation Website Hyperlink',
                                                                            `${profile.name}`
                                                                        );
                                                                    }}
                                                                >
                                                                    Website
                                                                </a>
                                                            </li>
                                                        ) : null}
                                                        {profile.email ? (
                                                            <li>
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    href={`mailto:${profile.email}`}
                                                                    onClick={() => {
                                                                        useEventTracking(
                                                                            'Community',
                                                                            'Emailed Organisation',
                                                                            `${profile.name}`
                                                                        );
                                                                    }}
                                                                >
                                                                    Email
                                                                </a>
                                                            </li>
                                                        ) : null}
                                                        <li>
                                                            {
                                                                profile.phoneNumber
                                                            }
                                                        </li>
                                                        <li>
                                                            {profile.address}
                                                        </li>
                                                    </NBul>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div style={{ marginBottom: '30px' }}>
                                            <h3>Social Media</h3>
                                            <Row>
                                                {profile.linkedIn ? (
                                                    <Col xs="auto">
                                                        <a
                                                            aria-label="Linkedin"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={`https://www.linkedin.com/company/${profile.linkedIn}`}
                                                            onClick={() => {
                                                                useEventTracking(
                                                                    `${category}`,
                                                                    'Clicked Organisation LinkedIn Hyperlink',
                                                                    `${profile.name}`
                                                                );
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faLinkedin
                                                                }
                                                                color="#0e76a8"
                                                                size="lg"
                                                            />
                                                        </a>
                                                    </Col>
                                                ) : null}

                                                {profile.facebook ? (
                                                    <Col xs="auto">
                                                        <a
                                                            aria-label="Facebook"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={`https://www.facebook.com/${profile.facebook}`}
                                                            onClick={() => {
                                                                useEventTracking(
                                                                    `${category}`,
                                                                    'Clicked Organisation Facebook Hyperlink',
                                                                    `${profile.name}`
                                                                );
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFacebook
                                                                }
                                                                color="#4267B2"
                                                                size="lg"
                                                            />
                                                        </a>
                                                    </Col>
                                                ) : null}

                                                {profile.twitter ? (
                                                    <Col xs="auto">
                                                        <a
                                                            aria-label="Twitter"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            href={`https://twitter.com/${profile.twitter}`}
                                                            onClick={() => {
                                                                useEventTracking(
                                                                    `${category}`,
                                                                    'Clicked Organisation Twitter Hyperlink',
                                                                    `${profile.name}`
                                                                );
                                                            }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTwitter}
                                                                color="#00acee"
                                                                size="lg"
                                                            />
                                                        </a>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </div>
                                        {ownerCheck ? (
                                            <div className="d-flex justify-content-end mt-2">
                                                <Button
                                                    size="sm"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                    href={`/editorganisation/${queryId}`}
                                                >
                                                    Edit Organisation
                                                </Button>
                                                {!deleteCheck ? (
                                                    <Button
                                                        variant="danger"
                                                        size="sm"
                                                        onClick={() =>
                                                            setDeleteCheck(true)
                                                        }
                                                    >
                                                        Delete Organisation
                                                    </Button>
                                                ) : (
                                                    <div>
                                                        <p>
                                                            Are you very sure?
                                                            This cannot be
                                                            undone.
                                                        </p>
                                                        <Button
                                                            variant="light mr-1"
                                                            size="sm"
                                                            onClick={() =>
                                                                setDeleteCheck(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={
                                                                handleDelete
                                                            }
                                                        >
                                                            Confirm Delete
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        ) : null}
                                        {/* {ownerCheck ? (
                                            // <div className="text-right mt-2">
                                            //     <Link
                                            //         to={`/editorganisation/${queryId}`}
                                            //         aria-label="Edit Company Profile"
                                            //     >
                                            //         <EventButton>
                                            //             Edit Organisation
                                            //         </EventButton>
                                            //     </Link>
                                            // </div>
                                        ) : null} */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="members">
                                        <div style={{ marginBottom: '30px' }}>
                                            <h3>Members</h3>
                                            {staffList.map((member) => (
                                                <StaffItem
                                                    key={member._id}
                                                    member={member}
                                                    authId={props.auth.user._id}
                                                    staffList={profile.staff}
                                                    orgId={queryId}
                                                    profile={profile}
                                                    setProfile={setProfile}
                                                />
                                            ))}
                                        </div>
                                        {ownerCheck ? (
                                            <div>
                                                <h3>Applicants</h3>
                                                {applicantList.map(
                                                    (applicant) => (
                                                        <StaffItem
                                                            key={applicant._id}
                                                            member={applicant}
                                                            authId={
                                                                props.auth.user
                                                                    ._id
                                                            }
                                                            staffList={
                                                                profile.staff
                                                            }
                                                            orgId={queryId}
                                                            profile={profile}
                                                            setProfile={
                                                                setProfile
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <Button
                                                onClick={handleApply}
                                                style={{
                                                    display:
                                                        staffCheck ||
                                                        !props.auth
                                                            .isAuthenticated
                                                            ? 'none'
                                                            : 'inline-block'
                                                }}
                                            >
                                                {applyCheck
                                                    ? 'Applied. Click me to cancel.'
                                                    : 'Already part of this organisation? Click me to send a request.'}
                                            </Button>
                                        )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="followers">
                                        <div>
                                            <h3>Followers</h3>
                                            {profile.followers.map(
                                                (follower) => (
                                                    <UserItem
                                                        key={follower._id}
                                                        user={follower}
                                                        authUsername={
                                                            props.auth.user
                                                                .username
                                                        }
                                                        authId={
                                                            props.auth.user._id
                                                        }
                                                    />
                                                )
                                            )}
                                        </div>
                                    </Tab.Pane>
                                    <EcosystemTabContent
                                        profile={profile}
                                        ownerCheck={ownerCheck}
                                        auth={props.auth}
                                        queryId={queryId}
                                    />
                                    {/* <Tab.Pane eventKey="opportunities">
                                        <h3>Opportunities</h3>
                                        {ownerCheck ? (
                                            <Button
                                                variant="primary"
                                                className="mb-2"
                                                as={Link}
                                                to={`/createopportunity/${queryId}`}
                                            >
                                                Add Opportunity
                                            </Button>
                                        ) : null}
                                        {profile.opportunities.map(
                                            (opportunity) => (
                                                <JobItem
                                                    key={opportunity._id}
                                                    job={opportunity}
                                                    logo={false}
                                                />
                                            )
                                        )}
                                    </Tab.Pane> */}
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </ProfileStyle>
    );
};
export default connect(mapStateToProps)(withRouter(OrganisationProfile));
