import {
    GET_ERRORS,
    CLEAN_UP_ERRORS,
    ErrorActionTypes,
    ErrorState
} from '../actions/types';

const initialState = {
    login: {},
    register: {},
    resetPassword: {}
};

export default (state: ErrorState = initialState, action: ErrorActionTypes) => {
    switch (action.type) {
        case GET_ERRORS:
            return {
                ...state,
                [action.source]: action.payload
            };
        case CLEAN_UP_ERRORS:
            return initialState;
        default:
            return state;
    }
};
