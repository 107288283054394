/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import IUser from '../../interfaces/IUser';
import getSrc from '../../utils/commonFunction';
import ProfileFollowButton from './ProfileFollowButton';

const Container = styled.div`
    padding: 0.4 rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    .profile-pic {
        margin: auto;
        width: 180px;
        height: 180px;
        object-fit: cover;
        overflow: hidden;
    }

    .name-and-follow {
        position: relative;
        margin: 1rem 0;

        .follow {
            position: absolute;
            top: 0;
            right: 1rem;
        }
    }

    .name-and-follow h2 {
        font-size: 1rem;
        font-weight: bold;
    }

    .group h4 {
        font-size: 0.8rem;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        color: gray;
    }

    .group h5 {
        font-size: 1rem;
    }

    .istyle {
        font-size: 0.6rem;
        font-weight: bold;
        background-color: #ffeab6;
        color: #826c42;
        border-radius: 8px;
        margin: 0.5rem;
        margin-top: 0;
        padding: 4px;
        display: inline-block;
    }

    .sustainability {
        background-color: #cfdfff;
    }
`;

const SingleProfileView: React.FC<{ entry: IUser }> = ({ entry }) => (
    <Container>
        <img className="profile-pic" src={getSrc(entry)} alt="" />
        <div className="name-and-follow">
            <Link to={`/profile/${entry.username}`}>
                <h2>{`${entry.firstName} ${entry.lastName}`}</h2>
            </Link>
            <ProfileFollowButton
                entryUsername={entry.username}
                entryId={entry._id}
            />
        </div>
        <div className="group">
            <h4>Current Position/Role</h4>
            <h5>{entry.currentRole}</h5>
        </div>
        <div className="group">
            <h4>Organisation name</h4>
            <h5>{entry.organisationName}</h5>
        </div>
        <div className="group">
            <h4>Area of interest</h4>
            {entry.interestAreas.map((area) => (
                <h5 className="istyle" key={area}>
                    {area}
                </h5>
            ))}
        </div>
        <div className="group">
            <h4>Involvement in sustainability</h4>
            {entry.sustainabilityInvolvement.map((si) => (
                <h5 className="istyle sustainability" key={si}>
                    {si}
                </h5>
            ))}
        </div>
    </Container>
);

export default SingleProfileView;
