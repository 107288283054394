/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React from 'react';
import { ListGroup, Container, Row, Col, Badge, Button } from 'react-bootstrap';
// import { Link } from 'react-router-dom'
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faComment } from '@fortawesome/free-solid-svg-icons';

import ILandscape from '../../interfaces/ILandscape';

interface thumbFile {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    src?: string | ArrayBuffer;
}

interface landscapeProp {
    items: ILandscape;
    thumb: thumbFile;
}

const Styles = styled.div`
    h2 {
        margin-bottom: 0.2rem;
    }

    img {
        object-fit: contain;
        width: 90px;
        height: 90px;
    }

    .imgcontainer {
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;

        img {
            object-fit: cover;
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #dee2e6;
            padding: 0.25rem;
        }
    }

    .badge {
        font-size: 16px;
    }

    .list-tagline {
        margin-bottom: 0.3rem;
    }

    .upvotes {
        -ms-flex: 0 0 12%;
        flex: 0 0 12%;
        width: 100%;
        padding: 0px;

        .btn {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .upvote-count {
        font-weight: bold;
    }
`;

const LandscapePreview: React.FC<landscapeProp> = ({
    items,
    thumb
}: landscapeProp) => (
    <>
        <Styles>
            <ListGroup.Item>
                <Container>
                    <Row className="d-flex justify-content-center">
                        <Col className="imgcontainer" sm={2}>
                            <img
                                src={
                                    thumb.src
                                        ? thumb.src.toString()
                                        : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                                }
                                alt=""
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                }}
                            />
                        </Col>
                        <Col className="text-center text-sm-left mb-3" sm={8}>
                            <h2>{items.title}</h2>
                            <p className="list-tagline">{items.tagline}</p>
                            <Badge pill variant="light">
                                <FontAwesomeIcon icon={faComment} /> 0
                            </Badge>
                            {items.primaryCategories.map((category) => (
                                <Badge variant="primary" className="mx-1">
                                    {category}
                                </Badge>
                            ))}
                        </Col>
                        <Col sm={2} className="upvotes text-center">
                            <Button
                                type="button"
                                variant="outline-dark"
                                disabled
                            >
                                <FontAwesomeIcon
                                    icon={faChevronUp}
                                    className="mt-3"
                                />
                                <br />
                                <p className="upvote-count">0</p>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </ListGroup.Item>
        </Styles>
    </>
);

export default LandscapePreview;
