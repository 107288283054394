/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router-dom';
import setAuthToken from '../../utils/setAuthToken';
import {
    IDecodedToken,
    ISocialUserData,
    IUserData,
    IResetPasswordUserData,
    IUserDataNewToken
} from '../../interfaces/AuthInterface';

import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING,
    SET_USER_STATUS,
    CLEAN_UP_ERRORS,
    AuthActionTypes
} from './types';
import { loadUserProfile } from './UserProfileActions';

// Set logged in user
export const setCurrentUser = (
    decodedToken: IDecodedToken | Record<string, never>
) => {
    const action: AuthActionTypes = {
        type: SET_CURRENT_USER,
        payload: decodedToken
    };
    return action;
};

// User loading
export const setUserLoading = (status: boolean) => {
    const action: AuthActionTypes = {
        type: USER_LOADING,
        payload: status
    };
    return action;
};

export const setUserStatus = (status: string) => {
    const action: AuthActionTypes = {
        type: SET_USER_STATUS,
        payload: status
    };
    return action;
};

// Login - get user token
export const loginUser = (
    userData: IUserData | ISocialUserData,
    history: RouteComponentProps['history'],
    isSocial: boolean
) => (dispatch: Dispatch) => {
    // Indicate beginnning of request
    dispatch(setUserLoading(true));

    const endpoint = isSocial ? 'sociallogin/google' : 'login';

    axios
        .post(`/api/users/${endpoint}`, userData)
        .then((res) => {
            const { token, status, username } = res.data;

            // Set token to localStorage
            localStorage.setItem('jwtToken', token);

            // Set token to Auth header
            setAuthToken(token);

            // Decode token to get user data
            const decoded: IDecodedToken = jwtDecode(token);

            // Set current user
            dispatch(setCurrentUser(decoded));
            dispatch(setUserStatus(status));
            // Load user profile after logging in
            loadUserProfile(username)(dispatch);
        })
        .then(() => {
            dispatch(setUserLoading(false));
            history.push('/dashboard');
        })
        .catch((err) => {
            // End of request
            dispatch(setUserLoading(false));

            // Get error information
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.errors,
                    source: 'login'
                });
            } else {
                // If server error redirect to error page
                history.push('/error500');
            }
        });
};

// Linkedin login - get user token
export const linkedinLoginUser = (
    userData: IUserData | ISocialUserData,
    history: RouteComponentProps['history']
) => (dispatch: Dispatch) => {
    // Indicate beginnning of request
    dispatch(setUserLoading(true));

    const endpoint = 'sociallogin/linkedin';

    axios
        .post(`/api/users/${endpoint}`, userData)
        .then((res) => {
            const { token, status, username } = res.data;

            // Set token to localStorage
            localStorage.setItem('jwtToken', token);

            // Set token to Auth header
            setAuthToken(token);

            // Decode token to get user data
            const decoded: IDecodedToken = jwtDecode(token);

            // Set current user
            dispatch(setCurrentUser(decoded));
            dispatch(setUserStatus(status));

            // Load user profile after logging in
            loadUserProfile(username)(dispatch);
        })
        .then(() => {
            dispatch(setUserLoading(false));
            history.push('/dashboard');
        })
        .catch((err) => {
            // End of request
            dispatch(setUserLoading(false));

            // Get error information
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.errors,
                    source: 'login'
                });
            } else {
                // If server error redirect to error page
                history.push('/error500');
            }
        });
};

// Register User
export const registerUser = (
    userData: IUserData,
    history: RouteComponentProps['history']
) => (dispatch: Dispatch) => {
    // Indicate beginnning of request
    dispatch(setUserLoading(true));

    axios
        .post('/api/users/register', userData)
        .then(({ data }) => {
            dispatch(setUserLoading(false));
            const user = {
                firstName: data.data.firstName,
                lastName: data.data.lastName,
                email: data.data.email,
                confirmationCode: data.data.confirmationCode
            };
            history.push({
                pathname: '/email-confirmation',
                state: {
                    userData: user,
                    init: 'true'
                }
            });
        })
        .catch((err) => {
            dispatch(setUserLoading(false));

            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.errors,
                    source: 'register'
                });
            } else {
                history.push('/error500');
            }
        });
};

export const cleanUpErrors = () => (dispatch: Dispatch) => {
    dispatch({
        type: CLEAN_UP_ERRORS
    });
};

// Log user out
export const logoutUser = () => (dispatch: Dispatch) => {
    // Set current user to empty object {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
    dispatch(setUserStatus('Pending'));

    // Remove token from local storage
    localStorage.removeItem('jwtToken');

    // Remove auth header for future requests
    setAuthToken(false);
    cleanUpErrors();

    // Refresh window
    window.location.reload();
};

export const confirmEmail = (
    confirmationCode: string,
    history: RouteComponentProps['history']
) => (dispatch: Dispatch) => {
    // Indicate beginnning of request
    dispatch(setUserLoading(true));

    axios
        .put(`/api/users/confirm/${confirmationCode}`)
        .then((res) => {
            dispatch(setUserStatus(res.data.status));
            dispatch(setUserLoading(false));
        })
        .catch((err) => {
            // End of request
            dispatch(setUserLoading(false));

            // Get error information
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.errors,
                    source: 'login'
                });
            } else {
                // If server error redirect to error page
                history.push('/error500');
            }
        });
};

export const getNewToken = (
    userData: IUserDataNewToken,
    history: RouteComponentProps['history']
) => (dispatch: Dispatch) => {
    // Indicate beginnning of request
    dispatch(setUserLoading(true));

    axios
        .post(`/api/users/newToken`, userData)
        .then((res) => {
            const { token, status, username } = res.data;

            // Set token to localStorage
            localStorage.setItem('jwtToken', token);

            // Set token to Auth header
            setAuthToken(token);

            // Decode token to get user data
            const decoded: IDecodedToken = jwtDecode(token);

            // Set current user
            dispatch(setCurrentUser(decoded));
            dispatch(setUserStatus(status));

            // Load user profile after logging in
            loadUserProfile(username)(dispatch);
        })
        .then(() => {
            dispatch(setUserLoading(false));
            history.push('/dashboard');
        })
        .catch((err) => {
            // End of request
            dispatch(setUserLoading(false));

            // Get error information
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.errors,
                    source: 'newToken'
                });
            } else {
                // If server error redirect to error page
                history.push('/error500');
            }
        });
};

export const updateUserStatus = (status: string) => (dispatch: Dispatch) => {
    dispatch(setUserStatus(status));
};

export const resetPassword = (
    userData: IResetPasswordUserData,
    history: RouteComponentProps['history']
) => (dispatch: Dispatch) => {
    dispatch(setUserLoading(true));
    axios
        .put(`api/users/passwordReset/${userData.token}`, userData)
        .then(() => {
            dispatch(setUserLoading(false));
        })
        .catch((err) => {
            dispatch(setUserLoading(false));
            if (err.response.data.errors) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data.errors,
                    source: 'resetPassword'
                });
            } else {
                history.push('/error500');
            }
        });
};
