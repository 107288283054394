import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const StyledButton = styled(Button)`
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 4px;
    background: none;
    padding: 6px 10px;
    margin-bottom: 14px;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 7px;

    &:hover {
        text-decoration: none;
    }
`;

const BackButton: React.FC = () => {
    const history = useHistory();

    return (
        <StyledButton
            className=""
            variant="link"
            onClick={() => history.goBack()}
        >
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
            <span>Back</span>
        </StyledButton>
    );
};

export default BackButton;
