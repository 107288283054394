/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';

import FundsList from '../components/opportunities/FundsList';
import SubmitFundingWidget from '../components/opportunities/SubmitFundingWidget';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

const Funding: React.FC<authProps> = ({ ...props }) => {
    const [showMobileVer, setShowMobileVer] = useState(false);

    useEffect(() => {
        const minWidth = 767
        const handleResize = () => {
            if (window.innerWidth > minWidth) {
                setShowMobileVer(false);
            } else {
                setShowMobileVer(true);
            }
        };
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
        
    })

    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status]);

    return (
        <div>
            <Container className="text-left">
                <Row>
                    <Col md={8}>
                        <Row
                        className='d-flex justify-content-between my-1 mx-1 align-items-center'
                        >
                            <h3 className="mb-3">Funding Opportunities</h3>
                            {showMobileVer
                            ? 
                            <Button
                                className = "mx-2 mb-2"
                                href="https://airtable.com/shr726nJ5gR8S0TM6"
                                target="_blank"
                                variant="success"
                            >
                                Add New
                            </Button>
                            : 
                            <></>
                            }
                        </Row>
                        <FundsList
                            username={props.auth.user.username}
                            authId={props.auth.user._id}
                        />
                    </Col>
                    {showMobileVer
                    ? 
                    <></> 
                    : 
                    <Col>
                        <Row
                            className='d-flex justify-content-between my-1 mx-1 align-items-center'
                        >
                            <h3 className="mb-3">Publish an Opportunity</h3>
                        </Row>
                        <SubmitFundingWidget />
                    </Col>
                    }
                    
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(Funding));
