import {
    faAddressBook,
    faCheck,
    faChevronDown,
    faChevronUp,
    faEllipsisH,
    faFilter,
    faHandHoldingHeart,
    faSearch,
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Dropdown, DropdownButton, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import useMediaQuery from '../../hooks/useMediaQuery';
import optionType from '../../interfaces/OptionType';
import {
    countriesOptions,
    generalAreaOptions,
    sustainabilityVerticalsOptions
} from '../../options/landscapeSelectOptions';
import {
    focusAreasOptions,
    fundingTypeOptions,
    impactAreasOptions,
    industriesOptions,
    startupStageOptions
} from '../../options/orgSelectOptions';
import {
    involvementOptions,
    lookingForOptions,
    skillsOptions
} from '../../options/profileSelectOptions';
import { MapMode, SearchParams } from './Types';

const SearchStyle = styled.div`
    h4 {
        font-size: 0.8rem;
        text-align: left;
    }

    .toggle-and-reset {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .nav-tabs {
            color: #1b3249;
            background-color: #fff;
            font-weight: 400;
            border-bottom: none;
        }

        .nav-link {
            padding: 1rem;
            border-bottom: none;
            color: #1b3249;
            font-weight: 400;

            &:hover {
                border: none;
            }
        }

        .nav-link.active {
            color: #007bff;
            font-weight: 700;
            border: none;
            border-bottom: 2px solid #007bff;
        }
    }

    .clear-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .reset {
            margin-left: 0.5rem;
            font-size: 1.5rem;
            color: #c1c3c6;
            cursor: pointer;
            vertical-align: middle;
        }

        .clear-text {
            font-size: 0.7rem;
            text-align: right;
            margin: 0.5rem 0;
            vertical-align: middle;
        }
    }

    .search-bar {
        margin-bottom: 1rem;

        .search {
            flex-grow: 1;
            display: flex;
            justify-content: stretch;
            position: relative;
            align-items: center;
            font-size: 1rem;
        }
    }

    form .search .icon {
        position: absolute;
        right: 0.4rem;
        background-color: #007bff;
        color: white;
        padding: 0.2rem;
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
    }

    form input {
        flex-grow: 1;
        padding: 0.5rem 1rem;
        outline: none;
        font-size: 1rem;
        border: none;
        border-radius: 2rem;
        background-color: #f2f4f8;
    }

    .expand-button {
        padding: 0.4rem 1rem;
        background-color: #f2f4f8;
        border: none;
        outline: none;
        border-radius: 2rem;
        font-size: 0.8rem;
        font-weight: medium;
        cursor: pointer;
        color: #007bff;
        font-weight: bold;
    }

    .expand-button:hover {
        background-color: #c1c3c6;
        transition: background-color 0.3s;
    }

    .expand-button .icon {
        margin-left: 1rem;
    }

    .tabText {
        font-size: 0.7rem;
    }

    .more-btn {
        margin-right: 0.75rem;
        color: #495057;
    }

    @media screen and (max-width: 768px) {
        .desktop-filters {
            display: none;
        }
    }
`;

const HighlightDropdownContainer = styled.div<{ highlight: boolean }>`
    div.dropdown {
        margin: 5px 10px;
        display: flex;
        justify-content: stretch;

        button {
            flex-grow: 1;
            padding: 0.7rem 0.5rem;
            background-color: ${({ highlight }) =>
                highlight ? '#c3dfeb' : '#f2f4f8'};
            border: none;
            outline: none;
            border-radius: 2rem;
            font-size: 0.65rem;
            font-weight: medium;
            cursor: pointer;
            color: black;

            @media screen and (max-width: 576px) {
                font-size: 14px;
                overflow: hidden;
            }
        }

        .dropdown-menu {
            z-index: 10000;
            max-height: 40vh;
            overflow: auto;

            @media screen and (max-width: 576px) {
                width: 70vw;
            }

            .dropdown-item {
                display: flex;

                @media screen and (max-width: 576px) {
                    white-space: normal;
                }
            }
        }
    }
`;

const Filters = styled.div<{ expanded: boolean; show: boolean }>`
    display: flex;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 0;

    input {
        padding: 0.7rem;
        margin: 5px 10px;
        background-color: #f2f4f8;
        border: none;
        outline: none;
        border-radius: 2rem;
        font-size: 0.65rem;
        font-weight: medium;

        ::placeholder {
            color: black;
            text-align: center;
        }
    }

    button:hover {
        background-color: #c1c3c6;
        transition: background-color 0.3s;
    }

    button:focus {
        background-color: #c1c3c6;
    }

    @media screen and (max-width: 768px) {
        z-index: ${(props) => (props.show ? 1001 : 0)};
        position: absolute;
        top: 5rem;
        right: 1rem;
        background-color: white;
        border-radius: 20px;
        padding: 0.25rem 0;
        box-shadow: 2px 3px 15px #00000066;
    }

    @media screen and (max-width: 388px) {
        left: 0.5rem;
        width: 95vw;
    }
`;

const ToolbarStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 100vw;

    .search-bar {
        margin-top: 1rem;
        padding-top: 1rem;
        width: 75vw;
        display: flex;

        @media screen and (max-width: 576px) {
            width: 90vw;
        }
    }

    .search-bar > .icon {
        background-color: #007bff;
        color: white;
        min-width: 2rem;
        height: 2rem;
        border-radius: 100%;
        margin: auto 0.5rem auto 0.5rem;
        font-size: 1rem;
        padding: 0.4rem;
    }

    .icon.show-filters {
        background-color: #495057;
    }

    .dropdown {
        position: relative;
    }

    @media screen and (min-width: 769px) {
        display: none;
    }
`;

// the type of keys of T which have values of V
type KeysWithValue<T, V> = keyof {
    [X in keyof T as T[X] extends V ? X : never]: V;
};
type MultiSearchParam = KeysWithValue<SearchParams, string[] | undefined>;

const DropDownOption = ({
    title,
    param,
    options,
    searchParams,
    setSearchParams
}: {
    title: string;
    param: MultiSearchParam;
    options: optionType[];
    searchParams: SearchParams | undefined;
    setSearchParams: Dispatch<SetStateAction<SearchParams | undefined>>;
}) => (
    <HighlightDropdownContainer
        highlight={(searchParams?.[param]?.length ?? 0) > 0}
    >
        <DropdownButton title={title}>
            {options.map((option: optionType) => (
                <Dropdown.Item
                    key={option.value}
                    onSelect={() => {
                        const newParams = searchParams
                            ? { ...searchParams }
                            : {};
                        let newOpts = newParams[param]
                            ? [...(newParams[param] as string[])]
                            : [];
                        // IF selected option was not previously selected, toggle it to be selected
                        // vice versa
                        if (!newOpts.includes(option.value)) {
                            newOpts.push(option.value);
                        } else {
                            newOpts = newOpts.filter((o) => o !== option.value);
                        }
                        newParams[param] = newOpts;
                        setSearchParams(newParams);
                    }}
                >
                    <div className="selected-area">
                        {searchParams?.[param]?.includes(option.value) && (
                            <FontAwesomeIcon icon={faCheck} />
                        )}
                    </div>
                    {option.label}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    </HighlightDropdownContainer>
);

const UserFilters = ({
    show,
    expanded,
    searchParams,
    setSearchParams
}: {
    show: boolean;
    expanded: boolean;
    searchParams: SearchParams | undefined;
    setSearchParams: Dispatch<SetStateAction<SearchParams | undefined>>;
}) =>
    show ? (
        <Filters expanded={expanded} show={show}>
            <DropDownOption
                title="Focus Areas"
                param="focusAreas"
                options={focusAreasOptions}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
            <DropDownOption
                title="Skillsets"
                param="skillsets"
                options={skillsOptions}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
            <DropDownOption
                title="Sustainability Involvement"
                param="sustainabilityInvolvement"
                options={involvementOptions}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
            <DropDownOption
                title="Looking For"
                param="lookingFor"
                options={lookingForOptions}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
            {expanded && (
                <>
                    <DropDownOption
                        title="Startup Stage"
                        param="startupStage"
                        options={startupStageOptions}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    />
                    <DropDownOption
                        title="Industries"
                        param="industries"
                        options={industriesOptions}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    />
                    <DropDownOption
                        title="Impact Areas"
                        param="impactAreas"
                        options={impactAreasOptions}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    />
                    <DropDownOption
                        title="Funding Type"
                        param="fundingType"
                        options={fundingTypeOptions}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    />
                </>
            )}
        </Filters>
    ) : (
        <></>
    );

const LandscapeFilters = ({
    show,
    expanded,
    searchParams,
    setSearchParams
}: {
    show: boolean;
    expanded: boolean;
    searchParams: SearchParams | undefined;
    setSearchParams: Dispatch<SetStateAction<SearchParams | undefined>>;
}) => (
    <Filters expanded={expanded} show={show}>
        <DropDownOption
            title="Countries"
            param="countries"
            options={countriesOptions}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
        />
        <DropDownOption
            title="Area of Interest"
            param="primaryTags"
            options={sustainabilityVerticalsOptions}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
        />
    </Filters>
);

const OpportunitiesFilters = ({
    show,
    expanded,
    searchParams,
    setSearchParams
}: {
    show: boolean;
    expanded: boolean;
    searchParams: SearchParams | undefined;
    setSearchParams: Dispatch<SetStateAction<SearchParams | undefined>>;
}) =>
    show ? (
        <Filters expanded={expanded} show={show}>
            <DropDownOption
                title="General Area"
                param="generalArea"
                options={generalAreaOptions}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
            <DropDownOption
                title="Sustainability Verticals"
                param="primaryTags"
                options={sustainabilityVerticalsOptions}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
            <DropDownOption
                title="Skills Preferred"
                param="secondaryTags"
                options={skillsOptions}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
            <DropDownOption
                title="Commitment"
                param="opportunityLength"
                options={[
                    { label: 'Ad-hoc', value: 'Ad-hoc' },
                    { label: 'Regular', value: 'Regular' }
                ]}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
            />
        </Filters>
    ) : (
        <></>
    );

const Search = ({
    mode,
    setMode,
    searchParams,
    setSearchParams,
    fullscreen
}: {
    mode: MapMode;
    setMode: Dispatch<SetStateAction<MapMode>>;
    searchParams: SearchParams | undefined;
    setSearchParams: Dispatch<SetStateAction<SearchParams | undefined>>;
    fullscreen: boolean;
}) => {
    const isMediumScreen = useMediaQuery('(max-width: 991px)');
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const isSmallOrMediumScreen = isMediumScreen || isSmallScreen;

    const [expand, setExpand] = useState(false);
    const handleExpand = () => {
        setExpand(!expand);
    };
    const [showFilters, setShowFilters] = useState(false);

    const openMenu = () => {
        const menuBtn = document.getElementsByClassName(
            'navbar-toggler'
        )[0] as HTMLElement;
        menuBtn.click();
    };

    if (fullscreen) {
        return (
            <ToolbarStyle>
                <SearchStyle>
                    <form
                        className="search-bar"
                        onSubmit={(ev) => {
                            ev.preventDefault();
                        }}
                        data-steps={isSmallOrMediumScreen && 'step-3'}
                    >
                        <div className="search">
                            <input
                                type="text"
                                placeholder={
                                    mode === 'community'
                                        ? 'Find your sustainability tribe'
                                        : 'Browse activities'
                                }
                                value={searchParams?.query ?? ''}
                                onChange={(ev) => {
                                    setSearchParams({
                                        ...searchParams,
                                        query: ev.target.value
                                    });
                                }}
                            />
                            <div className="icon">
                                <FontAwesomeIcon icon={faSearch} />
                            </div>
                        </div>
                        <div
                            className={`icon${
                                showFilters ? ' show-filters' : ''
                            }`}
                        >
                            <FontAwesomeIcon
                                icon={faFilter}
                                onClick={() => {
                                    setShowFilters(!showFilters);
                                }}
                            />
                        </div>
                    </form>
                    <div className="toggle-and-reset options">
                        <Tabs
                            activeKey={mode}
                            id="uncontrolled-tab-example"
                            data-steps={isSmallOrMediumScreen && 'step-2'}
                            onSelect={(k) => {
                                setMode(k as MapMode);
                            }}
                        >
                            <Tab
                                eventKey="community"
                                title={
                                    <>
                                        <FontAwesomeIcon
                                            icon={faAddressBook}
                                            size="lg"
                                        />
                                        <p className="tabText mb-0">
                                            Community
                                        </p>
                                    </>
                                }
                            />
                            <Tab
                                eventKey="opportunities"
                                title={
                                    <>
                                        <FontAwesomeIcon
                                            icon={faHandHoldingHeart}
                                            size="lg"
                                        />
                                        <p className="tabText mb-0">
                                            Volunteering
                                        </p>
                                    </>
                                }
                            />
                        </Tabs>
                        <Button
                            variant="link"
                            style={{ textDecoration: 'none' }}
                            className="more-btn"
                            onClick={openMenu}
                        >
                            <FontAwesomeIcon icon={faEllipsisH} size="lg" />
                            <p className="tabText mb-0">More</p>
                        </Button>
                    </div>
                </SearchStyle>
                {mode === 'community' ? (
                    <UserFilters
                        show={showFilters}
                        expanded
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    />
                ) : (
                    <OpportunitiesFilters
                        show={showFilters}
                        expanded
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    />
                )}
            </ToolbarStyle>
        );
    }

    return (
        <SearchStyle>
            <div
                data-steps={!isSmallOrMediumScreen && 'step-2'}
                className="toggle-and-reset"
            >
                {/* <Toggle /> */}
                <Tabs
                    activeKey={mode}
                    id="uncontrolled-tab-example"
                    onSelect={(k) => {
                        setMode(k as MapMode);
                    }}
                >
                    <Tab
                        eventKey="community"
                        title={
                            <>
                                <FontAwesomeIcon
                                    icon={faAddressBook}
                                    size="lg"
                                />
                                <span className="tabText ml-2 mb-0">
                                    Community
                                </span>
                            </>
                        }
                    />
                    <Tab
                        eventKey="opportunities"
                        title={
                            <>
                                <FontAwesomeIcon
                                    icon={faHandHoldingHeart}
                                    size="lg"
                                />
                                <span className="tabText ml-2 mb-0">
                                    Volunteering
                                </span>
                            </>
                        }
                    />
                </Tabs>
            </div>
            <form
                className="search-bar"
                onSubmit={(ev) => {
                    ev.preventDefault();
                }}
            >
                <div className="search">
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchParams?.query ?? ''}
                        onChange={(ev) => {
                            setSearchParams({
                                ...searchParams,
                                query: ev.target.value
                            });
                        }}
                    />
                    <div className="icon">
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                </div>
            </form>
            <div className="clear-filter">
                <h4>Filters</h4>
                <span>
                    <p className="clear-text">
                        Clear Filters
                        <FontAwesomeIcon
                            className="reset"
                            icon={faTimesCircle}
                            onClick={() => {
                                setSearchParams(undefined);
                            }}
                        />
                    </p>
                </span>
            </div>
            <div
                data-steps={!isSmallOrMediumScreen && 'step-3'}
                className="desktop-filters"
            >
                {mode === 'community' ? (
                    <>
                        <UserFilters
                            show
                            expanded={expand}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                        />

                        {!expand ? (
                            <button
                                className="expand-button"
                                type="button"
                                onClick={handleExpand}
                            >
                                More Filters
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faChevronDown}
                                />
                            </button>
                        ) : (
                            <button
                                className="expand-button"
                                type="button"
                                onClick={handleExpand}
                            >
                                Less Filters
                                <FontAwesomeIcon
                                    className="icon"
                                    icon={faChevronUp}
                                />
                            </button>
                        )}
                    </>
                ) : (
                    <OpportunitiesFilters
                        show
                        expanded={expand}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    />
                )}
            </div>
        </SearchStyle>
    );
};

export default Search;
