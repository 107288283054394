/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import VolOppsList from '../components/opportunities/VolOppsList';
import SubmitVolOppWidget from '../components/opportunities/SubmitVolOppWidget';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';


interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

const VolOppsPage: React.FC<authProps> = ({ ...props }) => {
    const [userOrgs, setUserOrgs] = useState<string[]>([]);

    // Check user auth
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status]);

    // Get user orgs
    useEffect(() => {
        const orgsObject = props.userProfile.userProfile?.organisation;
        if (orgsObject) {
            const allOrgs: string[] = [];
            Object.values(orgsObject).forEach((val) => {
                allOrgs.push(val._id);
            });
            setUserOrgs(allOrgs);
        } else {
            setUserOrgs([]);
        }
    }, [
        props.userProfile.userProfile,
        props.userProfile,
        props.auth.isAuthenticated
    ]);

    return (
        <div>
            <Container className="text-left">
                <Row>
                    <Col md={8}>
                        <h3 className="mb-3">Volunteering Opportunities</h3>
                        <VolOppsList
                            username={props.auth.user.username}
                            authId={props.auth.user._id}
                            userOrgs={userOrgs}
                        />
                    </Col>
                    <Col>
                        <h3>Publish an Opportunity</h3>
                        <SubmitVolOppWidget />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(VolOppsPage));
