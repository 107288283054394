import IUser, { UserBlankObj } from './IUser';

export interface IEvent {
    _id: string;
    title: string;
    description: string;
    startTime: string;
    endTime: string;
    numberOfParticipants: number;
    tags: any[];
    type: string;
    banner?: { location: string; key: string };
    zoom?: { joinUrl: string; startUrl?: string; id?: string };
    participants: IUser[];
    organizer: IUser;
    upcoming?: boolean;
    isRegistered?: boolean;
    receiveEmails?: boolean;
    sendEmails?: boolean;
    participantLength: number;
}

export const blankEvent = {
    _id: '',
    title: '',
    description: '',
    receiveEmails: true,
    startTime: '',
    endTime: '',
    numberOfParticipants: 0,
    participantLength: 0,
    tags: [],
    type: '',
    banner: {
        location: `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`,
        key: ''
    },
    zoom: {
        joinUrl: ``,
        startUrl: '',
        id: ''
    },
    participants: [UserBlankObj],
    organizer: UserBlankObj
};

export const eventTypes = ['Network', 'Pitch', 'Workshop'];
