/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { CommunityList } from '../components/community/CommunityList';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
}

const People: React.FC<authProps> = ({ ...props }) => {
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status]);

    return (
        <div className="font-arimo">
            <Container className="text-left">
                <h1 className="text-center mb-4 font-weight-bold">
                    The Interseed Community
                </h1>
                <CommunityList
                    username={props.auth.user.username}
                    authId={props.auth.user._id}
                />
            </Container>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(People));
