/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Link,
    withRouter,
    RouteComponentProps,
    useParams
} from 'react-router-dom';
import {
    Container,
    Card,
    Form,
    Button,
    Col,
    Image,
    InputGroup
} from 'react-bootstrap';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import Select from 'react-select';
import { whitespaceCheck } from '../../utils/commonFunction';

import BackToOrgProfile from '../../components/backbuttons/BackToOrgProfile';

// Interface
import IOrganisation, { orgBlankObj } from '../../interfaces/IOrganisation';

// Interface for Redux types
import { StoreState, AuthState } from '../../redux/actions/types';

// Select Options Import
import {
    countriesOptions,
    startupStageOptions,
    focusAreasOptions,
    industriesOptions,
    impactAreasOptions,
    lookingForOptions,
    fundingTypeOptions
} from '../../options/orgSelectOptions';

const CardContent = styled.div`
    padding: 2rem 1rem;
    label {
        font-size: 16px;
    }
    .form-label {
        font-weight: bold;
    }
    .custom-file {
        font-size: 16px;
    }
    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }
    .form-text {
        font-size: 12px;
    }
`;

const FormHeader = styled.h2`
    margin-bottom: 2rem;
`;

interface organisationProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    id: string;
}

interface thumbFile {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    src?: string | ArrayBuffer;
}

interface ParamTypes {
    queryId: string | undefined;
}

const descMaxLength = 1000;

// Main React Functional Component
const EditOrganisationProfile: React.FC<organisationProps> = ({ ...props }) => {
    const { queryId } = useParams<ParamTypes>();
    const alert = useAlert();

    const [details, setDetails] = useState<IOrganisation>(orgBlankObj);

    const [thumb, setThumb] = useState<thumbFile>({
        lastModified: 0,
        name: 'Choose a file...',
        size: 0,
        type: ''
    });

    const [thumbReal, setThumbReal] = useState<File>();

    const [descWordsRemaining, setDescWordsRemaining] = useState(descMaxLength);

    // Check whether an edit has been made to enable Submit button
    const [editCheck, setEditCheck] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);

    const compareImpactAreas = (a: string, b: string) => {
        const aMatch: RegExpMatchArray | null = a.match(/(\d+)/);
        const bMatch: RegExpMatchArray | null = b.match(/(\d+)/);
        if (!aMatch || !bMatch) return 0;
        const aNum: number = parseInt(aMatch[0], 10);
        const bNum: number = parseInt(bMatch[0], 10);

        if (aNum > bNum) {
            return 1;
        }
        if (bNum > aNum) {
            return -1;
        }
        return 0;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type } = event.target;
        if (!editCheck) setEditCheck(true);
        if (type === 'file') {
            if (event.target.files && event.target.files[0]) {
                if (
                    event.target.files[0].type !== 'image/jpeg' &&
                    event.target.files[0].type !== 'image/png'
                ) {
                    alert.error('Please choose a JPEG/PNG file.');
                    return;
                }
                if (event.target.files[0].size > 4000000) {
                    // eslint-disable-next-line
                    alert.error('Please choose an image under 4mb.');
                    return;
                }

                setThumbReal(event.target.files[0]);

                const file: thumbFile = event.target.files[0];
                const reader = new FileReader();

                reader.onload = function read(e) {
                    if (e.target && e.target.result) {
                        file.src = e.target.result;
                        if (file.name.length > 40) {
                            setThumb({
                                lastModified: file.lastModified,
                                name: file.name
                                    .slice(0, 40)
                                    .concat(
                                        `... ${file.type.replace(
                                            'image/',
                                            '.'
                                        )}`
                                    ),
                                size: file.size,
                                type: file.type
                            });
                        } else setThumb(file);
                    }
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        } else if (name === 'yearFounded') {
            setDetails({
                ...details,
                yearFounded: Number(value)
            });
        } else {
            if (name === 'description') {
                setDescWordsRemaining(descMaxLength - value.length);
            }
            setDetails({
                ...details,
                [name]: value
            });
        }
    };

    const handleSwitch = () => {
        if (!editCheck) setEditCheck(true);
        const curr = details.priorFunding;
        setDetails({
            ...details,
            priorFunding: !curr
        });
    };

    // eslint-disable-next-line
    const handleSelect = (newValue: any, type: string) => {
        let value: string[] | string;
        if (!editCheck) setEditCheck(true);
        if (
            type === 'country' ||
            type === 'startupStage' ||
            type === 'fundingType'
        ) {
            value = newValue.value;
        } else {
            value = [];
            for (let i = 0; i < newValue.length; i += 1) {
                value.push(newValue[i].value);
            }
            if (type === 'impactAreas') value.sort(compareImpactAreas);
            else value.sort();
        }

        setDetails({
            ...details,
            [type]: value
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (disableSubmit) return;
        setDisableSubmit(true);

        // Check for whitespace submissions
        if (
            !whitespaceCheck(details, [
                'name',
                'address',
                'description',
                'email'
            ])
        ) {
            alert.error(
                'Required fields cannot be filled with only whitespaces.'
            );
            setDisableSubmit(false);
            return;
        }

        if (thumbReal) {
            // eslint-disable-next-line
            const data: any = new FormData();
            data.append('image', thumbReal);

            if (details.logo) {
                const { key } = details.logo;
                axios.delete(`/api/uploads/images/${key}`);
            }

            axios
                .post(`/api/uploads/images`, data)
                .then((res) => {
                    const thumbnailData = res.data;
                    axios
                        .put(`/api/organisations/${queryId}`, {
                            name: details.name,
                            logo: thumbnailData,
                            city: details.city,
                            country: details.country,
                            startupStage: details.startupStage,
                            yearFounded: details.yearFounded,
                            focusAreas: details.focusAreas,
                            industries: details.industries,
                            impactAreas: details.impactAreas,
                            tagline: details.tagline,
                            description: details.description,
                            lookingFor: details.lookingFor,
                            fundingType: details.fundingType,
                            priorFunding: details.priorFunding,
                            address: details.address,
                            phoneNumber: details.phoneNumber,
                            website: details.website,
                            email: details.email,
                            facebook: details.facebook,
                            instagram: details.instagram,
                            twitter: details.twitter,
                            linkedIn: details.linkedIn
                        })
                        .then(() => {
                            props.history.push(`/organisation/${queryId}`);
                        })
                        .catch(() => {
                            alert.error(
                                'Error editing organisation. Please try again.'
                            );
                            setDisableSubmit(false);
                        });
                })
                .catch(() => {
                    alert.error(
                        'Error editing organisation (Image Upload Failed). Please try again.'
                    );
                    setDisableSubmit(false);
                });
        } else {
            axios
                .put(`/api/organisations/${queryId}`, {
                    name: details.name,
                    city: details.city,
                    country: details.country,
                    startupStage: details.startupStage,
                    yearFounded: details.yearFounded,
                    focusAreas: details.focusAreas,
                    industries: details.industries,
                    impactAreas: details.impactAreas,
                    tagline: details.tagline,
                    description: details.description,
                    lookingFor: details.lookingFor,
                    fundingType: details.fundingType,
                    priorFunding: details.priorFunding,
                    address: details.address,
                    phoneNumber: details.phoneNumber,
                    website: details.website,
                    email: details.email,
                    facebook: details.facebook,
                    instagram: details.instagram,
                    twitter: details.twitter,
                    linkedIn: details.linkedIn
                })
                .then(() => {
                    props.history.push(`/organisation/${queryId}`);
                })
                .catch(() => {
                    alert.error(
                        'Error editing organisation. Please try again.'
                    );
                    setDisableSubmit(false);
                });
        }
        event.preventDefault();
    };

    useEffect(() => {
        if (!props.auth.isAuthenticated) {
            props.history.push('/');
        }
    }, [props.auth.isAuthenticated]);

    useEffect(() => {
        axios
            .get<IOrganisation>(`/api/organisations/${queryId}`)
            .then(({ data }) => {
                setDetails(data);
                if (data.logo) {
                    setThumb({
                        ...thumb,
                        src: data.logo.location
                    });
                }
                setDescWordsRemaining(descMaxLength - data.description.length);
                setDisableSubmit(false);
            })
            .catch(() => {
                props.history.push('/error404');
            });
    }, []);

    return (
        <Container fluid='md'>
            <BackToOrgProfile id={details._id} />
            <h1>Edit your Organisation Profile</h1>
            <Card className="text-left mx-auto px-3 mb-4">
                <CardContent>
                    <FormHeader>Tell us about your oranisation</FormHeader>
                    <hr />
                    <Form className="text-muted" onSubmit={handleSubmit}>
                        <Form.Row className="align-items-center">
                            <Col
                                sm={4}
                                className="d-flex justify-content-center"
                            >
                                <Image
                                    className="profile-picture"
                                    src={
                                        thumb.src
                                            ? thumb.src.toString()
                                            : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                                    }
                                    alt="Organisation Logo"
                                    roundedCircle
                                    thumbnail
                                    fluid
                                    onError={(e) => {
                                        e.currentTarget.onerror = null; // prevents looping
                                        e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                    }}
                                />
                            </Col>
                            <Form.Group as={Col} controlId="formProfilePicture">
                                <Form.Label>Add Organisation Logo</Form.Label>
                                <Form.File
                                    name="thumbnail"
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={handleChange}
                                    label={thumb.name}
                                    custom
                                />
                                <Form.Text muted>
                                    File size limit: 4mb
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="formName" className="required">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                required
                                name="name"
                                type="text"
                                maxLength={30}
                                value={details.name}
                                onChange={handleChange}
                                placeholder="Organisation name"
                            />
                        </Form.Group>

                        <Form.Group controlId="formTagline">
                            <Form.Label>Tagline</Form.Label>
                            <Form.Control
                                name="tagline"
                                type="text"
                                maxLength={200}
                                value={details.tagline}
                                onChange={handleChange}
                                placeholder="Organisation tagline"
                            />
                        </Form.Group>

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                controlId="formCountry"
                                className="required"
                            >
                                <Form.Label>Country</Form.Label>
                                <Select
                                    name="country"
                                    options={countriesOptions}
                                    value={{
                                        value: details.country,
                                        label: details.country
                                    }}
                                    placeholder="Select your country..."
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleSelect(newValue, 'country')
                                    }
                                />
                                <input
                                    className="invis"
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0 }}
                                    defaultValue={details.country}
                                    required
                                />
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                controlId="formCity"
                                className="required"
                            >
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    name="city"
                                    type="text"
                                    maxLength={50}
                                    value={details.city}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group
                            controlId="formAddress"
                            className="required"
                        >
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                required
                                name="address"
                                type="text"
                                value={details.address}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formStartupStage"
                            className="required"
                        >
                            <Form.Label>Startup Stage</Form.Label>
                            <Select
                                name="startupStage"
                                options={startupStageOptions}
                                value={{
                                    value: details.startupStage,
                                    label: details.startupStage
                                }}
                                placeholder="Select your startup stage"
                                className="basic-single"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'startupStage')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={details.startupStage}
                                required
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formYearFounded"
                            className="required"
                        >
                            <Form.Label>Year Founded</Form.Label>
                            <Form.Control
                                required
                                name="yearFounded"
                                type="number"
                                min={1000}
                                max={new Date().getFullYear()}
                                maxLength={4}
                                value={details.yearFounded}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group
                            controlId="formDescription"
                            className="required"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required
                                name="description"
                                as="textarea"
                                rows={4}
                                maxLength={descMaxLength}
                                value={details.description}
                                onChange={handleChange}
                                placeholder="Briefly tell us about your organisation"
                            />
                            <Form.Text muted>
                                {descWordsRemaining} characters remaining.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group
                            controlId="formFocusAreas"
                            className="required"
                        >
                            <Form.Label>Focus Areas</Form.Label>
                            <Select
                                isMulti
                                name="focusAreas"
                                options={focusAreasOptions}
                                value={details.focusAreas.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="Choose your Focus Area(s)"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'focusAreas')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={details.focusAreas}
                                required
                            />
                        </Form.Group>
                        <Form.Group
                            controlId="formIndustries"
                            className="required"
                        >
                            <Form.Label>Industries</Form.Label>
                            <Select
                                isMulti
                                name="industries"
                                options={industriesOptions}
                                value={details.industries.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="Choose your Industry"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'industries')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={details.industries}
                                required
                            />
                        </Form.Group>
                        <Form.Group
                            controlId="formImpactAreas"
                            className="required"
                        >
                            <Form.Label>Impact Areas</Form.Label>
                            <Select
                                isMulti
                                name="impactAreas"
                                options={impactAreasOptions}
                                value={details.impactAreas.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="Choose the SDGs which most closely align with your impact"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'impactAreas')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={details.impactAreas}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formLookingFor">
                            <Form.Label>We are looking for</Form.Label>
                            <Select
                                isMulti
                                name="lookingFor"
                                options={lookingForOptions}
                                value={details.lookingFor.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="We are looking for..."
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'lookingFor')
                                }
                            />
                        </Form.Group>

                        <Form.Row>
                            <Form.Group as={Col} controlId="formFundingType">
                                <Form.Label>
                                    What type of funding are you looking for?
                                </Form.Label>
                                <Select
                                    name="fundingType"
                                    options={fundingTypeOptions}
                                    value={{
                                        value: details.fundingType,
                                        label: details.fundingType
                                    }}
                                    placeholder="Select your Funding Type..."
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleSelect(newValue, 'fundingType')
                                    }
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formPriorFunding">
                                <Form.Label>
                                    Have you received prior funding?
                                </Form.Label>
                                <Form.Check
                                    type="switch"
                                    id="priorFunding"
                                    label={details.priorFunding ? 'Yes' : 'No'}
                                    checked={details.priorFunding}
                                    onChange={handleSwitch}
                                />
                            </Form.Group>
                        </Form.Row>

                        {/* Contact Information */}
                        <Form.Group controlId="formEmail" className="required">
                            <Form.Label>Organisation Email Address</Form.Label>
                            <Form.Control
                                required
                                name="email"
                                type="email"
                                value={details.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Row>
                            <Form.Group
                                as={Col}
                                sm={6}
                                controlId="formPhoneNumber"
                            >
                                <Form.Label>
                                    Organisation Phone Number
                                </Form.Label>
                                <Form.Control
                                    name="phoneNumber"
                                    type="number"
                                    maxLength={12}
                                    value={details.phoneNumber}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} sm={6} controlId="formWebsite">
                                <Form.Label>Organisation Website</Form.Label>
                                <Form.Control
                                    name="website"
                                    type="text"
                                    value={details.website}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                sm={6}
                                controlId="formFacebook"
                            >
                                <Form.Label>Facebook</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            facebook.com/
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        name="facebook"
                                        type="text"
                                        value={details.facebook}
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                sm={6}
                                controlId="formLinkedIn"
                            >
                                <Form.Label>LinkedIn</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            linkedin.com/company/
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        name="linkedIn"
                                        type="text"
                                        value={details.linkedIn}
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                sm={6}
                                controlId="formInstagram"
                            >
                                <Form.Label>Instagram</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            instagram.com/
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        name="instagram"
                                        type="text"
                                        value={details.instagram}
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} sm={6} controlId="formTwitter">
                                <Form.Label>Twitter username</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            twitter.com/
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        name="twitter"
                                        type="text"
                                        value={details.twitter}
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>

                        <div className="text-right">
                            <Button
                                as={Link}
                                className="mr-1"
                                variant="light"
                                to={`/organisation/${queryId}`}
                            >
                                Cancel
                            </Button>
                            {editCheck ? (
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={disableSubmit}
                                >
                                    Save
                                </Button>
                            ) : (
                                <Button variant="light" disabled>
                                    Save
                                </Button>
                            )}
                        </div>
                    </Form>
                </CardContent>
            </Card>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(EditOrganisationProfile));
