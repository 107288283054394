import { Step } from 'react-joyride';
import LastStepContent from './LastStepContent';

export const STEP_TARGETS = [
    '[data-steps="step-1"]',
    '[data-steps="step-2"]',
    '[data-steps="step-3"]',
    '[data-steps="step-4"]',
    '[data-steps="step-5"]',
    '[data-steps="step-6"]',
    '[data-steps="step-7"]',
    '[data-steps="step-8"]',
    '[data-steps="step-9"]',
    'body'
];

const steps: Step[] = [
    {
        target: STEP_TARGETS[0],
        content: `Welcome to Interseed's sustainability community`,
        disableBeacon: true,
        placement: 'right-start'
    },
    {
        target: STEP_TARGETS[1],
        disableBeacon: true,
        content:
            'Check out inspiring individuals working on sustainability in the region!',
        placement: 'right'
    },
    {
        target: STEP_TARGETS[2],
        disableBeacon: true,
        content:
            'Find collaboration opportunities by using filters to narrow your search',
        placement: 'right'
    },
    {
        target: STEP_TARGETS[3],
        disableBeacon: true,
        content:
            'Have an impact-driven organisation? List and gain visibility for collaboration',
        placement: 'bottom'
    },
    {
        target: STEP_TARGETS[4],
        disableBeacon: true,
        content:
            'Find the directory of sustainability community members, organizations and networks here',
        placement: 'bottom-end'
    },
    {
        target: STEP_TARGETS[5],
        disableBeacon: true,
        content: `Your curated Sustainability newsfeed here! Don't worry, we keep our news sources things positive and actionable`,
        placement: 'bottom-end'
    },
    {
        target: STEP_TARGETS[6],
        disableBeacon: true,
        content: 'List or attend sustainability events',
        placement: 'bottom'
    },
    {
        target: STEP_TARGETS[7],
        disableBeacon: true,
        content:
            'Look for funding for your ideas or volunteer your skills/time for sustainability',

        placement: 'bottom'
    },
    {
        target: STEP_TARGETS[8],
        disableBeacon: true,
        content: 'Build your portfolio or read up on sustainability topics',
        placement: 'bottom-end'
    },
    {
        target: STEP_TARGETS[9],
        disableBeacon: true,
        content: LastStepContent(),
        placement: 'center'
    }
];

export default steps;
