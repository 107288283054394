import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Link,
    RouteComponentProps,
    useParams,
    withRouter
} from 'react-router-dom';
import { confirmEmail } from '../redux/actions/AuthActions';
import { AuthState, StoreState } from '../redux/actions/types';

interface ParamTypes {
    queryId: string | undefined;
}

interface pageProps extends RouteComponentProps {
    confirmEmail: (arg0: string, arg1: RouteComponentProps['history']) => void;
    auth: AuthState;
}

const ConfirmationPage: React.FC<pageProps> = ({ ...props }) => {
    const { queryId } = useParams<ParamTypes>();
    const [confirmed, setConfirmed] = useState(false);

    useEffect(() => {
        if (queryId) {
            props.confirmEmail(queryId, props.history);
        }

        if (props.auth.status === 'Email Confirmed') {
            setConfirmed(true);
        } else {
            setConfirmed(false);
        }
    }, [props.auth.status]);

    if (confirmed) {
        return (
            <div className="container">
                <header
                    className="jumbotron"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0)' }}
                >
                    <h3>
                        <strong>Account confirmed!</strong>
                    </h3>
                </header>
                <Link to="/login">Login to your account</Link>
            </div>
        );
    }
    return null;
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps, { confirmEmail })(
    withRouter(ConfirmationPage)
);
