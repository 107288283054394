import React, { useEffect, useState } from 'react';
import { Container, Form, FormControl, Tab } from 'react-bootstrap';
import styled from 'styled-components';
import { Ecosystem } from '../../../interfaces/Ecosystem';
import { AuthState } from '../../../redux/actions/types';
import MemberItem from './MemberItem';
import SearchIcon from './SearchIcon';

const MembersContainer = styled(Container)`
    margin: 1.5rem 0;
    background-color: #f5faff;
    border-radius: 20px;
    padding-left: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: start;
    justify-items: center;
    padding-right: 0;
    gap: 20px;

    @media screen and (max-width: 991px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 18px;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 14px;
        align-content: center;
    }
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        align-content: center;
    }
`;

const FormWrapper = styled.div`
    margin: 2rem 0;
    position: relative;

    .icon {
        position: absolute;
        right: 10px;
        top: 2px;
    }
`;

interface User {
    _id: string;
    username: string;
    firstName: string;
    lastName: string;
}

interface Props {
    profile: Ecosystem;
    members: User[];
    isAdminUser: boolean;
    auth: AuthState;
}

const EcosystemMembers: React.FC<Props> = ({
    profile,
    members,
    isAdminUser,
    auth
}) => {
    const [membersList, setMembersList] = useState<User[]>(members);
    const [searchParam, setSearchParam] = useState('');
    const [filter, setFilter] = useState(false);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setSearchParam(e.target.value);
        if (searchParam.length <= 1) {
            setFilter(false);
        } else {
            setFilter(true);
        }
    };

    useEffect(() => {
        if (!filter) {
            setMembersList(members);
        } else {
            setMembersList(
                members.filter(
                    (m) =>
                        m.username
                            .toLowerCase()
                            .includes(searchParam.toLowerCase()) ||
                        m.firstName
                            .toLowerCase()
                            .includes(searchParam.toLowerCase()) ||
                        m.lastName
                            .toLowerCase()
                            .includes(searchParam.toLowerCase())
                )
            );
        }
    }, [filter]);

    return (
        <Tab.Pane eventKey="members">
            <FormWrapper>
                <Form>
                    <FormControl
                        placeholder="Search"
                        name="search"
                        type="text"
                        value={searchParam}
                        onChange={handleSearch}
                    />
                    <div className="icon">
                        <SearchIcon />
                    </div>
                </Form>
            </FormWrapper>
            <MembersContainer>
                {membersList.map((member) => (
                    <MemberItem
                        key={member._id}
                        auth={auth}
                        ecosystem={profile}
                        member={member}
                        isAdminUser={isAdminUser}
                    />
                ))}
            </MembersContainer>
        </Tab.Pane>
    );
};

export default EcosystemMembers;
