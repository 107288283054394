import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const BackToJobsComponent = () => (
    <div className="text-left back-link mb-4">
        <Link to="/jobs">
            <FontAwesomeIcon icon={faLongArrowAltLeft} /> Back to Jobs
        </Link>
    </div>
);

export default BackToJobsComponent;
