import React, { ReactElement } from 'react';
import { ListGroup, Pagination } from 'react-bootstrap';
import styled from 'styled-components';
import { IEvent } from '../../interfaces/IEvent';
import EventItemHorizontal from './EventItemHorizontal';

const EventUpcomingStyle = styled.div`
    h4 {
        margin: 0;
    }
`;

interface EventUpcomingProps {
    events: IEvent[];
    setViewEvent: (val: boolean) => void;
    setSelectedEvent: (val: string) => void;
}

export default function EventUpcomingProps({
    events,
    setViewEvent,
    setSelectedEvent
}: EventUpcomingProps) {
    return (
        <EventUpcomingStyle
            className="d-flex flex-column "
            style={{ height: '100%' }}
        >
            <div>
                <div className="h3 p-3 text-left">Upcoming Events</div>
                <hr style={{ backgroundColor: '#f3f3f3', margin: 0 }} />
            </div>
            <ListGroup className="flex-grow-1 mt-2 mb-4">
                <div
                    style={{
                        height: '100%',
                        color: '#51617C',
                        padding: '0 1rem'
                    }}
                >
                    {events.map((event) => (
                        <div
                            style={{ opacity: event.upcoming ? 1 : 0.5 }}
                            key={event._id}
                        >
                            <EventItemHorizontal
                                key={event._id}
                                event={event}
                                setViewEvent={setViewEvent}
                                setSelectedEvent={setSelectedEvent}
                            />
                        </div>
                    ))}
                </div>
            </ListGroup>
        </EventUpcomingStyle>
    );
}
