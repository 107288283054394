import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Route } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';
import { useEventTracking } from '../../pages/eventTracking';
import useMediaQuery from '../../hooks/useMediaQuery';

const LinksStyle = styled.div`
    min-height: 41.5vh;
    overflow-y: auto;
    overflow-x: hidden;
    .links div {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid #b4bcce;
    }
    .links a {
        color: #000;
        text-decoration: none;
    }
    .links div h4 {
        font-size: 1rem;
        font-weight: medium;
    }
    .links div .icon {
        position: relative;
        transition: transform 0.3s ease;
        transform: translateX(-5px);
    }
    .links div:hover .icon {
        transform: translateX(1px);
    }
`;

const HeaderText = styled.h3`
    font-size: 1.8rem;
    text-align: left;
    margin-bottom: 2rem;
`;

const Links = ({ authId }) => {
    const isMediumScreen = useMediaQuery('(max-width: 991px)');
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const isSmallOrMediumScreen = isMediumScreen || isSmallScreen;

    const [blockerShow, setBlockerShow] = useState(false);

    const handleBlockerClose = () => {
        setBlockerShow(false);
    };

    const handleLinkSelect = (e) => {
        if (authId) {
            console.log('yay');
        } else {
            setBlockerShow(true);
            e.preventDefault();
        }
    };

    return (
        <LinksStyle>
            <HeaderText>Get Started</HeaderText>
            <div className="links">
                <Modal
                    show={blockerShow}
                    onHide={handleBlockerClose}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Login Required</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Thank you for checking out Interseed! However, please{' '}
                        <a href="/login" style={{ textDecoration: 'none' }}>
                            login
                        </a>{' '}
                        or{' '}
                        <a href="/register" style={{ textDecoration: 'none' }}>
                            register
                        </a>{' '}
                        to view more of our amazing content!
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={handleBlockerClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* <Route> */}
                <Link
                    to="/createopportunity"
                    onClick={(e) => {
                        handleLinkSelect(e);
                        useEventTracking(
                            'Dashboard',
                            'Clicked Get Started Option',
                            'Share your volunteering opportunity!'
                        );
                    }}
                >
                    <div>
                        <h4>Create a volunteering opportunity</h4>
                        <FontAwesomeIcon
                            className="icon"
                            icon={faChevronRight}
                        />
                    </div>
                </Link>
                <Link
                    to="/createorganisation"
                    onClick={(e) => {
                        handleLinkSelect(e);
                        useEventTracking(
                            'Dashboard',
                            'Clicked Get Started Option',
                            'Create your own organisation!'
                        );
                    }}
                >
                    <div data-steps={!isSmallOrMediumScreen && 'step-4'}>
                        <h4>Register your organisation</h4>
                        <FontAwesomeIcon
                            className="icon"
                            icon={faChevronRight}
                        />
                    </div>
                </Link>
                {/* <Link
                    to="/funding"
                    onClick={(e) =>
                        useEventTracking(
                            'Dashboard',
                            "Clicked I'm Looking For Option",
                            'Impact Funding'
                        )
                    }
                >
                    <div>
                        <h4> Impact Funding</h4>
                        <FontAwesomeIcon
                            className="icon"
                            icon={faChevronRight}
                        />
                    </div>
                </Link>
                <Link
                    to="/jobs"
                    onClick={(e) =>
                        useEventTracking(
                            'Dashboard',
                            "Clicked I'm Looking For Option",
                            'Impact Jobs'
                        )
                    }
                >
                    <div>
                        <h4> Impact Jobs</h4>
                        <FontAwesomeIcon
                            className="icon"
                            icon={faChevronRight}
                        />
                    </div>
                </Link>
                <Link
                    to="/landscapes"
                    onClick={(e) =>
                        useEventTracking(
                            'Dashboard',
                            "Clicked I'm Looking For Option",
                            'Idea Validation'
                        )
                    }
                >
                    <div>
                        <h4> Idea Validation</h4>
                        <FontAwesomeIcon
                            className="icon"
                            icon={faChevronRight}
                        />
                    </div>
                </Link>
                <Link
                    to="/resources"
                    onClick={(e) =>
                        useEventTracking(
                            'Dashboard',
                            "Clicked I'm Looking For Option",
                            'Impact Resources'
                        )
                    }
                >
                    <div>
                        <h4> Impact Resources</h4>
                        <FontAwesomeIcon
                            className="icon"
                            icon={faChevronRight}
                        />
                    </div>
                </Link> */}
                {/* </Route> */}
            </div>
        </LinksStyle>
    );
};

export default Links;
