/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import IUser from '../../interfaces/IUser';
import getSrc from '../../utils/commonFunction';
import ProfileFollowButton from './ProfileFollowButton';

const Container = styled.div`
    max-height: auto;
    overflow-y: auto;
    margin: 0 0.4rem;

    .profile {
        display: flex;
        box-shadow: 0 0 3px 7px #f1f2f2;
        padding: 0.5rem;
        cursor: pointer;
        justify-content: stretch;
        align-items: center;
    }

    .profile:not(:last-child) {
        margin-bottom: 1.5rem;
    }

    .profile-picture {
        width: 50px;
        height: 50px;
        margin: 0 1rem 0 0.5rem;
    }

    .profile-picture img {
        border-radius: 50%;
        width: inherit;
        height: inherit;
        object-fit: cover;
        overflow: hidden;
    }

    .center {
        flex-grow: 1;
    }

    .name {
        margin-bottom: 0.2rem 0;
    }

    .name h2 {
        font-weight: bold;
    }

    .group {
        display: flex;
        flex-wrap: wrap;
    }

    .group h4 {
        font-size: 0.8rem;
    }

    .interest-area {
        font-size: 0.8rem;
        font-weight: bold;
        background-color: #ffeab6;
        color: #826c42;
        border-radius: 8px;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 4px;
    }
`;

const ProfileList: React.FC<{
    entries: IUser[];
    onSelectUser?: (user: IUser) => void;
}> = ({ entries, onSelectUser }) => (
    <Container>
        {entries.map((entry) => (
            <div
                key={entry._id}
                className="profile"
                onClick={() => onSelectUser?.(entry)}
                role="button"
            >
                <div className="profile-picture">
                    <img src={getSrc(entry)} alt="" />
                </div>

                <div className="center">
                    <div className="name">
                        <h2>{`${entry.firstName} ${entry.lastName}`}</h2>
                    </div>
                    <div className="group">
                        <h4>{`${entry.currentRole} ${
                            entry.organisationName && ', '
                        } ${entry.organisationName}`}</h4>
                    </div>
                    <div
                        className="group"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        {entry.interestAreas.map((area) => (
                            <div className="interest-area" key={area}>
                                {area}
                            </div>
                        ))}
                        <h4>{entry.country}</h4>
                    </div>
                </div>
                <ProfileFollowButton
                    entryUsername={entry.username}
                    entryId={entry._id}
                />
            </div>
        ))}
    </Container>
);

export default ProfileList;
