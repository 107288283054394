/* eslint-disable no-underscore-dangle */
import React, { RefObject } from 'react';
import { Map as LeafletMap } from 'leaflet';
import styled from 'styled-components';
import { Tab, Tabs } from 'react-bootstrap';
import ProfileList from './ProfileList';
import LandscapesList from './LandscapesList';
import { CommunitySelection, PopupMap, SearchResults } from './Types';
import OrganisationsList from './OrganisationsList';
import ILandscape from '../../interfaces/ILandscape';
import IVolunteerOpportunity from '../../interfaces/IVolunteerOpportunity';
import IOpportunity from '../../interfaces/IOpportunity';
import OpportunitiesList from './OpportunitiesList';
import { useEventTracking } from '../../pages/eventTracking';

const ResultsBox = styled.div`
    height: 41.5vh;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    padding: 0.5rem 0;

    h1 {
        margin-bottom: 2rem;
        font-weight: bold;
    }
`;

export default function SearchResultsView({
    userOrgs,
    searchResults,
    communityPopups,
    landscapePopups,
    opportunitiesPopups,
    map
}: {
    userOrgs: string[];
    searchResults: SearchResults;
    communityPopups: RefObject<PopupMap<CommunitySelection>>;
    landscapePopups: RefObject<PopupMap<ILandscape>>;
    opportunitiesPopups: RefObject<PopupMap<IVolunteerOpportunity>>;
    map: RefObject<LeafletMap | undefined>;
}) {
    const Results = () => {
        if (searchResults.type === 'community') {
            return (
                <Tabs
                    defaultActiveKey={
                        searchResults.results.filter(
                            (r) => r.value.users.length > 0
                        ).length > 0
                            ? 'users'
                            : 'organisations'
                    }
                >
                    <Tab title="Users" eventKey="users">
                        <ProfileList
                            entries={searchResults.results.flatMap(
                                (r) => r.value.users
                            )}
                            onSelectUser={(user) => {
                                if (communityPopups.current) {
                                    const {
                                        popup,
                                        setSelected
                                    } = communityPopups.current[user._id];
                                    setSelected({ type: 'user', user });
                                    // For some reason, the map might crash here if we dont check this
                                    if (popup.getLatLng()) {
                                        map.current?.openPopup(popup);
                                    }
                                }
                            }}
                        />
                    </Tab>
                    <Tab title="Organisations" eventKey="organisations">
                        <OrganisationsList
                            entries={searchResults.results.flatMap(
                                (r) => r.value.organisations
                            )}
                            onItemClick={(organisation) => {
                                if (communityPopups.current) {
                                    const {
                                        popup,
                                        setSelected
                                    } = communityPopups.current[
                                        organisation._id
                                    ];
                                    setSelected({
                                        type: 'organisation',
                                        organisation
                                    });
                                    // For some reason, the map might crash here if we dont check this
                                    if (popup.getLatLng()) {
                                        map.current?.openPopup(popup);
                                    }
                                }
                            }}
                        />
                    </Tab>
                </Tabs>
            );
        }
        // Else landscapes
        // Dedupe search results when flattening them into a single list
        if (searchResults.type === 'landscapes') {
            const entries: ILandscape[] = [];
            searchResults.results
                .flatMap((r) => r.value)
                .forEach((ls) => {
                    if (!entries.find((e) => e._id === ls._id)) {
                        entries.push(ls);
                    }
                });
            return (
                <LandscapesList
                    entries={entries}
                    onItemClick={(landscape) => {
                        if (landscapePopups.current) {
                            const {
                                popup,
                                setSelected
                            } = landscapePopups.current[landscape._id];
                            setSelected(landscape);
                            map.current?.openPopup(popup);
                        }
                    }}
                />
            );
        }
        if (searchResults.type === 'opportunities') {
            const entries: IVolunteerOpportunity[] = [];
            searchResults.results
                .flatMap((r) => r.value)
                .forEach((o) => {
                    if (!entries.find((e) => e._id === o._id)) {
                        entries.push(o);
                    }
                });
            return (
                <OpportunitiesList
                    userOrgs={userOrgs}
                    entries={entries}
                    onItemClick={(opportunity) => {
                        useEventTracking(
                            'Dashboard Map (Volunteering Opportunities)',
                            'Viewed Volunteering Opportunity',
                            `${opportunity.title}`
                        );
                        if (opportunitiesPopups.current) {
                            const {
                                popup,
                                setSelected
                            } = opportunitiesPopups.current[opportunity._id];
                            setSelected(opportunity);
                            map.current?.openPopup(popup);
                        }
                    }}
                />
            );
        }
        return <></>;
    };
    return (
        <ResultsBox>
            <h1>Results</h1>
            {searchResults.results.length > 0 ? (
                <Results />
            ) : (
                <h2>No results</h2>
            )}
        </ResultsBox>
    );
}
