import React from 'react';

const EcosystemContactIcon = () => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.6252 11.174L17.2682 12.75C17.8142 13.076 18.0961 13.71 17.9711 14.334C17.4601 16.893 14.8902 18.533 12.3732 17.842C6.4342 16.212 1.79509 11.587 0.159086 5.635C-0.532914 3.117 1.10519 0.544978 3.66519 0.0329775L3.68204 0.0299868C4.30704 -0.0950132 4.94419 0.187988 5.26919 0.736988L6.83218 3.376C7.38818 4.314 7.11203 5.52398 6.20303 6.12798L4.54214 7.23299C5.71414 10.04 7.95408 12.289 10.7531 13.459L11.8681 11.794C12.4771 10.887 13.6872 10.615 14.6252 11.174ZM14.7502 6.99999C14.7502 4.93199 13.0672 3.24999 11.0002 3.24999C10.5862 3.24999 10.2502 3.58599 10.2502 3.99999C10.2502 4.41399 10.5862 4.74999 11.0002 4.74999C12.2402 4.74999 13.2502 5.75899 13.2502 6.99999C13.2502 7.41399 13.5862 7.74999 14.0002 7.74999C14.4142 7.74999 14.7502 7.41399 14.7502 6.99999ZM17.0002 7.74999C16.5862 7.74999 16.2502 7.41399 16.2502 6.99999C16.2502 4.10499 13.8952 1.74999 11.0002 1.74999C10.5862 1.74999 10.2502 1.41399 10.2502 0.999988C10.2502 0.585988 10.5862 0.249988 11.0002 0.249988C14.7222 0.249988 17.7502 3.27799 17.7502 6.99999C17.7502 7.41399 17.4142 7.74999 17.0002 7.74999Z"
            fill="#25314C"
        />
    </svg>
);

export default EcosystemContactIcon;
