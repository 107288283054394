import axios from 'axios';
import React from 'react';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Ecosystem, JoinAs } from '../../interfaces/Ecosystem';

const ImageContainer = styled.div`
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5rem;

    img {
        object-fit: contain;
        width: 6rem;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        padding: 0.25rem;
        aspect-ratio: 1;
    }
`;

const Title = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #002a52;
`;

const Description = styled.p`
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #315578;
`;

interface Props {
    show: boolean;
    ecosystem: Ecosystem;
    memberId: string;
    handleClose: () => void;
}

const EcosystemLeaveModal: React.FC<Props> = ({
    show,
    ecosystem,
    memberId,
    handleClose
}) => {
    const history = useHistory();

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        axios
            .put<Ecosystem>(
                `/api/ecosystems/${ecosystem._id}/remove/${memberId}`,
                {
                    removeAs: JoinAs.USER
                }
            )
            .then((data) => {
                console.log(data.data);
                history.go(0);
            })
            .catch((err) => console.log(err.response.data));
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton className="border-0">
                <Modal.Title className="ml-3 text-center">
                    Are you sure you want to remove?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-3">
                <Row className="mb-2">
                    <Col sm={3}>
                        <ImageContainer>
                            <Image
                                className="img-thumbnail"
                                src={
                                    ecosystem.logo
                                        ? ecosystem.logo.location
                                        : `${process.env.PUBLIC_URL}/assets/interseed.png`
                                }
                                alt="Ecosystem"
                                roundedCircle
                                thumbnail
                                fluid
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                }}
                            />
                        </ImageContainer>
                    </Col>
                    <Col>
                        <Title>{ecosystem.name}</Title>
                        <Description>{ecosystem.name}</Description>
                    </Col>
                </Row>
                <Button
                    className="btn-block"
                    variant="danger"
                    onClick={handleDelete}
                >
                    Remove
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default EcosystemLeaveModal;
