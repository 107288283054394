import { IDecodedToken } from '../../interfaces/AuthInterface';
import IUser from '../../interfaces/IUser';

// Error actions
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAN_UP_ERRORS = 'CLEAN_UP_ERRORS';

export interface AuthErrors {
    firstName?: string;
    username?: string;
    email?: string;
    password?: string;
    password2?: string;
    message?: string;
}

interface IGetErrorsAction {
    type: typeof GET_ERRORS;
    payload: AuthErrors;
    source: string;
}

interface ICleanUpErrorsAction {
    type: typeof CLEAN_UP_ERRORS;
}

export type ErrorActionTypes = IGetErrorsAction | ICleanUpErrorsAction;

// Auth actions
export const USER_LOADING = 'USER_LOADING';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_USER_STATUS = 'SET_USER_STATUS';

interface IUserLoadingAction {
    type: typeof USER_LOADING;
    payload: boolean;
}
interface ISetCurrentUserAction {
    type: typeof SET_CURRENT_USER;
    payload: IDecodedToken | Record<string, never>;
}

interface ISetUserStatusAction {
    type: typeof SET_USER_STATUS;
    payload: string;
}

export type AuthActionTypes =
    | IUserLoadingAction
    | ISetCurrentUserAction
    | ISetUserStatusAction;

// States
export interface AuthState {
    isAuthenticated: boolean;
    user: {
        _id: string;
        username: string;
        email: string;
        iat: number;
        exp: number;
    };
    status: string;
    loading: boolean;
}

export interface ErrorState {
    login: AuthErrors;
    register: AuthErrors;
    resetPassword: AuthErrors;
}

export interface UserProfileState {
    userProfile: IUser | undefined;
    loading: boolean;
}

export interface StoreState {
    auth: AuthState;
    errors: ErrorState;
    userProfile: UserProfileState;
}

// User Profile actions
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const USER_PROFILE_LOADING = 'USER_PROFILE_LOADING';

interface ISetUserProfileAction {
    type: typeof SET_USER_PROFILE;
    payload: IUser | undefined;
}
interface IUserProfileLoadingAction {
    type: typeof USER_PROFILE_LOADING;
    payload: boolean;
}
export type UserProfileActionTypes =
    | ISetUserProfileAction
    | IUserProfileLoadingAction;
