import axios from 'axios';
import React, { useEffect, ChangeEvent, useState } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import isEmpty from 'is-empty';
import {
    Container,
    Row,
    Button,
    Card,
    Col,
    Form,
    Modal
} from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBookReader,
    faChartLine,
    faUsers
} from '@fortawesome/free-solid-svg-icons';
import Google from '../components/login/Google';
import LinkedInComponent from '../components/login/LinkedInComponent';
import { loginUser } from '../redux/actions/AuthActions';
import {
    ErrorState,
    AuthState,
    StoreState,
    AuthErrors
} from '../redux/actions/types';
import { IUserData } from '../interfaces/AuthInterface';
import { useEventTracking } from './eventTracking';

// Interface for props in this component
interface loginProps extends RouteComponentProps {
    loginUser: (
        arg0: IUserData,
        arg1: RouteComponentProps['history'],
        arg2: boolean
    ) => void;
    auth: AuthState;
    error: ErrorState;
    history: RouteComponentProps['history'];
    refreshNav: () => void;
}

interface loginUserInput {
    email: string;
    password: string;
    errors: AuthErrors;
}

// Styles
const LoginForm = styled.div`
    width: 496px;

    @media screen and (max-width: 576px) {
        width: 100%;
    }

    margin-bottom: 2rem;
`;

const FrontPageStyle = styled.div`
    @media screen and (max-width: 576px) {
        height: 100%;
        padding: 0;
    }

    .bgRow {
        height: 100%;
        background: url('https://interseedstorage.s3.ap-southeast-1.amazonaws.com/interseed_white_transparent.png'),
            linear-gradient(to right, #409de3 60%, #f1f0f3 0%);
        margin-left: -20vh;
        padding-left: 20vh;
        background-repeat: no-repeat;
        background-position: 50% 90%;

        @media screen and (max-width: 576px) {
            background: url('https://interseedstorage.s3.ap-southeast-1.amazonaws.com/interseed_white_transparent.png'),
                linear-gradient(to right, #409de3 100%, #f1f0f3 0%);
            background-repeat: no-repeat;
            background-position: 50% 10%;
        }
    }

    .rowStyle {
        margin-bottom: 20px;
    }

    .textRow {
        width: 100vw;
        margin: 0;
    }

    .title {
        font-size: 5rem;
        margin-bottom: 0;

        @media screen and (max-width: 576px) {
            font-size: 3rem;
            margin-top: 1rem;
            margin-bottom: 0;
        }
    }
`;

const InfoStyle = styled.div`
    text-align: left;
    color: white;
`;

const LinkButton = styled.div`
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    color: #0645ad;
    font-size: 15px;
    line-height: 3;
`;

// Main Functional Component
const Login: React.FC<loginProps> = (props: loginProps) => {
    const initSecond = 20;
    const initMinute = 0;
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [disableResend, setDisableResend] = useState(false);
    const [disableResetPassword, setDisableResetPassword] = useState(false);
    const [show, setShow] = useState(false);
    const [showResend, setShowResend] = useState(false);
    const [text, setText] = useState('');

    // States for Reset Password Timer
    const [secondResetPassword, setSecondResetPassword] = useState(initSecond);
    const [minuteResetPassword, setMinuteResetPassword] = useState(initMinute);
    const [showResetPasswordTimer, setShowResetPasswordTimer] = useState(false);

    // States for Resend Confirmation Timer
    const [secondResendConfirmation, setSecondResendConfirmation] = useState(
        initSecond
    );
    const [minuteResendConfirmation, setMinuteResendConfirmation] = useState(
        initMinute
    );
    const [
        showResendConfirmationTimer,
        setShowResendConfirmationTimer
    ] = useState(false);

    const [userInput, setUserInput] = useState<loginUserInput>({
        email: '',
        password: '',
        errors: {
            email: '',
            password: '',
            message: ''
        }
    });

    // If user is authenticated, redirect them to the dashboard page
    useEffect(() => {
        if (props.auth.isAuthenticated) {
            props.history.push('/dashboard');
            props.refreshNav();
        }
    }, [props.auth.isAuthenticated]);

    // Set user input with errors if they are present upon login
    useEffect(() => {
        if (props.error.login) {
            setUserInput({
                ...userInput,
                errors: props.error.login
            });
            setDisableSubmit(false);

            if (
                props.error.login.message ===
                'Please verify your email to activate your account'
            ) {
                setShowResend(true);
                setShowResetPasswordTimer(false);
            } else {
                setShowResend(false);
            }
        }
    }, [props.error.login]);

    // Timer for reset password
    useEffect(() => {
        if (disableResetPassword) {
            if (minuteResetPassword === 0 && secondResetPassword === 0) {
                setMinuteResetPassword(initMinute);
                setSecondResetPassword(initSecond);
                setDisableResetPassword(false);
            } else if (secondResetPassword > 0) {
                setTimeout(
                    () => setSecondResetPassword(secondResetPassword - 1),
                    1000
                );
            } else if (minuteResetPassword > 0 && secondResetPassword === 0) {
                setTimeout(() => {
                    setSecondResetPassword(59);
                    setMinuteResetPassword(minuteResetPassword - 1);
                }, 1000);
            }
        }
    }, [secondResetPassword, minuteResetPassword, disableResetPassword]);

    // Timer for resend confirmation email
    useEffect(() => {
        if (disableResend) {
            if (
                minuteResendConfirmation === 0 &&
                secondResendConfirmation === 0
            ) {
                setMinuteResendConfirmation(initMinute);
                setSecondResendConfirmation(initSecond);
                setDisableResend(false);
            } else if (secondResendConfirmation > 0) {
                setTimeout(
                    () =>
                        setSecondResendConfirmation(
                            secondResendConfirmation - 1
                        ),
                    1000
                );
            } else if (
                minuteResendConfirmation > 0 &&
                secondResendConfirmation === 0
            ) {
                setTimeout(() => {
                    setSecondResendConfirmation(59);
                    setMinuteResendConfirmation(minuteResendConfirmation - 1);
                }, 1000);
            }
        }
    }, [secondResendConfirmation, minuteResendConfirmation, disableResend]);

    // Update user input whenever user changes something in the input fields
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setUserInput({ ...userInput, [id]: value, errors: {} });
        setShowResend(false);
        setShowResetPasswordTimer(false);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (disableSubmit) return;
        setDisableSubmit(true);
        const userData = {
            email: userInput.email,
            password: userInput.password
        };

        props.loginUser(userData, props.history, false);
    };

    const handlePasswordResetClick = () => {
        if (disableResetPassword) {
            setShowResetPasswordTimer(true);
            return;
        }

        setDisableResetPassword(true);
        setShowResend(false);

        if (isEmpty(userInput.email)) {
            const errors = { email: 'Please input your email!' };
            setUserInput({
                ...userInput,
                errors
            });
            setDisableResetPassword(false);
        } else {
            const data = { email: userInput.email };
            axios
                .post(`/api/users/passwordReset`, data)
                .then(() => {
                    setShow(true);
                    setShowResetPasswordTimer(true);
                    setText('reset your password');
                    setDisableResetPassword(true);
                })
                .catch((err) => {
                    setDisableResetPassword(false);
                    if (err.response && isEmpty(err.response.data)) {
                        props.history.push('/error500');
                    } else {
                        const errors = {
                            email: err.response.data.message
                        };
                        setUserInput({
                            ...userInput,
                            errors
                        });
                    }
                });
        }
    };

    const handleResendClick = () => {
        if (disableResend) {
            setShowResendConfirmationTimer(true);
            return;
        }

        if (isEmpty(userInput.email)) {
            const errors = { email: 'Please input your email!' };
            setUserInput({
                ...userInput,
                errors
            });
            setShowResend(false);
            setDisableResend(false);
        } else {
            const data = { email: userInput.email };
            axios
                .post(`/api/users/resend-email`, data)
                .then(() => {
                    setShowResendConfirmationTimer(true);
                    setDisableResend(true);
                    setShow(true);
                    setText('verify your account!');
                })
                .catch(() => {
                    setDisableResend(false);
                    props.history.push('/error500');
                });

            setUserInput({
                ...userInput,
                errors: {}
            });
            setShowResend(false);
        }
    };

    const handleClose = () => {
        setShow(false);
    };

    const { errors } = userInput;

    return (
        <FrontPageStyle>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Header closeButton>Success!</Modal.Header>
                <Modal.Body>
                    An email has been sent to your inbox at {userInput.email}
                    <br />
                    Click the link in the email to {text}
                    <br />
                    <br />
                    <i>
                        Note: Due to the high traffic, it might take up to 5
                        minutes for the email to be sent
                    </i>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Row className="justify-content-center">
                <Col
                    md={4}
                    xs={11}
                    className="d-flex justify-content-center align-self-center"
                >
                    <LoginForm>
                        <Card className="py-4 px-4 shadow">
                            <Form noValidate onSubmit={onSubmit}>
                                <div>
                                    <h4 className="mb-3">
                                        <b>Login</b>
                                    </h4>
                                </div>

                                {/* OAuth Buttons */}
                                <div className="mb-2">
                                    <Google
                                        setDisableSubmit={setDisableSubmit}
                                    />
                                </div>
                                <div>
                                    <LinkedInComponent
                                        setDisableSubmit={setDisableSubmit}
                                    />
                                </div>

                                <p className="or-divider">
                                    <span>or</span>
                                </p>

                                <Form.Control
                                    onChange={onChange}
                                    value={userInput.email}
                                    id="email"
                                    type="email"
                                    placeholder="Email address"
                                    className={classnames('', {
                                        invalid: errors.email
                                    })}
                                />
                                <Form.Text className="text-danger mb-2">
                                    {errors.email}
                                </Form.Text>
                                <Form.Control
                                    onChange={onChange}
                                    value={userInput.password}
                                    id="password"
                                    type="password"
                                    placeholder="Password"
                                    className={classnames('', {
                                        invalid: errors.password
                                    })}
                                />
                                <Form.Text className="text-danger mb-2">
                                    {errors.password}
                                </Form.Text>
                                <Form.Text className="text-danger mb-2">
                                    {errors.message}
                                </Form.Text>
                                {showResend && props.error.login.message ? (
                                    <Row className="justify-content-center">
                                        <Form.Text className="text-muted mb-2">
                                            Didn&apos;t receive email? &nbsp;
                                        </Form.Text>
                                        {disableResend &&
                                        showResendConfirmationTimer ? (
                                            <Form.Text className="text-muted mb-2">
                                                Try again in &nbsp;
                                                {minuteResendConfirmation > 0
                                                    ? `${minuteResendConfirmation} minutes `
                                                    : ''}
                                                {secondResendConfirmation}{' '}
                                                seconds
                                            </Form.Text>
                                        ) : (
                                            <Form.Text
                                                onClick={handleResendClick}
                                                className="text-primary mb-2"
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                Resend email
                                            </Form.Text>
                                        )}
                                    </Row>
                                ) : null}
                                <LinkButton
                                    onClick={handlePasswordResetClick}
                                    className="link-button"
                                    style={{
                                        cursor: disableResetPassword
                                            ? 'default'
                                            : 'pointer'
                                    }}
                                >
                                    Forgot password?
                                </LinkButton>
                                {disableResetPassword &&
                                showResetPasswordTimer ? (
                                    <Form.Text className="text-danger mb-2">
                                        Didn&apos;t receive the email? Try again
                                        in &nbsp;
                                        {minuteResetPassword > 0
                                            ? `${minuteResetPassword} minutes `
                                            : ''}
                                        {secondResetPassword} seconds
                                    </Form.Text>
                                ) : null}
                                <Button
                                    type="submit"
                                    variant="primary"
                                    onClick={() => {
                                        useEventTracking(
                                            'Log In',
                                            'Clicked Login',
                                            `No Third Party Auth`
                                        );
                                    }}
                                    disabled={disableSubmit}
                                    block
                                >
                                    Login
                                </Button>
                            </Form>
                            <hr />
                            <Button as={Link} to="/register" variant="success">
                                Sign up
                            </Button>
                        </Card>
                    </LoginForm>
                </Col>
            </Row>
        </FrontPageStyle>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    error: state.errors
});

export default connect(mapStateToProps, { loginUser })(withRouter(Login));
