import optionType from '../interfaces/OptionType';
import { populateArray } from '../utils/commonFunction';

const countries = [
    'Brunei',
    'Myanmar',
    'Cambodia',
    'Indonesia',
    'Laos',
    'Malaysia',
    'Philippines',
    'Singapore',
    'Thailand',
    'Vietnam',
    'Timor-Leste',
    'Global',
    'Across Southeast Asia'
];

const focusAreas = [
    'Food & Agriculture',
    'Circular Economy',
    'Energy Transitions',
    'Social Causes'
];

const startupStage = [
    'Idea Stage',
    'Proof of Concept / Prototype Stage',
    'Market / Revenue Generating Stage',
    'Growth and Expansion Stage'
];

const industries = [
    'Agriculture & Forestry / Wildlife',
    'Apparel & Fashion',
    'Automotive',
    'Business & Information Services',
    'Chemical Industries',
    'Commerce',
    'Construction',
    'Design',
    'Education Management',
    'Events Services',
    'Financial Services; Professional Services',
    'Food & Beverages',
    'Forestry; Wood; Pulp and Paper',
    'Gaming',
    'Hospital, Healthcare Services',
    'Hotels; Tourism; Catering',
    'Information Technology & Services',
    'Investment Management',
    'Legal',
    'Logistics & Supply Chain',
    'Management Consulting',
    'Maritime',
    'Marketing & Advertising',
    'Mechanical and Electrical Engineering',
    'Media; Culture; Graphical',
    'Mining (Coal; Other mining)',
    'Natural Resources, Environmental Services',
    'Oil and Gas Production, Refining',
    'Program Development',
    'Public Service',
    'Research',
    'Retail',
    'Think Tanks',
    'Transport (including civil aviation; railways; road transport)',
    'Utilities (water; gas; electricity)'
];

const impactAreas = [
    'GOAL 1: No Poverty',
    'GOAL 2: Zero Hunger',
    'GOAL 3: Good Health and Well-being',
    'GOAL 4: Quality Education',
    'GOAL 5: Gender Equality',
    'GOAL 6: Clean Water and Sanitation',
    'GOAL 7: Affordable and Clean Energy',
    'GOAL 8: Decent Work and Economic Growth',
    'GOAL 9: Industry, Innovation and Infrastructure',
    'GOAL 10: Reduced Inequality',
    'GOAL 11: Sustainable Cities and Communities',
    'GOAL 12: Responsible Consumption and Production',
    'GOAL 13: Climate Action',
    'GOAL 14: Life Below Water',
    'GOAL 15: Life on Land',
    'GOAL 16: Peace and Justice Strong Institutions',
    'GOAL 17: Partnerships for the Goals'
];

const lookingFor = [
    'Funding Opportunities',
    'A team to work with',
    'Collaboration Opportunities',
    'Knowledge & Resources',
    'Community'
];

const fundingType = [
    'Seed Stage',
    'Series A',
    'Series B',
    'Crowdfunding',
    'Others',
    'Not looking for funding'
];

export const countriesOptions: optionType[] = populateArray(countries, true);
export const startupStageOptions: optionType[] = populateArray(
    startupStage,
    false
);
export const focusAreasOptions: optionType[] = populateArray(focusAreas, true);
export const industriesOptions: optionType[] = populateArray(industries, true);
export const impactAreasOptions: optionType[] = populateArray(
    impactAreas,
    false
);
export const lookingForOptions: optionType[] = populateArray(lookingFor, true);
export const fundingTypeOptions: optionType[] = populateArray(
    fundingType,
    false
);
