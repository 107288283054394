/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React from 'react';
import styled from 'styled-components';
import LandscapeItem from './LandscapeItem';
import ILandscape from '../../interfaces/ILandscape';

const Styles = styled.div`
    max-height: 300px;
    overflow-y: auto;
`;

const LandscapesList: React.FC<{
    entries: ILandscape[];
    onItemClick?: (landscape: ILandscape) => void;
}> = ({ entries, onItemClick }) => (
    <Styles>
        {entries.map((item) => (
            <LandscapeItem
                key={item._id}
                landscape={item}
                onClick={onItemClick}
            />
        ))}
    </Styles>
);

export default LandscapesList;
