import optionType from '../interfaces/OptionType';
import { populateArray } from '../utils/commonFunction';

const interestArea = [
    'Food & Agriculture',
    'Circular Economy',
    'Energy Transitions',
    'Social Causes'
];

const involvement = [
    "I'm working in the sustainability space",
    "I'm running an impact startup/business",
    'I have an idea to work on/problem I want to solve',
    "I'm volunteering in the sustainability space",
    "I'm not working in the space but want to be involved",
    "I'm a student pursuing a sustainability related degree"
];

const lookingfor = [
    'Funding Opportunities',
    'A team to work with',
    'Collaboration Opportunities',
    'Knowledge & Resources',
    'Community'
];

const skills = [
    'Primary & Secondary Research',
    'Engineering',
    'Product Design',
    'Marketing & Branding',
    'Business Development & Sales',
    'Operations & Management',
    'Data Analytics and Visualisation',
    'Finance and Accounting',
    'Video Production',
    'Graphic Design',
    'Fundraising/VC',
    'Product Strategy',
    'Project Management',
    'Data Science',
    'Community Engagement',
    'Research and Development',
    'Integrated Coastal Management (ICM)',
    'Supply Chain',
    'Digital Marketing (CRM, Social, Web)',
    'Legal'
];

const countries = [
    'Brunei',
    'Myanmar',
    'Cambodia',
    'Indonesia',
    'Laos',
    'Malaysia',
    'Philippines',
    'Singapore',
    'Thailand',
    'Vietnam',
    'Timor-Leste'
];

const others = "Others"
const othersOption: optionType = {value: others, label: others}

export const interestOptions: optionType[] = populateArray(interestArea, true);
export const involvementOptions: optionType[] = populateArray(
    involvement,
    true
);
export const lookingForOptions: optionType[] = populateArray(lookingfor, true);
export const skillsOptions: optionType[] = populateArray(skills, true).concat([othersOption]);
export const countriesOptions: optionType[] = populateArray(countries, true);
