/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, { useState, useEffect } from 'react';
import {
    ListGroup,
    Container,
    Row,
    Col,
    Badge,
    FormCheck
} from 'react-bootstrap';
import styled from 'styled-components';

import IUser from '../../interfaces/IUser';
import getSrc, { checkEmail } from '../../utils/commonFunction';

interface userProp {
    user: IUser;
    isMasterChecked: boolean;
    userEmailList: string[];
    handleCheckbox: (email: string, type: string, isChecked: boolean) => void;
}

const Styles = styled.div`
    h2 {
        margin-bottom: 0.2rem;
    }

    .list-group-item {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .imgcontainer {
        overflow: hidden;
        position: relative;
        width: 100px;
        margin-right: 10px;

        &::after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }

        img {
            position: absolute;
            object-fit: cover;
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    .badge {
        font-size: 16px;
    }
`;

const ListText = styled.p`
    margin-bottom: 0;
`;

const UserItem: React.FC<userProp> = ({
    user,
    isMasterChecked,
    userEmailList,
    handleCheckbox
}: userProp) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(checkEmail(userEmailList, user));
    }, [userEmailList]);

    return (
        <>
            <Styles>
                <ListGroup.Item>
                    <Container>
                        <Row>
                            <span className="imgcontainer">
                                <img src={getSrc(user)} alt="" />
                            </span>
                            <Col className="my-auto" xs>
                                <h2>
                                    {user.firstName} {user.lastName}
                                </h2>
                                <ListText>{user.currentRole}</ListText>
                                <ListText className="text-muted">
                                    {user.country}
                                </ListText>
                                <ListText className="text-muted">
                                    {user.lookingFor}
                                </ListText>
                                <p className="mt-2">
                                    {user.interestAreas
                                        ? user.interestAreas
                                              .sort()
                                              .map((interest) => (
                                                  <Badge variant="light">
                                                      {interest}
                                                  </Badge>
                                              ))
                                        : null}
                                </p>
                            </Col>
                            <Col className="my-auto" xs={2}>
                                <FormCheck
                                    type="checkbox"
                                    id="select-user"
                                    checked={isMasterChecked || isChecked}
                                    onChange={() => {
                                        handleCheckbox(
                                            user.email,
                                            'user',
                                            !isChecked
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ListGroup.Item>
            </Styles>
        </>
    );
};

export default UserItem;
