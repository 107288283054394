import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    Badge,
    Button,
    Card,
    Col,
    Image,
    ListGroup,
    Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import IUser, { UserEcosystem } from '../../interfaces/IUser';
import { useEventTracking } from '../../pages/eventTracking';
import { AuthState } from '../../redux/actions/types';

const Styles = styled.div`
    .list-group-item {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;
        border: 1px solid #ddd;
        margin: 10px 0;
        border-radius: 8px;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .imgcontainer {
        display: flex;
        overflow: hidden;
        position: relative;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;

        img {
            object-fit: contain;
            width: 6rem;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #dee2e6;
            padding: 0.25rem;
            aspect-ratio: 1;
        }
    }

    .badge {
        font-size: 16px;
    }
`;

const ListHeading = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #002a52;
`;

const FocusAreas = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;

    & > div {
        background-color: #d3e8ff;
        color: #2b6098;
        padding: 7px 9px;
        border-radius: 8px;
        font-size: 14px;
    }
`;

interface EcosystemListProps extends Pick<IUser, 'ecosystems'> {
    auth: AuthState;
}

interface EcosystemListItemProps {
    ecosystem: UserEcosystem;
    auth: AuthState;
}

const EcosysytemListItem: React.FC<EcosystemListItemProps> = ({
    ecosystem,
    auth
}) => {
    const [joined, setJoined] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);

    const handleJoin = (memberId: string) => {
        if (disabled) return;
        setDisabled(true);
        if (joined) {
            axios
                .put(`/api/ecosystems/${ecosystem._id}/remove/${memberId}`)
                .then(() => {
                    setJoined(false);
                    setDisabled(false);
                });
        } else {
            axios
                .put(`/api/ecosystem/${ecosystem._id}/join/${memberId}`)
                .then(() => {
                    setJoined(true);
                    setDisabled(false);
                });
        }
    };

    useEffect(() => {
        if (!auth.user._id) return;

        for (let i = 0; i < ecosystem.members.length; i += 1) {
            if (ecosystem.members[i] === auth.user._id) {
                setJoined(true);
                break;
            }
        }
        setDisabled(false);
    }, []);

    return (
        <ListGroup.Item
            as={Link}
            to={`/ecosystem/${ecosystem._id}`}
            onClick={() => {
                if (
                    `${
                        window.location.pathname.split(
                            /\B\/([a-zA-Z0-9-]{0,})/
                        )[1]
                    }` === 'ecosystem'
                ) {
                    useEventTracking(
                        `My Ecosystem`,
                        'Viewed Ecosystem Profile',
                        `${ecosystem.name}`
                    );
                } else {
                    useEventTracking(
                        `Community`,
                        'Viewed Ecosystem Profile',
                        `${ecosystem.name}`
                    );
                }
            }}
        >
            <Row>
                <Col sm={2} className="imgcontainer">
                    <Image
                        className="img-thumbnail"
                        src={
                            ecosystem.logo
                                ? ecosystem.logo.location
                                : `${process.env.PUBLIC_URL}/assets/interseed.png`
                        }
                        alt="Ecosystem"
                        roundedCircle
                        thumbnail
                        fluid
                        onError={(e) => {
                            e.currentTarget.onerror = null; // prevents looping
                            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/interseed.png`;
                        }}
                    />
                </Col>
                <Col className="my-auto text-sm-left text-center">
                    <ListHeading>{ecosystem.name}</ListHeading>
                    <FocusAreas className="mt-2">
                        {ecosystem.focusAreas.sort().map((focus) => (
                            <div>{focus}</div>
                        ))}
                    </FocusAreas>
                </Col>
                <Col sm={2} className="my-auto text-center">
                    {joined ? (
                        <Badge pill variant="light" className="mt-3">
                            <FontAwesomeIcon icon={faUser} /> Member
                        </Badge>
                    ) : (
                        <>
                            {auth.user._id ? (
                                <Button
                                    variant={
                                        !joined ? 'outline-primary' : 'primary'
                                    }
                                    className="mt-2 ml-3"
                                    onClick={() => handleJoin(auth.user._id)}
                                    disabled={disabled}
                                >
                                    {!joined ? 'Join' : 'Leave'}
                                </Button>
                            ) : (
                                <Button
                                    variant="outline-primary"
                                    className="mt-2"
                                    as={Link}
                                    to="/login"
                                >
                                    Join
                                </Button>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </ListGroup.Item>
    );
};

const EcosystemList: React.FC<EcosystemListProps> = ({ ecosystems, auth }) => (
    <Styles>
        {ecosystems.map((ecosystem) => (
            <EcosysytemListItem
                ecosystem={ecosystem}
                auth={auth}
                key={ecosystem._id}
            />
        ))}
    </Styles>
);

export default EcosystemList;
