import IStaff from './IStaff';
import IUser from './IUser';

export default interface IOrganisation {
    name: string;
    logo: {
        location: string;
        key: string;
    };
    city: string;
    country: string;
    startupStage: string;
    yearFounded: number;
    focusAreas: string[];
    industries: string[];
    impactAreas: string[];
    tagline: string;
    description: string;
    lookingFor: string[];
    fundingType: string;
    priorFunding: boolean;
    address: string;
    phoneNumber: string;
    website?: string;
    email: string;
    facebook?: string;
    linkedIn?: string;
    instagram?: string;
    twitter?: string;
    additionalInfo?: string;
    staff: IStaff[];
    followers: IUser[];
    ecosystems: OrgEcosystem[];
    // eslint-disable-next-line
    opportunities: any[];
    createdAt: Date;
    _id: string;
    rootOrg: boolean;
}

interface Member {
    _id: string;
    username: string;
    profilePicture: {
        location: string;
        key: string;
    };
}

export interface OrgEcosystem {
    _id: string;
    name: string;
    description: string;
    members: string[];
    focusAreas: string[];
    logo?: {
        location: string;
        key: string;
    };
}

export const orgBlankObj = {
    name: '',
    logo: {
        location: `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`,
        key: ''
    },
    city: '',
    country: '',
    startupStage: '',
    yearFounded: new Date().getFullYear(),
    focusAreas: [],
    industries: [],
    impactAreas: [],
    tagline: '',
    description: '',
    lookingFor: [],
    fundingType: '',
    priorFunding: false,
    address: '',
    phoneNumber: '',
    website: '',
    email: '',
    facebook: '',
    linkedIn: '',
    instagram: '',
    twitter: '',
    additionalInfo: '',
    staff: [],
    followers: [],
    opportunities: [],
    ecosystems: [],
    createdAt: new Date(),
    _id: ''
};
