/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import {
    Card,
    ListGroup,
    FormControl,
    Container,
    Button,
    Row,
    Col,
    Collapse
} from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import VolOppItem from './VolOppItem';
import IVolunteerOpportunity from '../../interfaces/IVolunteerOpportunity';
import {
    countriesOptions,
    generalAreaOptions,
    sustainabilityVerticalsOptions,
    opportunityLengthOptions
} from '../../options/landscapeSelectOptions';

import { useEventTracking } from '../../pages/eventTracking';

const Styles = styled.div`
    .card-body {
        padding: 0;
    }

    .basic-multi-select {
        font-size: 16px;
    }
`;

interface Props {
    username: string | undefined;
    authId: string;
    userOrgs: string[];
}

interface filterType {
    country: string[];
    generalArea: string[];
    primaryTags: string[];
    opportunityLength: string[];
}

export const VolOppsListComponent: React.FC<Props> = ({
    username,
    authId,
    userOrgs
}: Props) => {
    const [masterVolOppsList, setMasterVolOppsList] = useState<IVolunteerOpportunity[]>([]);
    const [volOppsList, setVolOppsList] = useState<IVolunteerOpportunity[]>([]);
    const [initCheck, setInitCheck] = useState(false);
    const [search, setSearch] = useState('');
    const [shownVolOpps, setShownVolOpps] = useState(10);
    const [emptyListText, setEmptyListText] = useState(
        'Loading volunteer opportunities...'
    );
    const [filter, setFilter] = useState<filterType>({
        country: [],
        generalArea: [],
        primaryTags: [],
        opportunityLength: []
    });
    const [filtersOpen, setFiltersOpen] = useState(false);

    const user = username || 'Not Logged In';

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        useEventTracking(
            'Volunteer Opps',
            'Search (Volunteer Opportunities)',
            `${value}`
        );
        setSearch(value);
    };

    // eslint-disable-next-line
    const handleFilterSelect = (newValue: any, type: string) => {
        const value: string[] = [];
        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }
        // TODO: Improve consistency of search tracked
        if (value.join() !== '') {
            useEventTracking('Volunteer Opps', `Clicked ${type} filter`, value.join());
        }
        setFilter({
            ...filter,
            [type]: value
        });
    };

    // Helper for filterMainFunction
    const filterCheck = (i: number, opportunities: IVolunteerOpportunity[], type: string) => {
        if (filter[type].length <= 0) {
            return true;
        }
        for (let k = 0; k < filter[type].length; k += 1) {
            if (type === 'primaryTags') {
                for (let j = 0; j < opportunities[i][type].length; j += 1) {
                    if (opportunities[i][type][j] === filter[type][k]) {
                        return true;
                    }
                }
            } else if (opportunities[i][type] === filter[type][k]) {
                return true;
            }
        }
        return false;
    };

    // Main function for filtering and searching
    const filterMainFunction = () => {
        const opportunities: IVolunteerOpportunity[] = Object.assign([], masterVolOppsList);

        setEmptyListText('Loading volunteering opportunities...');

        // For Users
        let i = 0;
        let oppsLength = opportunities.length;
        while (i < oppsLength) {
            let searchCheck = false;
            const countriesCheck = filterCheck(i, opportunities, 'country');
            const generalAreaCheck = filterCheck(i, opportunities, 'generalArea');
            const primaryTagsCheck = filterCheck(i, opportunities, 'primaryTags');
            const opportunityLengthCheck = filterCheck(i, opportunities, 'opportunityLength');

            // Check Search
            if (search.length > 0) {
                if (
                    opportunities[i].title
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    opportunities[i].title
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else {
                searchCheck = true;
            }

            // Results of Check
            if (
                searchCheck === false ||
                countriesCheck === false ||
                generalAreaCheck === false ||
                primaryTagsCheck === false ||
                opportunityLengthCheck === false
            ) {
                opportunities.splice(i, 1);
                i -= 1;
                oppsLength = opportunities.length;
            }
            i += 1;
        }
        setVolOppsList(opportunities);


        setEmptyListText('No funding opportunities found.');
    };

    // const filterVerified = (opp: IVolunteerOpportunity) => opp.verified === true;

    // *** useEffects ***

    // Upon Initialisation
    useEffect(() => {
        axios.get(`/api/opportunities`).then(({ data }) => {
            // const filteredData = data.filter(filterVerified);
            setMasterVolOppsList(data);
        });
    }, []);

    // Whenever filter is selected or Search is filled
    useEffect(() => {
        if (initCheck === false) {
            setInitCheck(true);
        } else filterMainFunction();
    }, [search, filter, masterVolOppsList]);

    return (
        <Styles>
            <Card className="mb-4">
                <Card.Header className="text-center">
                    <FormControl
                        placeholder="Search..."
                        name="search"
                        type="text"
                        value={search}
                        onChange={handleSearch}
                        className="mb-3"
                    />
                    <Collapse in={filtersOpen}>
                        <Row id="filters-row" className="mt-3">
                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={countriesOptions}
                                    placeholder="Country"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'country'
                                        )
                                    }
                                />
                            </Col>
                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={generalAreaOptions}
                                    placeholder="General Area"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(newValue, 'generalArea')
                                    }
                                />
                            </Col>

                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={sustainabilityVerticalsOptions}
                                    placeholder="Sustainability Verticals"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'primaryTags'
                                        )
                                    }
                                />
                            </Col>
                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={opportunityLengthOptions}
                                    placeholder="Commitment Level"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'opportunityLength'
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    </Collapse>
                    <Button
                        onClick={() => setFiltersOpen(!filtersOpen)}
                        aria-controls="filters-row"
                        aria-expanded={filtersOpen}
                    >
                        {filtersOpen ? 'Hide Filters' : 'Show Filters'}
                    </Button>
                </Card.Header>
                <Card.Body className="p-3">
                    <ListGroup variant="flush">
                        {volOppsList.slice(0, shownVolOpps).map((opportunity, i) => (
                            <div
                                className={
                                    volOppsList.slice(0, shownVolOpps)[i + 1]
                                        ? 'list-item-border'
                                        : ''
                                }
                            >
                                <VolOppItem
                                    key={opportunity._id}
                                    opportunity={opportunity}
                                    authId={authId}
                                    userOrgs={userOrgs}
                                />
                            </div>
                        ))}
                    </ListGroup>
                    {volOppsList.length === 0 ? (
                        <Container>
                            <p className="mt-3">{emptyListText}</p>
                            <p className="mt-3 text-2">
                                <i>
                                    *Currently we only show opportunities within Singapore.
                                    If you are looking across the rest of South East Asia, please check back in time to come!
                                </i>
                            </p>
                        </Container>
                    ) : null}
                    {shownVolOpps < volOppsList.length ? (
                        <Button
                            type="button"
                            variant="light"
                            onClick={() => {
                                useEventTracking(
                                    'Volunteer Opps',
                                    'Clicked Show More (Volunteer Opps)',
                                    `${user}`
                                );
                                setShownVolOpps(shownVolOpps + 5);
                            }}
                            block
                            className="mb-1"
                        >
                            Show More
                        </Button>
                    ) : null}
                </Card.Body>
            </Card>
        </Styles>
    );
};

export default VolOppsListComponent;