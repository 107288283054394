/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAlert } from 'react-alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faComment,
    faEllipsisH,
    faChevronUp
} from '@fortawesome/free-solid-svg-icons';

import IReply from '../../interfaces/IReply';
import getSrc from '../../utils/commonFunction';

interface ReplyProps {
    reply: IReply;
    authId: string;
    commentId: string;
    id: string | undefined;
    updateReplies: (repliesList: IReply[]) => void;
    handleShowReply: (username: string) => void;
}

interface ToggleProps {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Reply = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
`;

const ProfilePic = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 100px;
`;

const Content = styled.div`
    margin-left: 15px;

    .comment-badge {
        cursor: pointer;
    }
`;

const ReplyItem: React.FC<ReplyProps> = ({
    reply,
    authId,
    commentId,
    id,
    updateReplies,
    handleShowReply
}: ReplyProps) => {
    const alert = useAlert();

    const CustomToggle = React.forwardRef(
        (
            { children, onClick }: ToggleProps,
            ref: React.Ref<HTMLAnchorElement>
        ) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
                href=""
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                <FontAwesomeIcon icon={faEllipsisH} className="comment-badge" />
                {children}
            </a>
        )
    );

    const [upvotes, setUpvotes] = useState(reply.upvotes.length);
    const [upvoteCheck, setUpvoteCheck] = useState(false);
    const [disableUpvote, setDisableUpvote] = useState(false);

    const handleUpvote = () => {
        if (disableUpvote) return;
        setDisableUpvote(true);

        if (upvoteCheck === false) {
            axios
                .put(
                    `/api/landscapes/${id}/comments/${commentId}/replies/${reply._id}/upvotes/${authId}`
                )
                .then(() => {
                    setUpvotes(upvotes + 1);
                    setUpvoteCheck(true);
                    setDisableUpvote(false);
                });
        } else {
            axios
                .delete(
                    `/api/landscapes/${id}/comments/${commentId}/replies/${reply._id}/upvotes/${authId}`
                )
                .then(() => {
                    setUpvotes(upvotes - 1);
                    setUpvoteCheck(false);
                    setDisableUpvote(false);
                });
        }
    };

    const handleDelete = () => {
        axios
            .delete(
                `/api/landscapes/${id}/comments/${commentId}/replies/${reply._id}`
            )
            .then((res) => {
                updateReplies(res.data);
            })
            .catch(() => {
                alert.error('Error deleting comment. Please try again.');
            });
    };

    useEffect(() => {
        for (let i = 0; i < reply.upvotes.length; i += 1) {
            if (reply.upvotes[i] === authId) setUpvoteCheck(true);
        }
    }, []);

    useEffect(() => {
        if (!authId) {
            setDisableUpvote(true);
        }
    }, [authId]);

    return (
        <Reply>
            <Link to={`/profile/${reply.author.username}`}>
                <h5>
                    <ProfilePic
                        className="mr-3"
                        src={getSrc(reply.author)}
                        alt="PFP"
                    />
                    {reply.author.firstName} {reply.author.lastName}
                </h5>
            </Link>
            <Content>
                <p className="allow-whitespace">{reply.content}</p>
                <Button
                    style={{ display: authId ? 'inline-block' : 'none' }}
                    variant="light"
                    size="sm"
                    className="mr-2"
                    onClick={() =>
                        handleShowReply(
                            `${reply.author.firstName} ${reply.author.lastName}`
                        )
                    }
                >
                    <FontAwesomeIcon icon={faComment} /> Reply
                </Button>
                <Button
                    variant={upvoteCheck ? 'primary' : 'light'}
                    size="sm"
                    className="mr-2"
                    disabled={disableUpvote || !authId}
                    onClick={handleUpvote}
                >
                    <FontAwesomeIcon icon={faChevronUp} /> {upvotes}
                </Button>
                <Dropdown style={{ display: 'inline-block' }}>
                    <Dropdown.Toggle as={CustomToggle} id="ellipses-dropdown" />
                    <Dropdown.Menu>
                        {reply.author._id === authId ? (
                            <Dropdown.Item onClick={handleDelete}>
                                Delete
                            </Dropdown.Item>
                        ) : (
                            <Dropdown.Item href="#report">Report</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            </Content>
        </Reply>
    );
};

export default ReplyItem;
