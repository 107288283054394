import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

interface usePaginationProps {
    route: string;
    limit: number;
    page?: number;
    sort: string;
    sortOrder?: 'asc' | 'desc';
    query?: string;
    isInfiniteScroll?: boolean;
}

export default function usePagination<T>({
    route,
    query: initQuery,
    limit,
    sort: initSort,
    page: pageProp = 1,
    sortOrder: sortOrderProp = 'asc',
    isInfiniteScroll = false
}: usePaginationProps) {
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState(initSort);
    const [page, setPage] = useState(pageProp);
    const [sortOrder, setSortOrder] = useState(sortOrderProp);
    const [data, setData] = useState<T[]>([]);
    const [query, setQuery] = useState(initQuery);

    const refresh = useCallback(async () => {
        try {
            setLoading(true);
            await axios
                .get(
                    `${route}?limit=${limit}&sort=${sort}&skip=${
                        (page - 1) * limit
                    }&descending=${sortOrder === 'asc' ? 1 : -1}${query}`
                )
                .then((res) => {
                    if (!isInfiniteScroll || page === 1) {
                        setData(res.data);
                    } else setData([...data, ...res.data]);
                });
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
        return true;
    }, [page, sort, sortOrder, query, data]);

    useEffect(() => {
        refresh();
    }, [page]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
            return;
        }
        refresh();
    }, [sortOrder, query, sort]);

    return {
        data,
        refresh,
        setSortOrder,
        setSort,
        currentPage: page,
        setPage,
        loading,
        setQuery
    };
}
