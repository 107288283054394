import React from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import { useEventTracking } from '../../pages/eventTracking';

// Interface for Redux types
import { StoreState, AuthState } from '../../redux/actions/types';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
}

const CreateOrganisationWidget: React.FC<authProps> = ({ ...props }) => (
    <div className="mb-3 mt-sm-3">
        <h4>Create an Organisation</h4>
        <Card>
            <Card.Body className="text-center">
                <p>
                    {props.auth.isAuthenticated
                        ? `Own an organisation? Create an organisation profile!`
                        : `Join us to add your organisation to our integrated community!`}
                </p>
                <Link
                    to={
                        props.auth.isAuthenticated
                            ? `/createorganisation`
                            : `/register`
                    }
                >
                    <Button
                        type="button"
                        block
                        onClick={() => {
                            if (props.auth.isAuthenticated) {
                                useEventTracking(
                                    'My Organisation',
                                    'Clicked Create Organisation',
                                    `${props.auth.user.username}`
                                );
                            } else {
                                useEventTracking(
                                    'My Organisation',
                                    'Clicked Sign Up Now',
                                    ``
                                );
                            }
                        }}
                    >
                        {props.auth.isAuthenticated
                            ? `Create Organisation`
                            : `Sign Up Now`}
                    </Button>
                </Link>
            </Card.Body>
        </Card>
    </div>
);

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(CreateOrganisationWidget));
