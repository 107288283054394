export enum JoinAs {
    USER = 'user',
    ORGANISATION = 'organisation'
}

interface Logo {
    location: string;
    key: string;
}
interface Member {
    _id: string;
    username: string;
    firstName: string;
    lastName: string;
    profilePicture: Logo;
}
interface Project {
    _id: string;
    name: string;
    rootOrg: boolean;
}

export interface Ecosystem {
    _id: string;
    name: string;
    logo?: Logo;
    description: string;
    contact: string;
    address: string;
    website?: string;
    email?: string;
    joinAs: JoinAs;
    focusAreas: string[];
    impactAreas: string[];
    verificationCode: string;
    organisation: Project;
    members: Member[];
    projects: Project[];
    createdBy: Member;
    admins: string[];
    createdAt: Date;
    updatedAt: Date;
}
