/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import {
    Card,
    ListGroup,
    FormControl,
    Container,
    Button,
    Row,
    Col,
    Collapse
} from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import FundItem from './FundItem';
import IFund from '../../interfaces/IFund';
import {
    fundingAmountsOptions,
    fundingStagesOptions,
    fundingStatusOptions,
    fundingTypesOptions,
    focusAreasOptions,
    availableForOptions
} from '../../options/fundSelectOptions';

import { useEventTracking } from '../../pages/eventTracking';

const Styles = styled.div`
    .card-body {
        padding: 0;
    }

    .basic-multi-select {
        font-size: 16px;
    }
`;

interface Props {
    username: string | undefined;
    authId: string;
}

interface filterType {
    fundAmount: string[];
    availableFor: string[];
    focusAreas: string[];
    stage: string[];
    fundingTypes: string[];
    fundStatus: string[];
}

export const FundsListComponent: React.FC<Props> = ({
    username,
    authId
}: Props) => {
    const [masterFundsList, setMasterFundsList] = useState<IFund[]>([]);
    const [fundsList, setFundsList] = useState<IFund[]>([]);
    const [initCheck, setInitCheck] = useState(false);
    const [search, setSearch] = useState('');
    const [shownFunds, setShownFunds] = useState(10);
    const [emptyListText, setEmptyListText] = useState(
        'Loading funding opportunities...'
    );
    const [filter, setFilter] = useState<filterType>({
        fundAmount: [],
        availableFor: [],
        focusAreas: [],
        stage: [],
        fundingTypes: [],
        fundStatus: []
    });
    const [filtersOpen, setFiltersOpen] = useState(false);

    const user = username || 'Not Logged In';

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        useEventTracking(
            'Funding',
            'Search (Funding Opportunities)',
            `${value}`
        );
        setSearch(value);
    };

    // eslint-disable-next-line
    const handleFilterSelect = (newValue: any, type: string) => {
        const value: string[] = [];
        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }
        // TODO: Improve consistency of search tracked
        if (value.join() !== '') {
            useEventTracking('Funding', `Clicked ${type} filter`, value.join());
        }
        setFilter({
            ...filter,
            [type]: value
        });
    };

    // Helper for filterMainFunction
    const filterCheck = (i: number, funds: IFund[], type: string) => {
        if (filter[type].length > 0) {
            if (
                type === 'availableFor' ||
                type === 'focusAreas' ||
                type === 'fundingTypes' ||
                type === 'stage'
            ) {
                for (let j = 0; j < funds[i][type].length; j += 1) {
                    if (type === 'availableFor') {
                        if (funds[i][type][j] === 'Global') return true;
                    }
                    for (let k = 0; k < filter[type].length; k += 1) {
                        if (filter[type][k] !== 'Global') {
                            if (
                                funds[i][type][j] === 'Southeast Asia' ||
                                funds[i][type][j] === 'Asia Pacific'
                            )
                                return true;
                        }

                        if (funds[i][type][j] === filter[type][k]) {
                            return true;
                        }
                    }
                }
            } else {
                for (let k = 0; k < filter[type].length; k += 1) {
                    if (funds[i][type] === filter[type][k]) {
                        return true;
                    }
                }
            }
            return false;
        }
        return true;
    };

    // Main function for filtering and searching
    const filterMainFunction = () => {
        const funds: IFund[] = Object.assign([], masterFundsList);

        setEmptyListText('Loading funding opportunities...');

        // For Users
        let i = 0;
        let fundsLength = funds.length;
        while (i < fundsLength) {
            let searchCheck = false;
            const fundAmountCheck = filterCheck(i, funds, 'fundAmount');
            const stageCheck = filterCheck(i, funds, 'stage');
            const fundStatusCheck = filterCheck(i, funds, 'fundStatus');
            const fundingTypesCheck = filterCheck(i, funds, 'fundingTypes');
            const focusAreasCheck = filterCheck(i, funds, 'focusAreas');
            const availableForCheck = filterCheck(i, funds, 'availableFor');

            // Check Search
            if (search.length > 0) {
                if (
                    funds[i].fundName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    funds[i].funderName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else {
                searchCheck = true;
            }

            // Results of Check
            if (
                searchCheck === false ||
                fundAmountCheck === false ||
                stageCheck === false ||
                fundStatusCheck === false ||
                fundingTypesCheck === false ||
                focusAreasCheck === false ||
                availableForCheck === false
            ) {
                funds.splice(i, 1);
                i -= 1;
                fundsLength = funds.length;
            }
            i += 1;
        }
        setFundsList(funds);

        setEmptyListText('No funding opportunities found.');
    };

    const filterVerified = (fund: IFund) => fund.verified === true;

    // *** useEffects ***

    // Upon Initialisation
    useEffect(() => {
        axios.get(`/api/fundings/`).then(({ data }) => {
            const filteredData = data.filter(filterVerified);
            setMasterFundsList(filteredData);
        });
    }, []);

    // Whenever filter is selected or Search is filled
    useEffect(() => {
        if (initCheck === false) {
            setInitCheck(true);
        } else filterMainFunction();
    }, [search, filter, masterFundsList]);

    return (
        <Styles>
            <Card className="mb-4">
                <Card.Header className="text-center">
                    <FormControl
                        placeholder="Search..."
                        name="search"
                        type="text"
                        value={search}
                        onChange={handleSearch}
                        className="mb-3"
                    />
                    <Collapse in={filtersOpen}>
                        <Row id="filters-row" className="mt-3">
                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={fundingAmountsOptions}
                                    placeholder="Fund amount"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'fundAmount'
                                        )
                                    }
                                />
                            </Col>
                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={fundingStagesOptions}
                                    placeholder="Fund stages"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(newValue, 'stage')
                                    }
                                />
                            </Col>

                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={fundingStatusOptions}
                                    placeholder="Fund status"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'fundStatus'
                                        )
                                    }
                                />
                            </Col>
                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={fundingTypesOptions}
                                    placeholder="Fund types"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'fundingTypes'
                                        )
                                    }
                                />
                            </Col>

                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={focusAreasOptions}
                                    placeholder="Focus areas"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'focusAreas'
                                        )
                                    }
                                />
                            </Col>
                            <Col sm={6} className="mb-3">
                                <Select
                                    isMulti
                                    options={availableForOptions}
                                    placeholder="Countries"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleFilterSelect(
                                            newValue,
                                            'availableFor'
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                    </Collapse>
                    <Button
                        onClick={() => setFiltersOpen(!filtersOpen)}
                        aria-controls="filters-row"
                        aria-expanded={filtersOpen}
                    >
                        {filtersOpen ? 'Hide Filters' : 'Show Filters'}
                    </Button>
                </Card.Header>
                <Card.Body className="p-3">
                    <ListGroup variant="flush">
                        {fundsList.slice(0, shownFunds).map((fund, i) => (
                            <div
                                className={
                                    fundsList.slice(0, shownFunds)[i + 1]
                                        ? 'list-item-border'
                                        : ''
                                }
                            >
                                <FundItem
                                    key={fund._id}
                                    fund={fund}
                                    authId={authId}
                                />
                            </div>
                        ))}
                    </ListGroup>
                    {fundsList.length === 0 ? (
                        <Container>
                            <p className="mt-3">{emptyListText}</p>
                        </Container>
                    ) : null}
                    {shownFunds < fundsList.length ? (
                        <Button
                            type="button"
                            variant="light"
                            onClick={() => {
                                useEventTracking(
                                    'Funding',
                                    'Clicked Show More (Funding Opportunities)',
                                    `${user}`
                                );
                                setShownFunds(shownFunds + 5);
                            }}
                            block
                            className="mb-1"
                        >
                            Show More
                        </Button>
                    ) : null}
                </Card.Body>
            </Card>
        </Styles>
    );
};

export default FundsListComponent;
