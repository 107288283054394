import React, { ReactElement, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { ListGroup, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton
} from 'react-share';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IEvent } from '../../interfaces/IEvent';
import { AuthState } from '../../redux/actions/types';
import EventNotification from './EventNotification';
import { EventButton } from './EventStyles';
import EventRejectModal from './EventRejectModal';
import EventLoading from './EventLoading';
import { useEventTracking } from '../../pages/eventTracking';

const Backdrop = styled.div`
    z-index: 3001;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
`;

interface EventActionButtonsProps {
    event: IEvent;
    auth: AuthState;
    getEvent: () => void;
    isAdmin: boolean;
    getNewEvents?: (id: string) => void;
    isPending?: boolean;
    isDelete?: boolean;
    isPage?: boolean;
    setIsDelete?: (val: boolean) => void;
}

export default function EventActionButtons({
    auth,
    event,
    getEvent,
    getNewEvents,
    isDelete,
    isAdmin,
    isPending,
    isPage,
    setIsDelete
}: EventActionButtonsProps): ReactElement {
    const alert = useAlert();
    const [eventAction, setEventAction] = useState('');
    const [showNotif, setShowNotif] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const history = useHistory();

    const shareLink = `https://app.interseed.co/event/${event._id}`;

    const notify = (action: string) => {
        setEventAction(action);
        setShowNotif(true);
    };

    const approveEvent = async () => {
        try {
            setLoading(true);
            const { data: newEvent } = await axios.put(
                `/api/events/${event._id}/approved`,
                {
                    title: event.title,
                    startTime: event.startTime,
                    endTime: event.endTime,
                    description: event.description
                }
            );
            if (getNewEvents) getNewEvents(newEvent._id);
        } catch (err) {
            console.log(err);
            alert.error('Error Approving for an event.');
        } finally {
            setLoading(false);
        }
    };

    const rejectEvent = async () => {
        try {
            setLoading(true);
            const { data: newEvent } = await axios.put(
                `/api/events/${event._id}/rejected`,
                {
                    approved: false,
                    rejected: true,
                    rejectReason
                }
            );
            if (getNewEvents) getNewEvents(newEvent._id);
        } catch (err) {
            console.log(err);
            alert.error('Error Rejecting an event.');
        } finally {
            setLoading(false);
        }
    };

    const registerEvent = async () => {
        try {
            setLoading(true);
            const { data: newEvent } = await axios.post(
                `/api/events/${event._id}/register`,
                {
                    participant: auth.user._id
                }
            );
            useEventTracking('Events', 'Clicked Register', `${event.title}`);
            getEvent();
            if (getNewEvents) getNewEvents(newEvent._id);
        } catch (err) {
            console.log(err);
            alert.error('Error Registering for an event.');
        } finally {
            setLoading(false);
        }
    };

    const deregisterEvent = async () => {
        try {
            setLoading(true);
            const { data: newEvent } = await axios.post(
                `/api/events/${event._id}/deregister`,
                {
                    participant: auth.user._id
                }
            );
            useEventTracking('Events', 'Clicked Deregister', `${event.title}`);
            getEvent();
            if (getNewEvents) getNewEvents(newEvent._id);
        } catch (err) {
            console.log(err);
            alert.error('Error Deregistering for an event.');
        } finally {
            setLoading(false);
        }
    };

    const deleteEvent = async () => {
        try {
            setLoading(true);
            const { data: newEvent } = await axios.delete(
                `/api/events/${event._id}`
            );
            useEventTracking(
                'Events',
                'Deleted Event',
                `${auth.user.username}`
            );
            if (getNewEvents) getNewEvents(newEvent._id);
        } catch (err) {
            console.log(err);
            alert.error('Error Deregistering for an event.');
        } finally {
            setLoading(false);
            if (setIsDelete) setIsDelete(false);
        }
    };

    const onOkNotif = () => {
        if (loading) return;
        switch (eventAction) {
            case 'approve':
                approveEvent();
                setShowNotif(false);
                break;
            case 'reject':
                rejectEvent();
                setShowNotif(false);
                break;
            case 'delete':
                deleteEvent();
                setShowNotif(false);
                break;
            case 'deregister':
                deregisterEvent();
                setShowNotif(false);
                break;
            case 'register':
                registerEvent();
                notify('success-register');
                break;
            default:
                setShowNotif(false);
                break;
        }
        if (isPage) history.push('/events/all');
    };

    useEffect(() => {
        if (isDelete) notify('delete');
    }, [isDelete]);

    return (
        <div>
            {isPending && isAdmin && (
                <Row style={{ gap: '3rem' }}>
                    <EventButton
                        className="col py-3"
                        onClick={() => {
                            setRejectModal(true);
                        }}
                        style={{
                            backgroundColor: '#FFE8E3',
                            color: '#FF5630'
                        }}
                    >
                        Reject
                    </EventButton>
                    <EventButton
                        className="col py-3"
                        onClick={() => {
                            notify('approve');
                        }}
                    >
                        Approve
                    </EventButton>
                </Row>
            )}
            {auth.isAuthenticated && !isPending && event.upcoming && (
                <Row style={{ gap: '3rem' }}>
                    {auth.user._id !== event.organizer._id && (
                        <EventButton
                            className="col py-3 font-weight-bold"
                            onClick={
                                event.isRegistered
                                    ? () => {
                                          notify('deregister');
                                      }
                                    : () => {
                                          notify('register');
                                      }
                            }
                            disabled={
                                event.isRegistered
                                    ? false
                                    : event.numberOfParticipants <=
                                          event.participants.length &&
                                      event.numberOfParticipants !== 0
                            }
                            style={
                                event.isRegistered
                                    ? {
                                          backgroundColor: '#FFE8E3',
                                          color: '#FF5630'
                                      }
                                    : {}
                            }
                        >
                            {event.isRegistered ? 'Deregister' : 'Register'}
                        </EventButton>
                    )}
                    {(event.isRegistered ||
                        auth.user._id === event.organizer._id) && (
                        <>
                            <OverlayTrigger
                                trigger="click"
                                key="top"
                                placement="top"
                                rootClose
                                overlay={
                                    <Popover id="bottom-popover-share">
                                        <div>
                                            <ListGroup
                                                style={{
                                                    minWidth: '15vw'
                                                }}
                                            >
                                                <ListGroup.Item
                                                    action
                                                    className="text-truncate font-weight-bold p-2"
                                                >
                                                    <FacebookShareButton
                                                        url={shareLink}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        onClick={() => {
                                                            useEventTracking(
                                                                'Events',
                                                                'Shared Event via Facebook',
                                                                `${event.title}`
                                                            );
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-center">
                                                            <FacebookIcon
                                                                size={32}
                                                                round
                                                                style={{
                                                                    flexShrink: 1
                                                                }}
                                                            />
                                                            <div
                                                                className="d-flex font-weight-bold justify-content-start align-items-center pl-2"
                                                                style={{
                                                                    flexGrow: 1
                                                                }}
                                                            >
                                                                Facebook
                                                            </div>
                                                        </div>
                                                    </FacebookShareButton>
                                                </ListGroup.Item>
                                                <ListGroup.Item
                                                    action
                                                    className="text-truncate font-weight-bold p-2"
                                                >
                                                    <TwitterShareButton
                                                        url={shareLink}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        onClick={() => {
                                                            useEventTracking(
                                                                'Events',
                                                                'Shared Event via Twitter',
                                                                `${event.title}`
                                                            );
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-center">
                                                            <TwitterIcon
                                                                size={32}
                                                                round
                                                            />
                                                            <div
                                                                className="d-flex font-weight-bold justify-content-start align-items-center pl-2"
                                                                style={{
                                                                    flexGrow: 1
                                                                }}
                                                            >
                                                                Twitter
                                                            </div>
                                                        </div>
                                                    </TwitterShareButton>
                                                </ListGroup.Item>
                                                <ListGroup.Item
                                                    action
                                                    className="text-truncate font-weight-bold p-2"
                                                >
                                                    <LinkedinShareButton
                                                        title={event.title}
                                                        summary={
                                                            event.description
                                                        }
                                                        url={shareLink}
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                        onClick={() => {
                                                            useEventTracking(
                                                                'Events',
                                                                'Shared Event via LinkedIn',
                                                                `${event.title}`
                                                            );
                                                        }}
                                                    >
                                                        <div className="d-flex justify-content-center">
                                                            <LinkedinIcon
                                                                size={32}
                                                                round
                                                            />
                                                            <div
                                                                className="d-flex font-weight-bold justify-content-start align-items-center pl-2"
                                                                style={{
                                                                    flexGrow: 1
                                                                }}
                                                            >
                                                                Linkedin
                                                            </div>
                                                        </div>
                                                    </LinkedinShareButton>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </div>
                                    </Popover>
                                }
                            >
                                <EventButton
                                    className="col py-3"
                                    style={{
                                        backgroundColor: '#364E7A'
                                    }}
                                >
                                    <img
                                        alt="share"
                                        src={`${process.env.PUBLIC_URL}/assets/interseed_share.svg`}
                                        style={{
                                            height: '1rem',
                                            width: '1rem',
                                            color: 'white'
                                        }}
                                        className="d-inline-block mr-2"
                                    />
                                    Share
                                </EventButton>
                            </OverlayTrigger>
                            <EventButton
                                className="col py-3"
                                style={{
                                    backgroundColor: '#364E7A'
                                }}
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        event.zoom?.joinUrl || ''
                                    );
                                    alert.success('Copied To Clipboard!');
                                    useEventTracking(
                                        'Events',
                                        'Clicked Copy Link',
                                        `${event.title}`
                                    );
                                }}
                            >
                                <img
                                    alt="share"
                                    src={`${process.env.PUBLIC_URL}/assets/interseed_copy.svg`}
                                    style={{
                                        height: '1rem',
                                        width: '1rem',
                                        color: 'white'
                                    }}
                                    className="d-inline-block mr-2"
                                />
                                Copy Link
                            </EventButton>
                        </>
                    )}
                </Row>
            )}
            {(rejectModal || showNotif || loading) && <Backdrop />}
            {loading && <EventLoading />}
            <EventNotification
                onOk={onOkNotif}
                show={showNotif}
                close={() => {
                    setShowNotif(false);
                    if (setIsDelete) setIsDelete(false);
                }}
                eventAction={eventAction}
            />
            <EventRejectModal
                show={rejectModal}
                onOk={(reason: string) => {
                    if (!loading) {
                        setRejectReason(reason);
                        notify('reject');
                        setRejectModal(false);
                    }
                }}
                close={() => {
                    setRejectModal(false);
                }}
            />
        </div>
    );
}
