import IOrganisation, { orgBlankObj } from './IOrganisation';

export default interface IOpportunity {
    position: string;
    description: string;
    renumeration: number;
    location: {
        remote: boolean;
        city: string;
        country: string;
    };
    verticals: string[];
    skillsets: string[];
    organisation: IOrganisation;
    _id: string;
}

export const oppBlankObj = {
    position: '',
    description: '',
    renumeration: 0,
    location: {
        remote: false,
        city: '',
        country: ''
    },
    verticals: [],
    skillsets: [],
    organisation: orgBlankObj,
    _id: ''
};
