import React from 'react';

const CloseIcon = () => (
    <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.9842 3.12549H5.00642C3.90361 3.12549 3.0096 4.02692 3.0096 5.13889V19.2327C3.0096 20.3447 3.90361 21.2461 5.00642 21.2461H18.9842C20.087 21.2461 20.981 20.3447 20.981 19.2327V5.13889C20.981 4.02692 20.087 3.12549 18.9842 3.12549Z"
            stroke="#067DF9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.00006 9.16565L14.9905 15.2059"
            stroke="#067DF9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.9905 9.16565L9.00006 15.2059"
            stroke="#067DF9"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CloseIcon;
