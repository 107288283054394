import React, { useState, useEffect, ChangeEvent } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import classnames from 'classnames';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Google from '../components/login/Google';
import LinkedInComponent from '../components/login/LinkedInComponent';
import { registerUser } from '../redux/actions/AuthActions';
import { AuthState, ErrorState, StoreState } from '../redux/actions/types';
import IRegisterUserInput, {
    BlankRegisterUserInput
} from '../interfaces/IRegisterUserInput';
import { IUserData } from '../interfaces/AuthInterface';
import { whitespaceCheck } from '../utils/commonFunction';
import { useEventTracking } from './eventTracking';

// Interface for props in this component
interface registerProps extends RouteComponentProps {
    registerUser: (
        arg0: IUserData,
        arg1: RouteComponentProps['history']
    ) => void;
    auth: AuthState;
    error: ErrorState;
    history: RouteComponentProps['history'];
    refreshNav: () => void;
}

// Styles
const RegisterForm = styled.div`
    width: 496px;
`;

// Main Functional Component
const Register: React.FC<registerProps> = (props: registerProps) => {
    const alert = useAlert();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [userInput, setUserInput] = useState<IRegisterUserInput>(
        BlankRegisterUserInput
    );

    useEffect(() => {
        if (props.error.register) {
            setUserInput({ ...userInput, errors: props.error.register });
            setDisableSubmit(false);
        }
    }, [props.error.register]);

    useEffect(() => {
        if (props.auth.isAuthenticated) {
            props.history.push('/landscapes');
            props.refreshNav();
        }
    }, [props.auth.isAuthenticated]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setUserInput({ ...userInput, [id]: value, errors: {} });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (disableSubmit) return;
        setDisableSubmit(true);

        // Check for whitespace submissions
        if (
            !whitespaceCheck(userInput, [
                'firstName',
                'email',
                'password',
                'password2'
            ])
        ) {
            alert.error(
                'Required fields cannot be filled with only whitespaces.'
            );
            setDisableSubmit(false);
            return;
        }

        const newUser = {
            firstName: userInput.firstName,
            lastName: userInput.lastName,
            username: userInput.username,
            email: userInput.email,
            password: userInput.password,
            password2: userInput.password2
        };

        props.registerUser(newUser, props.history);
    };

    const { errors } = userInput;

    return (
        <Container>
            <Row className="justify-content-center">
                <RegisterForm>
                    <Card className="py-4 px-4 shadow">
                        <div>
                            <h4>
                                <b>Register</b>
                            </h4>
                            <p className="text-muted">
                                Already have an account?{' '}
                                <Link to="/login">Log in</Link>
                            </p>
                        </div>

                        {/* OAuth Buttons */}
                        <div className="mb-2">
                            <Google setDisableSubmit={setDisableSubmit} />
                        </div>
                        <div>
                            <LinkedInComponent
                                setDisableSubmit={setDisableSubmit}
                            />
                        </div>

                        <p className="or-divider">
                            <span>or</span>
                        </p>

                        <Form noValidate onSubmit={onSubmit}>
                            <Form.Row>
                                <Col>
                                    <Form.Control
                                        onChange={onChange}
                                        value={userInput.firstName}
                                        id="firstName"
                                        type="text"
                                        placeholder="First name"
                                        className={classnames('', {
                                            invalid: errors.firstName
                                        })}
                                    />
                                    <Form.Text className="text-danger mb-2">
                                        {errors.firstName}
                                    </Form.Text>
                                </Col>
                                <Col>
                                    <Form.Control
                                        onChange={onChange}
                                        value={userInput.lastName}
                                        id="lastName"
                                        type="text"
                                        placeholder="Last name"
                                        className="lastNameRegisterInput mb-2"
                                    />
                                </Col>
                            </Form.Row>
                            <Form.Control
                                onChange={onChange}
                                value={userInput.username}
                                maxLength={100}
                                id="username"
                                type="text"
                                placeholder="Username"
                                className={classnames('', {
                                    invalid: errors.username
                                })}
                            />
                            <Form.Text className="text-danger mb-2">
                                {errors.username}
                            </Form.Text>
                            <Form.Control
                                onChange={onChange}
                                value={userInput.email}
                                id="email"
                                type="email"
                                placeholder="Email address"
                                className={classnames('', {
                                    invalid: errors.email
                                })}
                            />
                            <Form.Text className="text-danger mb-2">
                                {errors.email}
                            </Form.Text>
                            <Form.Control
                                onChange={onChange}
                                value={userInput.password}
                                id="password"
                                type="password"
                                placeholder="New password"
                                className={classnames('', {
                                    invalid: errors.password
                                })}
                            />
                            <Form.Text className="text-danger mb-2">
                                {errors.password}
                            </Form.Text>
                            <Form.Control
                                onChange={onChange}
                                value={userInput.password2}
                                id="password2"
                                type="password"
                                placeholder="Confirm new password"
                                className={classnames('', {
                                    invalid: errors.password2
                                })}
                            />
                            <Form.Text className="text-danger mb-2">
                                {errors.password2}
                            </Form.Text>
                            <Form.Text className="text-muted my-3">
                                By clicking Sign Up, you agree to our Terms,
                                Data Policy and Cookie Policy. You may receive
                                SMS notifications from us and can opt out at any
                                time.
                            </Form.Text>
                            <Button
                                type="submit"
                                variant="success"
                                onClick={() => {
                                    useEventTracking(
                                        'Log In',
                                        'Clicked Sign Up from Register Page',
                                        `No Third Party Auth`
                                    );
                                }}
                                disabled={disableSubmit}
                                block
                            >
                                Sign up
                            </Button>
                        </Form>
                    </Card>
                </RegisterForm>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    error: state.errors
});

export default connect(mapStateToProps, { registerUser })(withRouter(Register));
