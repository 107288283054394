import axios from 'axios';
import React, { useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Ecosystem, JoinAs } from '../../../interfaces/Ecosystem';
import { useEventTracking } from '../../../pages/eventTracking';
import { AuthState } from '../../../redux/actions/types';
import CopyIcon from '../CopyIcon';
import EcosystemAvatars from './EcosystemAvatars';

interface Props {
    profile: Ecosystem;
    isAdminUser: boolean;
    joined: boolean;
    auth: AuthState;
}

const Heading = styled.div`
    margin-bottom: 2rem;
    p {
        color: #315578;
    }
`;

const LeaveButton = styled(Button)`
    border: 2px solid #f94018;
    font-width: 500;
    color: #ff0f00;
`;

const CodeText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #002a52;
`;

const EcosystemInfo: React.FC<Props> = ({
    profile,
    isAdminUser,
    joined,
    auth
}) => {
    const history = useHistory();
    const [disabled, setDisabled] = useState(false);

    const handleLeave = () => {
        setDisabled(true);
        axios
            .put<Ecosystem>(
                `/api/ecosystems/${profile._id}/remove/${auth.user._id}`,
                {
                    removeAs: JoinAs.USER
                }
            )
            .then((data) => {
                console.log(data.data);
                history.go(0);
            })
            .catch((err) => console.log(err.response.data));
    };

    return (
        <>
            <Heading>
                <h1>{profile.name}</h1>
                <p>
                    {profile.members.length} member
                    {profile.members.length > 1 && 's'} on {profile.name}
                </p>
                <EcosystemAvatars members={profile.members} />
            </Heading>
            <Row>
                <Button
                    variant="outline-dark"
                    className="mt-2 mr-2"
                    href={`mailto:${profile.email}`}
                    onClick={() => {
                        useEventTracking(
                            'Community',
                            'Emailed Ecosystem',
                            `${profile.name}`
                        );
                    }}
                >
                    Email
                </Button>
                {auth.user._id ? (
                    <>
                        {!isAdminUser ? (
                            joined && (
                                <LeaveButton
                                    variant="outline-danger"
                                    className="mt-2"
                                    onClick={(
                                        ev: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                        ev.preventDefault();
                                        handleLeave();
                                    }}
                                    disabled={disabled}
                                >
                                    Leave
                                </LeaveButton>
                            )
                        ) : (
                            <>
                                <CodeText className="mt-2 mr-2">
                                    Code:{' '}
                                </CodeText>
                                <Button
                                    variant="outline-primary"
                                    className="mt-2"
                                    onClick={() =>
                                        navigator.clipboard.writeText(
                                            profile.verificationCode
                                        )
                                    }
                                >
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="mr-4">
                                            {profile.verificationCode}
                                        </div>
                                        <CopyIcon />
                                    </div>
                                </Button>
                            </>
                        )}
                    </>
                ) : (
                    <Button
                        variant="outline-primary"
                        className="mt-2"
                        as={Link}
                        to="/login"
                    >
                        Join
                    </Button>
                )}
            </Row>
        </>
    );
};

export default EcosystemInfo;
