import {
    faEnvelope,
    faTrash,
    faUserCog
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Ecosystem } from '../../../interfaces/Ecosystem';
import IUser from '../../../interfaces/IUser';
import { useEventTracking } from '../../../pages/eventTracking';
import { AuthState } from '../../../redux/actions/types';
import EcosystemLeaveModal from '../EcosystemLeaveModal';
import LocationMarkerIcon from './LocationMarkerIcon';

interface User {
    _id: string;
    username: string;
}

interface Props {
    auth: AuthState;
    ecosystem: Ecosystem;
    member: User;
    isAdminUser: boolean;
}

type AdmminActionType = 'admin' | 'mail' | 'delete';

const Member = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    overflow: hidden;
    border-radius: 20px;
    color: black;
    width: 150px;

    &:hover {
        border-radius: 0;
        text-decoration: none;
    }

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        margin-bottom: 1rem;
        margin-top: 1rem;
        max-height: 111px;
        max-width: 111px;
    }
`;

const Text = styled.div`
    color: #002a52;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.5rem;
`;

const Address = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #002a52;
    font-size: 12px;
`;

const IntersestAreas = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 10px;
    flex-direction: column;
    margin: 1rem 0;

    & > div {
        background-color: #d3e8ff;
        color: #2b6098;
        padding: 4px 7px;
        border-radius: 8px;
    }
`;

const AdminArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
    margin-top: 10px;
`;

const ActionWrapper = styled.div<{ type: AdmminActionType }>`
    padding: 6px 9px;
    border: 1px solid;
    border-color: ${({ type }) => {
        let borderColor = '#B5CCFF';
        if (type === 'admin') {
            borderColor = '#B5CCFF';
        }
        if (type === 'mail') {
            borderColor = '#A6ADB5';
        }
        if (type === 'delete') {
            borderColor = '#FFB19F';
        }
        return borderColor;
    }};
    border-radius: 50%;
    background-color: ${({ type }) => {
        let bgColor = '#E8F2FE';
        if (type === 'delete') {
            bgColor = '#FFEBE7';
        }
        return bgColor;
    }};

    &:hover {
        cursor: pointer;
    }
`;

const MemberItem: React.FC<Props> = ({
    ecosystem,
    member,
    auth,
    isAdminUser
}) => {
    const history = useHistory();

    let currentUser = false;
    if (auth.user._id === member._id) currentUser = true;

    const [userDetail, setUserDetail] = useState<IUser>({} as IUser);
    const [followCheck, setFollowCheck] = useState(false);
    const [disableFollow, setDisableFollow] = useState(true);
    const [show, setShow] = useState(false);

    const handleFollow = () => {
        if (disableFollow || currentUser) return;
        setDisableFollow(true);
        let location =
            `${window.location.pathname.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` ===
            'community'
                ? 'on Community Page'
                : 'Profile';
        if (location === 'Profile') {
            location =
                window.location.pathname.split(/.*\/(.*)/)[1] ===
                auth.user.username
                    ? 'On Own Profile'
                    : "On Others' Profile";
        }
        if (followCheck) {
            axios.delete(`/api/users/follows/${member._id}`).then(() => {
                setFollowCheck(false);
                setDisableFollow(false);
            });
            useEventTracking(
                `Community`,
                `Unfollowed User ${location}`,
                `${member.username}`
            );
        } else {
            axios.put(`/api/users/follows/${member._id}`).then(() => {
                setFollowCheck(true);
                setDisableFollow(false);
            });
            useEventTracking(
                `Community`,
                `Followed User ${location}`,
                `${member.username}`
            );
        }
    };

    const handleMakeAdmin = (memberId: string) => {
        axios
            .put(`/api/ecosystems/${ecosystem._id}/admin/${memberId}`)
            .then(({ data }) => {
                console.log(data);
                history.go(0);
            })
            .catch((err) => console.log(err.response.data));
    };

    useEffect(() => {
        axios
            .get<IUser>(`/api/users/profiles/${member.username}`)
            .then(({ data }) => {
                setUserDetail(data);
            });

        if (!auth.user._id) return;
        if (userDetail.followers) {
            for (let i = 0; i < userDetail.followers.length; i += 1) {
                if (userDetail.followers[i] === auth.user._id) {
                    setFollowCheck(true);
                    break;
                }
            }
        }
        setDisableFollow(false);
    }, [member.username]);

    return (
        <Member as={Link} to={`/profile/${member.username}`}>
            {userDetail && (
                <>
                    <Image
                        className="img-thumbnail"
                        src={
                            userDetail.profilePicture?.location ??
                            `${process.env.PUBLIC_URL}/assets/profile.png`
                        }
                        alt="Profile"
                        roundedCircle
                        thumbnail
                        fluid
                        onError={(e) => {
                            e.currentTarget.onerror = null; // prevents looping
                            e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile.png`;
                        }}
                    />
                    <div className="my-auto text-sm-left text-center">
                        <Text>
                            {userDetail.firstName} {userDetail.lastName}
                        </Text>
                        {userDetail.country && (
                            <Address>
                                <LocationMarkerIcon />
                                <div>{userDetail.country}</div>
                            </Address>
                        )}
                        {userDetail.interestAreas && (
                            <IntersestAreas>
                                {userDetail.interestAreas
                                    .sort()
                                    .map((interset) => (
                                        <div>{interset}</div>
                                    ))}
                            </IntersestAreas>
                        )}
                    </div>
                    {auth.user._id ? (
                        <Button
                            variant={
                                !followCheck ? 'outline-primary' : 'primary'
                            }
                            className={
                                isAdminUser
                                    ? 'mt-2 btn-block'
                                    : 'mt-2 mb-3 btn-block'
                            }
                            onClick={(ev) => {
                                handleFollow();
                                ev.preventDefault();
                            }}
                            disabled={disableFollow}
                        >
                            {!followCheck ? 'Follow' : 'Unfollow'}
                        </Button>
                    ) : (
                        <Button
                            variant="outline-primary"
                            className="mt-2"
                            as={Link}
                            to="/login"
                        >
                            Follow
                        </Button>
                    )}
                    {isAdminUser && (
                        <AdminArea>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip
                                        id="make-admin"
                                        className="font-arimo"
                                    >
                                        Make Admin
                                    </Tooltip>
                                }
                            >
                                <ActionWrapper
                                    type="admin"
                                    onClick={(
                                        e: React.MouseEvent<HTMLDivElement>
                                    ) => {
                                        e.preventDefault();
                                        handleMakeAdmin(userDetail._id);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faUserCog}
                                        color="#5088FF"
                                    />
                                </ActionWrapper>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip
                                        id="send-email"
                                        className="font-arimo"
                                    >
                                        Email
                                    </Tooltip>
                                }
                            >
                                <ActionWrapper
                                    type="mail"
                                    onClick={(
                                        e: React.MouseEvent<HTMLDivElement>
                                    ) => {
                                        e.preventDefault();
                                        window.location.href = `mailto:${userDetail.email}`;
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        color="#708193"
                                    />
                                </ActionWrapper>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip
                                        id="remove-member"
                                        className="font-arimo"
                                    >
                                        Remove Member
                                    </Tooltip>
                                }
                            >
                                <ActionWrapper
                                    type="delete"
                                    onClick={(
                                        e: React.MouseEvent<HTMLDivElement>
                                    ) => {
                                        e.preventDefault();
                                        setShow(true);
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        color="#FF5630"
                                    />
                                </ActionWrapper>
                            </OverlayTrigger>
                        </AdminArea>
                    )}
                    <EcosystemLeaveModal
                        show={show}
                        ecosystem={ecosystem}
                        memberId={member._id}
                        handleClose={() => setShow(false)}
                    />
                </>
            )}
        </Member>
    );
};

export default MemberItem;
