import styled from 'styled-components'

export const ShareButtons = styled.span`
    .left {
        margin-right: 2px
    }

    .middle {
        margin-left: 2px;
        margin-right: 2px
    }
    
    .right {
        margin-left: 2px
    }
`;

export default ShareButtons;