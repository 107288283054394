/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import { faPlusCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    FormControl,
    ListGroup,
    Row
} from 'react-bootstrap';
import styled from 'styled-components';
import { Ecosystem } from '../../interfaces/Ecosystem';
import IOrganisation from '../../interfaces/IOrganisation';
import IUser from '../../interfaces/IUser';
import { useEventTracking } from '../../pages/eventTracking';
import CommunitySearch from './CommunitySearch';
import EcosystemItem from './EcosystemItem';
import NewEcosystemList from './NewEcosystemList';
import NewOrganisationList from './NewOrganisationList';
import NewUserList from './NewUserList';
import OrganisationItem from './OrganisationItem';
import UserItem from './UserItem';

const Styles = styled.div`
    .card-body {
        padding: 0;
    }
    .basic-multi-select {
        font-size: 16px;
    }

    .createOrgButton {
        right: 5%;
    }
`;

const UsersListContainer = styled.div`
    width: 95%;
    margin: 1.5rem;
    background-color: #f5faff;
    border-radius: 20px;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0;
    }
`;

interface Props {
    username: string;
    authId: string;
}

export const CommunityList: React.FC<Props> = ({ username, authId }: Props) => {
    const [masterUserList, setMasterUserList] = useState<IUser[]>([]);
    const [masterOrgsList, setMasterOrgsList] = useState<IOrganisation[]>([]);
    const [masterEcosystemList, setMasterEcosystemList] = useState<Ecosystem[]>(
        []
    );
    const [userList, setUserList] = useState<IUser[]>([]);
    const [orgsList, setOrgsList] = useState<IOrganisation[]>([]);
    const [ecosystemList, setEcosystemList] = useState<Ecosystem[]>([]);
    const [newUserList, setNewUserList] = useState<IUser[]>([]);
    const [newOrgsList, setNewOrgsList] = useState<IOrganisation[]>([]);
    const [newEcosystemList, setNewEcosystemList] = useState<Ecosystem[]>([]);
    const [initFilterCheck, setInitFilterCheck] = useState(false);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState('users');
    const [emptyUserListText, setEmptyUserListText] = useState(
        'Loading users...'
    );
    const [emptyOrgsListText, setEmptyOrgsListText] = useState(
        'Loading organisations...'
    );
    const [emptyEcosystemListText, setEmptyEcosystemListText] = useState(
        'Loading ecosystems...'
    );

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        useEventTracking('Community', 'Search (Community)', `${value}`);
        setSearch(value);
    };

    // Main function for filtering and searching
    const filterMainFunction = () => {
        const users: IUser[] = Object.assign([], masterUserList);
        const orgs: IOrganisation[] = Object.assign([], masterOrgsList);
        const ecosystems: Ecosystem[] = Object.assign([], masterEcosystemList);

        setEmptyUserListText('Loading users...');
        setEmptyOrgsListText('Loading organisations...');
        setEmptyEcosystemListText('Loading ecosystems...');

        // For Users
        let i = 0;
        let userLength = users.length;
        while (i < userLength) {
            let searchCheck = false;

            // Check Search
            if (search.length > 0) {
                if (
                    users[i].firstName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    users[i].lastName
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    users[i].aboutMyself
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else {
                searchCheck = true;
                if (authId) users.splice(userLength - 1, 1);
                break;
            }

            // Results of Check
            if (searchCheck === false) {
                users.splice(i, 1);
                i -= 1;
                userLength = users.length;
            }
            i += 1;
        }
        setUserList(users.slice(0).reverse());

        // For Organisations
        let j = 0;
        let orgLength = orgs.length;
        while (j < orgLength) {
            let searchCheck = false;

            // Check Search
            if (search.length > 0) {
                if (
                    orgs[j].name.toLowerCase().indexOf(search.toLowerCase()) !==
                        -1 ||
                    orgs[j].description
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else {
                searchCheck = true;
                break;
            }

            // Results of Check
            if (searchCheck === false) {
                orgs.splice(j, 1);
                j -= 1;
                orgLength = orgs.length;
            }
            j += 1;
        }
        setOrgsList(orgs.slice(0).reverse());

        // For Ecosystems
        let k = 0;
        let ecosystemLength = ecosystems.length;
        while (k < ecosystemLength) {
            let searchCheck = false;

            // Check Search
            if (search.length > 0) {
                if (
                    ecosystems[k].name
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    ecosystems[k].description
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else {
                searchCheck = true;
                break;
            }

            // Results of Check
            if (searchCheck === false) {
                ecosystems.splice(k, 1);
                k -= 1;
                ecosystemLength = ecosystems.length;
            }
            k += 1;
        }
        setEcosystemList(ecosystems.slice(0).reverse());

        setEmptyUserListText('No users found.');
        setEmptyOrgsListText('No organisations found.');
        setEmptyEcosystemListText('No ecosystems found.');
    };

    function shuffle(array: IOrganisation[]): IOrganisation[];
    function shuffle(array: IUser[]): IUser[];
    function shuffle(
        array: IUser[] | IOrganisation[]
    ): IUser[] | IOrganisation[] {
        const tempArray = [...array];
        for (let i = tempArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [tempArray[i], tempArray[j]] = [tempArray[j], tempArray[i]];
        }
        if ('firstName' in array[0]) {
            return tempArray as IUser[];
        }
        return tempArray as IOrganisation[];
    }

    // *** useEffects ***

    // Upon Initialisation
    useEffect(() => {
        axios.get<IUser[]>(`/api/users/`).then(({ data }) => {
            let filteredData: IUser[] = data.filter(
                (item) => item.status === 'Active'
            );

            // 10 Newest Users
            setNewUserList(filteredData.slice(-5).reverse());
            filteredData = shuffle(filteredData);
            for (let i = 0; i < filteredData.length; i += 1) {
                if (filteredData[i]._id === authId) {
                    filteredData.push(filteredData.splice(i, 1)[0]);
                    break;
                }
            }
            setMasterUserList(filteredData);
        });
        axios.get<IOrganisation[]>(`/api/organisations/`).then(({ data }) => {
            // 10 Newest Organisations
            setNewOrgsList(data.slice(-5).reverse());

            let shuffledData: IOrganisation[] = data;
            shuffledData = shuffle(data);
            setMasterOrgsList(shuffledData);
        });
        axios.get<Ecosystem[]>(`/api/ecosystems/`).then(({ data }) => {
            // 10 Newest Ecosystems
            setNewEcosystemList(data.slice(-5).reverse());
            setMasterEcosystemList(data);
        });
    }, []);

    // Whenever filter is selected or Search is filled
    useEffect(() => {
        if (initFilterCheck === false) {
            setInitFilterCheck(true);
        } else filterMainFunction();
    }, [search, masterUserList, masterOrgsList, masterEcosystemList]);

    return (
        <Styles>
            <Card className="mb-4">
                <Card.Body className="p-4 m-2">
                    <Row>
                        {/* {sort === 'organisations' ? ( */}
                        {/*     <Col className="d-none d-sm-block" sm={5} /> */}
                        {/* ) : null} */}
                        <Col xs={12} md={6}>
                            <ButtonGroup aria-label="Sort Buttons">
                                <Button
                                    variant={
                                        sort === 'users'
                                            ? 'primary'
                                            : 'outline-primary'
                                    }
                                    onClick={() => setSort('users')}
                                >
                                    Users
                                </Button>
                                <Button
                                    variant={
                                        sort === 'organisations'
                                            ? 'primary'
                                            : 'outline-primary'
                                    }
                                    onClick={() => setSort('organisations')}
                                >
                                    Organisations
                                </Button>
                                <Button
                                    variant={
                                        sort === 'ecosystems'
                                            ? 'primary'
                                            : 'outline-primary'
                                    }
                                    onClick={() => setSort('ecosystems')}
                                >
                                    Ecosystems
                                </Button>
                            </ButtonGroup>
                            {sort === 'organisations' ? (
                                <Button
                                    href="/createorganisation"
                                    variant="success"
                                >
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                </Button>
                            ) : null}
                        </Col>
                        <Col xs={12} md={6}>
                            <CommunitySearch>
                                <FormControl
                                    placeholder="Search"
                                    name="search"
                                    type="text"
                                    value={search}
                                    onChange={handleSearch}
                                />
                                <div className="icon">
                                    <FontAwesomeIcon icon={faSearch} />
                                </div>
                            </CommunitySearch>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Body className="p-3">
                    {sort === 'users' && (
                        <div>
                            <NewUserList users={newUserList} />
                            <h4 className="ml-3 mt-5 font-weight-bold">
                                Users
                            </h4>
                            <UsersListContainer>
                                <Row xs={1} md={5} className="g-4">
                                    {userList.map((user) => (
                                        <Col>
                                            <UserItem
                                                key={user._id}
                                                authUsername={username}
                                                user={user}
                                                authId={authId}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </UsersListContainer>
                            {userList.length === 0 ? (
                                <Container>
                                    <p className="mt-3">{emptyUserListText}</p>
                                </Container>
                            ) : null}
                        </div>
                    )}

                    {sort === 'organisations' && (
                        <>
                            <NewOrganisationList organisations={newOrgsList} />
                            <div>
                                <h4 className="ml-3 mt-5 font-weight-bold">
                                    Organisations
                                </h4>
                                <ListGroup variant="flush">
                                    {orgsList.map((organisation, i) => (
                                        <div
                                            className={
                                                orgsList[i + 1]
                                                    ? 'list-item-border'
                                                    : ''
                                            }
                                        >
                                            <OrganisationItem
                                                key={organisation._id}
                                                authUsername={username}
                                                organisation={organisation}
                                                authId={authId}
                                            />
                                        </div>
                                    ))}
                                </ListGroup>
                                {orgsList.length === 0 ? (
                                    <Container>
                                        <p className="mt-3">
                                            {emptyOrgsListText}
                                        </p>
                                    </Container>
                                ) : null}
                            </div>
                        </>
                    )}

                    {sort === 'ecosystems' && (
                        <>
                            <NewEcosystemList ecosystems={newEcosystemList} />
                            <div>
                                <h4 className="ml-3 mt-5 font-weight-bold">
                                    Ecosystems
                                </h4>
                                <ListGroup variant="flush">
                                    {ecosystemList.map((ecosystem, i) => (
                                        <div
                                            className={
                                                ecosystemList[i + 1]
                                                    ? 'list-item-border'
                                                    : ''
                                            }
                                        >
                                            <EcosystemItem
                                                key={ecosystem._id}
                                                ecosystem={ecosystem}
                                                authId={authId}
                                            />
                                        </div>
                                    ))}
                                </ListGroup>
                                {ecosystemList.length === 0 ? (
                                    <Container>
                                        <p className="mt-3">
                                            {emptyEcosystemListText}
                                        </p>
                                    </Container>
                                ) : null}
                            </div>
                        </>
                    )}
                </Card.Body>
            </Card>
        </Styles>
    );
};

export default CommunityList;
