/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
// import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import {
    Nav,
    Navbar,
    NavDropdown,
    Button,
    Image,
    NavItem
} from 'react-bootstrap';
import {
    Link,
    withRouter,
    RouteComponentProps,
    NavLink
} from 'react-router-dom';
import styled from 'styled-components';
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';
import { logoutUser } from '../redux/actions/AuthActions';

import { Layout } from './Layout';
import IUser from '../interfaces/IUser';

import getSrc from '../utils/commonFunction';

import { useEventTracking } from '../pages/eventTracking';
import { useNavbarContext } from '../contexts/navbar.ctx';
import useMediaQuery from '../hooks/useMediaQuery';

const Styles = styled.div`
    a,
    .navbar-brand,
    navbar-nav .nav-link .nav-item {
        color: #dedede !important;
        // transition-timing-function: ease;
        transition: color 0.3s;
        margin-right: 6px;
        font-weight: 400;
        &:hover {
            color: white !important;
        }
    }

    .dropdown-menu {
        position: absolute;
    }

    @media (max-width: 991.98px) {
        .dropdown .dropdown-menu {
            left: 0;
            right: auto;
        }
    }

    .dropdown-item .nav-link {
        color: black !important;
    }

    .dp {
        height: 25px;
        width: 25px;
        object-fit: cover;
    }
`;

interface authProps extends RouteComponentProps {
    logoutUser: () => void;
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
    refresh: boolean;
}

const NavigationBar: React.FC<authProps> = ({ ...props }) => {
    const isMediumScreen = useMediaQuery('(max-width: 991px)');
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const isSmallOrMediumScreen = isMediumScreen || isSmallScreen;

    let loginButton;

    const user = props.auth.user.username
        ? props.auth.user.username
        : 'Not Logged In';

    const [profilePic, setProfilePic] = useState({
        location: `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`,
        name: ''
    });

    const { expanded, setExpanded } = useNavbarContext();

    const logoutFunction = () => {
        props.logoutUser();
        setExpanded(false);
        props.history.push('/');
    };

    useEffect(() => {
        if (props.auth.isAuthenticated) {
            axios
                .get<IUser>(`/api/users/profiles/${props.auth.user.username}`)
                .then(({ data }) => {
                    setProfilePic({
                        location: getSrc(data),
                        name: data.firstName
                    });
                })
                .catch(() => {
                    console.log('Profile unable to be retrieved');
                });
        }
    }, [props.refresh]);

    if (!props.auth.isAuthenticated) {
        loginButton = (
            <Nav.Item>
                <Link to="/login">
                    <Button
                        style={{
                            transition: '0.2s background',
                            width: '100px'
                        }}
                        onClick={() => setExpanded(false)}
                        variant="success"
                    >
                        Login
                    </Button>
                </Link>
            </Nav.Item>
        );
    } else {
        // if (!profilePic.name) {
        //   axios
        //   .get<IUser>(`/api/users/profiles`)
        //   .then(({ data }) => {
        //     setProfilePic({
        //       location: data.profilePicture.location,
        //       name: data.firstName
        //     })
        //   })
        //   .catch(() => {
        //     // alert(`Error! ${error}`);
        //   });
        // }

        loginButton = (
            <>
                <NavDropdown
                    alignRight
                    title={
                        <>
                            <Image
                                className="dp"
                                src={profilePic.location}
                                alt="Profile"
                                roundedCircle
                                fluid
                            />{' '}
                            {profilePic.name}
                        </>
                    }
                    id="profile-dropdown"
                >
                    <NavDropdown.Item>
                        <Nav.Link
                            onClick={() => setExpanded(false)}
                            as={Link}
                            to={`/profile/${props.auth.user.username}`}
                        >
                            My Profile
                        </Nav.Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <Nav.Link
                            onClick={() => setExpanded(false)}
                            as={Link}
                            to="/organisations"
                        >
                            My Organisations
                        </Nav.Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                        <Nav.Link onClick={logoutFunction}>Logout</Nav.Link>
                    </NavDropdown.Item>
                </NavDropdown>
            </>
        );
    }

    return (
        <Styles>
            <Navbar
                className="fixed-top shadow-sm"
                bg="primary"
                variant="dark"
                expand="lg"
                expanded={expanded}
            >
                <Layout>
                    <Navbar.Brand
                        as={Link}
                        to={props.auth.isAuthenticated ? '/dashboard' : '/'}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/interseed_white_full.svg`}
                            height="30px"
                            className="d-inline-block align-top"
                            alt="Interseed Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle
                        aria-controls="basic-navbar-nav"
                        onClick={() => setExpanded(!expanded)}
                    />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <div data-steps={isSmallOrMediumScreen && 'step-4'}>
                                <NavDropdown
                                    title="Community"
                                    id="dashboard-dropdown"
                                    data-steps="step-5"
                                >
                                    <NavDropdown.Item>
                                        <Nav.Link
                                            onClick={() => {
                                                setExpanded(false);
                                            }}
                                            as={Link}
                                            to="/dashboard"
                                        >
                                            Map
                                        </Nav.Link>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item>
                                        <Nav.Link
                                            onClick={() => {
                                                setExpanded(false);
                                            }}
                                            as={Link}
                                            to="/community"
                                        >
                                            Members
                                        </Nav.Link>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div>
                            <Nav.Item data-steps="step-6">
                                <Nav.Link
                                    onClick={() => {
                                        setExpanded(false);
                                        useEventTracking(
                                            'Newseed',
                                            'Clicked Newseed on Navigation Bar',
                                            `${user}`
                                        );
                                    }}
                                    as={Link}
                                    to="/newseed"
                                >
                                    NewsSeed
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item data-steps="step-7">
                                <Nav.Link
                                    onClick={() => {
                                        setExpanded(false);
                                        useEventTracking(
                                            'Events',
                                            'Clicked Events on Navigation Bar',
                                            `${user}`
                                        );
                                    }}
                                    as={Link}
                                    to="/events"
                                >
                                    Events
                                </Nav.Link>
                            </Nav.Item>
                            <NavDropdown
                                data-steps="step-8"
                                title="Opportunities"
                                id="opportunities-dropdown"
                                onClick={() => {
                                    useEventTracking(
                                        'Opportunities',
                                        'Clicked Opportunities on Navigation Bar',
                                        `${user}`
                                    );
                                }}
                            >
                                <NavDropdown.Item>
                                    <Nav.Link
                                        onClick={() => {
                                            useEventTracking(
                                                'Opportunities',
                                                'Clicked Funding on Navigation Bar',
                                                `${user}`
                                            );
                                            setExpanded(false);
                                        }}
                                        as={Link}
                                        to="/funding"
                                    >
                                        Funding
                                    </Nav.Link>
                                </NavDropdown.Item>
                                <NavDropdown.Item>
                                    <Nav.Link
                                        onClick={() => {
                                            useEventTracking(
                                                'Volunteering Opps',
                                                'Clicked Volunteering Opps on Navigation Bar',
                                                `${user}`
                                            );
                                            setExpanded(false);
                                        }}
                                        as={Link}
                                        to="/volunteeringopps"
                                    >
                                        Volunteering Opps
                                    </Nav.Link>
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Item data-steps="step-9">
                                <Nav.Link
                                    onClick={() => {
                                        setExpanded(false);
                                        useEventTracking(
                                            'Resources',
                                            'Clicked Resources on Navigation Bar',
                                            `${user}`
                                        );
                                    }}
                                    as={Link}
                                    to="/resources"
                                >
                                    Resources
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    onClick={() => {
                                        setExpanded(false);
                                        useEventTracking(
                                            'Blog',
                                            'Clicked Blogs on Navigation Bar',
                                            `${user}`
                                        );
                                    }}
                                    as={Link}
                                    to={{
                                        pathname:
                                            'https://www.interseed.co/blog'
                                    }}
                                    target="_blank"
                                >
                                    Blog
                                </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item className="mr-2">
                                <Nav.Link
                                    onClick={() => {
                                        setExpanded(false);
                                        useEventTracking(
                                            'Blog',
                                            'Clicked Blogs on Navigation Bar',
                                            `${user}`
                                        );
                                    }}
                                    as={Link}
                                    to="/greentechchallenge"
                                >
                                    GreenTech Challenge
                                </Nav.Link>
                            </Nav.Item> */}
                            {props.userProfile.userProfile &&
                            props.userProfile.userProfile.admin ? (
                                <Nav.Item>
                                    <Nav.Link
                                        onClick={() => setExpanded(false)}
                                        as={Link}
                                        to="/admin/sendemail"
                                    >
                                        Send Email
                                    </Nav.Link>
                                </Nav.Item>
                            ) : null}

                            {loginButton}
                        </Nav>
                    </Navbar.Collapse>
                </Layout>
            </Navbar>
        </Styles>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps, { logoutUser })(
    withRouter(NavigationBar)
);
