/* eslint-disable react/prop-types */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import isEmpty from 'is-empty';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
    Container,
    Card,
    Form,
    Button,
    Col,
    Image,
    Modal,
    ProgressBar
} from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import { useAlert } from 'react-alert';
import { getNewToken, updateUserStatus } from '../redux/actions/AuthActions';

// Interface
import IUser, { UserBlankObj } from '../interfaces/IUser';
import { IUserDataNewToken } from '../interfaces/AuthInterface';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';
import {
    loadUserProfile,
    setUserProfile
} from '../redux/actions/UserProfileActions';

// Select Options
import {
    interestOptions,
    involvementOptions,
    lookingForOptions,
    skillsOptions,
    countriesOptions
} from '../options/profileSelectOptions';
import getSrc, { whitespaceCheck } from '../utils/commonFunction';

const CardContent = styled.div`
    padding: 2rem 1rem;

    .form-label {
        font-size: 16px;
        font-weight: bold;
    }

    .custom-file {
        font-size: 16px;
    }

    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }

    .form-text {
        font-size: 12px;
    }
`;

const FormHeader = styled.h2`
    margin-bottom: 2rem;
`;

interface authProps extends RouteComponentProps {
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
    refreshNav: () => void;
    profile: IUser | undefined;
    setProfile: (_: IUser | undefined) => void;
    getNewToken: (
        arg0: IUserDataNewToken,
        arg1: RouteComponentProps['history']
    ) => void;
    loadUserProfile: (arg0: string) => void;
    updateUserStatus: (arg0: string) => void;
}

interface thumbFile {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    src?: string | ArrayBuffer;
}

interface IUploadObject {
    city: string;
    country: string;
    profilePicture?: { location: string; key: string };
    skillsets: string[];
    username?: string;
    interestAreas: string[];
    sustainabilityInvolvement: string[];
    lookingFor: string[];
    aboutMyself: string;
    status: string;
    onboarding: boolean;
}

interface ModalProps {
    show: boolean;
    onHide: () => void;
}

const MyVerticallyCenteredModal: React.FC<ModalProps> = (props) => (
    <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title
                className="mx-auto text-center w-100"
                id="contained-modal-title-vcenter"
            >
                Congratulations! Welcome to Interseed
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-auto text-center">
            <p>Have an organisation?</p>
            <Button
                className="mb-4 mx-auto"
                onClick={() => window.location.replace('/createorganisation')}
                variant="success"
            >
                Create an organisation
            </Button>{' '}
            <Button
                className="mb-4 mx-auto"
                onClick={() => window.location.replace('/community')}
            >
                Join an existing one
            </Button>
            <p>Trying to connect with like-minded individuals?</p>
            <Button
                className="mb-4 mx-auto"
                onClick={() => window.location.replace('/community')}
            >
                Meet Interseed&apos;s community
            </Button>
        </Modal.Body>
    </Modal>
);

// Main React Functional Component
const Onboarding: React.FC<authProps> = (props) => {
    const alert = useAlert();

    const profile = props.profile ?? UserBlankObj;
    const [thumbReal, setThumbReal] = useState<File>();
    const [thumb, setThumb] = useState<thumbFile>({
        lastModified: 0,
        name: 'Choose a file...',
        size: 0,
        type: '',
        src: getSrc(profile)
    });

    const [disableSubmit, setDisableSubmit] = useState(false);

    // Check whether an edit has been made to enable Submit button
    const [editCheck, setEditCheck] = useState(false);

    // Check whether OAuth aka if email === username
    const [oAuthCheck, setOAuthCheck] = useState(false);

    // To hide navigation page until successful submission
    const [modalShow, setModalShow] = useState(false);

    // To show form until successful navigation
    const [formShow, setFormShow] = useState(true);

    // Chars left in About Me Section
    const aboutMaxLength = 500;
    const [aboutCharsRemaining, setAboutCharsRemaining] = useState(
        aboutMaxLength
    );

    // Which step of the form the user is on
    const [step, setStep] = useState(1);
    const [pageAllowed, setPageAllowed] = useState(1);
    const [percentDone, setPercentDone] = useState(0);

    // Whether username provided by user is available
    const [usernameTaken, setUsernameTaken] = useState(false);

    // Whether user is typing in username section
    const [userTypingUsername, setUserTypingUsername] = useState(false);

    useEffect(() => {
        // Only makes buttons enabled if content on page is done
        const pageOneRequirementsUnmet =
            !profile.username || !profile.country || !profile.city || !whitespaceCheck(profile, ['username', 'country', 'city']);
        const pageTwoRequirementsUnmet =
            !profile.skillsets.length ||
            !profile.interestAreas.length ||
            !profile.sustainabilityInvolvement.length;
        const pageThreeRequirementsUnmet =
            !profile.aboutMyself || !profile.lookingFor.length || !whitespaceCheck(profile, ['aboutMyself', 'lookingFor']);

        const totalModules = 8

        const noOfModulesDone =
            (profile.username !== profile.email && profile.username && whitespaceCheck(profile, ['username']) ? 1 : 0) +
            (profile.country && whitespaceCheck(profile, ['country']) ? 1 : 0) +
            (profile.city && whitespaceCheck(profile, ['city']) ? 1 : 0) +
            (profile.skillsets.length ? 1 : 0) +
            (profile.interestAreas.length ? 1 : 0) +
            (profile.sustainabilityInvolvement.length ? 1 : 0) +
            (profile.aboutMyself && whitespaceCheck(profile, ['aboutMyself']) ? 1 : 0) +
            (profile.lookingFor.length && whitespaceCheck(profile, ['lookingFor']) ? 1 : 0);

        setPercentDone((noOfModulesDone / totalModules) * 100);

        if (pageOneRequirementsUnmet) {
            setPageAllowed(1);
        } else if (pageTwoRequirementsUnmet) {
            setPageAllowed(2);
        } else if (pageThreeRequirementsUnmet) {
            setPageAllowed(3);
        } else {
            setPageAllowed(4);
        }
    }, [profile]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, type, value } = event.target;
        const checkUsernameAvailability = () => {
            axios
                // Check if a profile exists with that username
                .get(`/api/users/profiles/${value}`)
                .then(() => {
                    // If a profile is found, username is taken
                    setUsernameTaken(true);
                    setUserTypingUsername(false);
                })
                .catch(() => {
                    // If profile not found, username is not taken
                    setUsernameTaken(false);
                    setUserTypingUsername(false);
                });
        };

        if (!editCheck) setEditCheck(true);

        // Parse Profile Thumbnail
        if (type === 'file') {
            if (event.target.files && event.target.files[0]) {
                if (
                    event.target.files[0].type !== 'image/jpeg' &&
                    event.target.files[0].type !== 'image/png'
                ) {
                    alert.error('Please choose a JPEG/PNG file.');
                    return;
                }
                if (event.target.files[0].size > 4000000) {
                    // eslint-disable-next-line
                    alert.error('Please choose an image under 4mb.');
                    return;
                }
                
                setThumbReal(event.target.files[0]);

                const file: thumbFile = event.target.files[0];
                const reader = new FileReader();

                reader.onload = function read(e) {
                    if (e.target && e.target.result) {
                        file.src = e.target.result;
                        if (file.name.length > 40) {
                            setThumb({
                                lastModified: file.lastModified,
                                name: file.name
                                    .slice(0, 40)
                                    .concat(
                                        `... ${file.type.replace(
                                            'image/',
                                            '.'
                                        )}`
                                    ),
                                size: file.size,
                                type: file.type
                            });
                        } else setThumb(file);
                    }
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        } else {
            if (name === 'aboutMyself') {
                setAboutCharsRemaining(aboutMaxLength - value.length);
            }

            props.setProfile({
                ...profile,
                [name]: value
            });

            // If change is caused by typing username
            if (name === 'username') {
                let myVar;
                const doneTyping = () => {
                    myVar = setTimeout(checkUsernameAvailability, 500);
                };
                const startTyping = () => {
                    clearTimeout(myVar);
                };
                if (userTypingUsername) {
                    startTyping();
                }
                doneTyping();
            }
        }
    };

    // eslint-disable-next-line
    const handleSelect = (newValue: any, type: string) => {
        let value: string[] | string;

        if (!editCheck) setEditCheck(true);

        if (type !== 'country') {
            value = [];
            for (let i = 0; i < newValue.length; i += 1)
                value.push(newValue[i].value);
            value.sort();
        } else {
            value = newValue.value;
        }

        props.setProfile({
            ...profile,
            [type]: value
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (disableSubmit) return;
        setDisableSubmit(true);

        const uploadObject: IUploadObject = {
            city: profile.city,
            country: profile.country,
            skillsets: profile.skillsets,
            interestAreas: profile.interestAreas,
            sustainabilityInvolvement: profile.sustainabilityInvolvement,
            lookingFor: profile.lookingFor,
            aboutMyself: profile.aboutMyself,
            status: 'Active',
            onboarding: true
        };

        if (oAuthCheck) uploadObject.username = profile.username;

        if (thumbReal) {
            // eslint-disable-next-line
            const data: any = new FormData();
            data.append('image', thumbReal);

            // Delete old profile picture from AWS
            if (profile.profilePicture) {
                const { key } = profile.profilePicture;
                axios.delete(`/api/uploads/images/${key}`);
            }

            axios
                .post(`/api/uploads/images`, data)
                .then((res) => {
                    const thumbnailData = res.data;
                    uploadObject.profilePicture = thumbnailData;
                    axios
                        .put(`/api/users/profiles`, uploadObject)
                        .then((resp) => {
                            if (oAuthCheck) {
                                const userData = {
                                    username: resp.data.user.username,
                                    email: resp.data.user.email,
                                    userId: resp.data.user._id
                                };
                                props.refreshNav();
                                props.getNewToken(userData, props.history);
                            } else {
                                props.refreshNav();
                                props.updateUserStatus(resp.data.user.status);
                                props.loadUserProfile(resp.data.user.username);
                            }

                            // window.location.replace('/dashboard');
                        })
                        .catch((err) => {
                            alert.error(
                                `Error completing profile. ${err.response.data.message} Please try again.`
                            );
                            setDisableSubmit(false);
                        });
                })
                .catch(() => {
                    alert.error(
                        'Error completing profile (Image Upload Failed). Please try again.'
                    );
                    setDisableSubmit(false);
                });
        } else {
            axios
                .put(`/api/users/profiles`, uploadObject)
                .then((respo) => {
                    if (oAuthCheck) {
                        const userData = {
                            username: respo.data.user.username,
                            email: respo.data.user.email,
                            userId: respo.data.user._id
                        };
                        props.refreshNav();
                        props.getNewToken(userData, props.history);
                    } else {
                        props.refreshNav();
                        props.updateUserStatus(respo.data.user.status);
                        props.loadUserProfile(respo.data.user.username);
                    }
                })
                .catch((err) => {
                    alert.error(
                        `Error completing profile. ${err.response.data.message} Please try again.`
                    );
                    setDisableSubmit(false);
                });
        }
        event.preventDefault();
    };

    useEffect(() => {
        // If status is already Active, go to dashboard
        if (
            props.userProfile &&
            props.userProfile.userProfile &&
            props.userProfile.userProfile.status === 'Active'
        ) {
            setPageAllowed(4);
            setFormShow(false);
            setModalShow(true);
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    useEffect(() => {
        if (!isEmpty(profile.loginType) && profile.loginType !== 'local') {
            setOAuthCheck(true);
            if (profile.username === profile.email) profile.username = '';
        }
    }, [props.userProfile]);

    useEffect(() => {
        // If not authenticated, push to login page
        if (!props.auth.isAuthenticated) props.history.push('/login');
    }, [props.auth.isAuthenticated]);

    return (
        <Container>
            <Card
                className={`text-center text-sm-left mb-4 ${
                    formShow ? ' ' : 'd-none'
                }`}
            >
                <CardContent className="px-4">
                    <FormHeader className="text-center text-sm-left">
                        {step === 1
                            ? 'Welcome - Tell us a little bit about yourself!'
                            : ''}
                        {step === 2
                            ? 'Nearing the end - A few more details!'
                            : ''}
                        {step === 3
                            ? 'One last thing - Help the right people find you!'
                            : ''}
                    </FormHeader>
                    <hr />
                    <Form
                        className="text-muted text-center text-sm-left"
                        onSubmit={handleSubmit}
                    >
                        <Form.Row
                            className={`align-items-center ${
                                step === 1 ? '' : 'd-none'
                            }`}
                        >
                            <Col
                                sm={4}
                                className="d-sm-flex justify-content-center"
                            >
                                <Image
                                    className="profile-picture"
                                    src={
                                        thumb.src
                                            ? thumb.src.toString()
                                            : `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
                                    }
                                    alt="Profile Picture"
                                    roundedCircle
                                    thumbnail
                                    fluid
                                    onError={(e) => {
                                        e.currentTarget.onerror = null; // prevents looping
                                        e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                                    }}
                                />
                            </Col>
                            <Form.Group
                                as={Col}
                                sm={8}
                                className="text-left"
                                controlId="formProfilePicture"
                            >
                                <Form.Label>Change Profile Picture</Form.Label>
                                <Form.File
                                    name="thumbnail"
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={handleChange}
                                    label={thumb.name}
                                    custom
                                />
                                <Form.Text muted>
                                    File size limit: 4mb
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>

                        {oAuthCheck ? (
                            <Form.Group
                                controlId="formUsername"
                                className={`required ${
                                    step === 1 ? '' : 'd-none'
                                } text-left`}
                            >
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    required
                                    pattern="[a-zA-Z0-9]+"
                                    name="username"
                                    type="text"
                                    maxLength={100}
                                    value={profile.username}
                                    onChange={handleChange}
                                />
                                <Form.Text muted>
                                    Only alphanumeric characters, no spaces.
                                </Form.Text>
                                {/* Button to show whether username is taken */}
                                {usernameTaken ? (
                                    <Button variant="danger">
                                        Username not available
                                    </Button>
                                ) : null}
                            </Form.Group>
                        ) : null}

                        <Form.Row className={step === 1 ? '' : 'd-none'}>
                            <Form.Group
                                as={Col}
                                controlId="formCountry"
                                className="required text-left"
                            >
                                <Form.Label>Country</Form.Label>
                                <Select
                                    name="country"
                                    options={countriesOptions}
                                    value={{
                                        value: profile.country,
                                        label: profile.country
                                    }}
                                    placeholder="Select your country..."
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleSelect(newValue, 'country')
                                    }
                                />
                                <input
                                    className="invis"
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0 }}
                                    defaultValue={profile.country}
                                    required
                                />
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                controlId="formCity"
                                className="required text-left"
                            >
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    required
                                    name="city"
                                    type="text"
                                    maxLength={100}
                                    value={profile.city}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group
                            controlId="formSkillsets"
                            className={`required ${
                                step === 2 ? '' : 'd-none'
                            } text-left`}
                        >
                            <Form.Label>Skills</Form.Label>
                            <Select
                                isMulti
                                name="skillsets"
                                options={skillsOptions}
                                value={profile.skillsets.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="Select your skill(s)"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'skillsets')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={profile.skillsets}
                                required
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formInterestAreas"
                            className={`required ${
                                step === 2 ? '' : 'd-none'
                            } text-left`}
                        >
                            <Form.Label>Interests</Form.Label>
                            <Select
                                isMulti
                                name="interestAreas"
                                options={interestOptions}
                                value={profile.interestAreas.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="Select your interest(s)"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'interestAreas')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={profile.interestAreas}
                                required
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formSustainabilityInvolvement"
                            className={`required ${
                                step === 2 ? '' : 'd-none'
                            } text-left`}
                        >
                            <Form.Label>Sustainability Involvement</Form.Label>
                            <Select
                                isMulti
                                style={{ height: 'max-content' }}
                                name="sustainabilityInvolvement"
                                options={involvementOptions}
                                value={profile.sustainabilityInvolvement.map(
                                    (item) => ({
                                        value: item,
                                        label: item
                                    })
                                )}
                                placeholder="Select your area(s) of focus"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(
                                        newValue,
                                        'sustainabilityInvolvement'
                                    )
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={profile.sustainabilityInvolvement}
                                required
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formAboutMyself"
                            className={`required ${
                                step === 3 ? '' : 'd-none'
                            } text-left`}
                        >
                            <Form.Label>About Me</Form.Label>
                            <Form.Control
                                name="aboutMyself"
                                as="textarea"
                                placeholder="Example: Motivated to foster meaningful innovation x collaboration in the sustainability space in Southeast Asia and beyond. My definition of success is in helping others succeed in creating and scaling their impact."
                                value={profile.aboutMyself}
                                onChange={handleChange}
                                maxLength={500}
                                style={{ height: '200px' }}
                            />
                            <Form.Text id="formAboutMyself">
                                {aboutCharsRemaining} characters left
                            </Form.Text>
                        </Form.Group>

                        <Form.Group
                            controlId="formLookingFor"
                            className={`required ${
                                step === 3 ? '' : 'd-none'
                            } text-left`}
                        >
                            <Form.Label>Looking For</Form.Label>
                            <Select
                                isMulti
                                name="lookingFor"
                                options={lookingForOptions}
                                value={profile.lookingFor.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="I am looking for..."
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'lookingFor')
                                }
                            />
                        </Form.Group>

                        <ProgressBar
                            style={{ height: '2em' }}
                            className="mt-4 mb-4"
                            label={`${percentDone}%`}
                            now={percentDone}
                        />
                        <Col className="d-flex justify-content-end">
                            <Button
                                className="mr-2"
                                variant="secondary"
                                onClick={() => setStep(step - 1)}
                                disabled={step === 1}
                            >
                                Prev
                            </Button>
                            {pageAllowed === 4  ? (
                                <Button
                                    variant= {disableSubmit ? 'success' : 'primary'}
                                    type="submit"
                                    disabled={disableSubmit}
                                    className={step === 3 ? '' : 'd-none'}
                                >
                                    Save
                                </Button>
                            ) : (
                                <Button
                                    variant="light"
                                    className={step === 3 ? '' : 'd-none'}
                                    disabled
                                >
                                    Save
                                </Button>
                            )}
                            <Button
                                onClick={() => {
                                    setStep(step + 1);
                                }}
                                className={step !== 3 ? '' : 'd-none'}
                                disabled={
                                    step === 3 ||
                                    pageAllowed < step + 1 ||
                                    usernameTaken
                                }
                            >
                                Next
                            </Button>
                        </Col>
                    </Form>
                </CardContent>
            </Card>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => {
                    window.location.replace('/community');
                }}
            />
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    profile: state.userProfile.userProfile,
    userProfile: state.userProfile
});

export default connect(mapStateToProps, {
    setProfile: setUserProfile,
    loadUserProfile,
    getNewToken,
    updateUserStatus
})(withRouter(Onboarding));
