/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { Card, ListGroup, FormControl, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import OrganisationItem from '../community/OrganisationItem';
import IOrganisation from '../../interfaces/IOrganisation';
import { useEventTracking } from '../../pages/eventTracking';

const Styles = styled.div`
    .card-body {
        padding: 0;
    }

    .basic-multi-select {
        font-size: 16px;
    }
`;

interface Props {
    username: string;
    authId: string;
}

interface filterType {
    countries: string[];
    categories: string[];
}

export const MyOrganisationsList: React.FC<Props> = ({
    username,
    authId
}: Props) => {
    const [masterList, setMasterList] = useState<IOrganisation[]>([]);
    const [list, setList] = useState<IOrganisation[]>([]);
    const [initFilterCheck, setInitFilterCheck] = useState(false);
    // eslint-disable-next-line
    const [sort, setSort] = useState('newest');
    const [filter, setFilter] = useState<filterType>({
        countries: [],
        categories: []
    });
    const [search, setSearch] = useState('');

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        useEventTracking(
            'My Organisation',
            'Search (My Organisations)',
            `${value}`
        );
        setSearch(value);
    };

    // eslint-disable-next-line
    const handleFilterSelect = (newValue: any, type: string) => {
        const value: string[] = [];
        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }
        setFilter({
            ...filter,
            [type]: value
        });
    };

    // Main function for filtering and searching
    const filterMainFunction = () => {
        const organisations: IOrganisation[] = Object.assign([], masterList);
        let i = 0;
        let { length } = organisations;
        while (i < length) {
            let searchCheck = false;

            // Check Search
            if (search.length > 0) {
                if (
                    organisations[i].name
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    organisations[i].description
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else searchCheck = true;

            // Results of Check
            if (searchCheck === false) {
                organisations.splice(i, 1);
                i -= 1;
                length = organisations.length;
            }
            i += 1;
        }
        setList(organisations);
    };

    // *** useEffects ***

    // Upon Initialisation
    useEffect(() => {
        // Axios request for retrieving landscape
        axios.get(`/api/organisations/`).then(({ data }) => {
            const tempOrgArray: IOrganisation[] = [];
            for (let i = 0; i < data.length; i += 1) {
                for (let j = 0; j < data[i].staff.length; j += 1) {
                    if (
                        data[i].staff[j].user._id === authId &&
                        data[i].staff[j].role !== 'Applicant'
                    ) {
                        tempOrgArray.push(data[i]);
                        break;
                    }
                }
            }
            setMasterList(tempOrgArray);
            setList(tempOrgArray.slice(0).reverse());
        });
    }, []);

    // Whenever filter is selected or Search is filled
    useEffect(() => {
        if (initFilterCheck === false) {
            setInitFilterCheck(true);
        } else filterMainFunction();
    }, [search, filter]);

    return (
        <div>
            <h4>My Organisations</h4>
            <Styles>
                <Card>
                    <Card.Header>
                        <FormControl
                            placeholder="Search your organisations"
                            name="search"
                            type="text"
                            value={search}
                            onChange={handleSearch}
                        />
                    </Card.Header>
                    <Card.Body className="p-3">
                        <ListGroup variant="flush">
                            {list.map((item, i) => (
                                <div
                                    className={
                                        list[i + 1] ? 'list-item-border' : ''
                                    }
                                >
                                    <OrganisationItem
                                        key={item._id}
                                        authUsername={username}
                                        organisation={item}
                                        authId={authId}
                                    />
                                </div>
                            ))}
                            {list.length === 0 ? (
                                <Container>
                                    <p className="mt-3">
                                        You have no organisations.{' '}
                                        <Link
                                            to="/createorganisation"
                                            onClick={(e) =>
                                                useEventTracking(
                                                    'My Organisation',
                                                    'Clicked Create',
                                                    username
                                                )
                                            }
                                        >
                                            Create
                                        </Link>{' '}
                                        one today!
                                    </p>
                                </Container>
                            ) : null}
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Styles>
        </div>
    );
};

export default MyOrganisationsList;
