import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInMinutes, isWithinInterval, parse } from 'date-fns';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Col,
    ListGroup,
    Row,
    Image,
    Button,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import styled from 'styled-components';
import { IEvent } from '../../interfaces/IEvent';
import getSrc from '../../utils/commonFunction';
import { color, EventButton } from './EventStyles';
import { useEventTracking } from '../../pages/eventTracking';

const RowContent = styled.div`
    .title {
        padding: 0;
        font-size: 0.9rem;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
        color: #51617c;
    }

    .p1 .p2 .p3 {
        font-weight: 400;
        color: rgba(81, 97, 124, 1);
    }

    .h1 {
        font-size: 3rem;
        line-height: 3.625rem;
        font-weight: 700;
        color: rgba(39, 50, 68, 1);
    }

    .h2 {
        font-size: 2rem;
        line-height: 2.375rem;
        font-weight: 700;
        color: rgba(39, 50, 68, 1);
    }

    .h3 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        color: rgba(39, 50, 68, 1);
    }

    .h4 {
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        color: rgba(81, 97, 124, 1);
    }

    .h5 {
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 500;
        color: rgba(81, 97, 124, 1);
    }

    .h6 {
        font-size: 1rem;
        line-height: 1.625rem;
        font-weight: 500;
        color: rgba(81, 97, 124, 1);
    }

    .p1 {
        font-size: 1.125rem;
        line-height: 1.875rem;
    }

    .p2 {
        font-size: 1.125rem;
        line-height: 1.625rem;
    }

    .p3 {
        font-size: 0.875rem;
        line-height: 1.5rem;
    }

    .item-button {
        font-size: 0.9rem;
        padding: 0.25rem 1.25rem;
    }

    .col-r {
        justify-content: space-between;
    }
`;

interface Props {
    event: IEvent;
    setViewEvent: (val: boolean) => void;
    setSelectedEvent: (val: string) => void;
}

const getTime = (startTime: string, endTime: string) => {
    const dateObj = new Date(startTime);
    const diffMinutes = differenceInMinutes(dateObj, new Date());
    let time = '';
    let days: number = Math.floor(diffMinutes / (60 * 24));
    let hours: number = Math.floor(diffMinutes / 60) % 24;
    if (
        isWithinInterval(new Date(), {
            start: new Date(startTime),
            end: new Date(endTime)
        })
    ) {
        return (
            <div
                className="position-relative font-weight-bold"
                style={{ color: '#ff5630' }}
            >
                Live
            </div>
        );
    }
    if (days >= 0) {
        if (days !== 0) {
            time += days === 1 ? `In ${days} Day` : `In ${days} Days`;
        }
        if (hours !== 0) {
            if (days !== 0) {
                time += ', ';
            } else {
                time += 'In ';
            }
            time += hours === 1 ? ` ${hours} Hour` : ` ${hours} Hours`;
        }
    } else {
        days = Math.abs(days);
        hours = Math.abs(hours);
        if (days !== 0) {
            time += days === 1 ? `${days} Day` : `${days} Days`;
        }
        if (hours !== 0) {
            if (days !== 0) {
                time += ', ';
            }
            time += hours === 1 ? ` ${hours} Hour` : ` ${hours} Hours`;
        }
        time += ` Ago`;
    }
    if (Math.abs(diffMinutes) < 60) {
        if (diffMinutes > 0) time = `${diffMinutes} Minutes Left`;
        else time = `${Math.abs(diffMinutes)} Minutes Ago`;
    }
    return time;
};

export default function EventItem({
    event,
    setViewEvent,
    setSelectedEvent
}: Props): ReactElement {
    const viewEvent = () => {
        setSelectedEvent(event._id);
        setViewEvent(true);
    };
    const history = useHistory();

    return (
        <ListGroup.Item className="flex-grow-1 px-0 py-3">
            <RowContent
                style={{
                    height: '100%'
                }}
            >
                <Row
                    className="align-items-center px-3"
                    style={{ gap: '1rem', height: '100%' }}
                >
                    <Col xs="3" className="d-flex align-items-center p-0">
                        <Image
                            src={event.banner?.location}
                            style={{
                                aspectRatio: '1/1',
                                width: '100%',
                                maxWidth: '100%',
                                borderRadius: '15px',
                                objectFit: 'cover'
                            }}
                            fluid
                        />
                    </Col>
                    <Col className="text-left col-r d-flex flex-column p-0">
                        <div>
                            <div className="h5 font-weight-bold text-truncate m-0">
                                {event.title}
                            </div>
                        </div>
                        <div
                            className="mb-2 d-flex align-items-center"
                            style={{ gap: '1rem' }}
                        >
                            <div>
                                <div
                                    className="p3 m-0"
                                    style={{
                                        color: color.shade2
                                    }}
                                >
                                    {getTime(event.startTime, event.endTime)}
                                </div>
                            </div>
                            {event.isRegistered && (
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Tooltip id="registered-tooltip">
                                            Registered on this event.
                                        </Tooltip>
                                    }
                                >
                                    <FontAwesomeIcon
                                        size="sm"
                                        icon={faCalendarCheck}
                                        style={{
                                            display: 'inline-block',
                                            color: color.theme
                                        }}
                                    />
                                </OverlayTrigger>
                            )}
                        </div>
                        <div
                            className="d-flex align-items-center"
                            style={{ gap: '0.5rem' }}
                        >
                            <EventButton
                                style={{
                                    padding: '0.25rem 1.5rem'
                                }}
                                onClick={() => {
                                    viewEvent();
                                    useEventTracking(
                                        'Events',
                                        'Clicked View Event from Upcoming Events',
                                        `${event.title}`
                                    );
                                }}
                            >
                                View
                            </EventButton>
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: '2rem'
                                }}
                            >
                                {event.participants
                                    .slice(0, 3)
                                    .map((participant, index) => (
                                        <Image
                                            key={participant._id}
                                            src={getSrc(participant)}
                                            onClick={() => {
                                                history.push(
                                                    `/profile/${participant.username}`
                                                );
                                            }}
                                            alt="Profile"
                                            roundedCircle
                                            fluid
                                            style={{
                                                position: 'absolute',
                                                left: `${1.5 * index}rem`,
                                                width: '2rem',
                                                height: '2rem',
                                                objectFit: 'cover',
                                                border: '2px solid #FFFFFF',
                                                zIndex: index % 2 ? 10 : 5,
                                                cursor: 'pointer'
                                            }}
                                        />
                                    ))}
                                {event.participantLength > 3 && (
                                    <div
                                        className="d-flex font-weight-bold align-items-center justify-content-center"
                                        style={{
                                            position: 'absolute',
                                            left: `${1.5 * 3}rem`,
                                            width: '2rem',
                                            height: '2rem',
                                            objectFit: 'cover',
                                            border: '2px solid #FFFFFF',
                                            backgroundColor: '#C1C7D0',
                                            color: '#fff',
                                            borderRadius: '999px',
                                            fontSize: '0.75rem',
                                            zIndex: 10
                                        }}
                                    >
                                        {`+${event.participantLength - 3}`}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </RowContent>
        </ListGroup.Item>
    );
}
