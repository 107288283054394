import React, { useState, useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import IOrganisation from '../../interfaces/IOrganisation';

const NewOrgsContainer = styled.table`
    width: 95%;
    margin: 1.5rem;
    background-color: #f5faff;
    border-radius: 20px;

    @media screen and (max-width: 768px) {
        width: 100%;
        margin: 0;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 20px;
    color: black;
    padding: 1rem;

    &:hover {
        background-color: #ccc;
        border-radius: 0;
        text-decoration: none;
    }

    h3 {
        font-size: min(16px, 1.5vw);

        @media screen and (max-width: 767px) {
            display: none;
        }
    }

    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        margin-bottom: 1rem;
        max-height: 4.5rem;
        max-width: 4.5rem;
    }
`;

function NewOrganisationItem({
    organisation
}: {
    organisation: IOrganisation;
}) {
    return (
        <Item as={Link} to={`/organisation/${organisation._id}`}>
            <Image
                className="img-thumbnail"
                src={
                    organisation.logo
                        ? organisation.logo.location
                        : `${process.env.PUBLIC_URL}/assets/interseed.png`
                }
                alt="Organisation"
                roundedCircle
                thumbnail
                fluid
                onError={(e) => {
                    e.currentTarget.onerror = null; // prevents looping
                    e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/interseed.png`;
                }}
            />
            <h3>{organisation.name}</h3>
        </Item>
    );
}

export default function NewOrganisationList({
    organisations
}: {
    organisations: IOrganisation[];
}) {
    return (
        <div>
            <h5 className="ml-4 my-4 font-weight-bold">
                Welcome our new Organisations!
            </h5>
            <NewOrgsContainer>
                <Row xs={3} md={5} className="g-4">
                    {organisations.map((organisation) => (
                        <NewOrganisationItem
                            organisation={organisation}
                            key={organisation._id}
                        />
                    ))}
                </Row>
            </NewOrgsContainer>
        </div>
    );
}
