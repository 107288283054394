import React from 'react';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Ecosystem } from '../../interfaces/Ecosystem';
import CopyIcon from './CopyIcon';

const ListHeading = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #002a52;
`;

const FocusAreas = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    & > div {
        background-color: #d3e8ff;
        color: #2b6098;
        padding: 7px 9px;
        border-radius: 8px;
    }
`;

interface Props {
    show: boolean;
    ecosystem: Ecosystem;
    handleClose: () => void;
    profileId: string;
}

const EcosystemCreateModal: React.FC<Props> = ({
    show,
    ecosystem,
    handleClose,
    profileId
}) => {
    const history = useHistory();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            centered
            dialogClassName="modal-width font-arimo"
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title as="h1" className="font-weight-bold">
                    Your Ecosystem have been Created!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-3">
                <Row className="justify-content-center my-3">
                    <Col className="mb-2 imgcontainer" sm={2}>
                        <Image
                            className="img-thumbnail"
                            src={
                                ecosystem.logo
                                    ? ecosystem.logo.location
                                    : `${process.env.PUBLIC_URL}/assets/interseed.png`
                            }
                            alt="Ecosystem"
                            roundedCircle
                            thumbnail
                            fluid
                            onError={(e) => {
                                e.currentTarget.onerror = null; // prevents looping
                                e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`;
                            }}
                        />
                    </Col>
                    <Col className="my-auto text-sm-left text-center" sm={7}>
                        <ListHeading>{ecosystem.name}</ListHeading>
                        {ecosystem.focusAreas?.length && (
                            <FocusAreas className="mt-2">
                                {ecosystem.focusAreas.sort().map((focus) => (
                                    <div>{focus}</div>
                                ))}
                            </FocusAreas>
                        )}
                    </Col>
                </Row>
                <Button
                    className="btn-block my-2"
                    variant="outline-primary"
                    onClick={() =>
                        navigator.clipboard.writeText(
                            ecosystem.verificationCode
                        )
                    }
                >
                    <div className="d-flex justify-content-center align-items-center">
                        <CopyIcon />
                        <div className="ml-2">
                            Copy Verification Code:
                            <span className="ml-3 font-weight-bold">
                                {ecosystem.verificationCode}
                            </span>
                        </div>
                    </div>
                </Button>
                <Button
                    className="btn-block mb-2"
                    variant="primary"
                    onClick={() => {
                        handleClose();
                        history.push(`/organisation/${profileId}`);
                    }}
                >
                    Finish
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default EcosystemCreateModal;
