import React, { ReactElement } from 'react';
import styled from 'styled-components';

const LoadingContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;

    .dot {
        margin: 0;
        padding: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #fff;
    }

    .loading {
        transform-origin: 50% 100%;
    }

    .dot-1,
    .dot-2,
    .dot-3 {
        animation: bounce1 0.5s linear infinite;
    }

    .dot-2 {
        animation-delay: 0.1s;
    }

    .dot-3 {
        animation-delay: 0.2s;
    }

    @keyframes bounce1 {
        from,
        to {
            transform: translateY(0) scale(1, 1);
            animation-timing-function: ease-in;
        }
        45% {
            transform: translateY(2em) scale(1, 1);
            animation-timing-function: linear;
        }
        50% {
            transform: translateY(2em) scale(1.5, 0.5);
            animation-timing-function: linear;
        }
        55% {
            transform: translateY(2em) scale(1, 1);
            animation-timing-function: ease-out;
        }
    }
`;

export default function EventLoading(): ReactElement {
    return (
        <LoadingContent>
            <div
                className="loading position-relative d-flex"
                style={{ left: '-50%', gap: '3px' }}
            >
                <div className="dot dot-1" />
                <div className="dot dot-2" />
                <div className="dot dot-3" />
            </div>
        </LoadingContent>
    );
}
