import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import styled from 'styled-components';
import { Ecosystem } from '../../../interfaces/Ecosystem';
import { useEventTracking } from '../../../pages/eventTracking';
import { AuthState } from '../../../redux/actions/types';
import EcosystemAbout from './EcosystemAbout';
import EcosystemMembers from './EcosystemMembersSection';
import EcosystemProjectIcon from './EcosystemProjectIcon';
import EcosystemProjects from './EcosystemProjects';

const TabContent = styled(Tab.Content)`
    padding-bottom: 200px;
`;

interface Props {
    profile: Ecosystem;
    isAdminUser: boolean;
    deleted: boolean;
    setDeleted: (deleted: boolean) => void;
    ecosystemId: string | undefined;
    auth: AuthState;
}

const EcosystemTabs: React.FC<Props> = ({
    profile,
    isAdminUser,
    deleted,
    setDeleted,
    ecosystemId,
    auth
}) => {
    const category =
        `${window.location.pathname.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` ===
            'ecosystem' && isAdminUser
            ? 'My Ecosystem'
            : 'Community';

    return (
        <Tab.Container id="profile-tabs" defaultActiveKey="about">
            <Nav variant="pills" title="About" className="my-4">
                <Nav.Item>
                    <Nav.Link
                        eventKey="about"
                        onClick={() => {
                            useEventTracking(
                                `${category}`,
                                'Clicked About on Ecosystem Profile',
                                `${profile.name}`
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faUser} />
                        <span className="ml-2">About</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="members"
                        onClick={() => {
                            useEventTracking(
                                `${category}`,
                                'Clicked Members on Ecosystem Profile',
                                `${profile.name}`
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faUsers} />
                        <span className="ml-2">
                            Members ({profile.members.length})
                        </span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link
                        eventKey="projects"
                        onClick={() => {
                            useEventTracking(
                                `${category}`,
                                'Clicked Followers on Ecosystem Profile',
                                `${profile.name}`
                            );
                        }}
                    >
                        <EcosystemProjectIcon />
                        <span className="ml-2">
                            Projects ({profile.projects.length})
                        </span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <TabContent>
                <EcosystemAbout
                    profile={profile}
                    isAdminUser={isAdminUser}
                    deleted={deleted}
                    setDeleted={setDeleted}
                    ecosystemId={ecosystemId}
                />
                <EcosystemMembers
                    profile={profile}
                    isAdminUser={isAdminUser}
                    members={profile.members}
                    auth={auth}
                />
                <EcosystemProjects projects={profile.projects} />
            </TabContent>
        </Tab.Container>
    );
};

export default EcosystemTabs;
