/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Link,
    withRouter,
    RouteComponentProps,
    useParams
} from 'react-router-dom';
import {
    Container,
    Card,
    Form,
    Button,
    Col,
    InputGroup
} from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import Textarea from 'react-expanding-textarea';
import { useAlert } from 'react-alert';
import { whitespaceCheck } from '../../utils/commonFunction';

import IOpportunity, { oppBlankObj } from '../../interfaces/IOpportunity';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../../redux/actions/types';

// Select Options
import { primaryCategoriesOptions } from '../../options/landscapeSelectOptions';
import { skillsOptions } from '../../options/profileSelectOptions';

const CardContent = styled.div`
    padding: 2rem 1rem;

    .form-label {
        font-size: 16px;
        font-weight: bold;
    }

    .custom-file {
        font-size: 16px;
    }

    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }
`;

const FormHeader = styled.h2`
    margin-bottom: 2rem;
`;

interface uploadProps extends RouteComponentProps {
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
}

interface ParamTypes {
    queryId: string | undefined;
}

const descMaxLength = 2000;

// Main React Functional Component
const EditInternship: React.FC<uploadProps> = ({ ...props }) => {
    const { queryId } = useParams<ParamTypes>();
    const alert = useAlert();

    const [details, setDetails] = useState<IOpportunity>(oppBlankObj);
    const [editCheck, setEditCheck] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [descWordsRemaining, setDescWordsRemaining] = useState(descMaxLength);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        if (!editCheck) setEditCheck(true);

        if (name === 'description') {
            setDescWordsRemaining(descMaxLength - value.length);
        }
        setDetails({
            ...details,
            [name]: value
        });
    };

    // eslint-disable-next-line
    const handleSelect = (newValue: any, type: string) => {
        const value: string[] = [];

        if (!editCheck) setEditCheck(true);

        for (let i = 0; i < newValue.length; i += 1) {
            value[i] = newValue[i].value;
        }
        value.sort();
        setDetails({
            ...details,
            [type]: value
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (disableSubmit) return;
        setDisableSubmit(true);

        // Check for whitespace submissions
        if (
            !whitespaceCheck(details, [
                'position',
                'description',
                'renumeration'
            ])
        ) {
            alert.error(
                'Required fields cannot be filled with only whitespaces.'
            );
            setDisableSubmit(false);
            return;
        }

        axios
            .put(`/api/internships/${details._id}`, {
                position: details.position,
                description: details.description,
                renumeration: details.renumeration,
                location: details.location,
                verticals: details.verticals,
                skillsets: details.skillsets,
                organisation: details.organisation._id
            })
            .then((rec) => {
                setDetails(oppBlankObj);
                props.history.push(`/internship/${rec.data._id}`);
            })
            .catch(() => {
                alert.error('Error editing opportunity. Please try again.');
                setDisableSubmit(false);
            });
    };

    // Check if user is logged in
    useEffect(() => {
        if (!props.auth.isAuthenticated) {
            props.history.push('/');
        }
    }, [props.auth.isAuthenticated]);

    // Check whether user is onboarded
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    // Enable submit upon edit
    useEffect(() => {
        if (editCheck) setDisableSubmit(false);
    }, [editCheck]);

    // Retrieve Opportunity Data
    useEffect(() => {
        axios
            .get<IOpportunity>(`/api/internships/${queryId}`)
            .then(({ data }) => {
                setDetails(data);
                setDescWordsRemaining(descMaxLength - data.description.length);
            })
            .catch(() => {
                props.history.push('/error404');
            });
    }, []);

    return (
        <Container fluid='md'>
            <h1>Edit your Opportunity</h1>
            <Card className="text-left mb-4">
                <CardContent>
                    <FormHeader>Tell us about this opportunity</FormHeader>
                    <hr />
                    <Form className="text-muted" onSubmit={handleSubmit}>
                        <Form.Group
                            controlId="formPosition"
                            className="required"
                        >
                            <Form.Label>Position</Form.Label>
                            <Form.Control
                                required
                                name="position"
                                type="text"
                                maxLength={100}
                                value={details.position}
                                onChange={handleChange}
                                placeholder="Job position / title"
                            />
                            <Form.Text muted>Max 100 characters.</Form.Text>
                        </Form.Group>

                        <Form.Row>
                            <Col sm={6}>
                                <Form.Group
                                    controlId="formVerticals"
                                    className="required"
                                >
                                    <Form.Label>Verticals</Form.Label>
                                    <Select
                                        isMulti
                                        name="verticals"
                                        options={primaryCategoriesOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={details.verticals.map(
                                            (item) => ({
                                                value: item,
                                                label: item
                                            })
                                        )}
                                        onChange={(newValue) =>
                                            handleSelect(newValue, 'verticals')
                                        }
                                    />
                                    <input
                                        className="invis"
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        defaultValue={details.verticals}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group
                                    controlId="formSkillsets"
                                    className="required"
                                >
                                    <Form.Label>Skillsets</Form.Label>
                                    <Select
                                        isMulti
                                        name="skillsets"
                                        options={skillsOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={details.skillsets.map(
                                            (item) => ({
                                                value: item,
                                                label: item
                                            })
                                        )}
                                        onChange={(newValue) =>
                                            handleSelect(newValue, 'skillsets')
                                        }
                                    />
                                    <input
                                        className="invis"
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{ opacity: 0, height: 0 }}
                                        defaultValue={details.skillsets}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>

                        <Form.Group
                            controlId="formDescription"
                            className="required"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required
                                as={Textarea}
                                name="description"
                                rows={4}
                                maxLength={descMaxLength}
                                value={details.description}
                                onChange={handleChange}
                                placeholder="Describe your opportunity."
                            />
                            <Form.Text muted>
                                {descWordsRemaining} characters remaining.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formYearFounded">
                            <Form.Label>Renumeration</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    required
                                    name="renumeration"
                                    type="number"
                                    value={`${details.renumeration}`}
                                    onChange={handleChange}
                                />
                            </InputGroup>
                        </Form.Group>

                        <div className="text-right mt-2">
                            <Button
                                as={Link}
                                className="mr-1"
                                variant="light"
                                to={`/internship/${details._id}`}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={disableSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </CardContent>
            </Card>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(EditInternship));
