/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import Textarea from 'react-expanding-textarea';
import { useAlert } from 'react-alert';

import IComment from '../../interfaces/IComment';
import CommentItem from './CommentItem';

import { useEventTracking } from '../../pages/eventTracking';

interface commentsProp {
    landscapeTitle: string;
    id: string | undefined;
    authId: string;
}

const CommentForm = styled.div`
    padding: 1.25rem;
    border-style: none none solid none;
    border-width: 1px 3px 1px 1px;
    border-color: rgba(0, 0, 0, 0.125);
`;

const maxChars = 2000;

// export const Comments: React.FC<commentsProp[]> = ([{ comments }]: commentsProp[]) => {
export const Comments: React.FC<commentsProp> = ({
    landscapeTitle,
    id,
    authId
}: commentsProp) => {
    const alert = useAlert();

    const [comments, setComments] = useState<IComment[]>([]);
    const [comment, setComment] = useState<IComment>({
        author: authId,
        content: '',
        createdAt: '',
        noOfReplies: 0,
        replies: [],
        upvotes: [],
        updatedAt: '',
        _id: ''
    });

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [charsRemaining, setCharsRemaining] = useState(maxChars);

    const updateComments = () => {
        axios.get(`/api/landscapes/${id}/comments`).then((res) => {
            const commentsList = res.data;
            const newComments: IComment[] = [];
            for (let i = 0; i < commentsList.length; i += 1) {
                newComments[i] = commentsList[i];
            }
            setComments(newComments);
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setComment({
            ...comment,
            [name]: value
        });
        setCharsRemaining(maxChars - value.length);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (disableSubmit) return;
        setDisableSubmit(true);

        axios
            .post(`/api/landscapes/${id}/comments`, {
                content: comment.content,
                author: comment.author,
                noOfReplies: comment.noOfReplies
            })
            .then((res) => {
                const commentsList = res.data;
                const newComments: IComment[] = [
                    {
                        author: '',
                        content: '',
                        createdAt: '',
                        noOfReplies: 0,
                        replies: [],
                        upvotes: [],
                        updatedAt: '',
                        _id: ''
                    }
                ];
                for (let i = 0; i < commentsList.length; i += 1) {
                    newComments[i] = commentsList[i];
                }
                setComments(newComments);
                setComment({
                    ...comment,
                    content: ''
                });
                setDisableSubmit(false);
            })
            .catch(() => {
                alert.error('Error submitting comment. Please try again.');
            });
        event.preventDefault();
    };

    useEffect(() => {
        if (id) updateComments();
    }, []);

    return (
        <div>
            <CommentForm>
                <Form className="text-muted" onSubmit={handleSubmit}>
                    <Form.Group controlId="formComment">
                        <Form.Control
                            required
                            readOnly={!authId}
                            name="content"
                            as={Textarea}
                            value={comment.content}
                            maxLength={maxChars}
                            onChange={handleChange}
                            style={{ height: '10rem' }}
                            placeholder={
                                authId
                                    ? 'What do you think of this landscape?'
                                    : 'Login to comment on this landscape.'
                            }
                        />
                        <Form.Text
                            muted
                            style={{ display: authId ? 'block' : 'none' }}
                        >
                            {charsRemaining} characters remaining.
                        </Form.Text>
                    </Form.Group>
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={disableSubmit}
                        onClick={() => {
                            useEventTracking(
                                'Landscapes',
                                'Commented on Landscape',
                                `${landscapeTitle}`
                            );
                        }}
                        style={{ display: authId ? 'block' : 'none' }}
                    >
                        Send
                    </Button>
                    <Button
                        as={Link}
                        variant="primary"
                        type="button"
                        to="/login"
                        style={{ display: authId ? 'none' : 'block' }}
                    >
                        Log In
                    </Button>
                </Form>
            </CommentForm>
            {comments
                .slice(0)
                .reverse()
                .map((commentx: IComment) => (
                    <CommentItem
                        key={commentx._id}
                        comment={commentx}
                        authId={authId}
                        landscapeTitle={landscapeTitle}
                        id={id}
                        updateComments={updateComments}
                    />
                ))}
        </div>
    );
};

export default Comments;
