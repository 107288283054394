/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import {
    Link,
    RouteComponentProps,
    withRouter,
    useParams
} from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Container,
    Row,
    Col,
    Badge,
    Card,
    Button,
    Image,
    Overlay,
    Tooltip
} from 'react-bootstrap';
import styled from 'styled-components';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon
} from 'react-share';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faLink } from '@fortawesome/free-solid-svg-icons';

import { Helmet } from 'react-helmet';
import { ShareButtons } from '../Styles/Styles';

import ILandscape, { LandscapeBlankObj } from '../interfaces/ILandscape';
import { Comments } from '../components/landscapes/Comments';
import BackToLandscapes from '../components/backbuttons/BackToLandscapes';

import { useEventTracking } from './eventTracking';

// Interface for Redux types
import { StoreState, AuthState } from '../redux/actions/types';

// Common function
import getSrc from '../utils/commonFunction';

interface pageProps extends RouteComponentProps {
    auth: AuthState;
}

const Thumbnail = styled.img`
    object-fit: cover;
    width: 100px;
    height: 100px;
    display: inline-block;
`;

// const CarouselStyle = styled.div`
//   .carousel {
//     height: 400px;
//     background-color: black;
//   }
// `;

// const CarouselImg = styled.img`
//   object-fit: contain;
//   width: 100%;
//   max-height: 400px;
// `;

const CardDesc = styled.div`
    iframe {
        width: 100%;
        height: 400px;
    }
`;

interface ParamTypes {
    queryId: string | undefined;
}

const LandscapePage: React.FC<pageProps> = ({ ...props }) => {
    const { queryId } = useParams<ParamTypes>();

    const shareLink = `https://app.interseed.co/landscape/${queryId}`;

    const [landscape, setLandscape] = useState<ILandscape>(LandscapeBlankObj);

    const [upvotes, setUpvotes] = useState(landscape.upvotes);
    const [upvoteCheck, setUpvoteCheck] = useState(false);
    const [disableUpvote, setDisableUpvote] = useState(false);
    const [ownerCheck, setOwnerCheck] = useState(false);
    const [deleteCheck, setDeleteCheck] = useState(false);
    const [initCheck, setInitCheck] = useState(false);
    const [copyShow, setCopyShow] = useState(false);
    const [landscapeLink, setLandscapeLink] = useState('');
    const copyTarget = useRef(null);

    const handleUpvote = () => {
        if (disableUpvote) return;
        setDisableUpvote(true);

        if (upvoteCheck === false) {
            axios
                .put(
                    `/api/landscapes/${landscape._id}/upvotes/${props.auth.user._id}`
                )
                .then((res) => {
                    setUpvotes(res.data.upvotes);
                    setUpvoteCheck(true);
                    setDisableUpvote(false);
                });
        } else {
            axios
                .delete(
                    `/api/landscapes/${landscape._id}/upvotes/${props.auth.user._id}`
                )
                .then((res) => {
                    setUpvotes(res.data.upvotes);
                    setUpvoteCheck(false);
                    setDisableUpvote(false);
                });
        }
    };

    const handleDelete = () => {
        if (deleteCheck && ownerCheck) {
            if (landscape.thumbnail) {
                const { key } = landscape.thumbnail;
                axios.delete(`/api/uploads/images/${key}`);
            }
            axios.delete(`/api/landscapes/${landscape._id}`).then(() => {
                props.history.push(`/landscapes`);
            });
        }
    };

    const modifyLandscapeLink = () => {
        if (
            landscape.link.match(
                /^https:\/\/docs.google.com\/presentation\/d\/e\//
            )
        ) {
            setLandscapeLink(
                `${landscape.link.match(
                    /.+?(?=pub)/
                )}embed?start=false&loop=true&delayms=3000`
            );
        } else if (
            landscape.link.match(
                /^https:\/\/docs.google.com\/document\/d\/e\//
            ) &&
            landscape.link.match(/\/pub$/)
        ) {
            setLandscapeLink(`${landscape.link}?embedded=true`);
        }
    };

    useEffect(() => {
        axios
            .get<ILandscape>(`/api/landscapes/${queryId}`)
            .then(({ data }) => {
                setLandscape({
                    ...landscape,
                    title: data.title,
                    tagline: data.tagline,
                    description: data.description,
                    link: data.link,
                    author: data.author,
                    noOfComments: data.noOfComments,
                    comments: data.comments,
                    primaryCategories: data.primaryCategories,
                    secondaryCategories: data.secondaryCategories,
                    upvotes: data.upvotes,
                    countries: data.countries,
                    thumbnail: data.thumbnail,
                    _id: data._id
                });
                setUpvotes(data.upvotes);
                for (let i = 0; i < data.upvotes.length; i += 1) {
                    if (data.upvotes[i] === props.auth.user._id)
                        setUpvoteCheck(true);
                }
            })
            .catch(() => {
                props.history.replace('/Error404');
            });
    }, []);

    useEffect(() => {
        if (initCheck) {
            if (landscape.author._id === props.auth.user._id) {
                setOwnerCheck(true);
                modifyLandscapeLink();
            }
        } else setInitCheck(true);
    }, [landscape]);

    useEffect(() => {
        if (!props.auth.isAuthenticated) {
            setDisableUpvote(true);
        }
    }, [props.auth.isAuthenticated]);

    return (
        <div>
            <Helmet>
                <meta
                    name="title"
                    property="og:title"
                    content={landscape.title}
                />
                <meta property="og:type" content="article" />
                <meta name="author" content={landscape.author.username} />
                <meta
                    name="image"
                    property="og:image"
                    content={landscape.thumbnail.location}
                />
                <meta
                    name="description"
                    property="og:description"
                    content={landscape.tagline}
                />
            </Helmet>
            <Container className="text-left">
                <BackToLandscapes />
                <Row>
                    <Col md={1} className="mr-3">
                        <Thumbnail
                            src={
                                landscape.thumbnail
                                    ? landscape.thumbnail.location
                                    : 'placeholder'
                            }
                            alt=""
                        />
                    </Col>
                    <Col className="ml-4">
                        <h2>{landscape.title}</h2>
                        <p>{landscape.tagline}</p>
                        <h2>
                            {landscape.primaryCategories
                                .sort()
                                .map((category) => (
                                    <Badge variant="primary" className="mx-1">
                                        {category}
                                    </Badge>
                                ))}
                            {landscape.secondaryCategories
                                .sort()
                                .map((category) => (
                                    <Badge variant="light" className="mx-1">
                                        {category}
                                    </Badge>
                                ))}
                        </h2>
                    </Col>
                </Row>
                <hr />

                <Row>
                    <Col md={8}>
                        <Card className="mb-4">
                            <CardDesc>
                                <Card.Body>
                                    <iframe
                                        src={landscapeLink}
                                        title="Test"
                                        frameBorder="0"
                                        allowFullScreen
                                    />
                                    <a
                                        href={landscape.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => {
                                            useEventTracking(
                                                'Landscapes',
                                                'Clicked Access the Document Hyperlink',
                                                `${landscape.title}`
                                            );
                                        }}
                                    >
                                        Access the document
                                    </a>
                                    <hr />
                                    <p className="allow-whitespace">
                                        {landscape.description}
                                    </p>
                                    <ShareButtons>
                                        <FacebookShareButton
                                            url={shareLink}
                                            className="left"
                                        >
                                            <FacebookIcon size={32} />
                                        </FacebookShareButton>
                                        <TwitterShareButton
                                            url={shareLink}
                                            className="middle"
                                        >
                                            <TwitterIcon size={32} />
                                        </TwitterShareButton>
                                        <LinkedinShareButton
                                            url={shareLink}
                                            className="middle"
                                        >
                                            <LinkedinIcon size={32} />
                                        </LinkedinShareButton>
                                        <TelegramShareButton
                                            url={shareLink}
                                            className="middle"
                                        >
                                            <TelegramIcon size={32} />
                                        </TelegramShareButton>
                                        <WhatsappShareButton
                                            url={shareLink}
                                            className="right"
                                        >
                                            <WhatsappIcon size={32} />
                                        </WhatsappShareButton>
                                        <span className="right">
                                            <CopyToClipboard text={shareLink}>
                                                <Button
                                                    ref={copyTarget}
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={() =>
                                                        setCopyShow(true)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faLink}
                                                    />{' '}
                                                    Share Via Link
                                                </Button>
                                            </CopyToClipboard>
                                            <Overlay
                                                target={copyTarget.current}
                                                show={copyShow}
                                                placement="right"
                                            >
                                                <Tooltip id="copy-overlay">
                                                    Copied!
                                                </Tooltip>
                                            </Overlay>
                                        </span>
                                    </ShareButtons>
                                </Card.Body>
                            </CardDesc>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Comments
                                    key={landscape._id}
                                    landscapeTitle={landscape.title}
                                    id={landscape._id}
                                    authId={props.auth.user._id}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md>
                        {props.auth.isAuthenticated ? (
                            <Button
                                disabled={disableUpvote}
                                type="button"
                                variant={
                                    upvoteCheck ? 'primary' : 'outline-primary'
                                }
                                onClick={handleUpvote}
                                block
                                className="mb-4"
                            >
                                <FontAwesomeIcon icon={faChevronUp} /> UPVOTE
                                &nbsp;&nbsp;
                                {upvotes.length}
                            </Button>
                        ) : (
                            <Button
                                type="button"
                                variant="outline-primary"
                                as={Link}
                                to="/login"
                                block
                                className="mb-4"
                            >
                                <FontAwesomeIcon icon={faChevronUp} /> UPVOTE
                                &nbsp;&nbsp;
                                {upvotes.length}
                            </Button>
                        )}
                        <Card className="mb-3">
                            <Card.Body>
                                <h2>Author</h2>
                                <Link
                                    to={`/profile/${landscape.author.username}`}
                                >
                                    <Image
                                        style={{
                                            height: '25px',
                                            width: '25px'
                                        }}
                                        className="mr-3"
                                        src={getSrc(landscape.author)}
                                        alt="Profile"
                                        roundedCircle
                                        fluid
                                    />
                                    {landscape.author.firstName}
                                    {landscape.author.lastName
                                        ? ` ${landscape.author.lastName}`
                                        : null}
                                </Link>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3">
                            <Card.Body>
                                <h2>Countries</h2>
                                <p>
                                    {landscape.countries
                                        .sort()
                                        .map((country) => (
                                            <Badge variant="light">
                                                {country}
                                            </Badge>
                                        ))}
                                </p>
                            </Card.Body>
                        </Card>
                        {ownerCheck ? (
                            <div className="text-right mt-2">
                                {!deleteCheck ? (
                                    <>
                                        <Button
                                            as={Link}
                                            to={`/editlandscape/${landscape._id}`}
                                            variant="success"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Landscapes',
                                                    'Edit Landscape',
                                                    `${landscape.author.username}`
                                                );
                                            }}
                                            size="sm"
                                            className="mr-2"
                                        >
                                            Edit Landscape
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => {
                                                useEventTracking(
                                                    'Landscapes',
                                                    'Delete Landscape',
                                                    `${landscape.author.username}`
                                                );
                                                setDeleteCheck(true);
                                            }}
                                        >
                                            Delete Landscape
                                        </Button>
                                    </>
                                ) : (
                                    <div>
                                        <p>
                                            Are you very sure? This cannot be
                                            undone.
                                        </p>
                                        <Button
                                            variant="light mr-1"
                                            size="sm"
                                            onClick={() =>
                                                setDeleteCheck(false)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={handleDelete}
                                        >
                                            Confirm Delete
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(withRouter(LandscapePage));
