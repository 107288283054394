import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

export const usePageTracking = () => {
    const location = useLocation();

    // keep track of developer client ids and exclude
    ReactGA.ga((tracker) => {
        // console.log(`Client ID: ${tracker.get('clientId')}`);
    });

    let trackingId: string =
        process.env.REACT_APP_IS_DEPLOYMENT === 'true'
            ? (process.env
                  .REACT_APP_DEPLOYMENT_GOOGLE_ANALYTICS_TRACKING_ID as string)
            : (process.env
                  .REACT_APP_DEV_GOOGLE_ANALYTICS_TRACKING_ID as string);

    let trackingIdGa4: string =
        process.env.REACT_APP_IS_DEPLOYMENT === 'true'
            ? (process.env.REACT_APP_DEPLOYMENT_GA4_TRACKING_ID as string)
            : (process.env.REACT_APP_DEV_GA4_TRACKING_ID as string);

    // add failsafe
    trackingId =
        window.location.hostname !== 'localhost'
            ? (process.env
                  .REACT_APP_DEPLOYMENT_GOOGLE_ANALYTICS_TRACKING_ID as string)
            : (process.env
                  .REACT_APP_DEV_GOOGLE_ANALYTICS_TRACKING_ID as string);

    trackingIdGa4 =
        window.location.hostname !== 'localhost'
            ? (process.env.REACT_APP_DEPLOYMENT_GA4_TRACKING_ID as string)
            : (process.env.REACT_APP_DEV_GA4_TRACKING_ID as string);

    useEffect(() => {
        ReactGA.initialize(trackingId);
        ReactGA.pageview(
            location.pathname + location.search,
            undefined,
            location.pathname.replace(/^\/([^/]*).*$/, '$1')
        );

        ReactGA4.initialize(trackingIdGa4);
        ReactGA4.send({
            hitType: 'pageview',
            page: `${location.pathname + location.search}`,
            title: `${location.pathname.replace(/^\/([^/]*).*$/, '$1')}`
        });
        ReactGA4.pageview(
            location.pathname + location.search,
            undefined,
            location.pathname.replace(/^\/([^/]*).*$/, '$1')
        );
    }, [location]);
};

export const useModalViewTracking = (route: string, documentId: string) => {
    const trackingId: string =
        process.env.REACT_APP_IS_DEPLOYMENT === 'true'
            ? (process.env
                  .REACT_APP_DEPLOYMENT_GOOGLE_ANALYTICS_TRACKING_ID as string)
            : (process.env
                  .REACT_APP_DEV_GOOGLE_ANALYTICS_TRACKING_ID as string);

    const trackingIdGa4: string =
        process.env.REACT_APP_IS_DEPLOYMENT === 'true'
            ? (process.env.REACT_APP_DEPLOYMENT_GA4_TRACKING_ID as string)
            : (process.env.REACT_APP_DEV_GA4_TRACKING_ID as string);

    ReactGA.initialize(trackingId);
    if (documentId) {
        ReactGA.pageview(`/${route}/${documentId}`, undefined, `${route}`);
    }

    ReactGA4.initialize(trackingIdGa4);
    if (documentId) {
        ReactGA4.send({
            hitType: 'pageview',
            page: `/${route}/${documentId}`,
            title: `${route}`
        });
    }
};
