/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React from 'react';
import { ListGroup, Container, Row, Col, Badge } from 'react-bootstrap';
import styled from 'styled-components';

import IFund from '../../interfaces/IFund';

import { useEventTracking } from '../../pages/eventTracking';

interface fundProp {
    fund: IFund;
    authId: string;
}

const Styles = styled.div`
    a {
        color: black;

        &: hover {
            text-decoration: none;
        }
    }

    h2 {
        margin-bottom: 0.2rem;
    }

    .list-group-item {
        -moz-transition: background-color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in;
        -webkit-transition: background-color 0.1s ease-in;
        transition: background-color 0.1s ease-in;
        color: black;

        &:hover {
            background-color: #ccc !important;
            text-decoration: none;
        }
    }

    .imgcontainer {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 100%;

        img {
            object-fit: cover;
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }

    .badge {
        font-size: 16px;
    }
`;

const ListText = styled.p`
    margin-bottom: 0;
`;

const FundItem: React.FC<fundProp> = ({ fund, authId }: fundProp) => (
    <Styles>
        <ListGroup.Item>
            <a
                href={authId ? fund.link : `/login`}
                onClick={() => {
                    useEventTracking(
                        'Funding',
                        'Clicked Funding Opportunity',
                        `${fund.fundName}`
                    );
                }}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Container>
                    <Row>
                        <Col sm={2} className="imgcontainer mb-2">
                            <img
                                src={
                                    fund.thumbnail.location !== ''
                                        ? fund.thumbnail.location
                                        : `${process.env.PUBLIC_URL}/assets/funding-placeholder.png`
                                }
                                alt=""
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/funding-placeholder.png`;
                                }}
                            />
                        </Col>
                        <Col sm={8} className="text-center text-sm-left">
                            <h2>
                                <b>{fund.fundName}</b>
                            </h2>
                            <ListText className="text-muted mb-1">
                                <i>
                                    by <b>{fund.funderName}</b>
                                </i>
                            </ListText>
                            <ListText>Amount: {fund.fundAmount}</ListText>
                            <ListText>
                                Type:{' '}
                                {fund.fundingTypes.map((x, i) => (
                                    <span>
                                        {x}
                                        {fund.fundingTypes[i + 1] ? ', ' : ''}
                                    </span>
                                ))}
                            </ListText>
                            <p className="mt-2">
                                {fund.focusAreas.map((area: string) => (
                                    <Badge
                                        variant="light"
                                        className="mr-1 mt-1"
                                        style={{ whiteSpace: 'normal' }}
                                    >
                                        {area}
                                    </Badge>
                                ))}
                            </p>
                            <p className="mt-2">
                                <b>Who can apply:</b>{' '}
                                {fund.availableFor.map((x, i) => (
                                    <span>
                                        {x}
                                        {fund.availableFor[i + 1] ? ', ' : ''}
                                    </span>
                                ))}
                            </p>
                            {fund.fundStatus ? (
                                <p>
                                    Status: <b>{fund.fundStatus}</b>
                                </p>
                            ) : null}
                            {fund.stage[0] ? (
                                <p>
                                    Stages:{' '}
                                    {fund.stage.map((x, i) => (
                                        <span>
                                            {x}
                                            {fund.stage[i + 1] ? ', ' : ''}
                                        </span>
                                    ))}
                                </p>
                            ) : null}
                            {fund.applicationDeadline ? (
                                <Badge variant="danger">
                                    Deadline: {fund.applicationDeadline}
                                </Badge>
                            ) : null}
                        </Col>
                        {/* <Button
                            variant="outline-primary"
                            className="mt-2"
                            href={authId ? fund.link : `/login`}
                            target="_blank"
                            // rel="noopener noreferrer"
                        >
                            Apply
                        </Button> */}
                    </Row>
                </Container>
            </a>
        </ListGroup.Item>
    </Styles>
);

export default FundItem;
