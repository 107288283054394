/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Card as div,
    Image,
    Button,
    Nav,
    Carousel
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';

import { useEventTracking } from './eventTracking';
import newsseedimg from '../assets/mockuper.png';
import communityimg from '../assets/mockupcommunity.png';
import eventsimg from '../assets/mockupevents.png';
import opportunitiesimg from '../assets/mockupopportunities.png';
import resourcesimg from '../assets/mockupresources.png';
import resourceslaptopimg from '../assets/mockupresourceslaptop.png';
import browserdashboard from '../assets/browserdashboard.png';

import { ReactComponent as CalendarSvg } from '../assets/svg/calendar.svg';
import { ReactComponent as TeamSvg } from '../assets/svg/team.svg';
import { ReactComponent as NewsSvg } from '../assets/svg/news.svg';
import { ReactComponent as FundingSvg } from '../assets/svg/funding.svg';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';
import './LandingPage.css';
import EventCalendar from '../components/events/EventCalendar';
import EventItem from '../components/events/EventItemHorizontal';

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

const Styles = styled.div`
    .icons {
        height: 8vh;
        max-width: 8vh;
        margin-bottom: 20px;
    }
`;

const LandingPage: React.FC<authProps> = ({ ...props }) => {
    const user = props.auth.user.username
        ? props.auth.user.username
        : 'Not Logged In';

    const loginButton = (
        <Nav.Item>
            <Link to="/login">
                <Button
                    className="loginbutton"
                    onClick={() => {
                        useEventTracking(
                            'Landing Page',
                            "Clicked 'Join our community now' Button (Top Near NavBar)",
                            `${user}`
                        );
                    }}
                >
                    Join our community now
                </Button>
            </Link>
        </Nav.Item>
    );

    const redirect = (path) => {
        props.history.push(path);
    };

    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status]);

    // TODO: Change login button to profile if logged in?

    return (
        <Container className="landingpage" fluid>
            <Helmet>
                <meta
                    name="title"
                    property="og:title"
                    content="Interseed: Sustainability, powered by the Community"
                />
                <meta property="og:type" content="website" />
                <meta
                    name="image"
                    property="og:image"
                    content="https://app.interseed.co/static/media/browserdashboard.72a0ceac.png"
                />
                <meta
                    name="description"
                    property="og:description"
                    content="Interseed is your one-stop digital platform for all
                        things sustainability"
                />
                <meta property="og:site_name" content="Interseed" />
            </Helmet>
            <div id="leaves">
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
                <i />
            </div>
            <Row className="intro align-items-center ">
                <Col sm={1} />
                <Col className="title" sm={{ span: 4 }}>
                    <h2 className="title-heading">
                        <b>Sustainability,</b> powered by the <b>Community</b>
                    </h2>

                    <p className="title-content">
                        Bring your community, memberships, and events together
                        in one place
                    </p>

                    {loginButton}
                </Col>
                <Col
                    className="website-pic align-items-center"
                    sm={{ span: 6 }}
                >
                    <Image
                        src={browserdashboard}
                        className="desktop-pic"
                        alt="na"
                        fluid
                    />
                    <Image
                        src={resourcesimg}
                        className="mobile-pic"
                        alt="na"
                        fluid
                    />
                </Col>
            </Row>
            <Row className="summary align-items-start no-gutters">
                <Col
                    className="summary-title align-items-center justify-content-center"
                    sm={12}
                >
                    <h1 style={{ fontSize: '2.2rem' }}>
                        Interseed is your one-stop digital platform for all
                        things sustainability
                    </h1>
                </Col>
                <Col sm={3}>
                    <TeamSvg className="landingIcon" />
                    <p>
                        Find your tribe to build sustainable cities and
                        communities and gain visibility
                    </p>
                </Col>
                <Col sm={3}>
                    <NewsSvg className="landingIcon" />
                    <p>Stay up to date with sustainability news</p>
                </Col>
                <Col sm={3}>
                    <CalendarSvg className="landingIcon" />
                    <p>Find and share sustainability events</p>
                </Col>
                <Col sm={3}>
                    <FundingSvg className="landingIcon" />
                    <p>
                        Get your impact startup funded or find new job
                        opportunities
                    </p>
                </Col>
                <Col sm={12}>
                    <Nav.Item>
                        <Link to="/login">
                            <Button
                                className="login-second-section"
                                onClick={() => {
                                    useEventTracking(
                                        'Landing Page',
                                        "Clicked 'Join our community now' Button (Middle)",
                                        `${user}`
                                    );
                                }}
                            >
                                Join our community now
                            </Button>
                        </Link>
                    </Nav.Item>
                </Col>
            </Row>
            <Row className="communitypage page align-items-center no-gutters mx-4">
                <Col
                    className="align-items-center justify-content-center"
                    sm={6}
                >
                    <img className="w-100" src={communityimg} alt="events" />
                </Col>
                <Col
                    className="communitypage-details justify-content-center page-details"
                    sm={6}
                    onClick={() => {
                        useEventTracking(
                            'Landing Page',
                            `Clicked 'Visit our Community' Button`,
                            `${user}`
                        );
                        redirect('/community');
                    }}
                >
                    <h1>Meet the Interseed Community</h1>
                    <div className="text-left  flip-card">
                        <div.Body className="flip-card-inner">
                            <div.Title className="flip-card-front">
                                <h2>
                                    Visit our Community{' '}
                                    <FontAwesomeIcon
                                        icon={faChevronCircleRight}
                                    />{' '}
                                </h2>
                            </div.Title>
                            <div.Text className="flip-card-back">
                                <h2>Find your sustainability tribe</h2>
                                <h2>Collaborate more effectively</h2>
                                <h2>Build sustainable communties</h2>
                            </div.Text>
                        </div.Body>
                    </div>
                </Col>
            </Row>
            <Row className="newsseedpage page align-items-center no-gutters mx-4 ">
                <Col
                    className="align-items-center justify-content-center order-1 order-sm-2"
                    sm={6}
                >
                    <img className="w-100" src={newsseedimg} alt="events" />
                </Col>
                <Col
                    className="newsseedpage-details page-details justify-content-center order-2 order-sm-1"
                    sm={6}
                    onClick={() => {
                        useEventTracking(
                            'Landing Page',
                            `Clicked 'Flip through our NewsSeed' Button`,
                            `${user}`
                        );
                        redirect('/newseed');
                    }}
                >
                    <h1>Stay up to date about sustainability</h1>
                    <div className="text-left flip-card">
                        <div.Body className="flip-card-inner">
                            <div.Title className="flip-card-front">
                                <h2>
                                    Flip through our NewsSeed{' '}
                                    <FontAwesomeIcon
                                        icon={faChevronCircleRight}
                                    />{' '}
                                </h2>
                            </div.Title>
                            <div.Text className="flip-card-back">
                                <h2>
                                    Get access to a curated list of news sources
                                </h2>
                                <h2>
                                    Learn more about what matters in the world
                                    of sustainability
                                </h2>
                            </div.Text>
                        </div.Body>
                    </div>
                </Col>
            </Row>
            <Row className="eventpage page align-items-center no-gutters mx-4">
                <Col
                    className="align-items-center justify-content-center"
                    sm={6}
                >
                    <img className="w-100" src={eventsimg} alt="events" />
                </Col>
                <Col
                    className="eventpage-details page-details justify-content-center"
                    sm={6}
                    onClick={() => {
                        useEventTracking(
                            'Landing Page',
                            `Clicked 'Check out our Events' Button`,
                            `${user}`
                        );
                        redirect('/events');
                    }}
                >
                    <h1>The calendar you never knew you needed</h1>
                    <div className="text-left  flip-card">
                        <div.Body className="flip-card-inner">
                            <div.Title className="flip-card-front">
                                <h2>
                                    Check out our Events{' '}
                                    <FontAwesomeIcon
                                        icon={faChevronCircleRight}
                                    />{' '}
                                </h2>
                            </div.Title>
                            <div.Text className="flip-card-back">
                                <h2>Find new sustainability events</h2>
                                <h2>
                                    Create and host your own sustainability
                                    events
                                </h2>
                            </div.Text>
                        </div.Body>
                    </div>
                </Col>
            </Row>
            <Row className="opportunitiespage page align-items-center no-gutters mx-4">
                <Col
                    className="opportunitiespage-details page-details justify-content-center order-2 order-sm-1"
                    sm={6}
                    onClick={() => {
                        useEventTracking(
                            'Landing Page',
                            `Clicked 'Find new opportunities' Button`,
                            `${user}`
                        );
                        redirect('/funding');
                    }}
                >
                    <h1>
                        Learn about new opportunities for both founders and
                        talent
                    </h1>
                    <div className="text-left  flip-card">
                        <div.Body className="flip-card-inner">
                            <div.Title className="flip-card-front">
                                <h2>
                                    Find new opportunities{' '}
                                    <FontAwesomeIcon
                                        icon={faChevronCircleRight}
                                    />{' '}
                                </h2>
                            </div.Title>
                            <div.Text className="flip-card-back">
                                <h2>
                                    Find job opportunities in the sustainability
                                    sector
                                </h2>
                                <h2>Get funding for your ideas</h2>
                            </div.Text>
                        </div.Body>
                    </div>
                </Col>
                <Col
                    className="align-items-center justify-content-center order-1 order-sm-2"
                    sm={6}
                >
                    <img
                        className="w-100"
                        src={opportunitiesimg}
                        alt="events"
                    />
                </Col>
            </Row>
            <Row className="resourcespage page align-items-center no-gutters mx-4">
                <Col
                    className="resourcespage-details page-details align-items-center justify-content-center order-2 order-sm-2"
                    sm={6}
                    onClick={() => {
                        useEventTracking(
                            'Landing Page',
                            `Clicked 'Get access to our Resources' Button`,
                            `${user}`
                        );
                        redirect('/landscapes');
                    }}
                >
                    <h1>Sustainability content at your fingertips</h1>
                    <div className="text-left flip-card">
                        <div.Body className="flip-card-inner">
                            <div.Title className="flip-card-front">
                                <h2>
                                    Get access to our Resources{' '}
                                    <FontAwesomeIcon
                                        icon={faChevronCircleRight}
                                    />{' '}
                                </h2>
                            </div.Title>
                            <div.Text className="flip-card-back">
                                <h2>Find new sustainability content</h2>
                                <h2>
                                    Listen to the most relevant sustainability
                                    podcasts
                                </h2>
                                <h2>
                                    Grow your career and idea with the right
                                    mentorship
                                </h2>
                            </div.Text>
                        </div.Body>
                    </div>
                </Col>
                <Col
                    className="align-items-center justify-content-center order-1 order-sm-1"
                    sm={6}
                >
                    <img
                        className="w-100"
                        src={resourceslaptopimg}
                        alt="events"
                    />
                </Col>
            </Row>
            <Row className="finalsection">
                <Col className="finalsection-sub">
                    <h1>Scale your impact</h1>
                    <br />
                    <Nav.Item>
                        <Link to="/login">
                            <Button
                                className="login-second-section"
                                onClick={() => {
                                    useEventTracking(
                                        'Landing Page',
                                        "Clicked 'Join our community now' Button (Bottom Near Footer)",
                                        `${user}`
                                    );
                                }}
                            >
                                Join our community now
                            </Button>
                        </Link>
                    </Nav.Item>
                </Col>
            </Row>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(LandingPage));
