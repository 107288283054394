/* eslint-disable react/prop-types */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import {
    Container,
    Card,
    Form,
    Button,
    Col,
    Image,
    InputGroup
} from 'react-bootstrap';
import styled from 'styled-components';
import Select from 'react-select';
import { useAlert } from 'react-alert';

import BackToProfile from '../../components/backbuttons/BackToProfile';

// Interface
import IUser, { UserBlankObj } from '../../interfaces/IUser';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../../redux/actions/types';

// Select Options
import {
    interestOptions,
    involvementOptions,
    lookingForOptions,
    skillsOptions,
    countriesOptions
} from '../../options/profileSelectOptions';
import {
    setUserProfile,
    loadUserProfile
} from '../../redux/actions/UserProfileActions';
import getSrc, { whitespaceCheck } from '../../utils/commonFunction';

const CardContent = styled.div`
    padding: 2rem 1rem;

    .form-label {
        font-size: 16px;
        font-weight: bold;
    }

    .custom-file {
        font-size: 16px;
    }

    .basic-multi-select,
    .basic-single {
        font-size: 16px;
    }

    .form-text {
        font-size: 12px;
    }
`;

const FormHeader = styled.h2`
    margin-bottom: 2rem;
`;

interface authProps extends RouteComponentProps {
    auth: AuthState;
    userProfile: UserProfileState;
    history: RouteComponentProps['history'];
    refreshNav: () => void;
    profile: IUser | undefined;
    setProfile: (_: IUser | undefined) => void;
    loadUserProfile: (arg0: string) => void;
}

interface thumbFile {
    lastModified: number;
    name: string;
    size: number;
    type: string;
    src?: string | ArrayBuffer;
}

const aboutMaxLength = 500;

// Main React Functional Component
const EditProfile: React.FC<authProps> = (props) => {
    const alert = useAlert();

    const profile = props.profile ?? UserBlankObj;
    const [profilePicPreview, setProfilePicPreview] = useState('');
    const [thumbReal, setThumbReal] = useState<File>();
    const [thumb, setThumb] = useState<thumbFile>({
        lastModified: 0,
        name: 'Choose a file...',
        size: 0,
        type: '',
        src: getSrc(profile)
    });

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [aboutCharsRemaining, setAboutCharsRemaining] = useState(
        aboutMaxLength
    );

    // Check whether an edit has been made to enable Submit button
    const [editCheck, setEditCheck] = useState(false);

    // Check whether user is onboarded
    useEffect(() => {
        if (
            props.auth.isAuthenticated &&
            props.userProfile &&
            props.userProfile.userProfile
        ) {
            if (props.userProfile.userProfile.status !== 'Active')
                props.history.push('/onboarding');
        }
    }, [props.auth.status, props.auth.isAuthenticated, props.userProfile]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, type } = event.target;
        let { value } = event.target;

        if (!editCheck) setEditCheck(true);

        // Parse Profile Thumbnail
        if (type === 'file') {
            if (event.target.files && event.target.files[0]) {
                if (
                    event.target.files[0].type !== 'image/jpeg' &&
                    event.target.files[0].type !== 'image/png'
                ) {
                    alert.error('Please choose a JPEG/PNG file.');
                    return;
                }
                if (event.target.files[0].size > 4000000) {
                    // eslint-disable-next-line
                    alert.error('Please choose an image under 4mb.');
                    return;
                }

                setThumbReal(event.target.files[0]);

                const file: thumbFile = event.target.files[0];
                const reader = new FileReader();

                reader.onload = function read(e) {
                    if (e.target && e.target.result) {
                        file.src = e.target.result;
                        if (file.name.length > 40) {
                            setThumb({
                                lastModified: file.lastModified,
                                name: file.name
                                    .slice(0, 40)
                                    .concat(
                                        `... ${file.type.replace(
                                            'image/',
                                            '.'
                                        )}`
                                    ),
                                size: file.size,
                                type: file.type
                            });
                        } else setThumb(file);
                    }
                };
                reader.readAsDataURL(event.target.files[0]);
            }
        } else {
            if (name === 'aboutMyself') {
                setAboutCharsRemaining(aboutMaxLength - value.length);
            }
            if (name === 'linkedin') {
                value = value.replace(/ +/, '');
                value = `https://www.linkedin.com/in/${value}`;
            }
            props.setProfile({
                ...profile,
                [name]: value
            });
        }
    };

    // eslint-disable-next-line
    const handleSelect = (newValue: any, type: string) => {
        let value: string[] | string;

        if (!editCheck) setEditCheck(true);

        if (type !== 'country') {
            value = [];
            for (let i = 0; i < newValue.length; i += 1)
                value.push(newValue[i].value);
            value.sort();
        } else {
            value = newValue.value;
        }

        props.setProfile({
            ...profile,
            [type]: value
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        if (disableSubmit) return;
        setDisableSubmit(true);

        // Check for whitespace submissions
        if (!whitespaceCheck(profile, ['firstName', 'country', 'city'])) {
            alert.error(
                'Required fields cannot be filled with only whitespaces.'
            );
            setDisableSubmit(false);
            return;
        }

        if (thumbReal) {
            // eslint-disable-next-line
            const data: any = new FormData();
            data.append('image', thumbReal);

            if (profile.profilePicture) {
                const { key } = profile.profilePicture;
                axios.delete(`/api/uploads/images/${key}`);
            }

            axios
                .post(`/api/uploads/images`, data)
                .then((res) => {
                    const thumbnailData = res.data;
                    axios
                        .put(`/api/users/profiles`, {
                            firstName: profile.firstName,
                            lastName: profile.lastName,
                            profilePicture: thumbnailData,
                            email: profile.email,
                            city: profile.city,
                            country: profile.country,
                            interestAreas: profile.interestAreas,
                            sustainabilityInvolvement:
                                profile.sustainabilityInvolvement,
                            currentRole: profile.currentRole,
                            lookingFor: profile.lookingFor,
                            aboutMyself: profile.aboutMyself,
                            skillsets: profile.skillsets,
                            linkedin: profile.linkedin,
                            website: profile.website
                        })
                        .then(() => {
                            props.loadUserProfile(props.auth.user.username);
                            props.history.push(
                                `/profile/${props.auth.user.username}`
                            );
                            props.refreshNav();
                        })
                        .catch(() => {
                            alert.error(
                                'Error editing profile. Please try again.'
                            );
                            setDisableSubmit(false);
                        });
                })
                .catch(() => {
                    alert.error(
                        'Error editing profile (Image Upload Failed). Please try again.'
                    );
                    setDisableSubmit(false);
                });
        } else {
            axios
                .put(`/api/users/profiles`, {
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                    email: profile.email,
                    city: profile.city,
                    country: profile.country,
                    interestAreas: profile.interestAreas,
                    sustainabilityInvolvement:
                        profile.sustainabilityInvolvement,
                    currentRole: profile.currentRole,
                    lookingFor: profile.lookingFor,
                    aboutMyself: profile.aboutMyself,
                    skillsets: profile.skillsets,
                    linkedin: profile.linkedin,
                    website: profile.website
                })
                .then(() => {
                    props.loadUserProfile(props.auth.user.username);
                    props.history.push(`/profile/${props.auth.user.username}`);
                })
                .catch(() => {
                    alert.error('Error editing profile. Please try again.');
                    setDisableSubmit(false);
                });
        }
        event.preventDefault();
    };

    useEffect(() => {
        if (!props.auth.isAuthenticated) {
            props.history.push('/login');
        }
    }, [props.auth.isAuthenticated]);

    useEffect(() => {
        setAboutCharsRemaining(aboutMaxLength - profile.aboutMyself.length);
    }, []);

    useEffect(() => {
        if (thumb.src) setProfilePicPreview(thumb.src.toString());
        else if (profile.profilePicture)
            setProfilePicPreview(profile.profilePicture.location);
        else
            setProfilePicPreview(
                `${process.env.PUBLIC_URL}/assets/profile-placeholder.png`
            );
    }, [thumb]);

    return (
        <Container fluid='md'>
            <BackToProfile username={props.auth.user.username} />
            <Card className="text-left mb-4">
                <CardContent>
                    <FormHeader>Edit your profile information</FormHeader>
                    <hr />
                    <Form className="text-muted" onSubmit={handleSubmit}>
                        <Form.Row className="align-items-center">
                            <Col
                                sm={4}
                                className="d-flex justify-content-center"
                            >
                                <Image
                                    className="profile-picture"
                                    src={profilePicPreview}
                                    alt="Profile Picture"
                                    roundedCircle
                                    thumbnail
                                    fluid
                                />
                            </Col>
                            <Form.Group as={Col} controlId="formProfilePicture">
                                <Form.Label>Change Profile Picture</Form.Label>
                                <Form.File
                                    name="thumbnail"
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    onChange={handleChange}
                                    label={thumb.name}
                                    custom
                                />
                                <Form.Text muted>
                                    File size limit: 4mb
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                className="required"
                                controlId="formFirstName"
                            >
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    required
                                    name="firstName"
                                    type="text"
                                    maxLength={50}
                                    value={profile.firstName}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    name="lastName"
                                    type="text"
                                    maxLength={50}
                                    value={profile.lastName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                controlId="formCountry"
                                className="required"
                            >
                                <Form.Label>Country</Form.Label>
                                <Select
                                    name="country"
                                    options={countriesOptions}
                                    value={{
                                        value: profile.country,
                                        label: profile.country
                                    }}
                                    placeholder="Select your country..."
                                    className="basic-single"
                                    classNamePrefix="select"
                                    onChange={(newValue) =>
                                        handleSelect(newValue, 'country')
                                    }
                                />
                                <input
                                    className="invis"
                                    tabIndex={-1}
                                    autoComplete="off"
                                    style={{ opacity: 0, height: 0 }}
                                    defaultValue={profile.country}
                                    required
                                />
                            </Form.Group>

                            <Form.Group
                                as={Col}
                                controlId="formCity"
                                className="required"
                            >
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    required
                                    name="city"
                                    type="text"
                                    maxLength={100}
                                    value={profile.city}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group controlId="formAbout">
                            <Form.Label>About Yourself</Form.Label>
                            <Form.Control
                                name="aboutMyself"
                                as="textarea"
                                rows={4}
                                maxLength={aboutMaxLength}
                                value={profile.aboutMyself}
                                onChange={handleChange}
                                placeholder="A short bio about yourself"
                            />
                            <Form.Text muted>
                                {aboutCharsRemaining} characters remaining.
                            </Form.Text>
                        </Form.Group>

                        <Form.Row>
                            <Form.Group as={Col} controlId="currentRole">
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                    name="currentRole"
                                    type="text"
                                    maxLength={100}
                                    value={profile.currentRole}
                                    onChange={handleChange}
                                    placeholder="Your role (Eg. Software Developer @ Google, Community Organiser in Chicago)"
                                />
                            </Form.Group>
                        </Form.Row>

                        <Form.Group
                            controlId="formSkillsets"
                            className="required"
                        >
                            <Form.Label>Skills</Form.Label>
                            <Select
                                isMulti
                                name="skillsets"
                                options={skillsOptions}
                                value={profile.skillsets.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="Select your skill(s)"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'skillsets')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={profile.skillsets}
                                required
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formInterestAreas"
                            className="required"
                        >
                            <Form.Label>Interests</Form.Label>
                            <Select
                                isMulti
                                name="interestAreas"
                                options={interestOptions}
                                value={profile.interestAreas.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="Select your interest(s)"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'interestAreas')
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={profile.interestAreas}
                                required
                            />
                        </Form.Group>

                        <Form.Group
                            controlId="formSustainabilityInvolvement"
                            className="required"
                        >
                            <Form.Label>Sustainability Involvement</Form.Label>
                            <Select
                                isMulti
                                name="sustainabilityInvolvement"
                                options={involvementOptions}
                                value={profile.sustainabilityInvolvement.map(
                                    (item) => ({
                                        value: item,
                                        label: item
                                    })
                                )}
                                placeholder="Select your area(s) of involvement"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(
                                        newValue,
                                        'sustainabilityInvolvement'
                                    )
                                }
                            />
                            <input
                                className="invis"
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                defaultValue={profile.sustainabilityInvolvement}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formLookingFor">
                            <Form.Label>Looking For</Form.Label>
                            <Select
                                isMulti
                                name="lookingFor"
                                options={lookingForOptions}
                                value={profile.lookingFor.map((item) => ({
                                    value: item,
                                    label: item
                                }))}
                                placeholder="Choose..."
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(newValue) =>
                                    handleSelect(newValue, 'lookingFor')
                                }
                            />
                        </Form.Group>

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                sm={12}
                                controlId="formLinkedIn"
                            >
                                <Form.Label>LinkedIn</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            linkedin.com/in/
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        name="linkedin"
                                        type="text"
                                        value={
                                            profile.linkedin
                                                ? profile.linkedin.replace(
                                                      /https:\/\/www.linkedin.com\/in\//g,
                                                      ''
                                                  )
                                                : profile.linkedin
                                        }
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formWebsite">
                                <Form.Label>Website</Form.Label>
                                <Form.Control
                                    name="website"
                                    type="url"
                                    value={profile.website}
                                    onChange={handleChange}
                                    placeholder="URL"
                                />
                            </Form.Group>
                        </Form.Row>
                        <div className="text-right">
                            <Button
                                as={Link}
                                className="mr-1"
                                variant="light"
                                to={`/profile/${props.auth.user.username}`}
                            >
                                Cancel
                            </Button>
                            {editCheck ? (
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={disableSubmit}
                                >
                                    Save
                                </Button>
                            ) : (
                                <Button variant="light" disabled>
                                    Save
                                </Button>
                            )}
                        </div>
                    </Form>
                </CardContent>
            </Card>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    profile: state.userProfile.userProfile,
    userProfile: state.userProfile
});

export default connect(mapStateToProps, {
    setProfile: setUserProfile,
    loadUserProfile
})(withRouter(EditProfile));
