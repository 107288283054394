import styled from 'styled-components';

const CommunitySearch = styled.div`
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex: 1;
    position: relative;
    font-size: 1rem;

    input {
        flex: 1;
        padding: 0.5rem 1rem;
        outline: none;
        font-size: 1rem;
        border: none;
        border-radius: 2rem;
        background-color: #f2f4f8;

        &::placeholder {
            color: #797779;
        }
    }

    .icon {
        position: absolute;
        right: 0.4rem;
        background-color: #007bff;
        color: white;
        padding: 0.2rem;
        width: 2rem;
        height: 2rem;
        border-radius: 100%;

        svg {
            margin: 0.3rem;
        }
    }

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

export default CommunitySearch;
