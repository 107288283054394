import {
    faCheckCircle,
    faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { color, EventButton } from './EventStyles';

const ModalNotification = styled(Modal)`
    z-index: 3001;

    .modal-content {
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0px 50px 77px rgba(176, 183, 195, 0.22);
        border: none;
    }
`;

interface EventNotificationProps {
    show: boolean;
    close: () => void;
    onOk: () => void;
    eventAction: string;
}

export default function EventNotification({
    show,
    close,
    onOk,
    eventAction
}: EventNotificationProps): ReactElement {
    const getFontColor = (action: string) => {
        switch (action) {
            case 'approve':
            case 'register':
                return color.theme;
            case 'deregister':
            case 'delete':
                return 'red';
            default:
                return '#FEB533';
        }
    };

    const getNotification = (action: string) => {
        switch (action) {
            case 'success-register':
                return (
                    <>
                        <div className="d-flex justify-content-center">
                            <img
                                alt="share"
                                src={`${process.env.PUBLIC_URL}/assets/interseed_check.svg`}
                                className="d-inline-block mr-2"
                            />
                        </div>
                        <div className="text-center h3 m-3 mb-5 font-weight-bold">
                            Yay! You have registered for the event successfully.
                        </div>
                        <div className="d-flex" style={{ gap: '1rem' }}>
                            <EventButton
                                className="col text-capitalize py-3"
                                onClick={onOk}
                            >
                                Done
                            </EventButton>
                        </div>
                    </>
                );
            case 'approve':
            case 'reject':
            case 'register':
            case 'deregister':
            case 'delete':
                return (
                    <>
                        <div className="d-flex justify-content-center">
                            <FontAwesomeIcon
                                color={getFontColor(action)}
                                size="3x"
                                icon={
                                    action === 'register'
                                        ? faCheckCircle
                                        : faExclamationCircle
                                }
                            />
                        </div>
                        <div className="text-center h3 m-3 mb-5 font-weight-bold">
                            Are you sure you want to {action} this event?
                        </div>
                        <div className="d-flex" style={{ gap: '1rem' }}>
                            <EventButton
                                className="col py-3"
                                onClick={() => {
                                    close();
                                }}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: color.shade2
                                }}
                            >
                                Cancel
                            </EventButton>
                            <EventButton
                                className="col text-capitalize py-3"
                                onClick={() => {
                                    onOk();
                                }}
                                style={
                                    ['register', 'approve'].includes(action)
                                        ? {}
                                        : {
                                              backgroundColor: '#FFE8E3',
                                              color: '#FF5630'
                                          }
                                }
                            >
                                {action}
                            </EventButton>
                        </div>
                    </>
                );
            default:
                return '';
        }
    };

    return (
        <ModalNotification show={show} onHide={close} centered>
            <Modal.Body style={{ padding: '3rem' }}>
                {getNotification(eventAction)}
            </Modal.Body>
        </ModalNotification>
    );
}
