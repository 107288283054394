import React, { ReactElement, useState } from 'react';
import { Form, FormControl, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { color, EventButton } from './EventStyles';

const ModalNotification = styled(Modal)`
    z-index: 3001;

    .modal-content {
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0px 50px 77px rgba(176, 183, 195, 0.22);
        border: none;
    }
`;

export default function RejectModal({
    onOk,
    close,
    show
}: {
    onOk: (val: string) => void;
    close: () => void;
    show: boolean;
}): ReactElement {
    const [reason, setReason] = useState('');

    const onReject = () => {
        onOk(reason);
    };

    return (
        <ModalNotification show={show} onHide={close} centered>
            <Modal.Header closeButton>
                <Modal.Title>Reject Event</Modal.Title>
            </Modal.Header>
            <Modal.Body className="col">
                <div className="row m-2">
                    <Form.Group className="col m-0 p-0">
                        <Form.Label
                            style={{
                                fontSize: '0.85rem',
                                color: color.shade2,
                                fontWeight: 700
                            }}
                        >
                            Reason
                        </Form.Label>
                        <FormControl
                            className="h5 m-0 position-relative"
                            required
                            as="textarea"
                            style={{
                                backgroundColor: '#FAFBFC',
                                borderRadius: '10px',
                                margin: 0,
                                padding: '0.6rem',
                                width: '100%',
                                color: reason ? '#000' : color.shade2
                            }}
                            rows={5}
                            value={reason}
                            onChange={(event) => {
                                setReason(event.target.value);
                            }}
                            placeholder="Enter Reject Reason."
                        />
                    </Form.Group>
                </div>
                <div className="col mt-3 d-flex" style={{ gap: '1rem' }}>
                    <EventButton
                        className="col py-3"
                        onClick={() => {
                            close();
                        }}
                        style={{
                            backgroundColor: 'transparent',
                            color: color.shade2
                        }}
                    >
                        Cancel
                    </EventButton>
                    <EventButton
                        className="col text-capitalize py-3"
                        onClick={() => {
                            onReject();
                        }}
                        style={{
                            backgroundColor: '#FFE8E3',
                            color: '#FF5630'
                        }}
                    >
                        Reject
                    </EventButton>
                </div>
            </Modal.Body>
        </ModalNotification>
    );
}
