import {
    faCompass,
    faEnvelope,
    faGlobe
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Tab } from 'react-bootstrap';
import styled from 'styled-components';
import { Ecosystem } from '../../../interfaces/Ecosystem';
import { useEventTracking } from '../../../pages/eventTracking';
import EcosystemContactIcon from './EcosystemContactIcon';

const Description = styled.p`
    color: #315578;
    margin: 1.5rem 0 2rem 0;
`;

const ContactInfo = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 16px;
    line-height: 32px;
    color: #315578;

    a {
        color: inherit;

        &:hover {
            text-decoration: none;
        }
    }
`;

interface Props {
    profile: Ecosystem;
    isAdminUser: boolean;
    deleted: boolean;
    setDeleted: (deleted: boolean) => void;
    ecosystemId: string | undefined;
}

const EcosystemAbout: React.FC<Props> = ({
    profile,
    isAdminUser,
    deleted,
    setDeleted,
    ecosystemId
}) => {
    const category =
        `${window.location.pathname.split(/\B\/([a-zA-Z0-9-]{0,})/)[1]}` ===
            'ecosystem' && isAdminUser
            ? 'My Ecosystem'
            : 'Community';

    return (
        <Tab.Pane eventKey="about">
            <div className="mb-2">
                <h2>About Ecosystem</h2>
                {profile.description && (
                    <Description>{profile.description}</Description>
                )}
                <h2>Contact Information</h2>
                <>
                    {profile.website && (
                        <ContactInfo>
                            <FontAwesomeIcon icon={faGlobe} color="#25314C" />
                            <div>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${profile.website}`}
                                    onClick={() => {
                                        useEventTracking(
                                            `${category}`,
                                            'Clicked Ecosystem Website Hyperlink',
                                            `${profile.name}`
                                        );
                                    }}
                                >
                                    {profile.website}
                                </a>
                            </div>
                        </ContactInfo>
                    )}
                    {profile.email && (
                        <ContactInfo>
                            <FontAwesomeIcon
                                icon={faEnvelope}
                                color="#25314C"
                            />
                            <div>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`mailto:${profile.email}`}
                                    onClick={() => {
                                        useEventTracking(
                                            'Community',
                                            'Emailed Ecosystem',
                                            `${profile.name}`
                                        );
                                    }}
                                >
                                    {profile.email}
                                </a>
                            </div>
                        </ContactInfo>
                    )}
                    {profile.contact && (
                        <ContactInfo>
                            <EcosystemContactIcon />
                            <div>{profile.contact}</div>
                        </ContactInfo>
                    )}
                    {profile.address && (
                        <ContactInfo>
                            <FontAwesomeIcon icon={faCompass} color="#25314C" />
                            <div>{profile.address}</div>
                        </ContactInfo>
                    )}
                </>
            </div>
            {isAdminUser ? (
                <div className="d-flex justify-content-end mt-2">
                    <Button
                        size="sm"
                        style={{
                            marginRight: '5px'
                        }}
                        href={`/editecosystem/${ecosystemId}`}
                    >
                        Edit Ecosystem
                    </Button>
                    {!deleted ? (
                        <Button
                            variant="danger"
                            size="sm"
                            onClick={() => setDeleted(true)}
                        >
                            Delete Ecosystem
                        </Button>
                    ) : (
                        <div>
                            <p>Are you very sure? This cannot be undone.</p>
                            <Button
                                variant="light mr-1"
                                size="sm"
                                onClick={() => setDeleted(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="danger"
                                size="sm"
                                // onClick={
                                //     handleDelete
                                // }
                            >
                                Confirm Delete
                            </Button>
                        </div>
                    )}
                </div>
            ) : null}
        </Tab.Pane>
    );
};

export default EcosystemAbout;
