import React from 'react';

const CircleMarker = ({ count }: { count: number }) => (
  <div style={{ transform: 'translate(0, -30%)' }}>
    <svg
      width="100"
      height="100"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3" filter="url(#filter0_d)">
        <circle cx="61" cy="55" r="37" fill="#141D2A" />
      </g>
      <circle
        cx="61"
        cy="55"
        r="23"
        fill="#141D2A"
        stroke="white"
        strokeWidth="2"
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        alignmentBaseline="middle"
        dominantBaseline="middle"
        stroke="#fff"
        strokeWidth="2px"
        dy="-.4em"
      >
        {count}
      </text>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="122"
          height="122"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188235 0 0 0 0 0.905882 0 0 0 0 0.662745 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  </div>
);

export default CircleMarker;
