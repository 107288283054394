import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    color: #365372;
    border: none;
    background: none;
    padding-left: 0px;
    margin-bottom: 1rem;
    font-weight: 700;

    &:hover {
        text-decoration: underline;
    }
`;

interface Props {
    setShowOrgSelector: (showOrSelector: boolean) => void;
}

const GoBack: React.FC<Props> = ({ setShowOrgSelector }) => (
    <StyledButton variant="link" onClick={() => setShowOrgSelector(false)}>
        <FontAwesomeIcon icon={faLongArrowAltLeft} />
        <span className="ml-2">Go Back</span>
    </StyledButton>
);

export default GoBack;
