/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import axios from 'axios';
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    Card,
    ListGroup,
    FormControl,
    Container,
    Button,
    Row,
    Col,
    ButtonGroup
} from 'react-bootstrap';
import styled from 'styled-components';
// import Select from 'react-select';
import JobItem from './JobItem';
import IOpportunity from '../../interfaces/IOpportunity';

import { useEventTracking } from '../../pages/eventTracking';

// Interface for Redux types
import {
    AuthState,
    StoreState,
    UserProfileState
} from '../../redux/actions/types';
import { loadUserProfile } from '../../redux/actions/UserProfileActions';

interface pageProps extends RouteComponentProps {
    loadUserProfile: (arg0: string) => void;
    auth: AuthState;
    userProfile: UserProfileState;
}

const Styles = styled.div`
    .card-body {
        padding: 0;
    }

    .basic-multi-select {
        font-size: 16px;
    }
`;

export const JobsListComponent: React.FC<pageProps> = ({ ...props }) => {
    const [masterJobsList, setMasterJobsList] = useState<IOpportunity[]>([]);
    const [masterAppliedList, setMasterAppliedList] = useState<IOpportunity[]>(
        []
    );
    const [jobsList, setJobsList] = useState<IOpportunity[]>([]);
    const [appliedList, setAppliedList] = useState<IOpportunity[]>([]);
    const [initCheck, setInitCheck] = useState(false);
    const [search, setSearch] = useState('');
    const [shownJobs, setShownJobs] = useState(10);
    const [shownAppliedJobs, setShownAppliedJobs] = useState(10);

    const [sort, setSort] = useState('all');
    const [emptyListText, setEmptyListText] = useState('Loading jobs...');
    const [emptyAppliedListText, setEmptyAppliedListText] = useState(
        'Loading applied jobs...'
    );

    const userOpportunities = props.userProfile.userProfile?.internships;

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value != null) {
            useEventTracking('Jobs', 'Search (Job Opportunities)', `${value}`);
        }
        setSearch(value);
    };

    // Main function for filtering and searching
    const filterMainFunction = () => {
        const jobs: IOpportunity[] = Object.assign([], masterJobsList);
        const appliedJobs: IOpportunity[] = Object.assign(
            [],
            masterAppliedList
        );
        setEmptyListText('Loading jobs...');
        setEmptyAppliedListText('Loading your applied jobs...');

        // For All Jobs
        let i = 0;
        let jobsLength = jobs.length;
        while (i < jobsLength) {
            let searchCheck = false;

            // Check Search
            if (search.length > 0) {
                if (
                    jobs[i].position
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    jobs[i].description
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else {
                searchCheck = true;
                break;
            }

            // Results of Check
            if (searchCheck === false) {
                jobs.splice(i, 1);
                i -= 1;
                jobsLength = jobs.length;
            }
            i += 1;
        }
        setJobsList(jobs.slice(0).reverse());

        // For Applied Jobs
        let j = 0;
        let appliedJobsLength = appliedJobs.length;
        while (i < appliedJobsLength) {
            let searchCheck = false;

            // Check Search
            if (search.length > 0) {
                if (
                    appliedJobs[j].position
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1 ||
                    appliedJobs[j].description
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) !== -1
                ) {
                    searchCheck = true;
                }
            } else {
                searchCheck = true;
                break;
            }

            // Results of Check
            if (searchCheck === false) {
                appliedJobs.splice(j, 1);
                j -= 1;
                appliedJobsLength = appliedJobs.length;
            }
            j += 1;
        }
        setAppliedList(appliedJobs.slice(0).reverse());

        setEmptyListText('No jobs found.');

        if (search.length > 0) {
            setEmptyAppliedListText('No jobs found.');
        } else {
            setEmptyAppliedListText('You have not applied to any jobs yet!');
        }
    };

    // *** useEffects ***

    // Upon Initialisation
    useEffect(() => {
        const tempAppliedList: IOpportunity[] = [];
        axios.get(`/api/internships/`).then(({ data }) => {
            setMasterJobsList(data);
            if (userOpportunities) {
                for (let i = 0; i < data.length; i += 1) {
                    for (let j = 0; j < userOpportunities.length; j += 1) {
                        if (data[i]._id === userOpportunities[j]) {
                            tempAppliedList.unshift(data[i]);
                            break;
                        }
                    }
                }
            }
        });
        setMasterAppliedList(tempAppliedList);
    }, [userOpportunities]);

    // Whenever filter is selected or Search is filled
    useEffect(() => {
        if (initCheck === false) {
            setInitCheck(true);
        } else filterMainFunction();
    }, [search, masterJobsList, masterAppliedList]);

    return (
        <Styles>
            <Card className="mb-4">
                <Card.Header className="p-4">
                    <FormControl
                        placeholder="Search..."
                        name="search"
                        type="text"
                        value={search}
                        onChange={handleSearch}
                        className="mb-4"
                    />
                    <Row className="text-center">
                        <Col>
                            <ButtonGroup aria-label="Sort Buttons">
                                <Button
                                    variant={
                                        sort === 'all'
                                            ? 'primary'
                                            : 'outline-primary'
                                    }
                                    onClick={() => {
                                        useEventTracking(
                                            'Jobs',
                                            'Clicked Sort By Filter',
                                            'All'
                                        );
                                        setSort('all');
                                    }}
                                >
                                    All
                                </Button>
                                <Button
                                    variant={
                                        sort === 'applied'
                                            ? 'primary'
                                            : 'outline-primary'
                                    }
                                    onClick={() => {
                                        useEventTracking(
                                            'Jobs',
                                            'Clicked Sort By Filter',
                                            'Applied'
                                        );
                                        setSort('applied');
                                    }}
                                >
                                    Applied
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className="p-3">
                    {/* If user selected 'All' */}
                    {sort === 'all' ? (
                        <div>
                            <ListGroup variant="flush">
                                {jobsList.slice(0, shownJobs).map((job, i) => (
                                    <div
                                        className={
                                            jobsList.slice(0, shownJobs)[i + 1]
                                                ? 'list-item-border'
                                                : ''
                                        }
                                    >
                                        <JobItem key={job._id} job={job} logo />
                                    </div>
                                ))}
                            </ListGroup>
                            {jobsList.length === 0 ? (
                                <Container>
                                    <p className="mt-3">{emptyListText}</p>
                                </Container>
                            ) : null}
                            {shownJobs < jobsList.length ? (
                                <Button
                                    type="button"
                                    variant="light"
                                    onClick={() => {
                                        setShownJobs(shownJobs + 5);
                                    }}
                                    block
                                    className="mb-4"
                                >
                                    Show More
                                </Button>
                            ) : null}
                        </div>
                    ) : null}

                    {/* If user selected 'Applied' */}
                    {sort === 'applied' ? (
                        <div>
                            <ListGroup variant="flush">
                                {appliedList
                                    .slice(0, shownAppliedJobs)
                                    .map((job) => (
                                        <JobItem key={job._id} job={job} logo />
                                    ))}
                            </ListGroup>
                            {appliedList.length === 0 ? (
                                <Container>
                                    <p className="mt-3">
                                        {emptyAppliedListText}
                                    </p>
                                </Container>
                            ) : null}
                            {shownAppliedJobs < appliedList.length ? (
                                <Button
                                    type="button"
                                    variant="light"
                                    onClick={() => {
                                        setShownAppliedJobs(
                                            shownAppliedJobs + 5
                                        );
                                    }}
                                    block
                                    className="mb-4"
                                >
                                    Show More
                                </Button>
                            ) : null}
                        </div>
                    ) : null}
                </Card.Body>
            </Card>
        </Styles>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps, { loadUserProfile })(
    withRouter(JobsListComponent)
);
