/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAlert } from 'react-alert';
import { Col, Container, Image, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons';
import { EventButton } from './EventStyles';

const ImageContent = styled.div`

    .title {
        font-size: 1rem;
        color: #8b9bb6;
        letter-spacing: -0.03rem;
        font-weight: 700;
    }

    .subtitle {
        font-size: 0.75rem;
        color: #8b9bb6;
    }

    img {
        height: '10rem',
        width: '100%',
        maxWidth: '100%',
        objectFit: 'scale-down'
    }
`;

interface UploadImageProps {
    resetImage: (image: any) => void;
    classNames?: string;
    src?: string;
}

export default function UploadImage({
    resetImage,
    classNames = undefined,
    src
}: UploadImageProps): ReactElement {
    const alert = useAlert();
    const { getRootProps, getInputProps } = useDropzone({
        accept: ['image/png', 'image/jpeg', 'image/jpg'],
        onDrop: (acceptedFiles) => {
            if (acceptedFiles[0].size > 4000000) {
                alert.error('Please choose an image under 4mb.');
                return;
            }
            resetImage(acceptedFiles[0]);
        }
    });
    
    return (
        <div>
            <ImageContent>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />

                    <div
                        className={classNames}
                        style={{
                            position: 'relative',
                            height: '20rem',
                            background: '#F7F9FB',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            border: '2px dashed #F3F3F3',
                            borderRadius: '15px'
                        }}
                    >
                        <div className="text-center container flex">
                            {src ? (
                                <>
                                    <Image
                                        src={src}
                                        style={{
                                            height: '18rem',
                                            maxWidth: '100%',
                                            objectFit: 'scale-down',
                                            padding: '1px',
                                            border: '5px solid #FFFFFF',
                                            borderRadius: '15px',
                                            filter:
                                                'drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.16))'
                                        }}
                                        fluid
                                    />
                                    <EventButton
                                        className="position-absolute"
                                        size="sm"
                                        style={{
                                            right: '0.5rem',
                                            top: '0.5rem',
                                            backgroundColor: '#007BFF',
                                            color: 'white'
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faPenAlt}/>
                                    </EventButton>
                                </>
                            ) : (
                                <>
                                    <Image
                                        src={`${process.env.PUBLIC_URL}/assets/image-upload.png`}
                                        fluid
                                        style={{
                                            height: '10rem',
                                            width: '100%',
                                            maxWidth: '100%',
                                            objectFit: 'scale-down'
                                        }}
                                    />
                                    <h4 className="mt-1 title">
                                        Drag and Drop or{' '}
                                        <span className="text-primary">
                                            Browse
                                        </span>{' '}
                                        to choose a file
                                    </h4>
                                    <h5 className="mt-1 subtitle">
                                        Recommended Image Size: 1200px x 600px{' '}
                                        <br />
                                        Maximum Upload Size 4MB
                                    </h5>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </ImageContent>
        </div>
    );
}
