import axios from 'axios';
import React, { useState } from 'react';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Ecosystem, JoinAs } from '../../../interfaces/Ecosystem';
import { OrgEcosystem } from '../../../interfaces/IOrganisation';

const Wrapper = styled.div`
    img {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        background-color: #fff;
        border: 1px solid #dee2e6;
        aspect-ratio: 1;
        max-height: 4.5rem;
        max-width: 4.5rem;
    }
`;

const Heading = styled.h1`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #002a52;
`;

const Description = styled.p`
    margin-top: 1rem;
    color: #315578;
`;

const FocusAreas = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    & > div {
        background-color: #f5faff;
        color: #2b6098;
        padding: 7px 9px;
        border: 1px solid #cecece;
        border-radius: 8px;
        font-size: 16px;
    }
`;

interface Props {
    show: boolean;
    handleClose: () => void;
    ecosystem: Ecosystem;
    joinAs: JoinAs;
    verificationCode: string;
    setVerificationCode: (verificationCode: string) => void;
    profileId: string;
}

const EcosystemJoinModalAsOrg: React.FC<Props> = ({
    show,
    handleClose,
    ecosystem,
    joinAs,
    verificationCode,
    setVerificationCode,
    profileId
}) => {
    const history = useHistory();

    const handleJoin = () => {
        axios
            .put<Ecosystem>(
                `/api/ecosystems/${ecosystem._id}/join/${profileId}`,
                {
                    verificationCode,
                    joinAs
                }
            )
            .then((data) => {
                console.log(data.data);
                handleClose();
                setVerificationCode('');
                history.go(0);
            })
            .catch((err) => console.log(err.response.data));
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            centered
            dialogClassName="modal-width font-arimo"
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title as="h1" className="font-weight-bold">
                    Join {ecosystem.name}{' '}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-3">
                <Row className="justify-content-center my-3">
                    <Col sm={4}>
                        <Wrapper>
                            <Image
                                className="img-thumbnail"
                                src={
                                    ecosystem.logo?.location ??
                                    `${process.env.PUBLIC_URL}/assets/interseed.png`
                                }
                                alt="Organisation"
                                roundedCircle
                                thumbnail
                                fluid
                                onError={(e) => {
                                    e.currentTarget.onerror = null; // prevents looping
                                    e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/interseed.png`;
                                }}
                            />
                        </Wrapper>
                    </Col>
                    <Col>
                        <Heading>{ecosystem.name}</Heading>
                        {ecosystem.focusAreas && (
                            <FocusAreas className="mt-2">
                                {ecosystem.focusAreas.sort().map((focus) => (
                                    <div>{focus}</div>
                                ))}
                            </FocusAreas>
                        )}
                        {/* <Description>{ecosystem.description}</Description> */}
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Button
                        className="btn-block"
                        variant="primary"
                        onClick={handleJoin}
                    >
                        Join
                    </Button>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default EcosystemJoinModalAsOrg;
