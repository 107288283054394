/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { Row, Col, Container, Modal, Button, Nav } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLinkedin,
    faInstagram,
    faFacebook,
    faSpotify
} from '@fortawesome/free-brands-svg-icons';
import { useEventTracking } from '../pages/eventTracking';

// CSS & HTML partially contributed by Scanfcode.com
// Adapted from https://codepen.io/scanfcode/pen/MEZPNd

const Styles = styled.div`
    .site-footer {
        background-color: #26272b;
        padding: 20px 0 16px;
        font-size: 15px;
        line-height: 24px;
        color: #737373;
    }
    .site-footer hr {
        border-top-color: #bbb;
        opacity: 0.5;
    }
    .site-footer hr.small {
        margin: 20px 0;
    }
    .site-footer h6 {
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        margin-top: 5px;
        letter-spacing: 2px;
    }
    .site-footer a {
        color: #737373;
    }
    .site-footer a:hover {
        color: #3366cc;
        text-decoration: none;
    }
    .footer-links {
        padding-left: 0;
        list-style: none;
    }
    .footer-links li {
        display: block;
    }
    .footer-links a {
        color: #737373;
    }
    .footer-links a:active,
    .footer-links a:focus,
    .footer-links a:hover {
        color: #3366cc;
        text-decoration: none;
    }
    .footer-links.inline li {
        display: inline-block;
    }
    .site-footer .social-icons {
        text-align: right;
    }
    .site-footer .social-icons a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-left: 6px;
        margin-right: 0;
        border-radius: 100%;
        background-color: #33353d;
    }
    .textual-info {
        text-align: left;
    }
    .copyright-text {
        margin: 0;
    }
    .copyright-text a {
        color: #999999;
    }
    @media (max-width: 991px) {
        .site-footer [class^='col-'] {
            margin-bottom: 30px;
        }
    }
    @media (max-width: 767px) {
        .site-footer {
            padding-bottom: 0;
        }
        .site-footer .copyright-text,
        .site-footer .social-icons {
            text-align: center;
        }
    }
    .social-icons {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
    .social-icons a {
        background-color: #eceeef;
        color: #818a91;
        font-size: 16px;
        display: inline-block;
        line-height: 44px;
        width: 44px;
        height: 44px;
        text-align: center;
        margin-right: 8px;
        border-radius: 100%;
        -webkit-transition: all 0.2s linear;
        -o-transition: all 0.2s linear;
        transition: all 0.2s linear;
    }
    .social-icons a:active,
    .social-icons a:focus,
    .social-icons a:hover {
        color: #fff;
        background-color: #29aafe;
    }
`;

const Footer: React.FC = () => {
    const [showPrivacyPolicy, setshowPrivacyPolicy] = useState(false);

    const handleClose = () => setshowPrivacyPolicy(false);

    const privacyPolicyCard = (
        <Modal
            scrollable
            show={showPrivacyPolicy}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>Privacy Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="text-muted">
                    <em>Last updated: April 24th 2021, 0800 SGT (UTC+8)</em>
                </p>
                <p>
                    Thank you for visiting Interseed&apos;s website at
                    www.interseed.co (“our website”). This Privacy Policy has
                    been prepared in compliance with Singapore’s Personal Data
                    Protection Act (“PDPA”). The PDPA promotes fairness and
                    transparency for all individuals in respect of their
                    personal data. This Privacy Policy applies to all products,
                    websites, online and offline events or engagements and
                    mobile applications on the Android, IOS, Windows or other
                    mobile (collectively the “Platform”) offered by Interseed
                    and informs you about how we collect your personal data, how
                    we may use your personal data and how you may exercise your
                    rights regarding our use of that data. The Privacy Policy
                    also describes the measures we take to safeguard the
                    personal data we obtain and how you can contact us about our
                    privacy practices.
                </p>
                <p>
                    By voluntarily providing us with your personal data, you are
                    consenting to our use of it in accordance with this Privacy
                    Policy
                </p>
                <p>Our Privacy Policy explains the following:</p>
                <ul>
                    <li>Data that we collect</li>
                    <li>How we collect the data</li>
                    <li>How we use the data that we collect</li>
                    <li>
                        What personal data do we share with third parties and
                        who are they
                    </li>
                    <li>Where do we transfer and use data</li>
                    <li>How do we keep your personal data secure</li>
                    <li>Your privacy rights</li>
                    <li>Cookies</li>
                    <li>Links to other website</li>
                    <li>No Spam, Spyware, or Virus</li>
                    <li>Our rights</li>
                    <li>Changes to this Privacy Policy</li>
                    <li>Contact Us</li>
                </ul>
                <h5>Data that we collect</h5>
                <p>
                    We collect personal data to deliver our Services. Here,
                    personal data refers to any data about an individual who can
                    be identified from that data (for example: name, address,
                    email address, phone number, photograph, video etc.)
                </p>
                <h5>How we collect the data</h5>
                <p>We collect data in the following ways:</p>
                <ul>
                    <li>
                        Data you give us. While using our website or subscribing
                        to our newsletter, we may ask you to provide us with
                        certain personally identifiable information that can be
                        used to contact or identify you. Personally identifiable
                        information may include, but is not limited to your
                        name, email address, phone number, photograph, video,
                        residential address etc. (“Personal Information”). For
                        example, when you open an account on our website, we
                        will ask for your name, email address or phone number.
                    </li>
                    <li>
                        Like many site operators, we collect information that
                        your browser sends whenever you visit our Site (“Log
                        Data”). This Log Data may include information such as
                        your computer’s Internet Protocol (“IP”) address,
                        browser type, browser version, the pages of our Site
                        that you visit, the time and date of your visit, the
                        time spent on those pages and other statistics.
                    </li>
                    <li>
                        Data collected through consensually disclosed
                        information to us, or at online or offline events
                        organized, sponsored or promoted by us solely or in
                        partnership with a third party’ or through a referral
                        from a third party, or the purchase of a database
                        containing personal data from a database reseller who
                        had obtained consent for the disclosure of the personal
                        data or publicly available sources such as LinkedIn, and
                        may be used for occasional targeted communications. This
                        may include but are not limited to names, contact
                        information, photographs, videos, information about
                        education experiences and career experiences.
                    </li>
                    <li>
                        We may use third party services such as Google Analytics
                        that collect, monitor and analyse this type of
                        information in order to increase our Service’s
                        functionality. These third party service providers have
                        their own privacy policies addressing how they use such
                        information. We may also provide links to other
                        third-party websites and features and we mainly collect
                        data through third-party sources that are compliant with
                        their local or regional Personal Data Privacy laws and
                        this Privacy Policy does not apply to third-party
                        sources and how they manage the data.
                    </li>
                    <li>
                        The data we collect through you and partners’ use of our
                        website or the Platform (such as name, address, billing
                        information and employee contact information) and
                        through our offline interactions with you and partners.
                    </li>
                    <li>
                        By way of cookies. Cookies are files with a small amount
                        of data, which may include an anonymous unique
                        identifier. Cookies are sent to your browser from a web
                        site and stored on your computer’s hard drive. Like many
                        sites, we use “cookies” to collect information. You can
                        instruct your browser to refuse all cookies or to
                        indicate when a cookie is being sent. However, if you do
                        not accept cookies, you may not be able to use some
                        portions of our Site.
                    </li>
                </ul>
                <p>
                    We do not engage in the business of selling any personal
                    data to third parties. Interseed’s website is not designed
                    for or directed towards children aged 13 or younger, and we
                    do not intentionally collect or maintain personal data about
                    anyone under this age. We will ask for your consent before
                    using your personal data for a purpose other than those set
                    out in this Privacy Policy.
                </p>
                <h5>How we use the data that we collect</h5>
                <p>
                    The personal data we collect and use depends on what
                    Interseed service(s) you use. We primarily use personal data
                    as part of the following activities:
                </p>
                <ul>
                    <li>
                        Provide and improve our Services: We use the data we
                        collect from you to provide and improve our Services. We
                        also use this data to offer you tailored information –
                        like giving you relevant opportunities or network
                        connections. When you contact Interseed, we keep a
                        record of your communication to help solve any issues
                        you might be facing while using our Platforms. We may
                        share non-personally identifiable data publicly and with
                        our partners – like publishers, advertisers or connected
                        sites or entities. We may share data publicly to show
                        trends about the general opinion of people in our
                        network (e.g. “1000 of our users are from Thailand”).
                    </li>
                    <li>
                        Marketing: We strive to provide you with choices
                        regarding certain personal data uses, particularly
                        around marketing. We collect limited personal data, such
                        as name and email address, in order to communicate with
                        business contacts, build brand awareness, share updates,
                        publicise events, and fulfil other general marketing
                        purposes involving both online and offline marketing
                        initiatives (including but not limited to our and
                        partners’ website, social media platforms, newsletters,
                        events, posters, campaigns, workshops, tournaments
                        etc.). We do so through occasional email communications,
                        ensuring minimal privacy impact. You have the right to
                        opt-out of any marketing communications we send you by
                        clicking unsubscribe.
                    </li>
                    <li>
                        Scouting: We collect personal data of people we think
                        may be potential participants in any events, hackathons,
                        workshops, incubations organized by Interseed solely or
                        in partnership with a third party or solely by a third
                        party, as well as for recruitment into Interseed. This
                        is on the grounds of our legitimate interest as a
                        business that is premised on finding and developing the
                        ecosystem of startups, founders, professionals to
                        accelerate sustainability solutions and is used in
                        accordance to regularly conducted legitimate interest
                        assessment. We use data which you have provided or which
                        we have found on public sources may also be stored in
                        our applicant data system. We will keep this data for no
                        longer than is necessary for the purposes for which the
                        personal data is used.
                    </li>
                    <li>
                        Events, workshops, hackathons, incubations: By signing
                        up for and attending Interseed events, workshops,
                        hackathons, incubations, you permit Interseed to use
                        limited personal contact information, such as name and
                        email address, for above mentioned marketing purposes
                        and make on-site recordings in which you may be
                        included, by any means, such as video recording and
                        photography. The videos and photos may be used in
                        marketing or editorial material, as long as they are
                        related to the article subject.
                    </li>
                    <li>
                        Contractual obligations with you: For the performance of
                        contracts to which you will be a party to and in order
                        to take steps at your request prior to you entering into
                        those contracts.
                    </li>
                    <li>
                        Website and email: Interseed or our authorized service
                        providers may use cookies, web beacons, and other
                        similar technologies for storing data to help provide
                        you with a better, faster, safer and personalized
                        experience when you use our website or the Platform.
                        When you visit our website or the Platform, servers will
                        automatically record data that your browser sends
                        whenever you visit a website. This data may include:
                        <ul>
                            <li>Your computer&apos;s IP address</li>
                            <li>Browser type</li>
                        </ul>
                        This data is collected for analysis and evaluation in
                        order to help us improve our website and our Services
                        and products we provide.
                    </li>
                    <li>
                        For any other purposes as permitted by applicable law or
                        with your consent.
                    </li>
                </ul>
                <h5>
                    What personal data do we share with third parties and who
                    are they
                </h5>
                <p>
                    If you provide personal data to us, you acknowledge and
                    agree that such personal data may be shared with the
                    authorized third parties referred to herein. These parties
                    include:
                </p>
                <ul>
                    <li>
                        Other users: For example, if you are a user, we may
                        share your name and email with users looking to connect
                        with users on the platform. If you are a user seeking to
                        connect to other users, we may share your name and email
                        with other users.
                    </li>
                    <li>
                        Third parties: We will seek your consent before sharing
                        personal data with any of these third parties. The
                        investors, advisors, venture partners and supporters we
                        potentially share your personal data with are all listed
                        on our website. We will make efforts that are reasonable
                        or commercially acceptable, where we can, to update our
                        website periodically to reflect any changes in the list
                        of our investors, advisors, venture partners and
                        supporters.
                    </li>
                    <li>
                        The owner of Interseed accounts that you may use: For
                        example, your employer or educational institute may
                        receive your personal data when you use your employer’s
                        Interseed account.
                    </li>
                    <li>
                        Subsidiaries and affiliates: We share personal data with
                        our subsidiaries, associated companies, jointly
                        controlled entities and affiliates.
                    </li>
                    <li>
                        Our service providers and business partners: We may
                        provide personal data to our vendors, consultants,
                        mentors, marketing partners, research firms, and other
                        service providers or business partners. This includes
                        payment processors and facilitators; background check
                        and anti-money laundering service providers; cloud
                        storage providers; marketing partners and marketing
                        platform providers; data analytics providers; research
                        partners, including those performing surveys or research
                        projects in partnership with Interseed or on Interseed’s
                        behalf; fleet and merchant partners; insurance and
                        financing partners; and vehicle solutions partners,
                        vendors or third-party vehicle suppliers.
                    </li>
                    <li>
                        Our legal advisors and governmental authorities: We may
                        share your personal data with our legal advisors, law
                        enforcement officials, government authorities and other
                        third parties.
                    </li>
                </ul>
                <p>
                    These partners, affiliates and third parties will only have
                    access to such personal data to the extent necessary to
                    perform their functions. If we are required to transfer any
                    personal data outside of Singapore, such transfer will be
                    carried out in accordance with the PDPA and this Privacy
                    Policy.
                </p>
                <p>
                    Save as provided above, we will not disclose personal data
                    to third parties unless we have your permission or are
                    required by law to do so.
                </p>
                <h5>Where do we transfer and use data</h5>
                <p>
                    Interseed uses personal data on servers in countries or
                    territories around the world where those countries have data
                    protection standards comparable to the protection under the
                    PDPA. We may use your personal data on a server located
                    outside the country where you live.
                </p>
                <h5>How do we keep your personal data secure</h5>
                <p>
                    The security of your personal data is important to us. We
                    use security arrangements that are commercially acceptable
                    or reasonable to prevent unauthorized access, collection,
                    use, disclosure, copying, modification, disposal or similar
                    risks. Although we will do our best to protect your personal
                    data, Interseed cannot guarantee that unauthorised persons
                    will not obtain access to your personal data.
                </p>
                <h5>Your Privacy Rights</h5>
                <p>
                    With reasonable notice, and based on the fulfilment of
                    criteria set out by PDPA, we guarantee the following rights:
                </p>
                <ul>
                    <li>
                        Accessing Your personal data: If you would like to view
                        the personal data we have on you or inquire about the
                        ways in which your personal data has been or may have
                        been used or disclosed by us within the past year,
                        please contact us at hello@interseed.co. We reserve the
                        right to charge a reasonable administrative fee for
                        retrieving your personal data records. You undertake to
                        treat your Interseed profile’s username, password and
                        personal details confidentially and not make it
                        available to unauthorised third parties. We cannot
                        assume any liability for misuse of your YSI profile’s
                        username, password or personal data.
                    </li>
                    <li>
                        Correction of your personal data: You can correct an
                        error or omission in your personal data at any time by
                        accessing your Interseed profile. If you do not have an
                        account with us, you can do so by contacting us at
                        hello@interseed.co. We take steps to share the updates
                        to your personal data with third parties and our
                        affiliates with whom we have shared your personal data
                        if your personal data is still necessary for the above
                        stated purposes.
                    </li>
                    <li>
                        Withdrawal of Consent: You may communicate your
                        objection to our continual use and/or disclosure of your
                        personal data for any of the purposes and in the manner
                        as stated above at any time by contacting us at
                        hello@interseed.co. Please note that if you communicate
                        your objection to our use and/or disclosure of your
                        personal data for the purposes and in the manner as
                        stated above, depending on the nature of your objection,
                        we may NOT be in a position to continue providing our
                        products or Services to you or perform on any contract
                        we have with you. Our legal rights and remedies are
                        expressly reserved in such an event.
                    </li>
                </ul>
                <h5>Cookies</h5>
                <p>
                    We use cookies on our website, which are small data files
                    sent to your browser to store and track information when you
                    enter our website. Cookies help us to provide you with an
                    improved website, by enabling us to monitor which pages you
                    find useful and which you do not. A cookie does not give us
                    access to information stored on your device or any personal
                    data about you.
                </p>
                <p>
                    You can choose to accept or decline cookies. Most web
                    browsers automatically accept cookies, but you can usually
                    modify your browser setting to decline cookies if you
                    prefer. However, doing so may prevent you from taking full
                    advantage of our website.
                </p>
                <h5>Links To Other Websites</h5>
                <p>
                    Our website may contain links to other websites operated by
                    third parties which may be of interest to you. However, once
                    you have used these links to leave our website, you should
                    note that we do not have any control over those websites.
                    Therefore, we cannot be responsible for the protection and
                    privacy of any information which you provide whilst visiting
                    such websites and such websites are not governed by this
                    Privacy Policy. You should exercise caution and look at the
                    privacy policy applicable to the website in question to
                    understand how your information may be used by the third
                    party which operates that website.
                </p>
                <h5>No Spam, Spyware, or Virus</h5>
                <p>
                    Spam, spyware or virus is not allowed on our website or the
                    Platform. Please set and maintain your communication
                    preferences so that we send communications to you as you
                    prefer. You are not licensed or otherwise allowed to add
                    other users (even a user who has purchased an item from you)
                    to your mailing list (email or physical mail) without their
                    express consent. You should not send any messages which
                    contain spam, spyware or virus via the Platform. If you
                    would like to report any suspicious messages, please contact
                    us at hello@interseed.co.
                </p>
                <h5>Our Rights</h5>
                <p>
                    You acknowledge and agree that there are certain situations
                    in which we may share access to your personal data without
                    your explicit consent; which includes, without limitation,
                    the following :
                </p>
                <ul>
                    <li>
                        Cases in which the disclosure is required or authorized
                        based on the applicable laws and/or regulations;
                    </li>
                    <li>
                        Cases in which the purpose of such disclosure is clearly
                        in your interests, and if consent cannot be obtained in
                        a timely way;
                    </li>
                    <li>
                        Cases in which the disclosure is necessary to respond to
                        an emergency that threatens the life, health or safety
                        of yourself or another individual;
                    </li>
                    <li>
                        Cases in which the disclosure is necessary for any
                        investigation or proceedings;
                    </li>
                    <li>
                        Cases in which the personal data is disclosed to any
                        officer of a prescribed law enforcement agency, upon
                        production of written authorisation signed by the head
                        or director of that law enforcement agency or a person
                        of a similar rank, certifying that the personal data is
                        necessary for the purposes of the functions or duties of
                        the officer;
                    </li>
                    <li>
                        Cases in which the disclosure is to a public agency and
                        such disclosure is necessary in the public interest;
                        and/or
                    </li>
                    <li>
                        Cases where such disclosure without your consent is
                        permitted by the PDPA or by law.
                    </li>
                </ul>
                <p>
                    The instances listed above under this section are not
                    intended to be exhaustive. For more information on the
                    exceptions, you are encouraged to peruse the Second, Third
                    and Fourth Schedules of the PDPA which is publicly available
                    at https://sso.agc.gov.sg/Act/PDPA2012.
                </p>
                <h5>Changes To this Privacy Policy</h5>
                <p>
                    This Privacy Policy is effective as of April 24th, 2021 and
                    will remain in effect except with respect to any changes in
                    its provisions in the future, which will be in effect
                    immediately after being posted on this page.
                </p>
                <p>
                    We reserve the right to update or change our Privacy Policy
                    at any time and you should check this Privacy Policy
                    periodically. Your continued use of the Service after we
                    post any modifications to the Privacy Policy on this page
                    will constitute your acknowledgment of the modifications and
                    your consent to abide and be bound by the modified Privacy
                    Policy.
                </p>
                <p>
                    If we make any material changes to this Privacy Policy, we
                    will let you know, where we can, either through the email
                    address you have provided us, or by placing a prominent
                    notice on our website.
                </p>
                <h5>Contact Us</h5>
                <p>
                    If you have any questions about this Privacy Policy, please
                    contact us at hello@interseed.co.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
    return (
        <Styles>
            {privacyPolicyCard}
            <footer className="site-footer">
                <Container>
                    <Row>
                        <Col md={6} sm={6} xs={12} className="textual-info">
                            <p className="copyright-text">
                                Copyright &copy; 2021
                                <a
                                    href="https://www.interseed.co/"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    {' '}
                                    Interseed
                                </a>
                                . All Rights Reserved.
                            </p>
                            <p className="copyright-text">
                                Facing Issues?{' '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://airtable.com/shrK393oWyxwy1eqG"
                                    onClick={() => {
                                        useEventTracking(
                                            'Footer',
                                            'Clicked Give Feedback',
                                            ``
                                        );
                                    }}
                                >
                                    Give Feedback
                                </a>
                                {' or '}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://airtable.com/shrdqKfiIo9eNugBT"
                                    onClick={() => {
                                        useEventTracking(
                                            'Footer',
                                            'Clicked Report a Bug',
                                            ``
                                        );
                                    }}
                                >
                                    Report a Bug.
                                </a>
                            </p>
                        </Col>
                        <Col md={2} sm={3} xs={12}>
                            <Nav.Link
                                onClick={() => {
                                    useEventTracking(
                                        'Footer',
                                        'Clicked Privacy Policy',
                                        ``
                                    );
                                    setshowPrivacyPolicy(true);
                                }}
                                rel="noreferrer"
                                target="_blank"
                                style={{ padding: 0 }}
                            >
                                Privacy Policy
                            </Nav.Link>
                            <Nav.Link
                                onClick={() => {
                                    useEventTracking(
                                        'Footer',
                                        'Clicked About Us',
                                        ``
                                    );
                                }}
                                as={Link}
                                to="/about"
                                style={{ padding: 0 }}
                            >
                                About Us
                            </Nav.Link>
                        </Col>

                        <Col md={4} sm={3} xs={12}>
                            <ul className="social-icons">
                                <a
                                    href="https://www.instagram.com/interseed.co/"
                                    onClick={() => {
                                        useEventTracking(
                                            'Footer',
                                            'Clicked Interseed Instagram Icon',
                                            ``
                                        );
                                    }}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>

                                <a
                                    href="https://www.linkedin.com/company/68030389/"
                                    onClick={() => {
                                        useEventTracking(
                                            'Footer',
                                            'Clicked LinkedIn Facebook Icon',
                                            ``
                                        );
                                    }}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>

                                <a
                                    href="https://open.spotify.com/show/6juG5jtUa4s9tFDokZ0uJ9?si=WVTG9SjwSFGP6RqW4yQvrA"
                                    onClick={() => {
                                        useEventTracking(
                                            'Footer',
                                            'Clicked Interseed Spotify Icon',
                                            ``
                                        );
                                    }}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faSpotify} />
                                </a>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </Styles>
    );
};

export default Footer;
