/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Row, Col, Card, Image, Button } from 'react-bootstrap';

import { useEventTracking } from './eventTracking';

// Interface for Redux types
import {
    StoreState,
    AuthState,
    UserProfileState
} from '../redux/actions/types';

const Style = styled.div`
    h2 {
        margin-top: 20px;
    }

    .poster {
        max-height: 100vh;
        max-width: 100%;
    }

    .registerbutton {
        border-radius: 10px;
        width: 15rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        transition: background-color 0.25s, transform 0.25s;
        border-color: #007bff;
        text-align: center;
        font-weight: bold;
    }

    .registerbutton:hover {
        background-color: #218838;
        border-color: #218838;
        transform: translateY(-0.2rem);
    }

    .interseed-hyperlink {
        text-decoration: none;
    }
`;

interface authProps extends RouteComponentProps {
    auth: AuthState;
    history: RouteComponentProps['history'];
    userProfile: UserProfileState;
}

const MicrosoftChallenge: React.FC<authProps> = ({ ...props }) => {
    function test(input) {
        console.log(input);
    }
    return (
        <Container className="mb-4">
            <Style>
                <Card>
                    <Card.Body className="my-5">
                        <h1>
                            Microsoft Singapore GreenTech Challenge for Startups
                        </h1>
                        <img
                            src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/ms-sustain-posters-01.jpg"
                            alt="Microsoft GreenTech Challenge Poster"
                            className="poster my-4"
                        />
                        <Col md={{ span: 8, offset: 2 }}>
                            <div style={{ textAlign: 'justify' }}>
                                <p>
                                    In partnership with Creitive, the Microsoft
                                    Innovation Center for Sustainability
                                    Solutions (MICSS) program harnesses the
                                    power of technology to enable corporate
                                    clients and solution providers to work
                                    together on creating a more sustainable
                                    future.
                                </p>
                                <p>
                                    Microsoft and Creitive are teaming up to
                                    provide their support, Microsoft Cloud
                                    Platform services, and business connections
                                    to early to mid-stage startups that are
                                    solving environmental or social challenges
                                    within the SG Green environmental areas.
                                </p>
                                <p>
                                    Pitch your sustainability solution and grow
                                    your business with up to $350,000 in
                                    benefits and with amazing partners
                                </p>
                            </div>
                            {/* <p>
                                Microsoft and Creitive are calling all B2B,
                                seed-to-series A stage, APAC startups with a
                                software-based solution to an environmental or a
                                social challenge, to join our Microsoft
                                Singapore GreenTech Challenge!
                            </p>
                            <p>
                                Microsoft and Creitive are teaming up to provide
                                their support, Microsoft Cloud Platform
                                services, and business connections to early to
                                mid-stage startups that are solving
                                environmental or social challenges within the SG
                                Green environmental areas:
                            </p>
                            <ul>
                                <li>City in Nature</li>
                                <li>Sustainable Living</li>
                                <li>Energy Reset</li>
                                <li>Green Economy</li>
                                <li>Resilient Future</li>
                            </ul>
                            <p>
                                Startups addressing any of the following areas
                                are also encouraged to apply: Accessibility,
                                Humanitarian, Digital Skills and Employability,
                                Healthcare, Female and underrepresented
                                founders.
                            </p>
                            <h2>Value Proposition for Startups:</h2>
                            <ul>
                                <li>
                                    Driving Innovation to improve the
                                    enterprise’s workflow
                                </li>
                                <li>
                                    Teamwork - Working together with
                                    industry/corporate partners
                                </li>
                                <li>
                                    Mentorship by the leaders within Microsoft
                                    and its Clients
                                </li>
                                <li>Microsoft Support (see below)</li>
                            </ul>
                            <h2>
                                Depending on your startup&apos;s readiness level
                                that will be evaluated during the selection
                                phase, you can receive:
                            </h2>
                            <ul>
                                <li>Up to USD $150,000 of Azure credits</li>
                                <li>A dedicated Account Manager</li>
                                <li>Github Enterprise</li>
                                <li>Power Platform</li>
                                <li>
                                    Visual Studio Enterprise cloud subscription
                                </li>
                                <li>Office 365 Business Premium</li>
                                <li>
                                    Dynamics 365 for Customer Engagement and
                                    Talent
                                </li>
                                <li>Enterprise-grade Azure support</li>
                                <li>
                                    Architectural consultations with our field
                                    engineers
                                </li>
                            </ul> */}

                            <Link
                                to={{
                                    pathname:
                                        'https://www.sggreentechchallenge.com/'
                                }}
                                target="_blank"
                                className="btn btn-primary registerbutton mt-4"
                            >
                                Join the Challenge
                            </Link>
                        </Col>
                    </Card.Body>
                </Card>
                <Card className="my-3">
                    <Card.Body className="my-5">
                        <Col md={{ span: 10, offset: 1 }}>
                            <h1>
                                Interseed has incubated 27 regional impact
                                startups under the YSI SEA&apos;s Innovation
                                Programmes.
                            </h1>
                            <p>
                                Here are tips that can help your application
                                process with our three years of experience:{' '}
                            </p>
                            <Row className="mb-3 cardrow">
                                <Col md={4}>
                                    <Card
                                        className="interseed-hyperlink"
                                        as={Link}
                                        to={{
                                            pathname:
                                                'https://www.interseed.co/post/sustainability-startup-pitch-investment-story'
                                        }}
                                        target="_blank"
                                    >
                                        <Card.Img
                                            variant="top"
                                            src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/Microsoft+Singapore+GreenTech+Challenge+for+Startups+1.png"
                                        />
                                        <Card.Body>
                                            <Card.Text>
                                                Telling your investment story
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card
                                        className="interseed-hyperlink"
                                        as={Link}
                                        to={{
                                            pathname:
                                                'https://www.interseed.co/post/sustainability-pitch-deck-structure-sdgs'
                                        }}
                                        target="_blank"
                                    >
                                        <Card.Img
                                            variant="top"
                                            src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/Microsoft+Singapore+GreenTech+Challenge+for+Startups+2.png"
                                        />
                                        <Card.Body>
                                            <Card.Text>
                                                Your pitch deck and structure
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card
                                        className="interseed-hyperlink"
                                        as={Link}
                                        to={{
                                            pathname:
                                                'https://www.interseed.co/post/assess-sustainability-impact-startup-business-growth'
                                        }}
                                        target="_blank"
                                    >
                                        <Card.Img
                                            variant="top"
                                            src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/Microsoft+Singapore+GreenTech+Challenge+for+Startups+3.png"
                                        />
                                        <Card.Body
                                            style={{
                                                paddingLeft: 0,
                                                paddingRight: 0
                                            }}
                                        >
                                            <Card.Text>
                                                How to access your business
                                                growth
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={{ span: 4, offset: 2 }}>
                                    <Card
                                        className="interseed-hyperlink"
                                        as={Link}
                                        to={{
                                            pathname:
                                                'https://www.interseed.co/post/theory-of-change-for-sustainability'
                                        }}
                                        target="_blank"
                                    >
                                        <Card.Img
                                            variant="top"
                                            src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/Microsoft+Singapore+GreenTech+Challenge+for+Startups+4.png"
                                        />
                                        <Card.Body>
                                            <Card.Text>
                                                Theory of change
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={{ span: 4 }}>
                                    <Card
                                        className="interseed-hyperlink"
                                        as={Link}
                                        to={{
                                            pathname:
                                                'https://www.interseed.co/post/southeast-asia-sustainability-incubation-acceleration-programme'
                                        }}
                                        target="_blank"
                                    >
                                        <Card.Img
                                            variant="top"
                                            src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/Microsoft+Singapore+GreenTech+Challenge+for+Startups+5.png"
                                        />
                                        <Card.Body>
                                            <Card.Text>
                                                YSI SEA 2020 Virtual Demo Day
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body className="my-5">
                        <h1>Join our Community Huddle</h1>
                        <img
                            src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/ms-sustain-posters-08.jpg"
                            alt="Community Huddle Poster"
                            className="poster my-4"
                        />
                        <Col md={{ span: 8, offset: 2 }}>
                            <div style={{ textAlign: 'left' }}>
                                <p>
                                    Join our Community Huddle on November 18
                                    featuring
                                </p>
                                <ul>
                                    <li>Tarun Shiroley from Microsoft</li>
                                    <li>Stanislav Borisov from EcoLabs</li>
                                    <li>
                                        and Rachel Fleishman from Carbonless
                                        Asia
                                    </li>
                                </ul>
                                <p>addressing SG Green Plan Challenges.</p>
                            </div>

                            <Row>
                                <Col lg={4}>
                                    <img
                                        src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/Creitive.png"
                                        alt="Creitive Logo"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <img
                                        src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/Carbonless.png"
                                        alt="Carbonless Logo"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <img
                                        src="https://interseedstorage.s3.ap-southeast-1.amazonaws.com/Microsoft.png"
                                        alt="Microsoft Logo"
                                    />
                                </Col>
                            </Row>
                            <h1>Supporting all founders from idea to exit!</h1>
                            <Link
                                to={{
                                    pathname:
                                        'https://www.eventbrite.com/e/addressing-sg-green-plan-challenges-tickets-205547256377'
                                }}
                                target="_blank"
                                className="btn btn-primary registerbutton mt-4"
                            >
                                Register Now
                            </Link>
                        </Col>
                    </Card.Body>
                </Card>
            </Style>
        </Container>
    );
};

const mapStateToProps = (state: StoreState) => ({
    auth: state.auth,
    userProfile: state.userProfile
});

export default connect(mapStateToProps)(withRouter(MicrosoftChallenge));
