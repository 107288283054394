import axios from 'axios';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Modal, Card, Container } from 'react-bootstrap';
import {
    Link,
    withRouter,
    useLocation,
    RouteComponentProps
} from 'react-router-dom';

interface State {
    userData?: string | undefined;
    init: string | undefined;
}

const CardContent = styled.div`
    padding: 2rem 8rem;

    @media screen and (max-width: 768px) {
        padding: 2rem;
    }
`;

export const EmailConfirmation: React.FC<RouteComponentProps<State>> = ({
    ...props
}) => {
    const initSecond = 20;
    const initMinute = 0;
    const location = useLocation();
    const state = location.state as State;
    let { init } = state;

    const [disableResend, setDisableResend] = useState(false);
    const [second, setSecond] = useState(initSecond);
    const [minute, setMinute] = useState(initMinute);
    const [show, setShow] = useState(false);

    const handleResend = () => {
        if (disableResend) return;
        setDisableResend(true);
        axios
            .post(`/api/users/resend-email`, state.userData)
            .then(() => {
                setDisableResend(true);
                setShow(true);
            })
            .catch(() => {
                setDisableResend(false);
                props.history.push('/error500');
            });
    };

    useEffect(() => {
        if (disableResend) {
            if (minute === 0 && second === 0) {
                setMinute(initMinute);
                setSecond(initSecond);
                setDisableResend(false);
            } else if (second > 0) {
                setTimeout(() => setSecond(second - 1), 1000);
            } else if (minute > 0 && second === 0) {
                setTimeout(() => {
                    setSecond(59);
                    setMinute(minute - 1);
                }, 1000);
            }
        }
    }, [second, minute, disableResend]);

    useEffect(() => {
        if (init === 'true') {
            setDisableResend(true);
            if (minute === 0 && second === 0) {
                setMinute(initMinute);
                setSecond(initSecond);
                setDisableResend(false);
                init = 'false';
            } else if (second > 0) {
                setTimeout(() => setSecond(second - 1), 1000);
            } else if (minute > 0 && second === 0) {
                setTimeout(() => {
                    setSecond(59);
                    setMinute(minute - 1);
                }, 1000);
            }
        }
    }, [init]);

    const handleClose = () => {
        setShow(false);
    };

    return (
        <Container>
            <Card className="mb-4">
                <CardContent>
                    <Modal show={show} onHide={handleClose} centered size="lg">
                        <Modal.Header closeButton>Success!</Modal.Header>
                        <Modal.Body>
                            An email has been sent to your inbox
                            <br />
                            Click the link in the email to verify your account!
                            <br />
                            <br />
                            <i>
                                Note: Due to the high traffic, it might take up
                                to 5 minutes for the email to be sent
                            </i>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <h1>Almost there!</h1>
                    <p style={{ marginBottom: 0 }}>
                        An email has been sent to your inbox
                        <br />
                        Click the link in the email to verify your account!{' '}
                        <br /> <br />
                        Didn&apos;t receive the email?
                    </p>
                    <Button onClick={handleResend} disabled={disableResend}>
                        Resend email
                    </Button>
                    <br />
                    <p />
                    {disableResend ? (
                        <p className="text-muted">
                            Resend email in{' '}
                            {minute > 0 ? `${minute} minutes ` : ''}
                            {second} seconds
                        </p>
                    ) : null}
                    <p style={{ marginBottom: 0 }}>
                        <i>
                            Note: Due to the high traffic, it might take up to 5
                            minutes for the email to be sent. <br />
                            Alternatively, you can sign up using Google or
                            LinkedIn account
                        </i>
                    </p>
                    <Button as={Link} to="/register" variant="link">
                        Back to Sign Up Page
                    </Button>
                </CardContent>
            </Card>
        </Container>
    );
};

export default withRouter(EmailConfirmation);
