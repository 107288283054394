import React from 'react';

const LocationMarkerIcon: React.FC = () => (
    <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5 0.25C2.6565 0.25 0.75 2.1565 0.75 4.5C0.75 6.9915 3.0645 8.52048 4.596 9.53198L4.861 9.70801C4.903 9.73601 4.9515 9.75 4.9995 9.75C5.0475 9.75 5.096 9.73601 5.138 9.70801L5.403 9.53198C6.9345 8.52048 9.249 6.9915 9.249 4.5C9.25 2.1565 7.3435 0.25 5 0.25ZM5 5.75C4.3095 5.75 3.75 5.1905 3.75 4.5C3.75 3.8095 4.3095 3.25 5 3.25C5.6905 3.25 6.25 3.8095 6.25 4.5C6.25 5.1905 5.6905 5.75 5 5.75Z"
            fill="#667F97"
        />
    </svg>
);

export default LocationMarkerIcon;
